import React, { useState, useEffect, useRef, useCallback, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import httpClient from "../httpClient"
import { useSelector, useDispatch } from "react-redux"
import './SalesPipePage.css'
import Header from "../components/Header"
import MenuBar from "../components/MenuBar"
import ComingSoonOverlay from "../components/placeholders/ComingSoonOverlay"
import CustomerFiche from "../components/CustomerFiche"
import CircularProgress from '@mui/material/CircularProgress'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import LoadingSpinnerOverlay from "../components/placeholders/LoadingSpinnerOverlay"
import DropdownButton from "../components/DropdownButton"
import SalespipeStageTable from "../components/SalespipeStageTable"
import { exportToExcel } from "../components/helpers/Exports"
import { formatDateExportTitle } from "../components/helpers/FormatDate"
import { toast } from "react-toastify"
import CachedIcon from '@mui/icons-material/Cached'
import { AiOutlineCloudDownload } from "react-icons/ai"
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import { PopOutWindowSalesPipeTags } from "../components/PopOutWindowSalesPipeTags"
import DropDownFilter from "../components/DropDownFilter"
import { updateFilters, updateSalesPipe, updateSalesPipeCounts, resetStateSalesPipe, resetStateSalesPipePage } from "../store/salesPipePage"
import Slider from "../components/Slider"
import SearchBarFilter from "../components/SearchBarFilter"
import DateRangeFilter from "../components/DateRangeFilter"
import CheckBox from "../components/CheckBox"
import NotificationPopupSalesPipeMove from "../components/NotificationPopupSalesPipeMove"
import { deepEqual } from "../components/helpers/GlobalFunctions"

function SalesPipePage() {
  const [user, setUser] = useState({ email: "", id: "" })
  const [error, setError] = useState(false)
  const [fetchingData, setFetchingData] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingMore, setLoadingMore] = useState(false)
  const [stageBig, setStageBig] = useState(false)
  const [loadingExport, setLoadingExport] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const [selectedStage, setSelectedStage] = useState("")
  const [limit, setLimit] = useState(20)
  const [fullLists, setFullLists] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [tags, setTags] = useState([])
  const [allTags, setAllTags] = useState([])
  const [myBrandowners, setMyBrandowners] = useState([])
  const [moveToStage, setMoveToStage] = useState(null)
  const [dragDataState, setDragDataState] = useState(null)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const userInfo = useSelector(state => state.user.userInfo)
  const customerTypesArray = useSelector(state => state.user.customer_types)
  const salesPipe = useSelector(state => state.salesPipePage.salesPipe)
  const salesPipeCounts = useSelector(state => state.salesPipePage.salesPipeCounts)
  const myTeam = useSelector(state => state.user.myTeam)

  const prospectsPic = require("../assets/pictos/prospects.png")
  const ambassadorsPic = require("../assets/pictos/marketing.png")
  const winsPic = require("../assets/pictos/rockets.png")
  const visitPlannedPic = require("../assets/pictos/map.png")
  const undecidedPic = require("../assets/pictos/anonymous.png")
  const graveyardPic = require("../assets/pictos/decrease.png")
  const requester = userInfo.type
  const filters = useSelector(state => state.salesPipePage.filters)
  const needsRefresh = useSelector(state => state.salesPipePage.needsRefresh)
  const page = filters.page

  const previousFilters = useRef(filters)

  const fetchBrandowners = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/salesforce/get-my-brandowners")
    setMyBrandowners(response.data)
  }

  const fetchTags = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/sales-pipe/get-tags?stage=${selectedStage}`)
    setTags(response.data)
  }

  const fetchDataAndCounts = async (type = null, merge = false) => {
    try {
      setFetchingData(true)
  
      const stages = ['PROSPECTS', 'VISITED', 'UNDECIDED', 'CLIENTS', 'AMBASSADORS', 'LOST']
  
      // Prepare fetch promises for all stages
      const fetchPromises = stages.map((stage) => {
  
        const requestData = {
          filters,
          limit,
          stage,
        }
  
        return httpClient
          .post(`${process.env.REACT_APP_API_URL}/api/sales-pipe/get?type=${type}`, requestData)
          .then((response) => {
            const [stageData, stageCounts] = response.data
            return { stage, data: stageData, counts: stageCounts }
          })
          .catch((error) => {
            console.error(`Error fetching data for stage ${stage}:`, error)
            return { stage, data: [], counts: {} }
          })
      })
  
      // Fetch all data in parallel
      const results = await Promise.all(fetchPromises)
  
      // Initialize new state objects
      const newSalesPipe = merge ? { ...salesPipe } : {} // Decide whether to merge or replace
      let newSalesPipeCounts = { ...salesPipeCounts }
  
      // Process each stage's data
      results.forEach((result) => {
        const { stage, data, counts } = result
        const stageKey = stage.toLowerCase()
  
        // Update counts
        newSalesPipeCounts = { ...newSalesPipeCounts, ...counts }
  
        if (merge) {
          // Deduplicate and merge data
          const prevStageEntries = salesPipe[stageKey]?.data || []
          const dataMap = new Map()
  
          // Add existing entries
          prevStageEntries.forEach((entry) => {
            if (entry.id) {
              dataMap.set(entry.id, entry)
            }
          })
  
          // Add new entries
          data.forEach((entry) => {
            if (entry.id) {
              dataMap.set(entry.id, entry)
            }
          })
  
          // Update the state for the current stage with merged data
          newSalesPipe[stageKey] = {
            data: Array.from(dataMap.values()),
          }
        } else {
          // Replace the existing data with new filtered data
          newSalesPipe[stageKey] = {
            data: data,
          }
        }
      })
  
      // Dispatch updated states to Redux
      dispatch(updateSalesPipe(newSalesPipe))
      dispatch(updateSalesPipeCounts(newSalesPipeCounts))
    } catch (error) {
      console.error('Error fetching sales pipe data:', error)
    } finally {
      setFetchingData(false)
      setLoadingMore(false)
      setLoading(false)
    }
  }
  
  useEffect(() => {
    if (userInfo.type == "salesforce") {
      fetchBrandowners()
    }
    if (Object.keys(salesPipe).length === 0 || needsRefresh) {
      fetchDataAndCounts()
    }
  }, [])

  useEffect(() => {
    fetchTags()
  }, [selectedStage])

  const uniqueTagNames = useMemo(() => {
    let uniqueTagNames = []
  
    if (stageBig) {
      uniqueTagNames = [...new Set(salesPipe[selectedStage.toLowerCase()].data.flatMap(record => [
        record.tag1_id?.tag,
        record.tag2_id?.tag
      ].filter(Boolean)))] // This filters out null or undefined values
    } else {
      const allStagesData = Object.values(salesPipe).flat()
      if (Array.isArray(allStagesData)) {
        uniqueTagNames = [...new Set(allStagesData.flatMap(record => [
          record.tag1_id?.tag,
          record.tag2_id?.tag
        ].filter(Boolean)))]
      }
    }
  
    return uniqueTagNames
  }, [salesPipe, selectedStage, stageBig])

  useEffect(() => {
    const previousFiltersDict = previousFilters.current

    // Compare the current filters with the previous filters
    if (JSON.stringify(previousFiltersDict) !== JSON.stringify(filters)) {

      if (previousFiltersDict.page != filters.page) {
        // handle page changes
        setLoadingMore(true)

        fetchDataAndCounts("", true)
        .catch(err => {
          console.log(err)
        })
      } else {
        // handle filter changes
        setLoading(true)

        fetchDataAndCounts()
        .catch(err => {
          console.log(err)
        })
      }

      // Update previous filters to current filters
      previousFilters.current = filters
    }
  }, [filters])

  const handleOnClickStage = (event) => {
    setStageBig(!stageBig)
    const thisStageElement = event.currentTarget
    const parentElement = event.currentTarget.parentElement
    const allElements = Array.from(document.getElementsByClassName("shark-list__title"))
    const filteredElements = allElements.filter(element => element !== thisStageElement)

    parentElement.classList.toggle("big")

    filteredElements.forEach(element => {
      element.parentElement.classList.toggle("hidden")
    })

    setSelectedStage(thisStageElement.getAttribute("data-stage"))
    setSelectedRows([])
  }

  const handleExportStageList = () => {
    setLoadingExport(true)

    const now = Date.now()
    const fileName = `STOQUP_export_salespipe_${selectedStage}_${formatDateExportTitle(now)}`
    const exportData = (
      salesPipe[selectedStage.toLowerCase()]
        .map(customer => {
          return (
            {
              "time stamp": customer.time_stamp,
              "Owner": `${customer.owner_id?.first_name} ${customer.owner_id?.last_name}`,
              "Stage": customer.stage,
              "Business name": customer.customer_id.business_name,
              "City": customer.customer_id.delivery_address_place,
              "Contact name": `${customer.customer_id.first_name} ${customer.customer_id.last_name}`,
              "Tel": customer.customer_id.tel_number,
              "Email": customer.customer_id.email,
              "type": customer.customer_id.type,
              "sub_type": customer.customer_id.sub_type,
              "tag1": customer.tag1_id?.tag,
              "tag2": customer.tag2_id?.tag,
            }
          )
        })
    )

    exportToExcel(exportData, fileName)

    setLoadingExport(false)
  }

  const changeOwners = async (salesforce_id) => {

    let data = {
      "salesforceID": salesforce_id,
      "customerIDs": []
    }

    selectedRows.forEach(row => {
      const customerID = row.getAttribute("data-customer-id")

      data["customerIDs"].push(customerID)
    })

    const response = await toast.promise(
      httpClient.post(process.env.REACT_APP_API_URL + "/api/sales-pipe/owner/update?requester=" + requester, data),
      {
        pending: "Changing owner(s)...",
        success: `Done!`,
        error: "Something went wrong"
      }
    )
    console.log(response.data)

    setSelectedRows([])
    fetchDataAndCounts()
  }

  const addToSalesPipe = async (value, okToMove = false) => {

    let data = {
      "type": value,
      "salesPipeIDs": []
    }

    // action when the function comes from selectedrows
    if (selectedRows.length != 0 | selectedRows == undefined) {

      selectedRows.forEach(row => {
        const salesPipeID = row.getAttribute("data-sales-pipe-id")

        data["salesPipeIDs"].push(salesPipeID)
      })

    } else { // action when the function comes from dragging 1 customer
      data["salesPipeIDs"] = [dragDataState.id]
    }

    if (userInfo.type == "salesforce") {
      data["brandowners"] = myBrandowners
    }

    const response = await toast.promise(
      httpClient.post(process.env.REACT_APP_API_URL + "/api/sales-pipe/add?requester=" + requester, data),
      {
        pending: "Adding to SALES PIPEs...",
        success: `Done!`,
        error: "Something went wrong"
      }
    )

    setSelectedRows([])
    fetchBrandowners()
    fetchDataAndCounts()
  }

  const onClickCustomerRow = (event) => {
    const customerID = event.currentTarget.getAttribute('data-user-id')
    const link = `/customer/${customerID}`
    navigate(link)
  }

  const handleOnClickLoadMore = (event) => {
    dispatch(updateFilters({ ...filters, page: filters.page + 1 }))
  }

  const handleLoadFullList = async () => {
    setFullLists(true)
    setLoading(true)

    fetchDataAndCounts("full", false)
  }

  const handleDragOver = (e) => {
    e.preventDefault()
    const dropTargetElement = e.currentTarget
    dropTargetElement.classList.add("dragover")
  }

  const handleDragLeave = (e) => {
    const dropTargetElement = e.currentTarget;
    dropTargetElement.classList.remove("dragover")
  }

  const handleDrop = async (e, stage) => {
    e.preventDefault()

    const dragData = JSON.parse(e.dataTransfer.getData('application/json'))
    const dropTargetElement = e.currentTarget

    if (stage == dragData.stage) {
      dropTargetElement.classList.remove("dragover")
      return
    }

    let data = {
      "type": stage,
      "salesPipeIDs": [dragData.id]
    }

    if (userInfo.type == "salesforce") {
      data["brandowners"] = myBrandowners
    }

    const response = await httpClient.post(process.env.REACT_APP_API_URL + `/api/sales-pipe/add?requester=${requester}`, data)
    console.log(response.data)

    dropTargetElement.classList.remove("dragover")
    fetchDataAndCounts()  
  }

  const handleClickSetCustomerTag = async () => {
    const addTagPopup = document.querySelector("#add-tag-popup")

    addTagPopup.classList.add("active")
  }

  const handleOnClickFilterItem = (event) => {

    const filter = event.currentTarget.textContent
    event.target.parentElement.previousSibling.firstChild.textContent = filter
    event.target.parentElement.classList.toggle("active")

    const updatedFiltersObj = { ...filters }

    switch (event.target.parentElement.id) {
      case "salespipe__dropdownList--tag":
        updatedFiltersObj["tagFilter"] = filter
        break
      case "salespipe__dropdownList--type":
        updatedFiltersObj["typeFilter"] = filter
        break
      case "salespipe__dropdownList--sub-type":
        updatedFiltersObj["subTypeFilter"] = filter
        break
    }

    dispatch(updateFilters(updatedFiltersObj))
  }

  const handleHideOwnerless = () => {
    const updatedFilters = { ...filters }
    updatedFilters["hideOwnerless"] = !filters.hideOwnerless

    dispatch(updateFilters(updatedFilters))
    fetchDataAndCounts()  
  }

  return (
    <div className="template">
      {/* {error ? window.location.href = "/" : null} */}

      <Header>
        <div className={`header__options`}>
          {
            stageBig ?
              <>
                <button className="options-button-header" onClick={handleExportStageList}>{loadingExport ? <LoadingSpinnerOverlay /> : <>Export <ExitToAppIcon /></>}</button>
              </> :
              <>
                <button className="options-button-header" onClick={handleLoadFullList}>Load full lists <AiOutlineCloudDownload /></button>
              </>
          }
          <div className="header__option--slider"><Slider size={1.2} text="Hide no owner" onClickFunction={handleHideOwnerless} state={filters.hideOwnerless} /></div>
        </div>

        <div className={`header__options--right-template ${(selectedRows.length == 0) ? "" : "hide"}`} id="header__options--sales-pipe--">
          <SearchBarFilter
            id="sales_pipe__search-bar--table"
            filters={filters}
            updateFilters={updateFilters}
            placeholder="Search customer..."
          />
          <DropDownFilter
            idList="salespipe__dropdownList--tag"
            placeholderText="Filter on tag"
            listArray={uniqueTagNames}
            onClickItem={handleOnClickFilterItem}
            value={filters.tagFilter}
            filter={"tagFilter"}
            setValue={updateFilters}
            dropdownType={"salesPipePage"}
            widthSize={"medium"}
          />
          <DropDownFilter
            idList="salespipe__dropdownList--type"
            placeholderText="Filter on type"
            listArray={Object.keys(customerTypesArray).filter(Boolean)}
            onClickItem={handleOnClickFilterItem}
            value={filters.typeFilter}
            filter={"typeFilter"}
            setValue={updateFilters}
            dropdownType={"salesPipePage"}
            widthSize={"medium"}
          />
          <DropDownFilter
            idList="salespipe__dropdownList--sub-type"
            placeholderText="Filter on sub type"
            listArray={Object.values(customerTypesArray).filter(type => type != "N/A").flat()}
            onClickItem={handleOnClickFilterItem}
            value={filters.subTypeFilter}
            filter={"subTypeFilter"}
            setValue={updateFilters}
            dropdownType={"salesPipePage"}
            widthSize={"medium"}
          />
          <DateRangeFilter
            filters={filters}
            date={filters.dateFilter}
            updateFilters={updateFilters}
          />
        </div>
        <div className={`header__options--right-template ${selectedRows.length == 0 ? "hide" : ""}`} id="header__options--sales-pipe--secondairy">
          {
            userInfo.type == "brandowner" | userInfo.type == "wholesaler" ?
              <>
                <DropdownButton
                  id={"salespipe-general-1"}
                  title="Assign/remove owner"
                  valuesArray={myTeam}
                  onClickFn={changeOwners}
                />
              </> : null
          }
          <DropdownButton
            id={"salespipe-general-2"}
            title="Move to SALES PIPE"
            value={moveToStage}
            setValue={setMoveToStage}
            valuesArray={["Prospects", "Visited", "Undecided", "Clients", "Lost", "Ambassadors"]}
            onClickFn={addToSalesPipe}
          />
          <button className="options-button-header" onClick={handleClickSetCustomerTag}>Set custom tag <LocalOfferIcon /></button>
        </div>

      </Header>

      <MenuBar />

      <div className="template__body">

        <PopOutWindowSalesPipeTags popupID={"add-tag-popup"}
          tags={tags}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          selectedStage={selectedStage}
          refresh={refresh}
          setRefresh={setRefresh}
          refreshData={fetchDataAndCounts}
        />

        <div className="body__sales-pipe">
          <div className="body__section">
            <div className="shark-list__title" data-stage="PROSPECTS" onClick={handleOnClickStage}>
              Prospects
              <div className="header_item--number">
                {
                  !salesPipeCounts?.PROSPECTS ?
                    0 :
                    salesPipeCounts.PROSPECTS
                }
              </div>
              <img src={prospectsPic} height={"100%"} />
            </div>
            <div
              className="shark-list__section"
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(e, 'PROSPECTS')}
              onDragLeave={handleDragLeave}
            >
              {
                loading ?

                  <div className="loading_spinner_circle"><CircularProgress color="inherit" size="2rem" /></div> :

                  !stageBig ? (
                    <div className="overflow-scroll no-scrollbar">
                      {
                        salesPipe?.prospects?.data.map((customer, index) => (
                          <CustomerFiche
                            key={index}
                            businessName={customer.customer_id.business_name}
                            owner={`${customer.owner_id != null ? customer.owner_id.first_name : ""}`}
                            tag1={customer.tag1_id}
                            tag2={customer.tag2_id}
                            index={index}
                            id={customer.id}
                            stage="PROSPECTS"
                            customerID={customer.customer_id_of_user}
                            userID={customer.user_id_of_customer}
                            onClickFiche={onClickCustomerRow}
                          />
                        ))
                      }
                      {
                        salesPipe?.prospects?.data.length != 0 && (salesPipeCounts.PROSPECTS > (page * limit)) && !fullLists &&
                        <button className="load_more_button" data-stage="PROSPECTS" onClick={handleOnClickLoadMore}>{loadingMore ? <CircularProgress color="inherit" size="1rem" /> : "Load more..."}</button>
                      }
                    </div>
                  ) :

                    <SalespipeStageTable
                      stage="PROSPECTS"
                      selectedRows={selectedRows}
                      setSelectedRows={setSelectedRows}
                      salesPipe={salesPipe?.prospects?.data || []}
                      id="prospects"
                      onClickRow={onClickCustomerRow}
                      handleLoadMore={handleOnClickLoadMore}
                      loadingMore={loadingMore}
                      salesPipeCounts={salesPipeCounts.PROSPECTS}
                      page={page}
                      limit={limit}
                      fullLists={fullLists}
                      filters={filters}
                    />

              }
            </div>
          </div>
          <div className="body__section">
            <div className="shark-list__title" data-stage="VISITED" onClick={handleOnClickStage}>
              Visited
              <div className="header_item--number">
                {
                  !salesPipeCounts?.VISITED ?
                    0 :
                    salesPipeCounts.VISITED
                }
              </div>
              <img src={visitPlannedPic} height={"100%"} />
            </div>
            <div
              className="shark-list__section"
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(e, 'VISITED')}
              onDragLeave={handleDragLeave}
            >
              {
                loading ?

                  <div className="loading_spinner_circle"><CircularProgress color="inherit" size="2rem" /></div> :

                  !stageBig ? (
                    <div className="overflow-scroll no-scrollbar">
                      {
                        salesPipe?.visited?.data.map((customer, index) => (
                          <CustomerFiche
                            key={index}
                            businessName={customer.customer_id.business_name}
                            owner={`${customer.owner_id != null ? customer.owner_id.first_name : ""}`}
                            tag1={customer.tag1_id}
                            tag2={customer.tag2_id}
                            index={index}
                            id={customer.id}
                            stage="VISITED"
                            customerID={customer.customer_id_of_user}
                            userID={customer.user_id_of_customer}
                            onClickFiche={onClickCustomerRow}
                          />
                        ))
                      }
                      {
                        salesPipe?.visited?.data.length != 0 && (salesPipeCounts.VISITED > (page * limit)) && !fullLists &&
                        <button className="load_more_button" data-stage="VISITED" onClick={handleOnClickLoadMore}>{loadingMore ? <CircularProgress color="inherit" size="1rem" /> : "Load more..."}</button>
                      }
                    </div>
                  ) :

                    <SalespipeStageTable
                      stage="VISITED"
                      selectedRows={selectedRows}
                      setSelectedRows={setSelectedRows}
                      salesPipe={salesPipe?.visited?.data || []}
                      id="visited"
                      onClickRow={onClickCustomerRow}
                      handleLoadMore={handleOnClickLoadMore}
                      loadingMore={loadingMore}
                      salesPipeCounts={salesPipeCounts.VISITED}
                      page={page}
                      limit={limit}
                      fullLists={fullLists}
                      filters={filters}
                    />

              }
            </div>
          </div>
          <div className="body__section">
            <div className="shark-list__title" data-stage="UNDECIDED" onClick={handleOnClickStage}>
              Undecided
              <div className="header_item--number">
                {
                  !salesPipeCounts?.UNDECIDED ?
                    0 :
                    salesPipeCounts.UNDECIDED
                }
              </div>
              <img src={undecidedPic} height={"100%"} />
            </div>
            <div
              className="shark-list__section"
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(e, 'UNDECIDED')}
              onDragLeave={handleDragLeave}
            >
              {
                loading ?

                  <div className="loading_spinner_circle"><CircularProgress color="inherit" size="2rem" /></div> :

                  !stageBig ? (
                    <div className="overflow-scroll no-scrollbar">
                      {
                        salesPipe?.undecided?.data.map((customer, index) => (
                          <CustomerFiche
                            key={index}
                            businessName={customer.customer_id.business_name}
                            owner={`${customer.owner_id != null ? customer.owner_id.first_name : ""}`}
                            tag1={customer.tag1_id}
                            tag2={customer.tag2_id}
                            index={index}
                            id={customer.id}
                            stage="UNDECIDED"
                            customerID={customer.customer_id_of_user}
                            userID={customer.user_id_of_customer}
                            onClickFiche={onClickCustomerRow}
                          />
                        ))
                      }
                      {
                        salesPipe?.undecided?.data.length != 0 && (salesPipeCounts.UNDECIDED > (page * limit)) && !fullLists &&
                        <button className="load_more_button" data-stage="UNDECIDED" onClick={handleOnClickLoadMore}>{loadingMore ? <CircularProgress color="inherit" size="1rem" /> : "Load more..."}</button>
                      }
                    </div>
                  ) :
                    <SalespipeStageTable
                      stage="UNDECIDED"
                      selectedRows={selectedRows}
                      setSelectedRows={setSelectedRows}
                      salesPipe={salesPipe?.undecided?.data || []}
                      id="undecided"
                      onClickRow={onClickCustomerRow}
                      handleLoadMore={handleOnClickLoadMore}
                      loadingMore={loadingMore}
                      salesPipeCounts={salesPipeCounts.UNDECIDED}
                      page={page}
                      limit={limit}
                      fullLists={fullLists}
                      filters={filters}
                    />

              }
            </div>
          </div>
          <div className="body__section">
            <div className="shark-list__title" data-stage="CLIENTS" onClick={handleOnClickStage}>
              Clients
              <div className="header_item--number">
                {
                  !salesPipeCounts?.CLIENTS ?
                    0 :
                    salesPipeCounts.CLIENTS
                }
              </div>
              <img src={winsPic} height={"100%"} />
            </div>
            <div
              className="shark-list__section"
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(e, 'CLIENTS')}
              onDragLeave={handleDragLeave}
            >
              {
                loading ?

                  <div className="loading_spinner_circle"><CircularProgress color="inherit" size="2rem" /></div> :

                  !stageBig ? (
                    <div className="overflow-scroll no-scrollbar">
                      {
                        salesPipe?.clients?.data.map((customer, index) => (
                          <CustomerFiche
                            key={index}
                            businessName={customer.customer_id.business_name}
                            owner={`${customer.owner_id != null ? customer.owner_id.first_name : ""}`}
                            tag1={customer.tag1_id}
                            tag2={customer.tag2_id}
                            index={index}
                            id={customer.id}
                            stage="CLIENTS"
                            customerID={customer.customer_id_of_user}
                            userID={customer.user_id_of_customer}
                            onClickFiche={onClickCustomerRow}
                          />
                        ))
                      }
                      {
                        salesPipe?.clients?.data.length != 0 && (salesPipeCounts.CLIENTS > (page * limit)) && !fullLists &&
                        <button className="load_more_button" data-stage="CLIENTS" onClick={handleOnClickLoadMore}>{loadingMore ? <CircularProgress color="inherit" size="1rem" /> : "Load more..."}</button>
                      }
                    </div>
                  ) :
                    <SalespipeStageTable
                      stage="CLIENTS"
                      selectedRows={selectedRows}
                      setSelectedRows={setSelectedRows}
                      salesPipe={salesPipe?.clients?.data || []}
                      id="clients"
                      onClickRow={onClickCustomerRow}
                      handleLoadMore={handleOnClickLoadMore}
                      loadingMore={loadingMore}
                      salesPipeCounts={salesPipeCounts.CLIENTS}
                      page={page}
                      limit={limit}
                      fullLists={fullLists}
                      filters={filters}
                    />

              }
            </div>
          </div>
          <div className="body__section">
            <div className="shark-list__title" data-stage="AMBASSADORS" onClick={handleOnClickStage}>
              Ambassadors
              <div className="header_item--number">
                {
                  !salesPipeCounts?.AMBASSADORS ?
                    0 :
                    salesPipeCounts.AMBASSADORS
                }
              </div>
              <img src={ambassadorsPic} height={"100%"} />
            </div>
            <div
              className="shark-list__section"
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(e, 'AMBASSADORS')}
              onDragLeave={handleDragLeave}
            >
              {
                loading ?

                  <div className="loading_spinner_circle"><CircularProgress color="inherit" size="2rem" /></div> :

                  !stageBig ? (
                    <div className="overflow-scroll no-scrollbar">
                      {
                        salesPipe?.ambassadors?.data.map((customer, index) => (
                          <CustomerFiche
                            key={index}
                            businessName={customer.customer_id.business_name}
                            owner={`${customer.owner_id != null ? customer.owner_id.first_name : ""}`}
                            tag1={customer.tag1_id}
                            tag2={customer.tag2_id}
                            index={index}
                            id={customer.id}
                            stage="AMBASSADORS"
                            customerID={customer.customer_id_of_user}
                            userID={customer.user_id_of_customer}
                            onClickFiche={onClickCustomerRow}
                          />
                        ))
                      }
                      {
                        salesPipe?.ambassadors?.data.length != 0 && (salesPipeCounts.AMBASSADORS > (page * limit)) && !fullLists &&
                        <button className="load_more_button" data-stage="AMBASSADORS" onClick={handleOnClickLoadMore}>{loadingMore ? <CircularProgress color="inherit" size="1rem" /> : "Load more..."}</button>
                      }
                    </div>
                  ) :

                    <SalespipeStageTable
                      stage="AMBASSADORS"
                      selectedRows={selectedRows}
                      setSelectedRows={setSelectedRows}
                      salesPipe={salesPipe?.ambassadors?.data || []}
                      id="ambassadors"
                      onClickRow={onClickCustomerRow}
                      handleLoadMore={handleOnClickLoadMore}
                      loadingMore={loadingMore}
                      salesPipeCounts={salesPipeCounts.AMBASSADORS}
                      page={page}
                      limit={limit}
                      fullLists={fullLists}
                      filters={filters}
                    />

              }
            </div>
          </div>
          <div className="body__section" id="sales-pipe-graveyard">
            <div
              className="shark-list__title graveyard"
              data-stage="LOST"
              onClick={handleOnClickStage}
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(e, 'LOST')}
              onDragLeave={handleDragLeave}
            >
              Lost<img src={graveyardPic} height={"100%"} />
            </div>
            <div className={`shark-list__section ${stageBig ? "" : "hide"}`}>

              {
                loading ?

                  <div className="loading_spinner_circle"><CircularProgress color="inherit" size="2rem" /></div> :
                  <SalespipeStageTable
                    stage="LOST"
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                    salesPipe={salesPipe?.lost?.data || []}
                    id="lost"
                    onClickRow={onClickCustomerRow}
                    handleLoadMore={handleOnClickLoadMore}
                    loadingMore={loadingMore}
                    salesPipeCounts={salesPipeCounts.LOST}
                    page={page}
                    limit={limit}
                    fullLists={fullLists}
                    filters={filters}
                  />
              }

            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

export default SalesPipePage