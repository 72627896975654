import axios from "axios"

const httpClient = axios.create({
    withCredentials: true,
})

httpClient.interceptors.response.use(
    response => response,
    error => {
        if (error.response && (
            error.response.status === 401 ||
            error.response.status === 402 ||
            error.response.status === 403 ||
            error.response.status === 400 ||
            error.response.status === 404 ||
            error.response.status === 405 ||
            error.response.status === 406 ||
            error.response.status === 407 ||
            error.response.status === 408 ||
            error.response.status === 409 ||
            error.response.status === 410
          )) {
            return Promise.resolve(error.response)
        }
        // For other errors, continue rejecting the promise
        return Promise.reject(error)
    }
);
export default httpClient