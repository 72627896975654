import httpClient from '../../httpClient'
import { urlBase64ToUint8Array } from './GlobalFunctions'

const publicVapidKey = process.env.REACT_APP_VAPID_PUBLIC_KEY

export const askPermissionAndSubscribe = async (userId) => {
  try {
    if ('serviceWorker' in navigator && 'Notification' in window) {
      const permission = await Notification.requestPermission()
      if (permission === 'granted') {
        console.log('Notification permission granted.')

        const registration = await navigator.serviceWorker.ready

        // Check if subscription already exists
        const existingSubscription = await registration.pushManager.getSubscription()
        if (!existingSubscription) {
          // Subscribe the user
          const subscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array(publicVapidKey)
          })
          console.log('User is subscribed:', subscription)

          // Send subscription to your server
          await httpClient.post(`${process.env.REACT_APP_API_URL}/api/subscribe`, {
            user_id: userId,
            ...subscription.toJSON()
          }, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
        } else {
          console.log('User is already subscribed.')
        }
      } else {
        console.log('Notification permission denied.')
      }
    }
  } catch (error) {
    console.log('Service Worker registration or subscription failed:', error)
  }
}
