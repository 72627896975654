import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux"
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'
import "./SearchBarList.css"

function SearchBarList({ id, filters, updateFilters, placeholder, stateType, stateKey, style }) {
	const [searchWord, setSearchWord] = useState(filters.searchWord)
	const [debouncedSearchWord, setDebouncedSearchWord] = useState(searchWord)

	const dispatch = useDispatch()

	useEffect(() => {
		setSearchWord(filters[stateKey])
	}, [filters])

	// Debouncing logic
	useEffect(() => {
		const timerId = setTimeout(() => {
			setDebouncedSearchWord(searchWord)
		}, 750) // 750ms delay

		return () => {
			clearTimeout(timerId)
		}
	}, [searchWord])

	// Set redux state after debouncing
	useEffect(() => {

		if (stateType == 'redux') {
			const updatedFilters = { ...filters }
			updatedFilters[stateKey] = debouncedSearchWord
			dispatch(updateFilters(updatedFilters))
		} else {
			const updatedFilters = { ...filters }
			updatedFilters[stateKey] = debouncedSearchWord
			updateFilters(updatedFilters)
		}

	}, [debouncedSearchWord])

	const handleSearchWord = (event) => {
		setSearchWord(event.target.value)
	}

	const handleClearSearchBar = () => {

		if (filters[stateKey] == "") {
			return
		}

		setSearchWord("")

		const updatedFilters = { ...filters }
		updatedFilters[stateKey] = ""

		if (stateType == 'redux') {
			dispatch(updateFilters(updatedFilters))
		} else {
			updateFilters(updatedFilters)
		}
	}

	return (
		<div className={`search_bar--in-list ${style}`} id={id}>
			<input placeholder={placeholder} value={searchWord} onChange={handleSearchWord} />
			{!filters[stateKey] ? <SearchIcon /> : <ClearIcon onClick={handleClearSearchBar} />}
		</div>
	)
}

export default SearchBarList

SearchBarList.defaultProps = {
	placeholder: "Search...",
	stateKey: "searchWord",
	style: ""
}
