import React from "react";

function Meeting() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66 66">
      <path d="M35 63.5h-1V38.8h10.6c.6 0 1-.4 1-1s-.4-1-1-1H21.4c-.5 0-1 .4-1 1s.5 1 1 1H32v24.7h-1c-.5 0-1 .5-1 1 0 .6.5 1 1 1h4c.5 0 1-.4 1-1s-.5-1-1-1zM58 21.2c3.6 0 6.5-2.9 6.5-6.5S61.6 8.2 58 8.2s-6.5 2.9-6.5 6.5 2.9 6.5 6.5 6.5z"></path>
      <path d="M62.2 24.2H46l-3.5-3.5c-1.3-1.4-3.5-1.3-4.9 0s-1.4 3.6 0 4.9l4.6 4.6c.7.6 1.5 1 2.5 1H50V44h-9.1c-1.8 0-3.3 1.5-3.3 3.3v17.2c0 .6.5 1 1 1h6.8c.6 0 1-.4 1-1V52.8h15.9c1.8 0 3.3-1.5 3.3-3.3V27.4a3.4 3.4 0 00-3.4-3.2zM25.2 44H16V27.4c0-1.8-1.5-3.3-3.3-3.3h-9c-1.8 0-3.3 1.5-3.3 3.3v22.1c0 1.8 1.5 3.3 3.3 3.3h15.9v11.7c0 .6.4 1 1 1h6.8c.6 0 1-.4 1-1V47.3c.1-1.8-1.4-3.3-3.2-3.3zM8 21.2c3.6 0 6.5-2.9 6.5-6.5S11.6 8.2 8 8.2s-6.5 2.9-6.5 6.5c.1 3.6 3 6.5 6.5 6.5z"></path>
      <path d="M61.5 63.5h-3.2v-8.7h-2v8.7h-3.2c-.5 0-1 .5-1 1 0 .6.5 1 1 1h8.4c.5 0 1-.4 1-1s-.4-1-1-1zM13 63.5H9.8v-8.7h-2v8.7H4.6c-.6 0-1 .5-1 1 0 .6.4 1 1 1H13c.6 0 1-.4 1-1s-.4-1-1-1zM20.1 17.2h2.6v5.3c0 .8 1 1.3 1.6.8l7.4-6.1h13.9c.5 0 1-.4 1-1V1.5c0-.6-.5-1-1-1H20.1c-.6 0-1 .4-1 1v14.7c0 .6.4 1 1 1zm5.1-11.7h15.2c.6 0 1 .4 1 1s-.4 1-1 1H25.2c-.6 0-1-.4-1-1s.5-1 1-1zm0 4.8h15.2c.6 0 1 .4 1 1s-.4 1-1 1H25.2c-.6 0-1-.4-1-1 0-.5.5-1 1-1z"></path>
    </svg>
  );
}

export default Meeting;