import React, { useState, useEffect, useRef } from "react"
import httpClient from "../httpClient"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import './InventoryPageSalesforce.css'
import Header from "../components/Header"
import MenuBar from "../components/MenuBar"
import { NotificationTop, showNotification } from "../components/NotificationTop"
import { toast } from "react-toastify"
import ImportExportIcon from '@mui/icons-material/ImportExport'
import { PopOutWindowInventoryChange } from "../components/PopOutWindowInventoryChange"
import Forklift from "../assets/pictos/svgs/Forklift"
import { PopupWindow } from "../components/PopupWindow"
import LoadingSpinner from "../components/placeholders/LoadingSpinner"
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import CircularProgress from '@mui/material/CircularProgress'
import SearchBarFilter from "../components/SearchBarFilter"
import Slider from "../components/Slider"

function InventoryPageSalesforce() {
  const [user, setUser] = useState({ email: "", id: "" })
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)
  const [productsAndMaterials, setProductsAndMaterials] = useState([])
  const [inventory, setInventory] = useState([])
  const [createInbound, setCreateInbound] = useState(false)
  const [createInboundSelectedProducts, setCreateInboundSelectedProducts] = useState([])
  const [createInboundComment, setCreateInboundComment] = useState("")
  const [addProductCounter, setAddProductCounter] = useState(1)
  const [selectedWarehouses, setSelectedWarehouses] = useState({ "from": { "id": null, "name": null }, "to": { "id": null, "name": null } })
  const [inventoryChangeReason, setInventoryChangeReason] = useState("Other")
  const [selectedWarehouse, setSelectedWarehouse] = useState()
  const [warehouseDetailIsActive, setWarehouseDetailIsActive] = useState(false)
  const [inventoryFilters, setInventoryFilters] = useState({
    "limit": 25,
    "page": 1,
    "searchWord": "",
    "hideEmpty": false
  })

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const stoqupWarehouse = require("../assets/pictos/warehouse_picto_stoqup.png")
  const otherWarehousePink = require("../assets/pictos/warehouse_picto_other_pink.png")

  const userInfo = useSelector(state => state.user.userInfo)
  const requester = userInfo.type

  const fetchUser = async () => {
    const responseUser = await httpClient.get(process.env.REACT_APP_API_URL + "/api/@me?type=full")
    setUser(responseUser.data)
  }

  const fetchProducts = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/brandowner/products/get-products")

    const newProductsAndMaterialsArray = response.data.map(({ id, name, sku, volume }) => {
      return (
        {
          id,
          sku,
          name,
          volume,
        }
      )
    })
    
    setProductsAndMaterials(newProductsAndMaterialsArray)
  }

  const fetchInventory = async () => {
    const responseInventory = await httpClient.post(process.env.REACT_APP_API_URL + "/api/brandowner/inventory/get-inventory", inventoryFilters)

    setInventory(responseInventory.data.inventory)

    if (responseInventory.data.inventory.length == 0) {
      setLoading(false)
      return
    }

    // Check if there's a selected warehouse
    if (selectedWarehouse) {
      const filteredInventory = responseInventory.data.inventory.find(item => item.warehouse_id.id === selectedWarehouse.warehouse_id.id)

      // Update the inventory state to only include items from the selected warehouse
      setSelectedWarehouse(filteredInventory)
    }

    setLoading(false)
  }

  const warehouses = inventory.map(warehouse => {
    return {
      "id": warehouse.warehouse_id.id,
      "name": warehouse.warehouse_id.name,
    }
  })

  useEffect(() => {
    fetchUser()
      .catch(err => {
        console.log(err)
        setError(true)
      })

    fetchInventory()
      .catch(err => {
        console.log(err)
        setError(true)
      })

    fetchProducts()
      .catch(err => {
        console.log(err)
        setError(true)
      })

  }, [])

  useEffect(() => {
    fetchInventory()
  }, [inventoryFilters.page])

  useEffect(() => {
    setLoading(true)

    fetchInventory()
      .then(() => setLoading(false))

  }, [inventoryFilters.searchWord, inventoryFilters.hideEmpty])

  const handleSaveInbound = async (createInboundStoqupOrder = false) => {

    const data = {
      products: createInboundSelectedProducts,
      warehouses: selectedWarehouses,
      comment: createInboundComment,
      stoqupOrderToCreate: createInboundStoqupOrder,
      reason: inventoryChangeReason,
    }

    // create inbound to do if target warehouse is STOQUP
    if (selectedWarehouses.to.name == "STOQUP") {
      const toDoData = {
        type: "inbound",
        importance: 2,
        todoJSON: {
          brandownerID: user.id,
          products: createInboundSelectedProducts,
        },
        comment: createInboundComment,
      }

      const response = await httpClient.post(process.env.REACT_APP_API_URL + "/api/todo/create", toDoData)
    }

    const response = await httpClient.post(process.env.REACT_APP_API_URL + "/api/brandowner/inventory/change", data)
    console.log(response.data)

    if (response.status === 200) {
      fetchInventory()
      setCreateInbound(false)

      setCreateInboundSelectedProducts([])
      setSelectedWarehouses({ "from": { "id": null, "name": null }, "to": { "id": null, "name": null } })
      setCreateInboundComment("")
      setInventoryChangeReason("other")
      document.querySelector("#popout__inventory").classList.remove("active")

      toast.success("Changes succesfully registered")
    }

  }

  const handleOnmouseOverPicto = (event) => {
    const details = event.currentTarget.childNodes[1]
    details.classList.remove("faded-away")
  }

  const handleOnmouseOfPicto = (event) => {
    const details = event.currentTarget.childNodes[1]
    details.classList.add("faded-away")
  }

  const handleCreateInbound = () => {
    const popup = document.getElementById("popout__inventory")

    const newStateWarehouses = { ...selectedWarehouses }
    newStateWarehouses['to'] = { "id": null, "name": null }
    newStateWarehouses['from'] = { "id": null, "name": null }
    setSelectedWarehouses(newStateWarehouses)

    popup.classList.add("active")
  }

  const handleSelectWarehouse = (index) => {
    setWarehouseDetailIsActive(true)
    setSelectedWarehouse(inventory[index])
  }

  const handleCreateInboundFromDetail = (type) => {
    const popup = document.getElementById(`popout__inventory--from-detail`)
    popup.classList.add("active")

    const newStateWarehouses = { ...selectedWarehouses }
    if (type == 'from') {
      newStateWarehouses['from'] = { "id": selectedWarehouse.warehouse_id.id, "name": selectedWarehouse.warehouse_id.name }
      newStateWarehouses['to'] = { "id": null, "name": null }
    } else {
      newStateWarehouses['to'] = { "id": selectedWarehouse.warehouse_id.id, "name": selectedWarehouse.warehouse_id.name }
      newStateWarehouses['from'] = { "id": null, "name": null }
    }
    setSelectedWarehouses(newStateWarehouses)
  }

  const handleHideEmpty = () => {
    const updatedFilters = { ...inventoryFilters }
    updatedFilters['page'] = 1
    updatedFilters['hideEmpty'] = !updatedFilters['hideEmpty']
    setInventoryFilters(updatedFilters)
  }

  return (
    <div className="template">
      {/* {error ? window.location.href = "/" : null} */}

      <NotificationTop />

      <Header>
        {
          !warehouseDetailIsActive &&
          <div className="header__options" id="header__options">
            <button className="options-button-header" onClick={handleCreateInbound} ><ImportExportIcon />Create Stock change</button>
          </div>
        }

        <div className="header__options--right">
          {
            warehouseDetailIsActive &&
            <>
              <button className="green-action-button" onClick={() => handleCreateInboundFromDetail('to')} ><AddCircleOutlineIcon />Add to warehouse</button>
              <button className="red-action-button" onClick={() => handleCreateInboundFromDetail('from')} ><RemoveCircleOutlineIcon />Remove from warehouse</button>
              <div className="header__option--slider"><Slider size={1.2} text="Hide empty" onClickFunction={handleHideEmpty} state={inventoryFilters.hideEmpty} /></div>
              <SearchBarFilter
                id="searchbar-filter--inventory-salesforce"
                filters={inventoryFilters}
                updateFilters={setInventoryFilters}
                placeholder={'Search for products...'}
                stateType={'usestate-object'}
              />
            </>
          }
        </div>
      </Header>

      <MenuBar />

      <div className="template__body">
        <div className="body__products-page--salesforce" id="body-inventory-page--salesforce">
          <PopOutWindowInventoryChange
            popupID={"popout__inventory"}
            handleSaveInbound={handleSaveInbound}
            warehouses={warehouses}
            productsAndMaterials={productsAndMaterials}
            addProductCounter={addProductCounter}
            setAddProductCounter={setAddProductCounter}
            createInboundSelectedProducts={createInboundSelectedProducts}
            setCreateInboundSelectedProducts={setCreateInboundSelectedProducts}
            createInboundComment={createInboundComment}
            setCreateInboundComment={setCreateInboundComment}
            selectedWarehouses={selectedWarehouses}
            setSelectedWarehouses={setSelectedWarehouses}
            inventoryChangeReason={inventoryChangeReason}
            setInventoryChangeReason={setInventoryChangeReason}
          />

          <div className="body__section" id="products-page--salesforce--inventory">

            <PopupWindow
              popupID={'popup-window--warehouse-detail'}
              isActive={warehouseDetailIsActive}
              setIsActive={setWarehouseDetailIsActive}
            >
              <PopOutWindowInventoryChange
                popupID={"popout__inventory--from-detail"}
                handleSaveInbound={handleSaveInbound}
                warehouses={warehouses}
                productsAndMaterials={productsAndMaterials}
                addProductCounter={addProductCounter}
                setAddProductCounter={setAddProductCounter}
                createInboundSelectedProducts={createInboundSelectedProducts}
                setCreateInboundSelectedProducts={setCreateInboundSelectedProducts}
                createInboundComment={createInboundComment}
                setCreateInboundComment={setCreateInboundComment}
                selectedWarehouses={selectedWarehouses}
                setSelectedWarehouses={setSelectedWarehouses}
                inventoryChangeReason={inventoryChangeReason}
                setInventoryChangeReason={setInventoryChangeReason}
              />

              <div className="warehouse_detail--header">

              </div>

              <h2>{selectedWarehouse?.warehouse_id.name}</h2>

              <div className="warehouse_detail--inventory_section">
                <div className="inventory__section-products">
                  <div className="table">
                    <div className="table__header-4">
                      <div className="table__header-field">SKU</div>
                      <div className="table__header-field">Product name</div>
                      <div className="table__header-field">Type</div>
                      <div className="table__header-field">Total</div>
                    </div>


                    {
                      selectedWarehouse?.products.map((product, index) => {
                        return (
                          <div className="table__row-4" key={index}>
                            <div className="table__row-field">{product.sku}</div>
                            <div className="table__row-field">{product.name} {product.volume}</div>
                            <div className="table__row-field">{product.is_POSM ? "Material" : "Product"}</div>
                            <div className="table__row-field">{product.quantity}</div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              </div>

            </PopupWindow>

            <h2 className="flex">warehouses</h2>
            {
              loading ?
                <div className="loading_spinner_wrapper"><LoadingSpinner /></div> :
                <>
                  {
                    inventory.length == 0 ?
                      <div class="placeholder_icon">
                        <Forklift />
                        <h3>No warehouse found</h3>
                      </div> :
                      <div className="warehouses__section">
                        {
                          inventory.map((warehouse, index) => {

                            let width = 100
                            const capacity_calc = Math.max(warehouse.warehouse_id.max_capacity - warehouse.warehouse_id.current_capacity, 0) / warehouse.warehouse_id.max_capacity * 100

                            if (warehouse.warehouse_id.max_capacity != null) {
                              width = capacity_calc
                            }


                            let warehouseIcon = require(`../assets/pictos/warehouse_picto_other.png`)
                            if (warehouse.warehouse_id.icon) {
                              warehouseIcon = require(`../assets/pictos/${warehouse.warehouse_id.icon}`)
                            }

                            return (
                              <div className="inventory__section" key={index} onClick={() => handleSelectWarehouse(index)}>
                                <div className="warehouse_container" >
                                  <div className="warehouse_picto__container">
                                    <p className="warehouse-title">{warehouse.warehouse_id.name}</p>
                                    <img src={warehouseIcon} alt="stoqup warehouse picto" className="inventory__section-picto" />
                                  </div>
                                </div>

                              </div>
                            )
                          })
                        }
                      </div>
                  }
                </>
            }

          </div>
        </div>
      </div>
    </div>
  )
}

export default InventoryPageSalesforce