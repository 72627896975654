import React from 'react'
import "./CustomerFiche.css"

export default function CustomerFiche({ id, businessName, owner, tag1, tag2, index, stage, stageBig, onClickFiche, customerID, userID }) {

  const handleDragStart = (e) => {
    const data = { id, stage }
    e.dataTransfer.setData('application/json', JSON.stringify(data))
  }

  return (
    <div
      className="customer-fiche"
      key={index}
      draggable
      onDragStart={handleDragStart}
      onClick={onClickFiche}
      data-customer-id={customerID}
      data-user-id={userID}
    >
      <div className="customer-fiche_customer">{businessName}</div>
      <div className="customer-fiche_sales-owner"><b>Owner: </b>{owner}</div>
      <div className='customer-fiche_tags'>
        {
          tag1 != null &&
          <div className={`customer-fiche_tag ${tag1.tag_color_name}`}>{tag1.tag}</div>
        }
        {
          tag2 != null &&
          <div className={`customer-fiche_tag ${tag2.tag_color_name}`}>{tag2.tag}</div>
        }
      </div>
    </div>
  )
}
