import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import './FormDropdownPopup.css'
import { MdClear } from "react-icons/md"

function FormDropdownPopup({ id, isOpen, setIsOpen, setValue, list, type, stateData, searchBar }) {
  const [selectedType, setSelectedType] = useState('')
  const [searchValue, setSearchValue] = useState("")
  const dropdownRef = useRef(null)
  const selectedViewRef = useRef(null)
  const dispatch = useDispatch()

  const checkSelectedItem = () => {
    const dropdown = dropdownRef.current
    const selectedView = selectedViewRef.current
    if (!dropdown || !selectedView) return

    const scrollOffset = dropdown.scrollTop
    const selectedViewMiddle = selectedView.offsetTop + selectedView.offsetHeight / 2

    const items = dropdown.children
    for (let i = 0; i < items.length; i++) {
      const item = items[i]
      const itemMiddle = item.offsetTop - scrollOffset + item.offsetHeight / 2

      if (itemMiddle >= selectedView.offsetTop && itemMiddle <= selectedView.offsetTop + selectedView.offsetHeight) {
        if (typeof list[0] === 'string' || typeof list[0] === 'number') {
          setSelectedType(item.textContent)
        }
        if (typeof list[0] === 'object') {
          setSelectedType(item.textContent)
        }
        break
      }
    }
  }

  useEffect(() => {
    if (isOpen) {
      setSelectedType('')
    }
  }, [isOpen])

  const handleClear = (e) => {
    e.stopPropagation()
    setValue(id, null, stateData)
    setIsOpen(false)
    setSearchValue("")
  }

  const handleOnclick = (e) => {
    e.stopPropagation()

    if (typeof list[0] === 'string' || typeof list[0] === 'number') {
      setValue(id, selectedType, stateData)
    }
    if (typeof list[0] === 'object') {
      const objValue = list.find(obj => obj.name === selectedType)
      setValue(id, objValue, stateData)
    }

    setIsOpen(false)
    setSearchValue("")
  }

  useEffect(() => {
    if (isOpen && list.length > 0) {
      if (typeof list[0] === 'string' || typeof list[0] === 'number') {
        setSelectedType(list[0])
      }
      if (typeof list[0] === 'object') {
        setSelectedType(list[0].name)
      }
    }
  }, [isOpen, list])

  useEffect(() => {
    const dropdown = dropdownRef.current
    if (dropdown) {
      dropdown.addEventListener('scroll', checkSelectedItem)
      return () => {
        dropdown.removeEventListener('scroll', checkSelectedItem)
      }
    }
  }, [list])

  useEffect(() => {
    if (isOpen && searchValue) {
      const filteredList = list.filter(item =>
        typeof item === 'object' ?
          item.name.toLowerCase().includes(searchValue.toLowerCase()) :
          item.toLowerCase().includes(searchValue.toLowerCase())
      )

      if (filteredList.length > 0) {
        const firstItem = filteredList[0]
        const firstItemIndex = list.findIndex(item => item === firstItem || item.name === firstItem.name)
        if (firstItemIndex >= 0) {
          const itemHeight = 50 // Adjust based on your item height
          const selectedView = selectedViewRef.current
          const selectedViewHeight = selectedView ? selectedView.offsetHeight : 0
          const offsetTop = selectedView ? selectedView.offsetTop : 0
          const scrollPosition = (firstItemIndex * itemHeight) - offsetTop + (selectedViewHeight / 2) - (itemHeight / 2)
          dropdownRef.current.scrollTo(0, scrollPosition)
          setSelectedType(firstItem.name || firstItem)
        }
      }
    }
  }, [searchValue, isOpen, list])

  return (
    <div className={`form_dropdown_popup__container ${isOpen ? 'open' : ''} ${type}`} id={id} onClick={() => setIsOpen(false)}>
      <div className='clear_icon' onClick={handleClear}><p>Clear</p><MdClear /></div>
      {
        searchBar &&
        <input className="search_bar__dropdown--mobile" defaultValue={"Search..."} value={searchValue} onChange={(e) => setSearchValue(e.currentTarget.value.trim())} onClick={(e) => e.stopPropagation()} />
      }
      <div ref={selectedViewRef} className='selected_view'></div>
      <div ref={dropdownRef} className='form_dropdown_popup no-scrollbar'>
        {
          list
            .filter(item => (
              typeof item === 'object' ?
                item.name.toLowerCase().includes(searchValue.toLowerCase()) :
                item.toLowerCase().includes(searchValue.toLowerCase()))
            )
            .map((type, index) => {
              if (typeof type === 'string' || typeof type === 'number') {
                return (
                  <div
                    key={index}
                    className={`form_dropdown_popup__item ${selectedType === type ? 'selected' : ''}`}
                    onClick={handleOnclick}
                  >
                    {type}
                  </div>
                )
              }

              if (typeof type === 'object') {
                return (
                  <div
                    key={index}
                    className={`form_dropdown_popup__item ${selectedType === type.name ? 'selected' : ''}`}
                    onClick={handleOnclick}
                  >
                    {type.name}
                  </div>
                )
              }

            })
        }
      </div>
    </div>
  )
}

export default FormDropdownPopup

FormDropdownPopup.defaultProps = {
  type: "normal",
  searchBar: false,
}
