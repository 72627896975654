import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import './index-mobile.css'
import App from './App'
import { Provider } from "react-redux"
import { store, persistor } from './store/store'
import { PersistGate } from 'redux-persist/es/integration/react'
import { LoadScript } from '@react-google-maps/api'
import LoadingPage from './components/LoadingPage'

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    // Show loading indicator
    const loadingElement = document.createElement('div')
    loadingElement.id = 'loading-page'
    document.body.appendChild(loadingElement)
    const loadingRoot = ReactDOM.createRoot(loadingElement) // Create a root for the loading component
    loadingRoot.render(<LoadingPage />) // Use the render method on the root

    navigator.serviceWorker.register('/service-worker.js')
      .then(registration => {
        console.log('Service Worker registered with scope:', registration.scope)
        // Hide loading indicator
        loadingRoot.unmount() // Unmount the loading component
        document.body.removeChild(loadingElement)
      })
      .catch(error => {
        console.log('Service Worker registration failed:', error)
        // Hide loading indicator
        loadingRoot.unmount() // Unmount the loading component
        document.body.removeChild(loadingElement)
      })
  })
}

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={LoadingPage} persistor={persistor}>
        <LoadScript
          googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
          loadingElement={<LoadingPage />}
        >
          <App />
        </LoadScript>
      </PersistGate>
    </Provider>
  </React.StrictMode>
)
