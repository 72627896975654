import React, { useState, useEffect, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"
import httpClient from "../httpClient"
import "./CustomersPage.css"
import Header from "../components/Header"
import MenuBar from "../components/MenuBar"
import DropDownFilter from "../components/DropDownFilter"
import LoadingSpinner from "../components/placeholders/LoadingSpinner"
import { Link, useLocation, useNavigate } from "react-router-dom"
import Slider from "../components/Slider"
import TableCheckmarkHeader from '../components/TableCheckmarkHeader'
import TableCheckmarkRow from '../components/TableCheckmarkRow'
import DropdownButton from "../components/DropdownButton"
import { toast } from "react-toastify"
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import LoadingSpinnerOverlay from "../components/placeholders/LoadingSpinnerOverlay"
import { exportToExcel } from "../components/helpers/Exports"
import SearchBarFilter from "../components/SearchBarFilter.js"
import {
  updateFilters,
  updateCustomerList,
  resetStateCustomerPage,
  updateTotalPagesCustomers,
  updateArrayOfCitiesCustomers,
  updateMapView,
  updateMapCustomers,
  updateMapTotalRecords,
  updateMapHasMore,
  updateMapPage
} from "../store/customerPage"
import CircularProgress from '@mui/material/CircularProgress'
import { IoClose } from "react-icons/io5"
import { showNotificationPopup } from "../components/NotificationPopup.js"
import Pagination from "../components/Pagination.js"
import CustomerMap from "../components/CustomerMap.js"
import { LiaMapMarkedAltSolid } from "react-icons/lia"
import { TfiMicrosoftAlt } from "react-icons/tfi"
import CheckBox from "../components/CheckBox.js"

function CustomersPage() {
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingMap, setLoadingMap] = useState(false)
  const [loadingSaveButton, setloadingSaveButton] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const [emailError, setEmailError] = useState("Not a valid email address")
  const [invoiceEmailError, setInvoiceEmailError] = useState("Not a valid email address")
  const [VATNumberError, setVATNumberError] = useState("VAT Number not in correct format BE0000.000.000")
  const [tableBig, setTableBig] = useState(true)
  const [limit, setLimit] = useState(20)
  const [typesDropdownIsOpen, setTypesDropdownIsOpen] = useState(false)
  const [subTypeDropdownIsOpen, setsubTypeDropdownIsOpen] = useState(false)
  const [selectedType, setSelectedType] = useState("")
  const [selectedSubType, setSelectedSubType] = useState("")
  const [loadingExport, setLoadingExport] = useState(false)
  const [colleagues, setColleagues] = useState([])
  const [loadingInputBoxes, setLoadingInputBoxes] = useState(false)

  const [businessNameDropdownIsActive, setBusinessNameDropdownIsActive] = useState(true)
  const [loadingBusinessNameDropdown, setLoadingBusinessNameDropdown] = useState(true)
  const [businessNameSelected, setBusinessNameSelected] = useState(false)
  const [googleBusinessSearchResults, setGoogleBusinessSearchResults] = useState([])
  const [debouncedBusinessName, setDebouncedBusinessName] = useState('')
  const [searchRequestLimitReached, setSearchRequestLimitReached] = useState(false)
  const [customer, setCustomer] = useState({
    business_name: "",
    email: "",
    tel_number: "",
    first_name: "",
    last_name: "",
    company_name: "",
    sales_email: "",
    invoice_email: "",
    VAT_number: "",
    invoice_address_street: "",
    invoice_address_nr: "",
    invoice_address_pc: "",
    invoice_address_place: "",
    invoice_address_country: "Belgium",
    delivery_address_street: "",
    delivery_address_nr: "",
    delivery_address_pc: "",
    delivery_address_place: "",
    delivery_address_country: "Belgium",
    bio: "",
    create_in_business_central: true,
    is_supplier: false
  })

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const userInfo = useSelector(state => state.user.userInfo)
  const filters = useSelector(state => state.customerPage.filters)
  const customers = useSelector(state => state.customerPage.customerList)
  const customerTypesArray = useSelector(state => state.user.customer_types)
  const totalPages = useSelector(state => state.customerPage.totalPagesCustomers)
  const arrayOfCities = useSelector(state => state.customerPage.arrayOfCitiesCustomers)
  const mapViewObj = useSelector(state => state.customerPage.mapView)
  const mapView = mapViewObj.state
  const mapCustomers = useSelector(state => state.customerPage.mapCustomers)
  const mapTotalRecords = useSelector(state => state.customerPage.mapTotalRecords)
  const mapHasMore = useSelector(state => state.customerPage.mapHasMore)
  const mapPage = useSelector(state => state.customerPage.mapPage)
  const arrayOfTypes = customerTypesArray && Object.keys(customerTypesArray)
  const arrayOfSubTypes = customerTypesArray &&customerTypesArray[filters.typeFilter] || []

  const previousFilters = useRef(filters)
  const previousMapviewState = useRef(mapView)
  const dropdownRefTypes = useRef(null)
  const parentDivRef = useRef(null)
  const dropdownRefSubTypes = useRef(null)

  const fetchColleagues = async () => {
    try {
      const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/brandowner/myteam")
      const data = response.data
      const activeColleagues = data
        .filter(person => person.active)
        .map(person => ({
          id: person.salesforce_id,
          name: person.salesforce_name
        }))
      setColleagues(activeColleagues)
    } catch (error) {
      console.error("Error fetching colleagues:", error)
    }
  }

  const fetchCustomers = async (loadMore = false) => {
    if (mapView) {
      setLoadingMap(true)
    } else {
      setLoading(true)
    }

    // Determine the current map page
    const currentMapPage = loadMore ? mapPage : 1

    const requestData = {
      listType: filters.customersListType,
      filters: {
        city: filters.cityFilter,
        postal_code: filters.postalCodeFilter,
        type: filters.typeFilter,
        sub_type: filters.subTypeFilter,
        search_word: filters.searchWord,
        b2c: filters.filterB2C,
        owner: filters.owner,
        mapBounds: filters.mapBounds
      },
      sort: filters.sortFilter,
      sortAscending: filters.sortAscending,
      mapView: mapView,
      mapPage: currentMapPage
    }

    try {
      const responseCustomers = await httpClient.post(
        `${process.env.REACT_APP_API_URL}/api/users/get?page=${filters.page}&limit=${limit}`,
        requestData
      )

      if (mapView) {
        const { customers: newCustomers, totalRecords, hasMore, currentPage } = responseCustomers.data

        if (loadMore) {
          dispatch(updateMapCustomers([...mapCustomers, ...newCustomers]))
        } else {
          dispatch(updateMapCustomers(newCustomers))
        }

        dispatch(updateMapTotalRecords(totalRecords))
        dispatch(updateMapHasMore(hasMore))
        dispatch(updateMapPage(currentPage))
        setLoadingMap(false)
      } else {
        dispatch(updateCustomerList(responseCustomers.data[0]))
        dispatch(updateTotalPagesCustomers(responseCustomers.data[1]))
        dispatch(updateArrayOfCitiesCustomers(responseCustomers.data[2]))
        setLoading(false)
      }
    } catch (error) {
      console.error('Error fetching customers:', error)
      setLoadingMap(false)
      setLoading(false)
    }
  }

  useEffect(() => {

    if (customers.length == 0) {
      fetchCustomers()
        .catch(err => {
          console.log(err)
        })
    }

    fetchColleagues()
      .catch(err => {
        console.log(err)
      })

    const header = document.getElementById("header-title")
    header.classList.add("hide")
  }, [])

  useEffect(() => {
    if (selectedType == "wholesaler") {
      setCustomer(prev => ({ ...prev, is_supplier: true }))
    }
  }, [selectedType])

  useEffect(() => {
    const previousFiltersDict = previousFilters.current

    // Compare the current filters with the previous filters
    if (JSON.stringify(previousFiltersDict) !== JSON.stringify(filters)) {
      if (previousFiltersDict.page == filters.page) {
        // if page did not change, page has to be reset to 1 (because a filter changed)
        dispatch(updateFilters({ ...filters, page: 1 }))
      }

      fetchCustomers()
        .catch(err => {
          console.log(err)
        })

      // Update previous filters to current filters
      previousFilters.current = filters
    }
  }, [filters])

  useEffect(() => {
    if (previousMapviewState.current != mapView) {
      fetchCustomers()
        .catch(err => {
          console.log(err)
        })

      // Update previous mapViewState to current filters
      previousMapviewState.current = mapView
    }
  }, [mapView])

  const handleLoadMore = () => {
    dispatch(updateMapPage(mapPage + 1))
  }

  useEffect(() => {
    if (mapView && mapPage > 1) {
      fetchCustomers(true)
    }
  }, [mapPage])

  useEffect(() => {
    let dropdownElement = null

    if (typesDropdownIsOpen) {
      dropdownElement = dropdownRefTypes.current
    } else if (subTypeDropdownIsOpen) {
      dropdownElement = dropdownRefSubTypes.current
    }

    const parentDivElement = parentDivRef.current

    if (dropdownElement) {
      const dropdownRect = dropdownElement.getBoundingClientRect()
      const parentDivRect = parentDivElement.getBoundingClientRect()

      const { top: dropdownTop, height: dropdownHeight } = dropdownRect
      const { top: parentDivTop, height: parentDivHeight } = parentDivRect

      const dropdownBottom = dropdownTop + dropdownHeight
      const parentDivBottom = parentDivTop + parentDivHeight

      if (dropdownBottom > parentDivBottom) {
        // Calculate the amount to scroll by
        const scrollAmount = dropdownBottom - parentDivBottom
        // Scroll the parent div with smooth animation
        parentDivElement.scrollBy({
          top: scrollAmount,
          behavior: 'smooth',
        })
      }
    }
  }, [typesDropdownIsOpen, subTypeDropdownIsOpen])

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedBusinessName(customer.business_name)
    }, 750) // 750ms delay

    if (!customer.business_name) {
      setBusinessNameSelected(false)
      setBusinessNameDropdownIsActive(true)
      setGoogleBusinessSearchResults([])
    }

    return () => {
      clearTimeout(timerId)
    }
  }, [customer.business_name])

  useEffect(() => {
    const fetchBusinessName = async () => {
      if (!debouncedBusinessName) {
        return
      }

      if (!businessNameSelected) {
        try {
          const response = await httpClient.get(`${process.env.REACT_APP_API_URL}/api/user/text-search?textQuery=${debouncedBusinessName}`)

          if (response.status == 200) {
            setGoogleBusinessSearchResults(response.data.data.places)
          } else {
            setBusinessNameDropdownIsActive(false)
            console.log(response.data)
          }
        } catch (error) {
          console.log(error)
          if (error.response.status == 423) {
            setSearchRequestLimitReached(true)
          }
        }
      }

      setLoadingBusinessNameDropdown(false)

    }

    fetchBusinessName()
  }, [debouncedBusinessName])

  const handleOnCLickType = () => {
    setTypesDropdownIsOpen(!typesDropdownIsOpen)
  }

  const handleOnCLickSubType = () => {
    setsubTypeDropdownIsOpen(!subTypeDropdownIsOpen)
  }

  const handleOnClickDropdownItem = (event) => {
    const dropdownList = event.target.parentElement
    switch (dropdownList.id) {
      case "dropdown__customers_type":
        setSelectedType(event.target.innerHTML)
        setSelectedSubType("")
        setTypesDropdownIsOpen(false)
        setCustomer(prev => ({ ...prev, type: event.target.innerHTML, sub_type: "" }))
        break
      case "dropdown__customers_sub_type":
        setSelectedSubType(event.target.innerHTML)
        setsubTypeDropdownIsOpen(false)
        setCustomer(prev => ({ ...prev, sub_type: event.target.innerHTML }))
        break
    }
  }

  const handleCustomerClick = (customerId) => {
    navigate(`/customer/${customerId}`)
  }

  const handleDuplicateButton = (event) => {
    const input_field = event.target.previousSibling
    switch (input_field.name) {
      case "company_name":
        setCustomer(prev => ({ ...prev, company_name: customer.business_name }))
        break
      case "invoice_email":
        setCustomer(prev => ({ ...prev, invoice_email: customer.email }))
        break
      case "sales_email":
        setCustomer(prev => ({ ...prev, sales_email: customer.email }))
        break
      case undefined:
        if (input_field.parentElement.id == "delivery_address_info") {
          setCustomer(prev => ({
            ...prev,
            delivery_address_street: customer.invoice_address_street,
            delivery_address_nr: customer.invoice_address_nr,
            delivery_address_pc: customer.invoice_address_pc,
            delivery_address_place: customer.invoice_address_place,
            delivery_address_country: customer.invoice_address_country
          }))
        }
        break
    }
  }

  const handleSaveNewCustomer = async () => {
    const saveButton = document.getElementById("customers__btn-save")
    saveButton.disabled = true
    setloadingSaveButton(true)

    const emailErrorElement = document.getElementById("input_error_email")
    const invoiceEmailErrorElement = document.getElementById("input_error_invoice_email")
    const salesEmailErrorElement = document.getElementById("input_error_sales_email")
    const VATNumberErrorElement = document.getElementById("input_error_VAT_number")

    const VATValidation = /[A-Z][0-9]{4}\.[0-9]{3}\.[0-9]{3}/
    const emailValidation = /\S+@\S+\.\S+/

    const data = {
      ...customer,
      "type": selectedType,
      "sub_type": selectedSubType,
      "sales_email": ""
    }

    if (!data.business_name) {

      toast.error("Business name is required", { theme: "dark" })

      saveButton.disabled = false
      setloadingSaveButton(false)
      return
    }

    if (data.email) {
      if (emailValidation.test(data.email)) {
        emailErrorElement.classList.remove("active")
      } else {
        emailErrorElement.classList.add("active")

        toast.error("Not a valid email address", { theme: "dark" })

        saveButton.disabled = false
        setloadingSaveButton(false)
        return
      }
    }

    if (data.invoice_email) {
      if (emailValidation.test(data.invoice_email)) {
        invoiceEmailErrorElement.classList.remove("active")
      } else {
        invoiceEmailErrorElement.classList.add("active")

        toast.error("Not a valid email address", { theme: "dark" })

        saveButton.disabled = false
        setloadingSaveButton(false)
        return
      }
    }

    if (data.sales_email) {
      if (emailValidation.test(data.sales_email)) {
        salesEmailErrorElement.classList.remove("active")
      } else {
        salesEmailErrorElement.classList.add("active")

        toast.error("Not a valid email address", { theme: "dark" })

        saveButton.disabled = false
        setloadingSaveButton(false)
        return
      }
    }

    const cleanState = () => {
      setSelectedSubType("")
      setSelectedType("")
      setCustomer({
        business_name: "",
        email: "",
        tel_number: "",
        first_name: "",
        last_name: "",
        company_name: "",
        sales_email: "",
        invoice_email: "",
        VAT_number: "",
        invoice_address_street: "",
        invoice_address_nr: "",
        invoice_address_pc: "",
        invoice_address_place: "",
        invoice_address_country: "Belgium",
        delivery_address_street: "",
        delivery_address_nr: "",
        delivery_address_pc: "",
        delivery_address_place: "",
        delivery_address_country: "Belgium",
        bio: "",
        create_in_business_central: true
      })

      saveButton.disabled = false
      setloadingSaveButton(false)
    }

    const updateCustomer = async (customerId) => {
      const requestData = {
        ...data,
        "id": customerId
      }

      try {
        const response = await httpClient.post(`${process.env.REACT_APP_API_URL}/api/user/update`, requestData)
        if (response.status === 200) {
          toast.success("Customer data updated")

          cleanState()
          return
        }
      } catch (err) {
        console.log(err)
      }
    }

    try {
      const response = await httpClient.post(`${process.env.REACT_APP_API_URL}/api/user/create`, data)
      console.log(response)

      if (response.status === 200) {
        toast.success("Customer created!")
        fetchCustomers()

        cleanState()
      } else if (response.status === 409) {
        showNotificationPopup({
          title: `Customer with this email address & delivery address already exists under the name of '${response.data.customer_name}'`,
          message: 'Do you want to overwrite the old data with the new data?',
          actionButtonOnClick: () => updateCustomer(response.data.customer_id),
          actionButtonText: 'overwrite'
        })
      } else if (response.status === 403) {
        toast.error("You have reached your free user limit of 10 customers. Upgrade your account.", { theme: "dark" })
      } else {
        // Handle other statuses if necessary
      }
      saveButton.disabled = false
      setloadingSaveButton(false)
    } catch (err) {
      console.log(err)
      saveButton.disabled = false
      setloadingSaveButton(false)
    }

    saveButton.disabled = false
    setloadingSaveButton(false)
  }

  const handleCreateNewCustomer = () => {
    const newCustomerForm = document.getElementById("new-customer-form")
    const allCustomersTable = document.getElementById("all-customers-table")
    const saveButton = document.getElementById("customers__btn-save")
    const filters = document.getElementById("customer__table-btns")

    newCustomerForm.classList.toggle("invisible")
    allCustomersTable.classList.toggle("big")
    saveButton.classList.toggle("hide")
    filters.classList.toggle("hide")

    setTableBig(!tableBig)
  }

  const handleOnClickItem = (event, data) => {

    const filter = event.currentTarget.textContent

    const updatedFilters = { ...filters }

    switch (event.target.parentElement.id) {
      case "customers__dropdownList--sub-type":
        updatedFilters["subTypeFilter"] = filter
        document.getElementById("customers__dropdownList--sub-type").classList.remove("active")
        break
      case "customers__dropdownList--type":
        updatedFilters["typeFilter"] = filter
        document.getElementById("customers__dropdownList--type").classList.remove("active")
        break
      case "customers__dropdownList--c":
        updatedFilters["cityFilter"] = filter
        document.getElementById("customers__dropdownList--c").classList.remove("active")
        break
      case "customers__dropdownList--customer-owner":
        updatedFilters["owner"] = data
        document.getElementById("customers__dropdownList--c").classList.remove("active")
        break
      case "customers__dropdownList--customer-status":
        if (filter == "All") {
          updatedFilters["customersListType"] = filter
        } else if (filter == "Have my products") {
          updatedFilters["customersListType"] = filter
        } else if (filter == "Don't have my products (yet 😏)") {
          updatedFilters["customersListType"] = "Don't have my products"
        }

        document.getElementById("customers__dropdownList--customer-status").classList.remove("active")
        break
    }

    updatedFilters["page"] = 1
    dispatch(updateFilters(updatedFilters))
  }

  const handleSetSortFilter = (event) => {

    const innerHTML = event.currentTarget.innerHTML
    const updatedFilters = { ...filters }

    switch (innerHTML) {
      case "Business name":
        if (filters.sortFilter == "business_name") {
          updatedFilters["sortAscending"] = !filters.sortAscending
        } else {
          updatedFilters["sortFilter"] = "business_name"
          updatedFilters["sortAscending"] = true
        }
        break
      case "Contact name":
        if (filters.sortFilter == "first_name") {
          updatedFilters["sortAscending"] = !filters.sortAscending
        } else {
          updatedFilters["sortFilter"] = "first_name"
          updatedFilters["sortAscending"] = true
        }
        break
      case "Tel":
        if (filters.sortFilter == "tel_number") {
          updatedFilters["sortAscending"] = !filters.sortAscending
        } else {
          updatedFilters["sortFilter"] = "tel_number"
          updatedFilters["sortAscending"] = true
        }
        break
      case "City":
        if (filters.sortFilter == "delivery_address_place") {
          updatedFilters["sortAscending"] = !filters.sortAscending
        } else {
          updatedFilters["sortFilter"] = "delivery_address_place"
          updatedFilters["sortAscending"] = true
        }
        break
      case "Postal Code":
        if (filters.sortFilter == "delivery_address_pc") {
          updatedFilters["sortAscending"] = !filters.sortAscending
        } else {
          updatedFilters["sortFilter"] = "delivery_address_pc"
          updatedFilters["sortAscending"] = true
        }
        break
      case "Email":
        if (filters.sortFilter == "email") {
          updatedFilters["sortAscending"] = !filters.sortAscending
        } else {
          updatedFilters["sortFilter"] = "email"
          updatedFilters["sortAscending"] = true
        }
        break
      case "Type":
        if (filters.sortFilter == "type") {
          updatedFilters["sortAscending"] = !filters.sortAscending
        } else {
          updatedFilters["sortFilter"] = "type"
          updatedFilters["sortAscending"] = true
        }
        break
      case "Sub type":
        if (filters.sortFilter == "sub_type") {
          updatedFilters["sortAscending"] = !filters.sortAscending
        } else {
          updatedFilters["sortFilter"] = "sub_type"
          updatedFilters["sortAscending"] = true
        }
        break
    }
    updatedFilters["page"] = 1
    dispatch(updateFilters(updatedFilters))
  }

  const handlePagination = (event) => {
    const element = event.currentTarget

    if (element.classList.contains("grayed-out")) {
      return
    }

    const type = element.dataset.type

    const updatedFilters = { ...filters }

    switch (type) {

      case "previous":
        updatedFilters["page"] = filters.page - 1

        dispatch(updateFilters(updatedFilters))
        break
      case "next":
        updatedFilters["page"] = filters.page + 1

        dispatch(updateFilters(updatedFilters))
        break
    }
  }

  const handleHideB2C = () => {
    const updatedFilters = { ...filters }
    updatedFilters["filterB2C"] = !filters.filterB2C

    dispatch(updateFilters(updatedFilters))
  }

  const addToSalesPipe = async (value) => {

    let data = {
      "type": value,
      "customerIDs": []
    }

    selectedRows.forEach(row => {
      const customerID = row.getAttribute("data-customer-id")

      data["customerIDs"].push(parseInt(customerID))
    })

    const response = await toast.promise(
      httpClient.post(process.env.REACT_APP_API_URL + "/api/sales-pipe/add", data),
      {
        pending: "Adding to SALES PIPEs...",
        success: `Done!`,
        error: "Something went wrong"
      }
    )
    console.log(response.data)

    setSelectedRows([])
  }

  const handleExportAllCustomers = async () => {
    setLoadingExport(true)

    const fileName = `Customers_export_STOQUP`
    const exportData = []

    const requestData = {
      listType: filters.customersListType,
      filters: {
        city: filters.cityFilter,
        postal_code: filters.postalCodeFilter,
        type: filters.typeFilter,
        sub_type: filters.subTypeFilter,
        search_word: filters.searchWord,
        b2c: filters.filterB2C,
        owner: filters.owner,
        mapBounds: filters.mapBounds
      },
      sort: filters.sortFilter,
      sortAscending: filters.sortAscending,
      unPaginatedList: mapView ? true : false
    }

    const response = await httpClient.post(process.env.REACT_APP_API_URL + "/api/users/get", requestData)

    response.data.forEach(customer => {
      const customerObj = {
        'id': customer.id,
        'company_name': customer.company_name,
        'business_name': customer.business_name,
        'invoice_email': customer.invoice_email,
        'email': customer.email,
        'VAT_number': customer.VAT_number,
        'first_name': customer.first_name,
        'last_name': customer.last_name,
        'tel_number': customer.tel_number,
        'delivery_address_street': customer.delivery_address_street,
        'delivery_address_nr': customer.delivery_address_nr,
        'delivery_address_pc': customer.delivery_address_pc,
        'delivery_address_place': customer.delivery_address_place,
        'delivery_address_country': customer.delivery_address_country,
        'invoice_address_street': customer.invoice_address_street,
        'invoice_address_nr': customer.invoice_address_nr,
        'invoice_address_pc': customer.invoice_address_pc,
        'invoice_address_place': customer.invoice_address_place,
        'invoice_address_country': customer.invoice_address_country,
        'type': customer.type,
        'sub_type': customer.sub_type,
        'bio': customer.bio,
      }
      exportData.push(customerObj)
    })

    exportToExcel(exportData, fileName)

    setLoadingExport(false)
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setCustomer(prev => ({ ...prev, [name]: value }))

    if (name == 'business_name') {
      setLoadingBusinessNameDropdown(true)
    }
  }

  const handleIsSupplier = (value) => {
    setCustomer(prev => ({ ...prev, is_supplier: !prev.is_supplier }))
  }

  const handleOnClickSearchResult = async (searchResult) => {

    setLoadingInputBoxes(true)

    setBusinessNameDropdownIsActive(false)
    setBusinessNameSelected(true)
    setGoogleBusinessSearchResults([])

    const customerState = { ...customer }
    customerState.business_name = searchResult.displayName.text
    customerState.tel_number = searchResult.nationalPhoneNumber

    const addressParts = searchResult.formattedAddress.split(', ')

    if (addressParts.length === 3) {
      const streetAndNumber = addressParts[0].split(' ')
      const street = streetAndNumber.slice(0, -1).join(' ')
      const number = streetAndNumber[streetAndNumber.length - 1]

      const postalAndPlace = addressParts[1].split(' ')
      const postalCode = postalAndPlace[0]
      const place = postalAndPlace.slice(1).join(' ')

      const country = addressParts[2]

      customerState.delivery_address_street = street
      customerState.delivery_address_nr = number
      customerState.delivery_address_pc = postalCode
      customerState.delivery_address_place = place
      customerState.delivery_address_country = country
    } else {
      // Handle cases where the address does not fit the expected structure
      console.error('Address format is not as expected:', searchResult.formattedAddress)
    }

    // try to find the email address, type & subtype and short description

    const response = await httpClient.post(process.env.REACT_APP_API_URL + `/api/ai/find-customer-info`, searchResult)
    const emailAddresses = response.data.email_addresses

    if (emailAddresses) {
      customerState.email = emailAddresses[0]
    }

    customerState.bio = response.data.short_description
    setSelectedType(response.data.type)
    setSelectedSubType(response.data.sub_type)

    setCustomer(customerState)
    setLoadingInputBoxes(false)
  }

  const handleBusinessCentralToggle = () => {
    setCustomer(prev => ({
      ...prev,
      create_in_business_central: !prev.create_in_business_central
    }))
  }

  return (
    <div className="template">

      {/* {error ? window.location.href = "/" : ""} */}

      <div className={`header__options--right-template ${selectedRows.length == 0 ? "hide" : ""}`} id="header__options--users-admin">
        <DropdownButton
          id={"customers-1"}
          title="Add to SALES PIPE"
          valuesArray={["Prospects", "Visited", "Undecided", "Clients", "Lost", "Ambassadors"]}
          onClickFn={addToSalesPipe}
        />
      </div>

      <Header>
        <div className="header__options">
          {
            (userInfo.type == "brandowner" | userInfo.type == "admin" | userInfo.type == "wholesaler") & selectedRows.length == 0 ?
              <>
                <button className="options-button-header medium_small" onClick={handleExportAllCustomers}>{
                  loadingExport ?
                    <LoadingSpinnerOverlay /> :
                    <>Export <ExitToAppIcon /></>
                }
                </button>
                <div className="header__option--slider"><Slider size={1.2} text="Hide B2C" onClickFunction={handleHideB2C} state={filters.filterB2C} defaultState={filters.filterB2C} /></div>
              </>
              : ""
          }
          <button className={`options-button-header medium_small ${mapView ? "selected" : ""}`} onClick={() => dispatch(updateMapView({ ...mapViewObj, state: !mapView }))}>Map view<LiaMapMarkedAltSolid /> </button>
        </div>

        <button className="green-button-header hide" id="customers__btn-save" onClick={handleSaveNewCustomer}>
          {
            loadingSaveButton ?
              <CircularProgress color="inherit" size={"1rem"} /> :
              <>SAVE</>
          }
        </button>

        {
          selectedRows.length == 0 &&
          <div className="header__filters" id="customer__table-btns">
            <SearchBarFilter
              id="search-bar--customers"
              filters={filters}
              updateFilters={updateFilters}
              stateKey="searchWord"
              stateType="redux"
            />
            <DropDownFilter
              idList="customers__dropdownList--c"
              placeholderText="Filter on city"
              listArray={arrayOfCities || []}
              onClickItem={handleOnClickItem}
              value={filters.cityFilter}
              filter={"cityFilter"}
              setValue={updateFilters}
              widthSize={"medium"}
            />
            <DropDownFilter
              idList="customers__dropdownList--type"
              placeholderText="Filter on outlet type"
              listArray={arrayOfTypes}
              onClickItem={handleOnClickItem}
              value={filters.typeFilter}
              filter={"typeFilter"}
              setValue={updateFilters}
              widthSize={"medium"}
            />
            <DropDownFilter
              idList="customers__dropdownList--sub-type"
              placeholderText="Filter on outlet sub type"
              listArray={arrayOfSubTypes}
              onClickItem={handleOnClickItem}
              value={filters.subTypeFilter}
              filter={"subTypeFilter"}
              setValue={updateFilters}
              widthSize={"large"}
            />
            <DropDownFilter
              idList="customers__dropdownList--customer-status"
              placeholderText="Filter on customer status"
              listArray={["All", "Have my products", "Don't have my products (yet 😏)"]}
              onClickItem={handleOnClickItem}
              value={filters.customersListType}
              filter={"customersListType"}
              setValue={updateFilters}
              widthSize={"large"}
            />
            {
              userInfo.type != "admin" ?
                <DropDownFilter
                  idList="customers__dropdownList--customer-owner"
                  placeholderText="Filter on owner"
                  listArray={colleagues}
                  onClickItem={handleOnClickItem}
                  value={filters.owner}
                  filter={"owner"}
                  setValue={updateFilters}
                  widthSize={"large"}
                /> : null
            }
          </div>
        }
      </Header>
      <MenuBar />

      <div className="template__body">

        {
          !mapView && <button className="floating__button--bottom-right" id="add--btn" onClick={handleCreateNewCustomer} />
        }

        <div className="body__customers" id="body-customers">
          <div className="body__section invisible" id="new-customer-form">
            <div className="form__section" ref={parentDivRef}>
              <h1>Details</h1>

              <div className="input_field">
                <label className="input_field__label" htmlFor="business_name">Business name</label>
                <input
                  className={`input_field__input_box--text ${loadingInputBoxes ? "loading" : ""}`}
                  id="customers__business_name"
                  type="text"
                  name="business_name"
                  value={customer.business_name}
                  onChange={handleChange}
                  disabled={loadingInputBoxes}
                />
                <div className={`dropdown_field ${(businessNameDropdownIsActive & customer.business_name != '') ? "" : "hide"}`}>
                  {
                    searchRequestLimitReached ?
                      <div className="limit" onClick={() => setBusinessNameDropdownIsActive(false)}><p>🤖 You've reached your monthly search limit.</p> <IoClose /></div> :

                      loadingBusinessNameDropdown ?
                        <div className='circular_loading_spinner__wrapper'><CircularProgress color='inherit' size="1rem" /></div>
                        :
                        <>
                          {
                            googleBusinessSearchResults.map((result, index) => {
                              const addressParts = result.formattedAddress.split(', ')
                              const place = addressParts.length >= 3 ? addressParts[addressParts.length - 2].split(' ')[1] : ''

                              return (
                                <div className="dropdown_field__item" key={index} onClick={() => handleOnClickSearchResult(result)}>
                                  <div>{result.displayName.text}</div>
                                  <div>{result.primaryType}</div>
                                  <div>{place}</div>
                                </div>
                              )
                            })
                          }
                        </>
                  }
                </div>
              </div>

              <div className="input_field">
                <label className="input_field__label" htmlFor="email">Email</label>
                <input
                  className={`input_field__input_box--text ${loadingInputBoxes ? "loading" : ""}`}
                  id="customers__email"
                  type="text"
                  name="email"
                  value={customer.email}
                  onChange={handleChange}
                  disabled={loadingInputBoxes}
                />
              </div>
              <div className="input_error" id="input_error_email">{emailError}</div>

              <div className="input_field">
                <label className="input_field__label" htmlFor="tel">Telephone number</label>
                <input
                  className={`input_field__input_box--text ${loadingInputBoxes ? "loading" : ""}`}
                  id="customers__tel"
                  type="text"
                  name="tel_number"
                  value={customer.tel_number}
                  onChange={handleChange}
                  disabled={loadingInputBoxes}
                />
              </div>

              <div className="input_field--combo-5050">
                <div className="input_field">
                  <label className="input_field__label" htmlFor="first_name">First name</label>
                  <input
                    className={`input_field__input_box--text ${loadingInputBoxes ? "loading" : ""}`}
                    id="customers__first_name"
                    type="text"
                    name="first_name"
                    value={customer.first_name}
                    onChange={handleChange}
                    disabled={loadingInputBoxes}
                  />
                </div>
                <div className="input_field">
                  <label className="input_field__label" htmlFor="last_name">Last name</label>
                  <input
                    className={`input_field__input_box--text ${loadingInputBoxes ? "loading" : ""}`}
                    id="customers__last_name"
                    type="text"
                    name="last_name"
                    value={customer.last_name}
                    onChange={handleChange}
                    disabled={loadingInputBoxes}
                  />
                </div>
              </div>

              <div className="input_field--combo-5050">
                <div className="input_field">
                  <div className="dropdown">
                    <p className="input_field__label">Type</p>
                    <div
                      className={`input_field__input_box--text dropdown ${loadingInputBoxes ? "loading" : ""}`}
                      id="customers__type"
                      value={selectedType}
                      onClick={handleOnCLickType}
                    >
                      {selectedType}
                    </div>
                  </div>
                  <ul
                    className={`input_field__dropdown ${typesDropdownIsOpen ? "active" : ""}`}
                    id="dropdown__customers_type"
                    ref={dropdownRefTypes}
                  >
                    {arrayOfTypes.map(type => (
                      <li key={type} onClick={handleOnClickDropdownItem}>{type}</li>
                    ))}
                  </ul>
                </div>
                <div className="input_field">
                  <div className="dropdown">
                    <p className="input_field__label">Sub type</p>
                    <div
                      className={`input_field__input_box--text dropdown ${loadingInputBoxes ? "loading" : ""}`}
                      id="customers__sub_type"
                      value={selectedSubType}
                      onClick={handleOnCLickSubType}
                    >
                      {selectedSubType}
                    </div>
                  </div>
                  <ul
                    className={`input_field__dropdown ${subTypeDropdownIsOpen ? "active" : ""}`}
                    id="dropdown__customers_sub_type"
                    ref={dropdownRefSubTypes}
                  >
                    {customerTypesArray[selectedType]?.map(subtype => (
                      <li key={subtype} onClick={handleOnClickDropdownItem}>{subtype}</li>
                    ))}
                  </ul>
                </div>
              </div>

              {customer.is_supplier && (
                <>
                  <div className="input_field">
                    <label className="input_field__label" htmlFor="sales_email">Order email</label>
                    <div className="flex">
                      <input
                        className={`input_field__input_box--text ${loadingInputBoxes ? "loading" : ""}`}
                        id="customers__sales_email"
                        type="text"
                        name="sales_email"
                        value={customer.sales_email}
                        onChange={handleChange}
                        disabled={loadingInputBoxes}
                      />
                      <button className="secondairy-button" onClick={handleDuplicateButton}>Duplicate</button>
                    </div>
                  </div>
                  <div className="input_error" id="input_error_sales_email">{emailError}</div>
                </>
              )}

              <div className="input_field">
                <label className="input_field__label" htmlFor="bio">Bio</label>
                <textarea
                  className={`input_field__input_box--text ${loadingInputBoxes ? "loading" : ""}`}
                  style={{ minHeight: "90px" }}
                  id="customers__bio"
                  name="bio"
                  value={customer.bio}
                  onChange={handleChange}
                  disabled={loadingInputBoxes}
                />
              </div>
            </div>

            <div className="form__section">
              <h1>Invoicing</h1>
              <div className="input_field">
                <label className="input_field__label" htmlFor="company_name">Company name</label>
                <div className="flex">
                  <input
                    className={`input_field__input_box--text ${loadingInputBoxes ? "loading" : ""}`}
                    id="customers__company_name"
                    type="text"
                    name="company_name"
                    value={customer.company_name}
                    onChange={handleChange}
                    disabled={loadingInputBoxes}
                  />
                  <button className="secondairy-button" onClick={handleDuplicateButton}>Duplicate</button>
                </div>
              </div>

              <div className="input_field">
                <label className="input_field__label" htmlFor="invoice_email">Invoice email</label>
                <div className="flex">
                  <input
                    className={`input_field__input_box--text ${loadingInputBoxes ? "loading" : ""}`}
                    id="customers__invoice_email"
                    type="text"
                    name="invoice_email"
                    value={customer.invoice_email}
                    onChange={handleChange}
                    disabled={loadingInputBoxes}
                  />
                  <button className="secondairy-button" onClick={handleDuplicateButton}>Duplicate</button>
                </div>
              </div>
              <div className="input_error" id="input_error_invoice_email">{invoiceEmailError}</div>

              <div className="input_field">
                <label className="input_field__label" htmlFor="VAT_number">VAT number</label>
                <input
                  className={`input_field__input_box--text ${loadingInputBoxes ? "loading" : ""}`}
                  id="customers__VAT_number"
                  type="text"
                  name="VAT_number"
                  value={customer.VAT_number}
                  onChange={handleChange}
                  disabled={loadingInputBoxes}
                />
              </div>
              <div className="input_error" id="input_error_VAT_number">{VATNumberError}</div>

              <div className="input_field--combo-7030">
                <div className="input_field">
                  <label className="input_field__label" htmlFor="invoice_address_street">Street</label>
                  <input
                    className={`input_field__input_box--text ${loadingInputBoxes ? "loading" : ""}`}
                    id="customers__invoice_address_street"
                    type="text"
                    name="invoice_address_street"
                    value={customer.invoice_address_street}
                    onChange={handleChange}
                    disabled={loadingInputBoxes}
                  />
                </div>
                <div className="input_field">
                  <label className="input_field__label" htmlFor="invoice_address_nr">Number</label>
                  <input
                    className={`input_field__input_box--text ${loadingInputBoxes ? "loading" : ""}`}
                    id="customers__invoice_address_nr"
                    type="text"
                    name="invoice_address_nr"
                    value={customer.invoice_address_nr}
                    onChange={handleChange}
                    disabled={loadingInputBoxes}
                  />
                </div>
              </div>

              <div className="input_field--combo-3070">
                <div className="input_field">
                  <label className="input_field__label" htmlFor="invoice_address_pc">Postal code</label>
                  <input
                    className={`input_field__input_box--text ${loadingInputBoxes ? "loading" : ""}`}
                    id="customers__invoice_address_pc"
                    type="text"
                    name="invoice_address_pc"
                    value={customer.invoice_address_pc}
                    onChange={handleChange}
                    disabled={loadingInputBoxes}
                  />
                </div>
                <div className="input_field">
                  <label className="input_field__label" htmlFor="invoice_address_place">City</label>
                  <input
                    className={`input_field__input_box--text ${loadingInputBoxes ? "loading" : ""}`}
                    id="customers__invoice_address_place"
                    type="text"
                    name="invoice_address_place"
                    value={customer.invoice_address_place}
                    onChange={handleChange}
                    disabled={loadingInputBoxes}
                  />
                </div>
              </div>

              <div className="input_field">
                <label className="input_field__label" htmlFor="invoice_address_nr">Country</label>
                <input
                  className={`input_field__input_box--text ${loadingInputBoxes ? "loading" : ""}`}
                  id="customers__invoice_address_country"
                  type="text"
                  name="invoice_address_country"
                  value={customer.invoice_address_country}
                  onChange={handleChange}
                  disabled={loadingInputBoxes}
                />
              </div>
            </div>

            <div className="form__section" id="delivery_address_info">
              <h1>Delivery</h1>
              <div className="input_field--combo-7030">
                <div className="input_field">
                  <label className="input_field__label" htmlFor="delivery_address_street">Street</label>
                  <input
                    className={`input_field__input_box--text ${loadingInputBoxes ? "loading" : ""}`}
                    id="customers__delivery_address_street"
                    type="text"
                    name="delivery_address_street"
                    value={customer.delivery_address_street}
                    onChange={handleChange}
                    disabled={loadingInputBoxes}
                  />
                </div>
                <div className="input_field">
                  <label className="input_field__label" htmlFor="delivery_address_nr">Number</label>
                  <input
                    className={`input_field__input_box--text ${loadingInputBoxes ? "loading" : ""}`}
                    id="customers__delivery_address_nr"
                    type="text"
                    name="delivery_address_nr"
                    value={customer.delivery_address_nr}
                    onChange={handleChange}
                    disabled={loadingInputBoxes}
                  />
                </div>
              </div>

              <div className="input_field--combo-3070">
                <div className="input_field">
                  <label className="input_field__label" htmlFor="delivery_address_pc">Postal code</label>
                  <input
                    className={`input_field__input_box--text ${loadingInputBoxes ? "loading" : ""}`}
                    id="customers__delivery_address_pc"
                    type="text"
                    name="delivery_address_pc"
                    value={customer.delivery_address_pc}
                    onChange={handleChange}
                    disabled={loadingInputBoxes}
                  />
                </div>
                <div className="input_field">
                  <label className="input_field__label" htmlFor="delivery_address_place">City</label>
                  <input
                    className={`input_field__input_box--text ${loadingInputBoxes ? "loading" : ""}`}
                    id="customers__delivery_address_place"
                    type="text"
                    name="delivery_address_place"
                    value={customer.delivery_address_place}
                    onChange={handleChange}
                    disabled={loadingInputBoxes}
                  />
                </div>
              </div>

              <div className="input_field">
                <label className="input_field__label" htmlFor="invoice_address_nr">Country</label>
                <input
                  className={`input_field__input_box--text ${loadingInputBoxes ? "loading" : ""}`}
                  id="customers__delivery_address_country"
                  type="text"
                  name="delivery_address_country"
                  value={customer.delivery_address_country}
                  onChange={handleChange}
                  disabled={loadingInputBoxes}
                />
              </div>
              <button className="secondairy-button" id="duplicate-btn-delivery" onClick={handleDuplicateButton}>Duplicate</button>

              <div className="other_options">
                <CheckBox
                  id="customers__is_supplier"
                  text="Is supplier"
                  state={customer.is_supplier}
                  setData={handleIsSupplier}
                />
              </div>
              <div className={`api_options ${tableBig ? "hide" : ""}`}>
                <div
                  className={`business-central-icon ${customer.create_in_business_central ? 'clicked' : ''} ${!userInfo.api_partners.includes('BusinessCentral') ? "hide" : ""}`}
                  onClick={handleBusinessCentralToggle}
                >
                  <TfiMicrosoftAlt />
                </div>
              </div>
            </div>
          </div>

          <div className="body__section big" id="all-customers-table">
            {
              loading ?
                <LoadingSpinner /> :
                mapView ?
                  <CustomerMap
                    customers={mapCustomers}
                    loading={loadingMap}
                    totalRecords={mapTotalRecords}
                    hasMore={mapHasMore}
                    onLoadMore={handleLoadMore}
                    setMapPage={updateMapPage}
                  /> :
                  <div className="table" id="customer--table">
                    <div className="table__header-9">
                      <TableCheckmarkHeader
                        id="table-checkmark-header-customer"
                        selectedRows={selectedRows}
                        setSelectedRows={setSelectedRows}
                      />
                      <div className="table__header--field" onClick={handleSetSortFilter}>Business name</div>
                      <div className="table__header--field" onClick={handleSetSortFilter}>Contact name</div>
                      <div className="table__header--field" onClick={handleSetSortFilter}>Tel</div>
                      <div className="table__header--field" onClick={handleSetSortFilter}>City</div>
                      <div className="table__header--field" onClick={handleSetSortFilter}>Postal Code</div>
                      <div className="table__header--field" onClick={handleSetSortFilter}>Email</div>
                      <div className="table__header--field" onClick={handleSetSortFilter}>Type</div>
                      <div className="table__header--field" onClick={handleSetSortFilter}>Sub type</div>
                    </div>
                    <div className="table__rows_container">
                      {
                        customers
                          .map((customer, index) => {
                            return (
                              <div className="table__row-9" id='checkmark-row' data-customer-id={customer.id} key={index} onClick={() => handleCustomerClick(customer.user_id.id)} >
                                <TableCheckmarkRow
                                  index={index}
                                  id={`customers-${index}`}
                                  selectedRows={selectedRows}
                                  setSelectedRows={setSelectedRows}
                                />
                                <div className="table__row--field">{customer.business_name}</div>
                                <div className="table__row--field">{customer.first_name} {customer.last_name}</div>
                                <div className="table__row--field">{customer.tel_number}</div>
                                <div className="table__row--field">{customer.delivery_address_place}</div>
                                <div className="table__row--field">{customer.delivery_address_pc}</div>
                                <div className="table__row--field">{customer.email}</div>
                                <div className="table__row--field">{customer.type}</div>
                                <div className="table__row--field">{customer.sub_type}</div>
                              </div>
                            )
                          })
                      }
                    </div>
                    <Pagination
                      id="pagination-customers-page-list"
                      stateType="redux"
                      filters={filters}
                      page={filters.page}
                      setPage={updateFilters}
                      totalPages={totalPages}
                    />
                  </div>

            }
          </div>
        </div>

      </div>
    </div >
  )
}

export default CustomersPage
