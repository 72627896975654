import Smartphone from "../assets/pictos/svgs/Smartphone"
import Visit from "./pictos/svgs/Visit"
import Task from "./pictos/svgs/Task"
import Mail from "./pictos/svgs/Mail"
import Meeting from "./pictos/svgs/Meeting"
import Note from "./pictos/svgs/Note"

export const types_array = {
  "horeca": ["restaurant", "fine dining", "star restaurant", "bar", "bistro", "cocktail bar", "pub", "food pub", "tearoom", "hotel", "catering", "summer bar", "beachbar"],
  "wholesaler": ["N/A"],
  "shop": ["liquor store", "specialty shop", "retail", "concept store"],
  "distributor": ["N/A"],
  "other": ["N/A"],
  "B2C": ["N/A"],
  "": [],
}

export const couriers = ["be-post", "nl-post", "dhl", "dpd", "nl-post-int", "be-post-int"]

const emailPicto = require(`./pictos/email.png`)
const formPicto = require(`./pictos/form.png`)
const phonePicto = require(`./pictos/smartphone.png`)
const visitPicto = require(`./pictos/visit.png`)
const meetingPicto = require(`./pictos/meeting.png`)
const notePicto = require(`./pictos/note.png`)
const orderPicto = require(`./pictos/order.png`)

export const activity_types = {
  "Task": { "picto": formPicto },
  "Visit": { "picto": visitPicto },
  "Phone call": { "picto": phonePicto },
  "Email": { "picto": emailPicto },
  "Meeting": { "picto": meetingPicto },
  "Note": { "picto": notePicto }
}

export const activity_types_svgs = {
  "Task": {
    "picto": <Task/>
  },
  "Visit": {
    "picto": <Visit/>
  },
  "Phone call": {
    "picto": <Smartphone/>
  },
  "Email": {
    "picto": <Mail/>
  },
  "Meeting": {
    "picto": <Meeting/>
  },
  "Note": {
    "picto": <Note/>
  }
}
