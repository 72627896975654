import React, { useState, useEffect } from 'react'
import './InputBox.css'


InputBox.defaultProps = {
  positionInput: "right",
  inputBoxSize: 50,
  fontSize: "0.8rem",
  inputType: "general",
  onChangeFunction: null,
}

function InputBox({ text, textPosition, inputBoxSize, fontSize, inputType, value, setValue, id, onChangeFunction, stateType, stateValueName }) {
  const [styleInputBox, setStyleInputBox] = useState({})
  const [styleTitle, setStyleTitle] = useState({
    "fontSize": fontSize,
  })

  useEffect(() => {
    const setStyles = () => {
      if (textPosition) {
        setStyleTitle({...styleTitle,
          "maxWidth": `${100 - inputBoxSize}%`,
          "width": `${100 - inputBoxSize}%`,
        })
      
        setStyleInputBox({...styleInputBox,
          "width": `${inputBoxSize}%`,
        })
      }
    }

    setStyles()
  }, [])

  const handleOnChange = (event) => {
    const inputValue = event.currentTarget.value

    if (stateType === 'object') {
      setValue(prevState => ({
        ...prevState,
        [stateValueName]: inputValue
      }))
      
    } else {
      switch (inputType) {
  
        case "number":
          !isNaN(inputValue) && setValue(inputValue)
          break
  
        case "general":
          setValue(inputValue)
          break
      }
    }



    onChangeFunction()
  }

  return (
    <div className={`input_box__container ${textPosition == "vert" ? "vert" : ""}`} id={id}>
      {
        textPosition == "right" ?
          <>
            <input className='input_box__input' style={styleInputBox} />
            <div className='input_box__title' style={styleTitle} value={value} onChange={handleOnChange}>{text}</div>
          </> :

          <>
            <div className='input_box__title' style={styleTitle}>{text}</div>
            <input className='input_box__input' style={styleInputBox} value={value} onChange={handleOnChange} />
          </>

      }
    </div>
  )
}

export default InputBox

InputBox.defaultProps = {
  inputType: "general",
  textPosition: "left",
  stateType: null,
}