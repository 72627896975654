import React, { useEffect, useState, useRef } from 'react'
import httpClient from "../../httpClient"
import './HomePageBrandownerMobile.css'
import { useSelector, useDispatch } from "react-redux"
import { changeThemeColor } from '../../components/helpers/GlobalFunctions'
import MenuBarMobile from '../../components/mobile/MenuBarMobile'
import TotalSales from '../../components/TotalSales'
import TodoToday from '../../components/TodoToday'
import BestSellers from '../../components/BestSellers'
import MySales from '../../components/MySales'
import { askPermissionAndSubscribe } from '../../components/helpers/ServiceWorker'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import { resetStateCustomerPage } from '../../store/customerPage'
import { resetStateSalesPageBrandOwner } from "../../store/salesPageBrandOwner"
import { resetStateSalesPipePage } from "../../store/salesPipePage"
import { resetStateUser } from "../../store/user"
import { resetStateActivitiesPage } from "../../store/activitiesPage"
import { resetStatePerformancePage } from "../../store/performancePage"

function HomePageBrandownerMobile() {

  const dispatch = useDispatch()
  const userInfo = useSelector(state => state.user.userInfo)
  const previousUserIdRef = useRef()

  useEffect(() => {
    changeThemeColor("#EBEBEB")
  }, [])

  useEffect(() => {
    if (userInfo.id && userInfo.id !== previousUserIdRef.current) {
      previousUserIdRef.current = userInfo.id
      askPermissionAndSubscribe(userInfo.id)
    }
  }, [userInfo])

  const logoutUser = async () => {

    dispatch(resetStateCustomerPage())
    dispatch(resetStateSalesPageBrandOwner())
    dispatch(resetStateSalesPipePage())
    dispatch(resetStateUser())
    dispatch(resetStateActivitiesPage())
    dispatch(resetStatePerformancePage())

    // Clear localStorage
    localStorage.removeItem("userInfo")

    await httpClient.post(process.env.REACT_APP_API_URL + "/api/logout")
    window.location.href = "/"
  }

  return (
    <div className='template--mobile'>
      <div className='template__body--mobile' id='mobile-homepage--brandowner'>
        <div className='body__section--transparent' id='header--mobile'>
          <h1>Hi {userInfo.first_name}! 🥷</h1>
          <LogoutOutlinedIcon onClick={logoutUser} />
        </div>
        <div className='body__section' id='bestsellers'><BestSellers /></div>
        <div className='body__section' id='my-sales'><MySales /></div>
        <div className='body__section' id='total-sales'><TotalSales /></div>

        <div className='body__section' id='activities'><TodoToday /></div>

      </div>

      <MenuBarMobile />
    </div>
  )
}

export default HomePageBrandownerMobile