import React, { useEffect } from 'react'
import './LoadingPage.css'; // Create a CSS file for styles
import LoadingLogo from '../assets/animations/svg/LoadingLogo'; // Adjust the import path as necessary
import { changeThemeColor } from "../components/helpers/GlobalFunctions"

function LoadingPage() {
  const handleReload = () => {
    window.location.reload()
  }

  useEffect(() => {
    changeThemeColor("#000000")
  }, [])

  return (
    <div className="loading-container">
      <div id="loading-logo-container">
        <LoadingLogo />
      </div>
      <p onClick={handleReload} className="reload-text">click here to reload</p>
    </div>
  );
}

export default LoadingPage