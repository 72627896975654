import React, { useState, useEffect, useRef } from 'react'
import httpClient from '../httpClient'
import { useSelector, useDispatch } from 'react-redux'
import './PerformanceDetailTable.css'
import { formatDate, formatDateWithTime } from './helpers/FormatDate'
import Label from './Label'
import CircularProgress from '@mui/material/CircularProgress'

function PerformanceDetailTable({ dataSet1, dataSet2, type, refreshOn, hasMoreData, onLoadMore, loadingMore }) {
  const [combinedData, setCombinedData] = useState([])

  useEffect(() => {
    if (dataSet1 != undefined & dataSet2 != undefined) {
      const winClients = dataSet1.map(client => ({
        ...client,
        result: 'WIN',
        sortDate: client.win_date,
      }))

      const lostClients = dataSet2.map(client => ({
        ...client,
        result: 'LOST',
        sortDate: client.lost_date,
      }))
      const combinedClients = [...winClients, ...lostClients]

      const sortedData = combinedClients.sort((a, b) => new Date(a.sortDate) - new Date(b.sortDate))
      setCombinedData(sortedData)
    }
  }, [refreshOn])

  return (
    <div className='performance_detail__wrapper'>
      {
        type == 'visits' &&
        <div className="table" id="performance-detail-visits-table">
          <div className="table__header-5">
            <div className="table__header--field">date</div>
            <div className="table__header--field">customer</div>
            <div className="table__header--field">type</div>
            <div className="table__header--field">sub type</div>
            <div className="table__header--field">Report</div>
          </div>

          <div className="table__rows_container">
            {
              dataSet1 &&
              dataSet1.map((visit, index) => (
                <div className="table__row-5" key={index}>
                  <div className="table__row--field">{formatDateWithTime(visit.done_date, true)}</div>
                  <div className="table__row--field">{visit.customer_id.business_name}</div>
                  <div className="table__row--field">{visit.customer_id.type}</div>
                  <div className="table__row--field">{visit.customer_id.sub_type}</div>
                  <div className="table__row--field">{visit.activity_reports[0]?.report}</div>
                </div>
              ))
            }
            {hasMoreData && (
              <div className="load-more-container">
                <button onClick={onLoadMore} className="load_more_button">
                  {
                    loadingMore ?
                      <CircularProgress color="inherit" size={"1rem"} /> :
                      "Load More"
                  }
                </button>
              </div>
            )}
          </div>


        </div>
      }

      {
        type == "clients" &&
        <div className="table" id="performance-detail-clients-table">
          <div className="table__header-6">
            <div className="table__header--field">date</div>
            <div className="table__header--field">customer</div>
            <div className="table__header--field">type</div>
            <div className="table__header--field">sub type</div>
            <div className="table__header--field"></div>
            <div className="table__header--field"></div>
          </div>

          <div className="table__rows_container">
            {
              combinedData.map((item, index) => (
                <div className="table__row-6" key={index}>
                  <div className="table__row--field">{
                    item.result == 'WIN' ?
                      formatDateWithTime(item.win_date, true) :
                      formatDateWithTime(item.lost_date, true)
                  }</div>
                  <div className="table__row--field">{item.customer_id.business_name}</div>
                  <div className="table__row--field">{item.customer_id.type}</div>
                  <div className="table__row--field">{item.customer_id.sub_type}</div>
                  <div className="table__row--field"></div>
                  <div className="table__row--field">
                    {
                      item.result == 'WIN' ?
                        <Label labelColor='green' title='WIN' /> :
                        <Label labelColor='red' title='LOST' />
                    }
                  </div>
                </div>
              ))
            }
            {hasMoreData && (
              <div className="load-more-container">
                <button onClick={onLoadMore} className="load_more_button">
                  {
                    loadingMore ?
                      <CircularProgress color="inherit" size={"1rem"} /> :
                      "Load More"
                  }
                </button>
              </div>
            )}
          </div>


        </div>
      }

      {
        type == "products" &&
        <div className="table" id="performance-detail-products-table">
          <div className="table__header-6">
            <div className="table__header--field">date</div>
            <div className="table__header--field">customer</div>
            <div className="table__header--field">type</div>
            <div className="table__header--field">sub type</div>
            <div className="table__header--field">product</div>
            <div className="table__header--field"></div>
          </div>

          <div className="table__rows_container">
            {
              combinedData.map((item, index) => (
                <div className="table__row-6" key={index}>
                  <div className="table__row--field">{
                    item.result == 'WIN' ?
                      formatDateWithTime(item.listed_date, true) :
                      formatDateWithTime(item.delisted_date, true)
                  }</div>
                  <div className="table__row--field">{item.product_availability_id.customer_user_id.business_name}</div>
                  <div className="table__row--field">{item.product_availability_id.customer_user_id.type}</div>
                  <div className="table__row--field">{item.product_availability_id.customer_user_id.sub_type}</div>
                  <div className="table__row--field">{item.product_availability_id.product_id.name} {item.product_availability_id.product_id.volume}</div>
                  <div className="table__row--field">
                    {
                      item.result == 'WIN' ?
                        <Label labelColor='green' title='WIN' /> :
                        <Label labelColor='red' title='LOST' />
                    }
                  </div>
                </div>
              ))
            }
            {hasMoreData && (
              <div className="load-more-container">
                <button onClick={onLoadMore} className="load_more_button">
                  {
                    loadingMore ?
                      <CircularProgress color="inherit" size={"1rem"} /> :
                      "Load More"
                  }
                </button>
              </div>
            )}
          </div>
        </div>
      }

      {
        type == "sales" &&
        <div className="table" id="performance-detail-sales-table">
          <div className="table__header-8">
            <div className="table__header--field">Order ID</div>
            <div className="table__header--field">date</div>
            <div className="table__header--field">customer</div>
            <div className="table__header--field">type</div>
            <div className="table__header--field">sub type</div>
            <div className="table__header--field">product</div>
            <div className="table__header--field">amount</div>
            <div className="table__header--field">comment</div>
          </div>

          <div className="table__rows_container">
            {
              dataSet1 &&
              dataSet1.map((item, index) => (
                <div className="table__row-8" key={index}>
                  <div className="table__row--field">{item.order_id.id}</div>
                  <div className="table__row--field">{formatDate(item.created_at)}</div>
                  <div className="table__row--field">{item.order_id.user_id.business_name}</div>
                  <div className="table__row--field">{item.order_id.user_id.type}</div>
                  <div className="table__row--field">{item.order_id.user_id.sub_type}</div>
                  <div className="table__row--field">{item.product_id.name} {item.product_id.volume}</div>
                  <div className="table__row--field">{item.quantity}</div>
                  <div className="table__row--field">{item.comment}</div>
                </div>
              ))
            }
            {hasMoreData && (
              <div className="load-more-container">
                <button onClick={onLoadMore} className="load_more_button">
                  {
                    loadingMore ?
                      <CircularProgress color="inherit" size={"1rem"} /> :
                      "Load More"
                  }
                </button>
              </div>
            )}
          </div>
        </div>
      }
    </div>
  )
}

export default PerformanceDetailTable