import React, {useState, useEffect} from 'react'
import './ComingSoonOverlay.css'

function ComingSoonOverlay() {
    return (
        <div className='coming-soon__container'>
            <div className='coming-soon__text'>COMING SOON</div>
        </div>
    )
}

export default ComingSoonOverlay