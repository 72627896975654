import React, {useState, useEffect} from "react"
import { Link } from "react-router-dom"
import httpClient from "../httpClient"

import { LuCalendarRange } from "react-icons/lu"
import { PiFunnelBold } from "react-icons/pi"
import { SiHackthebox } from "react-icons/si"
import { MdOutlineAnalytics, MdOutlineShoppingCart, MdOutlinePeopleAlt, MdOutlineGroups2 } from "react-icons/md"

function MenuBar() {
  const [user, setUser] = useState({email: "", id: ""})
  const [error, setError] = useState(false)
  
  const fetchUser = async () => {
    const responseUser = await httpClient.get(process.env.REACT_APP_API_URL + "/api/@me?type=full")
    setUser(responseUser.data)
  }

  useEffect(() => {
    fetchUser()
      .catch( err => {
        console.log(err)
        setError(true)
      })
  }, [])

  const handleClickMenuItem = (e) => {
    const menu = e.currentTarget.parentElement.nextSibling
    const asideMenu = document.querySelector("#template__aside")
    const allSubMenus = document.querySelectorAll("#sub-menu")

    if(!menu) return
    if(menu.id !== "sub-menu") return allSubMenus.forEach(menu => menu.classList.remove("active"))

    menu.classList.toggle("active")
    asideMenu.classList.remove("mini")
  }

  return (
    <div className="primary__menu">
      <Link to="/brandowner/home">
        <div className="menu__item" onClick={handleClickMenuItem}><MdOutlineAnalytics/><p className="menu__title">Dashboard</p></div>
      </Link>
      <Link to="/customers">
        <div className="menu__item" onClick={handleClickMenuItem}><MdOutlinePeopleAlt/><p className="menu__title">Customers</p></div>
      </Link>
      <Link to="/activities">
        <div className="menu__item" onClick={handleClickMenuItem}><LuCalendarRange/><p className="menu__title">Activities</p></div>
      </Link>
      <Link to="/brandowner/sales">
        <div className="menu__item" onClick={handleClickMenuItem}><MdOutlineShoppingCart/><p className="menu__title">Sales</p></div>
      </Link>
      <Link to="/sales-pipe">
        <div className="menu__item" onClick={handleClickMenuItem}><PiFunnelBold/><p className="menu__title">Sales Pipe</p></div>
      </Link>
      <div><div className="menu__item" onClick={handleClickMenuItem}><MdOutlineGroups2/><p className="menu__title">Salesforce</p></div></div>
      <ul className="sub-menu" id="sub-menu">
        <Link to="/brandowner/salesforce/myteam"><li>My Team</li></Link>
        {/* <Link to="/brandowner/salesforce/reports"><li>Visits Report</li></Link> */}
        <Link to="/brandowner/salesforce/performance"><li>Performance</li></Link>
      </ul>
      <div><div className="menu__item" onClick={handleClickMenuItem}><SiHackthebox/><p className="menu__title">Logistics</p></div></div>
      <ul className="sub-menu" id="sub-menu">
        <Link to="/brandowner/products"><li>My Products</li></Link>
        <Link to="/brandowner/inventory"><li>Inventory</li></Link>
      </ul>
    </div>
)}

export default MenuBar
