import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import "./AddProductForSales.css"
import DropDownProduct from "./DropDownProduct"
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import CheckBox from "./CheckBox"
import DropDownVendor from "./DropDownVendor"
import httpClient from "../httpClient"
import DropDownWarehouseSales from "./DropDownWarehouseSales"

function AddProductForSales({
  customerID, listArray, listArray2, index, value, setValue,
  identifier, parentDivRef, resetState, onClickDelete, warehouses
}) {
  const [selectedProduct, setSelectedProduct] = useState()
  const [amount, setAmount] = useState(6)
  const [productID, setProductID] = useState()
  const [productType, setProductType] = useState("")
  const [alreadyDelivered, setAlreadyDelivered] = useState(false)
  const [onConsignation, setOnConsignation] = useState(false)
  const [comment, setComment] = useState("")
  const [vendors, setVendors] = useState([])
  const [supplierOfSelectedProduct, setSupplierOfSelectedProduct] = useState({})
  const [warehouseOfSelectedProduct, setWarehouseOfSelectedProduct] = useState({})

  const userInfo = useSelector(state => state.user.userInfo)
  const requester = userInfo.type

  const fetchVendors = async () => {
    const reponseVendors = await httpClient.get(process.env.REACT_APP_API_URL + "/api/customer/get-vendors?requester=" + requester + "&customerID=" + customerID)

    if (reponseVendors.status == 200) {
      setVendors(reponseVendors.data)
    }
  }

  const fetchProductSupplier = async () => {
    const responseProducts = await httpClient.get(process.env.REACT_APP_API_URL + "/api/customer/get-supplier-of-product?productID=" + productID + "&customerID=" + customerID)
    setSupplierOfSelectedProduct(responseProducts.data)
  }

  useEffect(() => {
    if (resetState == true) {
      setSelectedProduct("")
      setAmount(6)
      setProductID()
      setAlreadyDelivered(false)
      setOnConsignation(false)
      setComment("")
      setSupplierOfSelectedProduct({})
    }
  }, [resetState])

  useEffect(() => {
    fetchVendors()
      .catch(err => {
        console.log(err)
      })

    fetchProductSupplier()
      .catch(err => {
        console.log(err)
      })

  }, [productID, customerID])

  useEffect(() => {
    switch (productType) {
      case "products":
        setAmount(6)
        break
      case "materials":
        setAmount(1)
        break
    }
  }, [productType])

  useEffect(() => {

    if (value[index]) {
      const currentArray = [...value]
      currentArray[index] = { "productID": productID, "product": selectedProduct, "amount": amount, "alreadyDelivered": alreadyDelivered, "onConsignation": onConsignation, "comment": comment, "vendor": alreadyDelivered ? null : supplierOfSelectedProduct.vendor_id, "warehouse": warehouseOfSelectedProduct.warehouse_id, "type": productType }
      setValue(currentArray)
    } else {
      const currentArray = [...value]
      currentArray.push({ "productID": productID, "product": selectedProduct, "amount": amount, "alreadyDelivered": alreadyDelivered, "onConsignation": onConsignation, "comment": comment, "vendor": alreadyDelivered ? null : supplierOfSelectedProduct.vendor_id, "warehouse": warehouseOfSelectedProduct.warehouse_id, "type": productType })
      setValue(currentArray)
    }

  }, [productID, customerID, amount, comment, supplierOfSelectedProduct, warehouseOfSelectedProduct, alreadyDelivered, onConsignation])

  const handleOnClickDropdownItem = async (event, type) => {
    const productName = event.currentTarget.firstChild.textContent
    const productID = event.currentTarget.getAttribute("id")

    setSelectedProduct(productName)
    setProductID(productID)
    setProductType(type)

    event.currentTarget.parentElement.parentElement.previousSibling.classList.remove("red-border")
    event.currentTarget.parentElement.parentElement.parentElement.nextSibling.select()
  }

  const handleOnClickDropdownItemVendor = (event) => {
    const vendor = event.currentTarget.firstChild.textContent
    const vendorID = event.currentTarget.getAttribute('data-vendor-id')
    setSupplierOfSelectedProduct({ "vendor_id": vendorID, "vendor_name": vendor })
  }

  const handleOnClickDropdownItemWarehouse = (warehouse, warehouseID) => {
    setWarehouseOfSelectedProduct({ "warehouse_id": warehouseID, "warehouse_name": warehouse })
  }

  const handleOnChangeAmount = (event) => {
    setAmount(event.target.value)
  }

  const handleOnchangeComment = (event) => {
    setComment(event.target.value)
  }

  return (
    <div className="add-product__container" id={identifier} >
      <DropDownProduct
        index={identifier}
        idList="sales__dropdownList--product"
        placeholderText="Select product"
        value={selectedProduct}
        listArray={listArray}
        listArray2={listArray2}
        onClickItem={handleOnClickDropdownItem}
        parentDivRef={parentDivRef}
      />
      <input className="input_field__input_box--text product" value={amount} onChange={handleOnChangeAmount} />
      <div className="checkboxes">
        <div className="checkboxes_wrapper"><CheckBox text="Already delivered" state={alreadyDelivered} setState={setAlreadyDelivered} margin={"0"} /></div>
        <div className="checkboxes_wrapper"><CheckBox text="On consignation" state={onConsignation} setState={setOnConsignation} margin={"0"} /></div>
      </div>
      {
        alreadyDelivered ?
          <DropDownWarehouseSales
            index={identifier}
            listArray={warehouses}
            placeholderText="Select supplier"
            value={warehouseOfSelectedProduct}
            onClickItem={handleOnClickDropdownItemWarehouse}
            idList="sales__dropdownList--warehouse"
            parentDivRef={parentDivRef}
          /> :
          <DropDownVendor
            index={identifier}
            listArray={vendors}
            placeholderText="Select supplier"
            value={supplierOfSelectedProduct}
            onClickItem={handleOnClickDropdownItemVendor}
            idList="sales__dropdownList--vendor"
            parentDivRef={parentDivRef}
          />
      }
      <textarea type="text" className="input_field__input_box--text big" placeholder="Add a comment" value={comment} onChange={handleOnchangeComment} />
      <div className="delete"><DeleteOutlineOutlinedIcon id="delete-icon" onClick={onClickDelete} /></div>
    </div>
  )
}

export default AddProductForSales

