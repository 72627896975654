import React from "react";

function Task() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      viewBox="0 0 512 512"
    >
      <path d="M346 120h96.211L331 8.789V105c0 8.276 6.724 15 15 15z"></path>
      <path d="M106 512h300c24.814 0 45-20.186 45-45V150H346c-24.814 0-45-20.186-45-45V0H106C81.186 0 61 20.186 61 45v422c0 24.814 20.186 45 45 45zm90-271h150c8.291 0 15 6.709 15 15s-6.709 15-15 15H196c-8.291 0-15-6.709-15-15s6.709-15 15-15zm0 60h150c8.291 0 15 6.709 15 15s-6.709 15-15 15H196c-8.291 0-15-6.709-15-15s6.709-15 15-15zm0 60h150c8.291 0 15 6.709 15 15s-6.709 15-15 15H196c-8.291 0-15-6.709-15-15s6.709-15 15-15zm-60-120c8.284 0 15 6.716 15 15s-6.716 15-15 15-15-6.716-15-15 6.716-15 15-15zm0 60c8.284 0 15 6.716 15 15s-6.716 15-15 15-15-6.716-15-15 6.716-15 15-15zm0 60c8.284 0 15 6.716 15 15s-6.716 15-15 15-15-6.716-15-15 6.716-15 15-15z"></path>
    </svg>
  );
}

export default Task;