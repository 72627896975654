import React, { useState, useEffect, useRef } from 'react'
import './PopOutWindowCreateActivityReport.css'
import { useSelector, useDispatch } from "react-redux"
import { resetStateVisitReport, updateVisitReport } from "../store/visitReport"
import httpClient from "../httpClient"
import CloseIcon from '@mui/icons-material/Close'
import Dropdown from './Dropdown'
import DatePicker from './DatePicker'
import { activity_types } from '../assets/Data'
import Slider from './Slider'
import DropDownInputCustomer from './DropDownInputCustomer'
import { toast } from "react-toastify"
import { getRoundedDateHalfHour } from './helpers/FormatDate'

export function PopOutWindowCreateActivityReport({
  popupID, onClose, customers, selectedCustomer, setSelectedCustomer,
  selectedCustomerID, setSelectedCustomerID, setNewCustomerPopupIsActive,
  fetchActivities, selectedActivity, setSelectedActivity, myBrands, type
}) {
  const [localSelectedCustomer, setLocalSelectedCustomer] = useState("")
  const [activityType, setActivityType] = useState("")
  const [dueDate, setDueDate] = useState(new Date().getTime())
  const [toDate, setToDate] = useState(null)
  const [isDone, setIsDone] = useState(false)
  const [comment, setComment] = useState("")
  const [priority, setPriority] = useState(3)
  const [title, setTitle] = useState("")
  const [report, setReport] = useState({})
  const [createNextActivity, setCreateNextActivity] = useState(false)
  const [selectedSalesPipeActions, setSelectedSalesPipeActions] = useState({})

  const refParentDiv = useRef(null)

  useEffect(() => {
    const popup = document.getElementById(popupID)
    window.addEventListener('keydown', e => {
      if (e.key === 'Escape') {
        if (popup.classList.contains("active")) {
          closePopupWindow()
        }
      }
    })

    setDueDate(getRoundedDateHalfHour())
  }, [])

  useEffect(() => {
    if (selectedActivity) {
      setLocalSelectedCustomer(selectedActivity.customer_id.business_name)
      setSelectedCustomerID(selectedActivity.customer_id.id)
    }
  }, [selectedActivity])

  useEffect(() => {

    switch (activityType) {
      case "Visit":
      case "Meeting":
        setTitle(`${activityType} at ${localSelectedCustomer}`)
        break
      case "Task":
      case "Note":
        setTitle(`${activityType} for ${localSelectedCustomer}`)
        break
      case "Phone call":
        setTitle(`${activityType} with ${localSelectedCustomer}`)
        break
      case "Email":
        setTitle(`${activityType} of ${localSelectedCustomer}`)
        break
    }

  }, [activityType, localSelectedCustomer])

  useEffect(() => {
    setSelectedSalesPipeActions({})
  }, [isDone])

  useEffect(() => {
    if (dueDate) {
      const newToDate = new Date(dueDate)
      newToDate.setMinutes(newToDate.getMinutes() + 60)
      setToDate(newToDate.getTime())
    }
  }, [dueDate])

  const closePopupWindow = () => {
    const popup = document.getElementById(popupID)
    popup.classList.remove("active")


    // clean up state
    setActivityType("")
    setDueDate(null)
    setToDate(null)
    setIsDone(false)
    setComment("")
    setPriority(3)
    setTitle("")
    setLocalSelectedCustomer("")
    setSelectedCustomerID("")
    setReport({})
    setCreateNextActivity(false)
    setSelectedSalesPipeActions({})
    const allPriorityBoxes = document.querySelectorAll(".priority_option")
    allPriorityBoxes.forEach(box => box.classList.remove("selected"))

    onClose()
  }

  const handleSaveActivity = async () => {

    let data = {
      activityType, dueDate, toDate, isDone,
      selectedCustomerID, comment, priority, title, report
    }

    const response = await toast.promise(httpClient.post(process.env.REACT_APP_API_URL + "/api/activities/create", data),
      {
        "success": "Activity successfully created 🎯",
        "pending": "Creating the activity...",
        "error": "Something went wrong 😢"
      }
    )

    // Check if the response contains an .ics file
    if (response.headers['content-type'].includes('text/calendar')) {
      const blob = new Blob([response.data], { type: 'text/calendar' })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'event.ics')
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
    } else {
      console.log(response.data)
    }

    // clean up state
    setActivityType("")
    setDueDate(null)
    setToDate(null)
    setIsDone(false)
    setComment("")
    setPriority(3)
    setTitle("")
    setLocalSelectedCustomer("")
    setSelectedCustomerID("")
    setReport({})
    setCreateNextActivity(false)
    setSelectedSalesPipeActions({})
    const allPriorityBoxes = document.querySelectorAll(".priority_option")
    allPriorityBoxes.forEach(box => box.classList.remove("selected"))
  }

  const handleSave = async () => {
    const saveButton = document.querySelector('#save-btn-create-activity')
    saveButton.disabled = true

    let data = {
      "selectedActivityID": selectedActivity.id,
      selectedCustomerID,
      report
    }

    if (createNextActivity & (!activityType || selectedCustomerID == "" || selectedCustomerID == null || !dueDate)) {
      toast.error("Please fill out all required data.", { theme: "dark" })
      saveButton.disabled = false
      return
    }

    const response = await toast.promise(httpClient.post(process.env.REACT_APP_API_URL + "/api/activities/create-report", data),
      {
        "success": "Activity report successfully created 🎯",
        "pending": "Creating the activity report...",
        "error": "Something went wrong 😢"
      }
    )
    console.log(response.data)

    if (createNextActivity) {
      handleSaveActivity()
    }

    setReport({})
    setCreateNextActivity(false)
    setSelectedSalesPipeActions({})


    saveButton.disabled = false

    fetchActivities()
    closePopupWindow()

  }

  const handleOnClickDropdownItemCustomer = (event) => {
    setLocalSelectedCustomer(event.currentTarget.firstChild.textContent)
    setSelectedCustomerID(event.currentTarget.getAttribute("id"))
  }

  const handleOnclickPriority = (event, number) => {
    const allPriorityBoxes = document.querySelectorAll(".priority_option")
    const priorityBox = event.currentTarget

    allPriorityBoxes.forEach(box => box.classList.remove("selected"))

    priorityBox.classList.add("selected")
    setPriority(number)
  }

  const handleClickSalesPipeActionBtn = (brand_id, type) => {
    const newReport = { ...report }

    if (type === "undecided") {
      if (!newReport[brand_id]) {
        // Initialize brand_id object if it doesn't exist
        newReport[brand_id] = {}
      }

      if (newReport[brand_id].hasOwnProperty("undecided")) {
        // Toggle "undecided" if it exists
        newReport[brand_id]["undecided"] = !newReport[brand_id]["undecided"]
      } else {
        // Initialize "undecided" if it doesn't exist
        newReport[brand_id]["undecided"] = true
      }

      // Reset 'lost' to false if it exists
      if (newReport[brand_id]["lost"]) {
        newReport[brand_id]["lost"] = false
      }
    }

    if (type === "lost") {
      if (!newReport[brand_id]) {
        // Initialize brand_id object if it doesn't exist
        newReport[brand_id] = {}
      }

      if (newReport[brand_id].hasOwnProperty("lost")) {
        // Toggle "lost" if it exists
        newReport[brand_id]["lost"] = !newReport[brand_id]["lost"]
      } else {
        // Initialize "lost" if it doesn't exist
        newReport[brand_id]["lost"] = true
      }

      // Reset 'undecided" to false if it exists
      if (newReport[brand_id]["undecided"]) {
        newReport[brand_id]["undecided"] = false
      }
    }

    setReport(newReport)

    // Update the selected actions state
    setSelectedSalesPipeActions(prev => {
      // Deselect if the same button is clicked again
      if (prev[brand_id] === type) {
        return { ...prev, [brand_id]: null };
      }
      return { ...prev, [brand_id]: type };
    })

  }

  const handleSetReport = (event, brand_id) => {
    const newReport = { ...report }
    newReport[brand_id] = { "report": event.currentTarget.value }
    setReport(newReport)
  }

  const handleCopyToAll = () => {
    const firstKeyReport = Object.keys(report)[0]
    const firstReport = report[firstKeyReport]
    const firstKeySelectedSalesPipeActions = Object.keys(selectedSalesPipeActions)[0]
    const firstSelectedSalesPipeActions = selectedSalesPipeActions[firstKeySelectedSalesPipeActions]

    const newReport = { ...report }
    const newSelectedSalesPipeActions = { ...selectedSalesPipeActions }

    myBrands.forEach(brand => {
      newReport[brand.id] = firstReport
      newSelectedSalesPipeActions[brand.id] = firstSelectedSalesPipeActions

    })

    setReport(newReport)
    setSelectedSalesPipeActions(newSelectedSalesPipeActions)
  }

  return (
    <div className='popout__container' id={popupID}>
      <div className='popout__box'>
        <CloseIcon id="popup__close-icon" onClick={closePopupWindow} />
        <div className='popout__header'>
          <button className='popup__options-button' id='save-btn-create-activity' onClick={handleSave}>SAVE</button>
          {
            myBrands.length > 1 &&
            <>
              <button className='options-button-header high-contrast' id='save-btn-create-activity' onClick={handleCopyToAll}>Copy to all</button>
            </>
          }
        </div>
        <div className='popout__body' ref={refParentDiv}>
          {
            myBrands.map((brand, index) => (
              <div key={index}>
                <h2 className='form__section_title'>Report for {brand.business_name}</h2>
                <textarea className='input_field__input_box--textarea' value={report[brand.id] ? report[brand.id]["report"] : ""} onChange={(event) => handleSetReport(event, brand.id)} maxLength={"1000"} />

                <div className='action-buttons' id={`sales-pipe-buttons-${index}`}>
                  <button className={`gray-action-button selecting wdth100 hght100 ${selectedSalesPipeActions[brand.id] === 'undecided' ? 'selected' : ''}`} onClick={() => handleClickSalesPipeActionBtn(brand.id, "undecided")}>Move to undecided</button>
                  <button className={`red-action-button selecting wdth100 hght100 ${selectedSalesPipeActions[brand.id] === 'lost' ? 'selected' : ''}`} onClick={() => handleClickSalesPipeActionBtn(brand.id, "lost")}>Move to lost</button>
                </div>
              </div>
            ))
          }

          <div className='slider__wrapper wdth75'>
            <Slider
              state={createNextActivity}
              onClickFunction={() => setCreateNextActivity(!createNextActivity)}
              text={"Create next activity?"}
              fontSize={"0.75rem"}
              size={1.3}
              style={"h2"}
            />
          </div>

          {
            createNextActivity &&
            <>
              <h2 className='form__section_title'>Customer</h2>
              <DropDownInputCustomer
                idList={"activities__dropdownList--customer"}
                placeholderText=""
                listArray={customers}
                onClickItem={handleOnClickDropdownItemCustomer}
                value={localSelectedCustomer}
                setValue={setLocalSelectedCustomer}
                createNewCustomer={setNewCustomerPopupIsActive}
              />

              <h2 className='form__section_title'>Type</h2>
              <Dropdown
                listArray={Object.keys(activity_types)}
                state={activityType}
                setState={setActivityType}
                inherit={true}
                height={"40px"}
              />

              {
                ["Note"].includes(activityType) ?
                  null :
                  ["Visit", "Meeting"].includes(activityType) ?
                    <>
                      <h2 className='form__section_title'>{isDone ? "From" : "Planned from"}</h2>
                      <DatePicker
                        date={dueDate}
                        setDate={setDueDate}
                        placeholder={""}
                        enableTime={true}
                        altInput={true}
                      />

                      <h2 className='form__section_title'>{isDone ? "To" : "Planned to"}</h2>
                      <DatePicker
                        date={toDate}
                        setDate={setToDate}
                        placeholder={""}
                        enableTime={true}
                        altInput={true}
                      />
                    </> :
                    <>
                      <h2 className='form__section_title'>{isDone ? "Date" : "Due date"}</h2>
                      <DatePicker
                        date={dueDate}
                        setDate={setDueDate}
                        placeholder={""}
                        enableTime={true}
                        altInput={true}
                      />
                    </>
              }

              <h2 className='form__section_title'>Title</h2>
              <input className='input_field__input_box--text big' value={title} onChange={(event) => setTitle(event.currentTarget.value)} maxLength={"100"} />

              <h2 className='form__section_title'>Comment</h2>
              <textarea className='input_field__input_box--textarea' value={comment} onChange={(event) => setComment(event.currentTarget.value)} maxLength={"1000"} />

              {
                !["Note"].includes(activityType) & !isDone ?
                  <>
                    <h2 className='form__section_title'>Priority</h2>
                    <div className='priority_container'>
                      <div className='priority_title important'>important</div>
                      <div className='priority_title not-important'>not important</div>
                      <div className='priority_title urgent'>urgent</div>
                      <div className='priority_title not-urgent'>not urgent</div>

                      <div className='priority_options'>
                        <div className='priority_option one' onClick={(event) => handleOnclickPriority(event, 1)}>
                          <div className='priority_option_content'>
                            1
                          </div>
                        </div>
                        <div className='priority_option two' onClick={(event) => handleOnclickPriority(event, 2)}>
                          <div className='priority_option_content'>
                            2
                          </div>
                        </div>
                        <div className='priority_option three' onClick={(event) => handleOnclickPriority(event, 3)}>
                          <div className='priority_option_content'>
                            3
                          </div>
                        </div>
                        <div className='priority_option four' onClick={(event) => handleOnclickPriority(event, 4)}>
                          <div className='priority_option_content'>
                            4
                          </div>
                        </div>
                      </div>
                    </div>
                  </> : null
              }
            </>
          }

        </div>
      </div>
    </div>
  )
}

PopOutWindowCreateActivityReport.defaultProps = {
  onClose: () => console.log(""),
  overflow: "scroll",
  type: "acitivities-page",
}