import React from "react";

function Visit() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      viewBox="0 0 512 512"
    >
      <path d="M256 0C147.19 0 58.67 88.52 58.67 197.33c0 61.2 31.66 132.28 94.11 211.26 45.7 57.79 90.74 97.74 92.63 99.41 6.011 5.297 15.086 5.37 21.18 0 1.89-1.67 46.93-41.62 92.63-99.41 62.45-78.98 94.11-150.06 94.11-211.26C453.33 88.52 364.81 0 256 0zm-82.3 299.2C142.12 274.01 124 236.39 124 196c0-72.79 59.21-132 132-132s132 59.21 132 132c0 40.419-18.113 78.901-51.9 104.92-48.058 36.736-115.181 35.968-162.4-1.72z"></path>
      <circle cx="256" cy="176" r="24"></circle>
      <path d="M208.325 214.373c2.327-1.535 2.873-4.694 1.298-6.995C184.875 171.208 210.455 120 256 120c45.592 0 71.102 51.235 46.376 87.379-1.574 2.301-1.029 5.46 1.299 6.995 12.649 8.342 22.63 19.754 29.301 32.864 1.776 3.49 6.7 3.724 8.723.372 9.62-15.939 14.674-34.439 14.279-53.706-1.139-55.597-46.766-99.181-102.359-97.875C199.574 97.297 156 141.656 156 196c0 18.497 5.023 36.23 14.279 51.577 2.031 3.367 6.963 3.161 8.746-.344 6.659-13.082 16.628-24.502 29.3-32.86z"></path>
      <path d="M307.235 281.892a5.01 5.01 0 002.296-5.416L309 274.17c-5.73-24.83-27.52-42.17-53-42.17s-47.27 17.34-53 42.17l-.534 2.321a5.004 5.004 0 002.273 5.401c30.22 18.312 70.425 19.297 102.496 0z"></path>
    </svg>
  );
}

export default Visit;