import React, { createContext, useContext, useState } from 'react'

const PopupContext = createContext()

export const usePopup = () => useContext(PopupContext)

export const PopupProvider = ({ children }) => {
  const [popupState, setPopupState] = useState({
    isActive: false,
    id: '',
    title: '',
    message: '',
    actionButtonFn: null,
    actionButtonColor: "green",
    actionButtonText: "Yes"
  })

  const showPopup = ({ isActive, title, message, actionButtonFn, actionButtonColor, actionButtonText }) => {
    setPopupState({
      isActive,
      title,
      message,
      actionButtonFn,
      actionButtonColor,
      actionButtonText,
    })
  }

  const hidePopup = () => {
    setPopupState((prevState) => ({ ...prevState, isActive: false }))
  }

  return (
    <PopupContext.Provider value={{ popupState, showPopup, hidePopup }}>
      {children}
    </PopupContext.Provider>
  )
}
