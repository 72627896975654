import React, { useState, useEffect, useRef } from "react"
import httpClient from "../httpClient"
import { useNavigate } from "react-router-dom"
import "./SignupLandingPage.css"
import { Link, useParams } from 'react-router-dom'
import { toast } from "react-toastify"
import CircularProgress from '@mui/material/CircularProgress'

function SignupLandingPage() {
  const [codeValues, setCodeValues] = useState([])
  const [loading, setLoading] = useState(false)

  const logo_black = require("../assets/logos/Stoqup_logo_black.png")

  const navigate = useNavigate()
  const { userID } = useParams()

  // Create a ref for the input boxes
  const inputRefs = useRef([])
  inputRefs.current = [0, 1, 2, 3].map(
    (_, i) => inputRefs.current[i] ?? React.createRef()
  )

  const handleInputChange = (e, index) => {
    const { value } = e.target
    if (value === "" || /^[0-9]+$/.test(value)) {
      // If input is a number, update its value (restrict to the first character if more than one is entered)
      e.target.value = value.slice(0, 1)
      const newValuesArray = [...codeValues]
      newValuesArray[index] = value.slice(0, 1)
      setCodeValues(newValuesArray)

      // Move focus to next input if there's a value and it's not the last input
      if (value && index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].current.focus()
      }
    } else {
      // Prevent input if not a number
      e.target.value = ""
    }
  }

  const verifyUser = async () => {

    setLoading(true)

    try {
      const data = {
        userID, codeValues
      }
      const response = await httpClient.post(process.env.REACT_APP_API_URL + "/api/verify-user", data)

      toast.success(response.data.message)

      setTimeout(() => {
        navigate("/")
      }, 1500)
    } catch (error) {
      toast.error(error.response.data.error, {theme: 'dark'})
    }

    setLoading(false)
  }

  return (
    <div className="body-loginpage">
      <Link className="logo" to="/"><img src={logo_black} alt="stoqup logo black" /></Link>
      <div className='login-form landingpage'>
        <p>
          An email has been send with a 4 digit code. Enter the code here below to verify your email address.
        </p>

        <div className="verification_boxes">
          {inputRefs.current.map((_, index) => (
            <input
              key={index}
              className="verification_box"
              ref={inputRefs.current[index]}
              onChange={(e) => handleInputChange(e, index)}
              maxLength={1} // Ensures only 1 character can be entered
            />
          ))}
        </div>
        <button type='button' className={`button-login ${codeValues.length < 4 | codeValues.some(value => !value) ? "disabled" : ""}`} onClick={verifyUser}><div className={`${loading ? "hide" : ""}`}>SEND</div>{loading && <CircularProgress color="inherit" size={"2.3rem"} />}</button>


      </div>
    </div>
  )
}

export default SignupLandingPage
