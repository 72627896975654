import React, {useState} from 'react'
import httpClient from '../httpClient'
import { Link, useParams } from 'react-router-dom'
import "./ResetPasswordPage.css"
import { useSelector, useDispatch } from "react-redux"
import { changeNotificationMessage } from "../store/notification"
import { NotificationTop, showNotification } from "../components/NotificationTop"
import CircularProgress from '@mui/material/CircularProgress'
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

function ResetPasswordPage() {
  const [password, setPassword] = useState("")
  const [confirmedPassword, setConfirmedPassword] = useState("")
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)

  const { token } = useParams()
  const logo_black = require("../assets/logos/Stoqup_logo_black.png")
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const HandResetPassword = async () => {
    setLoading(true)

    if(password === confirmedPassword) {
      try {
        const data = {password}
        const response = await toast.promise(
          httpClient.post(process.env.REACT_APP_API_URL + "/api/reset?token=" + token, data),
          {
            success: "Password changed",
            pending: "Changing password...",
            error: "Something went wrong"
          }
        )
        console.log(response.data)
        navigate("/")

      } catch (error) {
        if (error.response.status == 409) {
          const message = "Password reset link is expired"
          const errorType = "red"
          dispatch(changeNotificationMessage(message))
          showNotification(errorType)
        }
      }
    } else {
      const message = "Passwords don't match"
      const errorType = "red"
      dispatch(changeNotificationMessage(message))
      showNotification(errorType)
    }

    setLoading(false)
  }

  return (
    <div className="body-loginpage">
      <NotificationTop />
      <a className="logo" href="/"><img src={logo_black} alt="stoqup logo black" id="forgot-password-logo" /></a>
      <form className='login-form'>
        <input 
            value={password} 
            onChange={e => setPassword(e.target.value)} 
            placeholder='PASSWORD'
            type="password"
          />
        <input 
          value={confirmedPassword} 
          onChange={e => setConfirmedPassword(e.target.value)} 
          placeholder='CONFIRM PASSWORD'
          type="password"
        />

        <button type='button' className='button-login' onClick={HandResetPassword}><div className={`${loading ? "hide" : ""}`}>RESET PASSWORD</div>{loading && <CircularProgress color="inherit" size={"2.3rem"} />}</button>
      </form>
    </div>
)}

export default ResetPasswordPage
