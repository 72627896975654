import React, { useEffect, useState, useRef } from 'react'
import httpClient from '../../httpClient'
import './DropdownCustomerMobile.css'
import { IoCloseOutline } from "react-icons/io5"
import { CiSquarePlus } from "react-icons/ci"
import { normalizeString } from '../helpers/GlobalFunctions'

function DropdownCustomerMobile({ onClickItem, value, setValue, createNewCustomer }) {
  const [selectedCustomerLocal, setSelectedCustomerLocal] = useState("")
  const [searchValue, setSearchValue] = useState("")
  const [customers, setCustomers] = useState([])
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false)

  const fetchCustomers = async () => {
    const responseCustomers = await httpClient.get(process.env.REACT_APP_API_URL + "/api/users/get")
    setCustomers(responseCustomers.data)
  }

  useEffect(() => {
    fetchCustomers()
  }, [])

  useEffect(() => {
    const filteredCustomers = customers.filter(customer => {
      return (
        (customer.business_name && customer.business_name.toLowerCase().includes(selectedCustomerLocal.toLowerCase())) ||
        (customer.first_name && customer.first_name.toLowerCase().includes(selectedCustomerLocal.toLowerCase())) ||
        (customer.last_name && customer.last_name.toLowerCase().includes(selectedCustomerLocal.toLowerCase()))
      )
    })

    if (filteredCustomers.length === 1) {
      const customer = filteredCustomers[0]
      const name = customer.type === "B2C" ? `${customer.first_name} ${customer.last_name}` : customer.business_name
      const id = customer.id
      setSelectedCustomerLocal(name)
      setDropdownIsOpen(false)
      setValue({ id, name })
    }
  }, [customers, selectedCustomerLocal])

  useEffect(() => {
    if (value.name) {
      setSelectedCustomerLocal(value.name)
    } 
    
    if (!value.id) {
      setSelectedCustomerLocal("")
    }

    setDropdownIsOpen(false)
  }, [value])

  const handleOnChangeInput = (e) => {
    setDropdownIsOpen(true)
    const rawValue = e.currentTarget.value
    const normalizedValue = normalizeString(rawValue)
    setSelectedCustomerLocal(rawValue)
    setSearchValue(normalizedValue)
  }

  const handleOnClickItem = (e, name, id) => {
    setDropdownIsOpen(!dropdownIsOpen)
    setSelectedCustomerLocal(name)
    setValue({ id, name })
    // onClickItem(e)
  }

  const handleClearInput = () => {
    setSelectedCustomerLocal("")
    setDropdownIsOpen(false)
    setValue({})
    fetchCustomers()
  }

  return (
    <div className='dropdown_customer_container--mobile'>
      <input
        className='input_text--mobile'
        placeholder='Customer'
        value={selectedCustomerLocal}
        onChange={handleOnChangeInput}
        readOnly={Boolean(value.id)}
      />
      {
        selectedCustomerLocal != "" &&
        <IoCloseOutline onClick={handleClearInput} />
      }
      <ul className={`dropdown_list_container no-scrollbar ${dropdownIsOpen ? "" : "hide"}`}>
        {
          createNewCustomer && !value.id ?
            <div className='create_new_customer--btn' onClick={() => createNewCustomer(true)}>
              <CiSquarePlus />
              <p>CREATE NEW CUSTOMER</p>
            </div> : null
        }
        {
          customers
            .filter(customer => {
              return (
                (customer.business_name_normalized && customer.business_name_normalized.includes(searchValue)) ||
                (customer.first_name_normalized && customer.first_name_normalized.includes(searchValue)) ||
                (customer.last_name_normalized && customer.last_name_normalized.includes(searchValue))
              )
            })
            .map((customer, index) => {
              let name = ""
              if (customer.type == "B2C") {
                name = `${customer.first_name} ${customer.last_name}`
              } else {
                name = `${customer.business_name}`
              }

              return (
                <li className="dropdown_item" key={index} onClick={(e) => handleOnClickItem(e, name, customer.id)}>
                  <div>{name}</div>
                  <div>{customer.delivery_address_place}</div>
                </li>
              )
            })
        }
      </ul>
    </div>
  )
}

export default DropdownCustomerMobile