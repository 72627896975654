import React, { useState, useEffect } from 'react'
import './DropdownButton.css'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

function DropdownButton({ id, title, value, setValue, valuesArray, onClickFn, style, disabled, index, ref }) {

  useEffect(() => {
    let top = 0
    const dropdownBox = document.querySelector(`#dropdown-button-${id}-${index}`)
    const button = dropdownBox.querySelector('button')
  
    if (button) {
      top = button.clientHeight + 2
  
      const someElement = document.querySelector(`dropdownButtonList`)
      if (someElement) {
        someElement.style.top = `${top}px`
      }
    } else {
      console.log('button not found')
    }
  }, [])

  const handleHoverIn = () => {
    const thisDropdownButton = document.querySelector(`#dropdown-button-${id}-${index}`)
    const list = thisDropdownButton.querySelector('.dropdown_button_header__list')
    list.classList.add("hovered")
  }

  const handleHoverOut = () => {
    const thisDropdownButton = document.querySelector(`#dropdown-button-${id}-${index}`)
    const list = thisDropdownButton.querySelector('.dropdown_button_header__list')
    list.classList.remove("hovered")
  }

  const handleOnClick = (event, item) => {
    onClickFn(item, event)

    if (setValue == undefined) {
      return
    }

    if (value != "") {
      setValue(item)
    }

  }

  return (
    <div className={`dropdown_button_header__container ${disabled ? "disabled" : ""} ${style}`} id={`dropdown-button-${id}-${index}`} onMouseOver={handleHoverIn} onMouseLeave={handleHoverOut} ref={ref} >
      <button className={`dropdown_button_header`}>{title} <ArrowDropDownIcon /></button>
      <ul className='dropdown_button_header__list' id={"dropdownButtonList"}>
        {
          typeof valuesArray[0] == "object" &&
          valuesArray.map((obj, index) => {
            return <li className='dropdown_button_header__list_item' onClick={(event) => handleOnClick(event, obj.id)} key={index}>{obj.name}</li>
          })

        }
        {
          typeof valuesArray[0] == "string" &&
          valuesArray.map((item, index) => {
            return <li className='dropdown_button_header__list_item' onClick={(event) => handleOnClick(event, item)} key={index}>{item}</li>
          })
        }
      </ul>
    </div>
  )
}

DropdownButton.defaultProps = {
  title: "button title",
  valuesArray: ["value1", "value2", "value3"],
  value: "",
  disabled: false,
  index: 0,
  ref: null,
}

export default DropdownButton