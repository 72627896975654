import React, { useState, useEffect } from 'react'
import httpClient from "../httpClient"
import { useParams } from 'react-router-dom'
import './InvoicePage.css'

function InvoicePage() {
  const { orderID } = useParams()

  const [order, setOrder] = useState({})
  const [error, setError] = useState(false)
  const [loadingOrder, setloadingOrder] = useState(true)
  const [loadingUser, setloadingUser] = useState(true)
  const [user, setUser] = useState({})

  const fetchOrder = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/order/get?ID=" + orderID)
    setOrder(response.data)
    setloadingOrder(false)
  }

  const fetchUser = async () => {
    const responseUser = await httpClient.get(process.env.REACT_APP_API_URL + "/api/@me?type=full")
    setUser(responseUser.data)
    setloadingUser(false)
  }

  function allAreFalse(array) {
    return array.every(element => element === false);
  }

  useEffect(() => {
    fetchOrder()
      .catch(err => {
        console.log(err)
        setError(true)
      })

    fetchUser()
      .catch(err => {
        console.log(err)
        setError(true)
      })

  }, [])

  const renderInvoice = () => {
    return (
      <div className='template__invoice'>
        <div className='invoice__header flex'>
          <img src='/img/Hana_logo.png' height={"130px"} />
          <h1>INVOICE</h1>
        </div>
        <div className='invoice__details--seller'>
          <p>{order.client.client_company_name}</p>
          <p>{order.client.client_VAT_number}</p>
          <div className='flex'>
            <p>{order.client.client_invoice_address_street}</p><p>{order.client.client_invoice_address_nr}</p>
          </div>
          <div className='flex'>
            <p>{order.client.client_invoice_address_pc}</p><p>{order.client.client_invoice_address_place}</p>
          </div>
        </div>
        <div className='invoice__details--client'>
          <div className='flex'>
            <p>invoice: </p><p>1</p>
          </div>
          <div className='flex'>
            <p>date: </p><p>04/01/2022</p>
          </div>
        </div>
        <div className='invoice__details--order'>
          <div className='table__header-6'>
            <p className='table__header--field'>PRODUCT</p>
            <p className='table__header--field'>QUANTITY</p>
            <p className='table__header--field'>UNIT</p>
            <p className='table__header--field'>PRICE</p>
            <p className='table__header--field'>VAT %</p>
            <p className='table__header--field'>TOTAL</p>
          </div>
          {order.order_items.map(orderItem => {
            return (
              <div className='table__row-6'>
                <div className='table__row--field'>{orderItem.product_name}</div>
                <div className='table__row--field'>{orderItem.order_item_quantity}</div>
                <div className='table__row--field'>{orderItem.product_volume}</div>
                <div className='table__row--field'>{orderItem.product_price}</div>
                <div className='table__row--field'>21%</div>
                <div className='table__row--field'>{orderItem.product_price}</div>
              </div>
            )
          })}
        </div>
        <div className='invoice__footer flex'>
          <div>
            <p>{user.company_name}</p>
            <p>{user.VAT_number}</p>
          </div>
          <div>
            <div className='flex'>
              <p>{user.invoice_address_street}</p><p>{user.invoice_address_nr}</p>
            </div>
            <div className='flex'>
              <p>{user.invoice_address_pc}</p><p>{user.invoice_address_place}</p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      {(!loadingOrder && !loadingUser) ? renderInvoice() : ""}
    </div>
  )
}

export default InvoicePage