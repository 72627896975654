import { createSlice } from "@reduxjs/toolkit";

export const miniMenuSlice = createSlice({
    name: "miniMenu",
    initialState: false,
    reducers: {
      setStateMiniMenu: (state, action) => {
        return state = action.payload
      }
    }
})

export const { setStateMiniMenu } = miniMenuSlice.actions
export default miniMenuSlice.reducer