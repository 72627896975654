import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import './AlertPopupMobile.css'
import { MdErrorOutline } from "react-icons/md"
import { IoNotificationsOutline } from "react-icons/io5"
import { CiCircleCheck } from "react-icons/ci"

function AlertPopupMobile({ type, isActive, setIsActive, message }) {

  const dispatch = useDispatch()

  useEffect(() => {
    if (isActive) {
      setTimeout(() => {
        setIsActive(false)
      }, 5000);
    }
  }, [isActive])

  const renderIcon = () => {
    switch (type) {
      case "error":
        return <MdErrorOutline/>
      case "notification":
        return <IoNotificationsOutline/>
      case "success":
        return <CiCircleCheck/>
    }
  }

  return (
    <div className={`alert--mobile__wrapper ${isActive ? "active" : ""} ${type}`} onClick={() => setIsActive(false)}>
      <div className='alert--mobile'>
        <div className='alert__close'>x</div>
        <div className='alert__icon'>{renderIcon()}</div>
        <div className='alert__message'>{message}</div>
      </div>
    </div>
  )
}

export default AlertPopupMobile

AlertPopupMobile.defaultProps = {
  type: "notification",
  message: "",
}