import React, { useState, useEffect } from 'react'
import './SalespipeStageTable.css'
import TableCheckmarkHeader from '../components/TableCheckmarkHeader'
import TableCheckmarkRow from '../components/TableCheckmarkRow'
import CircularProgress from '@mui/material/CircularProgress'

function SalespipeStageTable({ stage, filters, selectedRows, setSelectedRows, salesPipe, onClickRow, id, handleLoadMore, loadingMore, salesPipeCounts, page, limit, fullLists }) {

  const [sortFilter, setSortFilter] = useState("Default")
  const [sortAsc, setSortAsc] = useState(true)
  const [refreshState, setRefreshState] = useState(false)

  const handleSetSortFilter = (event) => {
    setSortFilter(event.currentTarget.innerHTML)
    setSortAsc(!sortAsc)
  }

  return (
    <div className="table" id="salespipe--table">
      <div className="table__header-10">
        <TableCheckmarkHeader
          id={`table-checkmark-header-salespipe-${id}`}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
        <div className="table__header--field clickable" onClick={handleSetSortFilter}>Business name</div>
        <div className="table__header--field clickable" onClick={handleSetSortFilter}>Owner</div>
        <div className="table__header--field clickable" onClick={handleSetSortFilter}>Contact name</div>
        <div className="table__header--field clickable" onClick={handleSetSortFilter}>Tel</div>
        <div className="table__header--field clickable" onClick={handleSetSortFilter}>Email</div>
        <div className="table__header--field clickable" onClick={handleSetSortFilter}>City</div>
        <div className="table__header--field clickable" onClick={handleSetSortFilter}>Type</div>
        <div className="table__header--field clickable" onClick={handleSetSortFilter}>System Tag</div>
        <div className="table__header--field clickable" onClick={handleSetSortFilter}>Custom Tag</div>
      </div>
      <div className='table__rows_container'>
        {
          Array.isArray(salesPipe) && salesPipe.length !== 0 &&
          [...salesPipe] 
            .sort((a, b) => {
              const getValue = (item) => {
                switch (sortFilter) {
                  case "Business name":
                    return item.customer_id?.business_name || ""
                  case "Owner":
                    return item.owner_id?.first_name || ""
                  case "Contact name":
                    return item.customer_id?.first_name || ""
                  case "Tel":
                    return item.customer_id?.tel_number || ""
                  case "Email":
                    return item.customer_id?.email || ""
                  case "City":
                    return item.customer_id?.delivery_address_place || ""
                  case "Type":
                    return item.customer_id?.type || ""
                  case "System Tag":
                    return item.tag1_id?.tag || ""
                  case "Custom Tag":
                    return item.tag2_id?.tag || ""
                  default:
                    return ""
                }
              }

              const nameA = getValue(a).toUpperCase()
              const nameB = getValue(b).toUpperCase()

              if (nameA < nameB) {
                return sortAsc ? -1 : 1
              }

              if (nameA > nameB) {
                return sortAsc ? 1 : -1
              }

              return 0
            })
            .map((customer, index) => {

              let tag1Color = ""

              switch (customer.tag1) {
                case "slow":
                  tag1Color = "blue"
                  break
                case "lost":
                  tag1Color = "red"
                  break
                case "star":
                  tag1Color = "gold"
                  break
                case "cold":
                  tag1Color = "blue"
                  break
              }

              let tag2Color = ""

              switch (customer.tag2) {
                case "slow":
                  tag1Color = "blue"
                  break
                case "lost":
                  tag1Color = "red"
                  break
                case "star":
                  tag1Color = "gold"
                  break
              }

              return (
                <div className="table__row-10" id='checkmark-row' data-customer-id={customer.customer_id_of_user} data-user-id={customer.user_id_of_customer} data-sales-pipe-id={customer.id} key={index} onClick={onClickRow} >
                  <TableCheckmarkRow
                    index={index}
                    id={`${id}-${index}`}
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                  />
                  <div className="table__row--field">{customer.customer_id.business_name}</div>
                  <div className="table__row--field">{customer.owner_id != null && `${customer.owner_id.first_name} ${customer.owner_id.last_name != null ? customer.owner_id.last_name : ""}`}</div>
                  <div className="table__row--field">{`${customer.customer_id.first_name != null ? customer.customer_id.first_name : ""} ${customer.customer_id.last_name != null ? customer.customer_id.last_name : ""}`}</div>
                  <div className="table__row--field">{customer.customer_id.tel_number}</div>
                  <div className="table__row--field">{customer.customer_id.email}</div>
                  <div className="table__row--field">{customer.customer_id.delivery_address_place}</div>
                  <div className="table__row--field">{customer.customer_id.type} {customer.customer_id.sub_type != "N/A" && customer.customer_id.sub_type}</div>
                  <div className="table__row--field"><div className={`customer-fiche_tag ${customer.tag1_id != null && customer.tag1_id.tag_color_name}`}>{customer.tag1_id != null && customer.tag1_id.tag}</div></div>
                  <div className="table__row--field"><div className={`customer-fiche_tag ${customer.tag2_id != null && customer.tag2_id.tag_color_name}`}>{customer.tag2_id != null && customer.tag2_id.tag}</div></div>
                </div>
              )
            })
        }
      </div>
      {
        salesPipe?.length != 0 && (salesPipeCounts > (page * limit)) && !fullLists &&
        <button className="load_more_button--table" data-stage="PROSPECTS" onClick={handleLoadMore}>{loadingMore ? <CircularProgress color="inherit" size="1rem" /> : "Load more..."}</button>
      }
    </div>
  )
}



export default SalespipeStageTable