export function formatDateExportTitle(date) {
  // Create a date object from the provided date
  var d = new Date(date);

  // Extract month, day, and year from local date
  var month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  // Add leading zero if necessary
  if (month.length < 2)
      month = '0' + month;
  if (day.length < 2)
      day = '0' + day;

  // Return the formatted date
  return [year, month, day].join('');
}

export function formatDate(dateString) {
  const date = new Date(dateString);

  // Extract day, month, and year from local date
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}


export function formatDateWithTime(dateString, beautify=false) {
  const date = new Date(dateString);

  // Extract day, month, year, hours, and minutes from local date
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  if (beautify) {
    return `${day}/${month}/${year} ${String.fromCodePoint(0x1F553)} ${hours}:${minutes}`;
  } else {
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  }
}


export function getRoundedDateHalfHour() {
  const now = new Date();
  let minutes = now.getMinutes();
  let hours = now.getHours();

  if (minutes === 0) {
    // No change needed if exactly on the hour
  } else if (minutes <= 30) {
    // Round up to the next half hour
    minutes = 30;
  } else {
    // Round up to the next hour
    minutes = 0;
    hours++;

    // Adjust for the next day if it goes beyond 23 hours
    if (hours > 23) {
      hours = 0;
      now.setDate(now.getDate() + 1);
    }
  }

  now.setMinutes(minutes);
  now.setHours(hours);
  now.setSeconds(0); // Set seconds to 0

  return now.getTime();
}
