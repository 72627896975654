import React, { useState, useEffect } from "react"
import httpClient from "../httpClient"
import { useSelector, useDispatch } from "react-redux"
import { resetStatePerformancePage, updateFilters, updatePerformance, updateAllTimePerformance, updatePerformanceDetail } from "../store/performancePage"
import Slider from "../components/Slider"
import "./SalesforcePageBrandOwnerPerformance.css"
import Header from "../components/Header"
import MenuBar from "../components/MenuBar"
import ComingSoonOverlay from "../components/placeholders/ComingSoonOverlay"
import DropDownFilter from "../components/DropDownFilter"
import CircularProgress from '@mui/material/CircularProgress'
import { PopupWindow } from "../components/PopupWindow"
import { formatDateWithTime } from "../components/helpers/FormatDate"
import PerformanceDetailTable from "../components/PerformanceDetailTable"

function SalesforcePageBrandOwnerPerformance() {
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState({
    "performance": true,
    "allTimePerformance": true,
    "performanceDetail": false,
    "performanceDetailMore": true,
  })
  const [popupWindowDetailIsOpen, setPopupWindowDetailIsOpen] = useState(false)
  const [selectedSalesRep, setSelectedSalesRep] = useState()
  const [detailHeader, setDetailHeader] = useState("visits")
  const [detailPage, setDetailPage] = useState(1)
  const [detailLimit, setDetailLimit] = useState(15)
  const [detailHasMore, setDetailHasMore] = useState({})

  const dispatch = useDispatch()
  const performance = useSelector(state => state.performancePage.performance)
  const performanceDetail = useSelector(state => state.performancePage.performanceDetail)
  const allTimePerformance = useSelector(state => state.performancePage.allTimePerformance)
  const filters = useSelector(state => state.performancePage.filters)
  const customerTypesArray = useSelector(state => state.user.customer_types)

  const arrayOfTypes = Object.keys(customerTypesArray)

  const fetchPerformance = async () => {
    const response = await httpClient.post(process.env.REACT_APP_API_URL + `/api/brandowner/salesforce/performance`, filters)
    let data = response.data

    switch (filters.ranking_type) {
      case "by sales":
        data.performance_salesforce.sort((a, b) => {
          const salesA = a.total_sales === null ? 0 : parseInt(a.total_sales, 10)
          const salesB = b.total_sales === null ? 0 : parseInt(b.total_sales, 10)

          return salesB - salesA
        })
        break

      case "by hit rate":
        data.performance_salesforce.sort((a, b) => {
          const winRateA = a.hit_rate === null ? 0 : parseFloat(a.hit_rate)
          const winRateB = b.hit_rate === null ? 0 : parseFloat(b.hit_rate)
          return winRateB - winRateA
        })
        break

      case "by visits":
        data.performance_salesforce.sort((a, b) => {
          const visitsA = a.total_visits === null ? 0 : parseInt(a.total_visits, 10)
          const visitsB = b.total_visits === null ? 0 : parseInt(b.total_visits, 10)

          return visitsB - visitsA
        })
        break
    }

    if (filters.hide_inactive) {
      data.performance_salesforce = data.performance_salesforce.filter(salesPerformance => salesPerformance.user.is_active)
    }

    dispatch(updatePerformance(data))
    setLoading(prevState => ({
      ...prevState,
      performance: false
    }))
  }

  const fetchPerformanceDetail = async (page = 1) => {
    setLoading(prevState => ({ ...prevState, performanceDetailMore: true }))

    try {    
      const response = await httpClient.post(
        `${process.env.REACT_APP_API_URL}/api/brandowner/salesforce/performance?type=detail&page=${page}&limit=${detailLimit}&salesRepId=${selectedSalesRep?.user.id}`,
        filters
      )
      
      const newData = response.data.detail[selectedSalesRep?.user.id]
      const hasMoreData = response.data.has_more

      const updatedData = {
        ...performanceDetail,
        [selectedSalesRep?.user.id]: {
          visits: page === 1 ? newData.visits : [...(performanceDetail[selectedSalesRep?.user.id]?.visits || []), ...newData.visits],
          win_clients: page === 1 ? newData.win_clients : [...(performanceDetail[selectedSalesRep?.user.id]?.win_clients || []), ...newData.win_clients],
          lost_clients: page === 1 ? newData.lost_clients : [...(performanceDetail[selectedSalesRep?.user.id]?.lost_clients || []), ...newData.lost_clients],
          win_products: page === 1 ? newData.win_products : [...(performanceDetail[selectedSalesRep?.user.id]?.win_products || []), ...newData.win_products],
          lost_products: page === 1 ? newData.lost_products : [...(performanceDetail[selectedSalesRep?.user.id]?.lost_products || []), ...newData.lost_products],
          sales: page === 1 ? newData.sales : [...(performanceDetail[selectedSalesRep?.user.id]?.sales || []), ...newData.sales],
        }
      }

      dispatch(updatePerformanceDetail(updatedData))
      setDetailHasMore(hasMoreData)
      setDetailPage(page)
    } catch (error) {
      console.error('Error fetching performance details:', error)
    } finally {
      setLoading(prevState => ({ ...prevState, performanceDetail: false }))
      setLoading(prevState => ({ ...prevState, performanceDetailMore: false }))
    }
  }

  const fetchPerformanceAlltime = async () => {
    const response = await httpClient.post(process.env.REACT_APP_API_URL + `/api/brandowner/salesforce/alltime-performance`, filters)
    let data = response.data

    if (filters.hide_inactive) {
      data = data.filter(salesPerformance => salesPerformance.user.is_active)
    }

    switch (filters.ranking_type) {
      case "by sales":
        data.sort((a, b) => {
          const salesA = a.total_sales === null ? 0 : parseInt(a.total_sales, 10)
          const salesB = b.total_sales === null ? 0 : parseInt(b.total_sales, 10)

          return salesB - salesA
        })

      case "by hit rate":
        data.sort((a, b) => {
          const winRateA = a.hit_rate === null ? 0 : parseFloat(a.hit_rate)
          const winRateB = b.hit_rate === null ? 0 : parseFloat(b.hit_rate)
          return winRateB - winRateA
        })
        break

      case "by visits":
        data.sort((a, b) => {
          const visitsA = a.total_visits === null ? 0 : parseInt(a.total_visits, 10)
          const visitsB = b.total_visits === null ? 0 : parseInt(b.total_visits, 10)

          return visitsB - visitsA
        })
        break
    }


    dispatch(updateAllTimePerformance(data))
    setLoading(prevState => ({
      ...prevState,
      allTimePerformance: false
    }))
  }

  useEffect(() => {
    fetchPerformance()
      .catch(err => {
        console.log(err)
        setError(true)
      })

    fetchPerformanceAlltime()
      .catch(err => {
        console.log(err)
        setError(true)
      })
  }, [])

  useEffect(() => {
    setLoading(prevState => ({
      ...prevState,
      performance: true
    }))
    fetchPerformance()
  }, [filters])

  useEffect(() => {
    if (selectedSalesRep) {
      setLoading(prevState => ({
        ...prevState,
        performanceDetail: true
      }))
      dispatch(updatePerformanceDetail([]))
      fetchPerformanceDetail()
    }
  }, [selectedSalesRep])

  useEffect(() => {
    setLoading(prevState => ({
      ...prevState,
      allTimePerformance: true
    }))
    fetchPerformanceAlltime()
  }, [filters.hide_inactive])

  useEffect(() => {
    setLoading(prevState => ({
      ...prevState,
      allTimePerformance: true
    }))
    fetchPerformanceAlltime()
  }, [filters.ranking_type])

  const handleLoadMore = () => {
    fetchPerformanceDetail(detailPage + 1)
  }

  const handleHideInactive = () => {
    const newFilterObj = { ...filters }
    newFilterObj.hide_inactive = !filters.hide_inactive
    dispatch(updateFilters(newFilterObj))
  }

  const handleOnClickFilterItem = (event) => {

    const filter = event.currentTarget.textContent
    event.target.parentElement.classList.toggle("active")

    const updatedFiltersObj = { ...filters }

    switch (event.target.parentElement.id) {
      case "perfomance-page--period":
        updatedFiltersObj["period"] = filter
        break
      case "perfomance-page--ranking":
        updatedFiltersObj["ranking_type"] = filter
        break
    }

    dispatch(updateFilters(updatedFiltersObj))
  }

  const handleClickDetail = (salesRep) => {
    setPopupWindowDetailIsOpen(true)
    setSelectedSalesRep(salesRep)
  }

  const handleOnCLickHeaderItem = (event, data) => {
    const headerItem = event.currentTarget
    const allHeaderItems = document.getElementsByClassName("body__section__header_item")

    allHeaderItems.forEach(item => {
      item.classList.remove("selected")
    })
    headerItem.classList.add("selected")

    setDetailHeader(data)
  }

  const handleOnMouseOver = (event) => {
    const headerItem = event.currentTarget
    headerItem.classList.add("hovered")
  }

  const handleOnMouseOut = (event) => {
    const headerItem = event.currentTarget
    headerItem.classList.remove("hovered")
  }

  return (
    <div className="template">

      <Header>
        <div className="header__options">
          <div className="header__option--slider"><Slider size={1.2} text="Hide inactive" onClickFunction={handleHideInactive} defaultState={false} state={filters.hide_inactive} /></div>
        </div>
        <div className={`header__options--right-template`} id="header__options--performance-page">

          {
            popupWindowDetailIsOpen ?
              <>
                {/* <DropDownFilter
                  idList="perfomance-page-detail--customer-type"
                  placeholderText="Filter on customer type"
                  listArray={arrayOfTypes}
                  onClickItem={handleOnClickFilterItem}
                  value={filters.detail_customer_types}
                  filter={"detail_customer_types"}
                  setValue={updateFilters}
                  dropdownType={"performancePage"}
                  widthSize={"big"}
                  clearOption={false}
                  searchBar={false}
                /> */}
              </> :
              <>
                <DropDownFilter
                  idList="perfomance-page--period"
                  placeholderText="Period"
                  listArray={["today", "yesterday", "this week", "last week", "this month", "last month", "this year", "all time"]}
                  onClickItem={handleOnClickFilterItem}
                  value={filters.period}
                  filter={"period"}
                  setValue={updateFilters}
                  dropdownType={"performancePage"}
                  widthSize={"medium"}
                  clearOption={false}
                  innerTitle={"Period: "}
                  searchBar={false}
                />
                <DropDownFilter
                  idList="perfomance-page--ranking"
                  placeholderText="Ranking type"
                  listArray={["by sales", "by hit rate", "by visits"]}
                  onClickItem={handleOnClickFilterItem}
                  value={filters.ranking_type}
                  filter={"ranking_type"}
                  setValue={updateFilters}
                  dropdownType={"performancePage"}
                  widthSize={"medium"}
                  clearOption={false}
                  innerTitle={"Ranking: "}
                  searchBar={false}
                />
              </>
          }
        </div>
      </Header>
      <MenuBar />
      <div className="template__body">
        <div className="body__salesforce--brandowner">

          <PopupWindow
            popupID="performance-detail--popup"
            isActive={popupWindowDetailIsOpen}
            setIsActive={setPopupWindowDetailIsOpen}
            overflow={"hidden"}
          >
            <div className="popup__header">
              {/* <div className="body__section__header_item selected" data-id="header-contact-details" onClick={(e) => handleOnCLickHeaderItem(e, "overview")} onMouseOver={handleOnMouseOver} onMouseOut={handleOnMouseOut}>Overview</div> */}
              <div className="body__section__header_item selected" data-id="header-details" onClick={(e) => handleOnCLickHeaderItem(e, "visits")} onMouseOver={handleOnMouseOver} onMouseOut={handleOnMouseOut}>Visits</div>
              <div className="body__section__header_item" data-id="header-details" onClick={(e) => handleOnCLickHeaderItem(e, "sales")} onMouseOver={handleOnMouseOver} onMouseOut={handleOnMouseOut}>Sales</div>
              <div className="body__section__header_item" data-id="header-availability" onClick={(e) => handleOnCLickHeaderItem(e, "clients")} onMouseOver={handleOnMouseOver} onMouseOut={handleOnMouseOut}>Client Win/Loss</div>
              <div className="body__section__header_item" data-id="header-suppliers" onClick={(e) => handleOnCLickHeaderItem(e, "listings")} onMouseOver={handleOnMouseOver} onMouseOut={handleOnMouseOut}>Listing Win/Loss</div>

            </div>
            <div className="popup__body">
              {
                loading.performanceDetail ?
                  <div className="loading_spinner_circle"><CircularProgress color="inherit" size="2rem" /></div> :
                  <div className="performance_detail">
                    {/* {
                      detailHeader == "overview" &&
                      <div>
                        {selectedSalesRep?.user.first_name}
                      </div>
                    } */}

                    {
                      detailHeader == "visits" &&
                      <PerformanceDetailTable
                        dataSet1={performanceDetail[selectedSalesRep?.user.id]?.visits}
                        type={'visits'}
                        refreshOn={popupWindowDetailIsOpen}
                        hasMoreData={detailHasMore.visits}
                        onLoadMore={handleLoadMore}
                        loadingMore={loading.performanceDetailMore}
                      />
                    }

                    {
                      detailHeader == "clients" &&
                      <PerformanceDetailTable
                        dataSet1={performanceDetail[selectedSalesRep?.user.id]?.win_clients}
                        dataSet2={performanceDetail[selectedSalesRep?.user.id]?.lost_clients}
                        type={'clients'}
                        refreshOn={popupWindowDetailIsOpen}
                        hasMoreData={detailHasMore.win_clients || detailHasMore.lost_clients}
                        onLoadMore={handleLoadMore}
                        loadingMore={loading.performanceDetailMore}
                      />
                    }

                    {
                      detailHeader == "listings" &&
                      <PerformanceDetailTable
                        dataSet1={performanceDetail[selectedSalesRep?.user.id]?.win_products}
                        dataSet2={performanceDetail[selectedSalesRep?.user.id]?.lost_products}
                        type={'products'}
                        refreshOn={popupWindowDetailIsOpen}
                        hasMoreData={detailHasMore.win_products || detailHasMore.lost_products}
                        onLoadMore={handleLoadMore}
                        loadingMore={loading.performanceDetailMore}
                      />
                    }

                    {
                      detailHeader == "sales" &&
                      <PerformanceDetailTable
                        dataSet1={performanceDetail[selectedSalesRep?.user.id]?.sales}
                        type={'sales'}
                        refreshOn={popupWindowDetailIsOpen}
                        hasMoreData={detailHasMore.sales}
                        onLoadMore={handleLoadMore}
                        loadingMore={loading.performanceDetailMore}
                      />
                    }
                  </div>
              }
            </div>
          </PopupWindow>

          <div className="body__section" id="total-visits">
            <div className="title_tag" id="visits-title">VISITS</div>
            <h2>visits</h2>
            <div className="performance_content_wrapper">
              {
                loading.performance ?
                  <div className="loading_spinner_circle"><CircularProgress color="inherit" size="2rem" /></div> :
                  <div className="performance_content--number">
                    {performance.total_visits}
                  </div>
              }
            </div>
          </div>
          <div className="body__section" id="total-visits-unique">
            <h2>unique visits</h2>
            <div className="performance_content_wrapper">
              {
                loading.performance ?
                  <div className="loading_spinner_circle"><CircularProgress color="inherit" size="2rem" /></div> :
                  <div className="performance_content--number">
                    {performance.total_unique_visits.toFixed(0)}
                  </div>
              }
            </div>
          </div>
          <div className="body__section" id="total-visits-unique-percent">
            <h2>unique visits %</h2>
            <div className="performance_content_wrapper">
              {
                loading.performance ?
                  <div className="loading_spinner_circle"><CircularProgress color="inherit" size="2rem" /></div> :
                  <div className="performance_content--number">
                    {isNaN(performance.total_unique_visits / performance.total_visits)
                      ? 0
                      : ((performance.total_unique_visits / performance.total_visits) * 100).toFixed(0)}%
                  </div>
              }
            </div>
          </div>
          <div className="body__section" id="total-new">
            <div className="title_tag" id="clients-title">CLIENTS</div>
            <h2>win</h2>
            <div className="performance_content_wrapper">
              {
                loading.performance ?
                  <div className="loading_spinner_circle"><CircularProgress color="inherit" size="2rem" /></div> :
                  <div className="performance_content--number">
                    {performance.total_win_clients}
                  </div>
              }
            </div>
          </div>
          <div className="body__section" id="total-lost">
            <h2>lost</h2>
            <div className="performance_content_wrapper">
              {
                loading.performance ?
                  <div className="loading_spinner_circle"><CircularProgress color="inherit" size="2rem" /></div> :
                  <div className="performance_content--number">
                    {performance.total_lost_clients}
                  </div>
              }
            </div>
          </div>
          <div className="body__section" id="net-new">
            <h2>net new</h2>
            <div className="performance_content_wrapper">
              {
                loading.performance ?
                  <div className="loading_spinner_circle"><CircularProgress color="inherit" size="2rem" /></div> :
                  <div className="performance_content--number">
                    {performance.total_win_clients - performance.total_lost_clients}
                  </div>
              }
            </div>
          </div>
          <div className="body__section" id="total-new-listings">
            <div className="title_tag" id="clients-title">LISTINGS</div>
            <h2>win</h2>
            <div className="performance_content_wrapper">
              {
                loading.performance ?
                  <div className="loading_spinner_circle"><CircularProgress color="inherit" size="2rem" /></div> :
                  <div className="performance_content--number">
                    {performance.total_win_products}
                  </div>
              }
            </div>
          </div>
          <div className="body__section" id="total-lost-listings">
            <h2>lost</h2>
            <div className="performance_content_wrapper">
              {
                loading.performance ?
                  <div className="loading_spinner_circle"><CircularProgress color="inherit" size="2rem" /></div> :
                  <div className="performance_content--number">
                    {performance.total_lost_products}
                  </div>
              }
            </div>
          </div>
          <div className="body__section" id="net-new-listings">
            <h2>net new</h2>
            <div className="performance_content_wrapper">
              {
                loading.performance ?
                  <div className="loading_spinner_circle"><CircularProgress color="inherit" size="2rem" /></div> :
                  <div className="performance_content--number">
                    {performance.total_win_products - performance.total_lost_products}
                  </div>
              }
            </div>
          </div>
          <div className="body__section" id="all-time-leaderbord">
            <h2>all time leaderboard</h2>
            <div className="performance_content_wrapper">
              {
                loading.allTimePerformance ?
                  <div className="loading_spinner_circle"><CircularProgress color="inherit" size="2rem" /></div> :
                  <div className="performance_content--leaderboard alltime">
                    {
                      allTimePerformance.map((salesRep, index) => {
                        const imagePath = require(`../assets/pics/${salesRep.user.image_name}`)
                        return (
                          <div className="sales_rep__fiche" key={index}>
                            <img
                              key={index}
                              src={imagePath}
                              alt={salesRep.user?.first_name || 'Sales Representative STOQUP'}
                            />
                            <div className="name">{salesRep.user?.first_name} {salesRep.user?.last_name}</div>
                            <div className="data">
                              <h3>visits</h3>
                              <p>{salesRep.total_visits}</p>
                            </div>
                            <div className="data">
                              <h3>clients</h3>
                              <p>{salesRep.total_win_clients}</p>
                            </div>
                            <div className="data">
                              <h3>hit rate</h3>
                              <p>{(salesRep.hit_rate * 100).toFixed(0)}%</p>
                            </div>
                            <div className="data">
                              <h3>listings</h3>
                              <p>{salesRep.total_win_products}</p>
                            </div>
                            <div className="data">
                              <h3>sales</h3>
                              <p>{salesRep.total_sales ? salesRep.total_sales : 0}</p>
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
              }
            </div>
          </div>
          <div className="body__section" id="leaderboard">
            <h2>leaderboard</h2>
            <div className="performance_content_wrapper">
              {
                loading.performance ?
                  <div className="loading_spinner_circle"><CircularProgress color="inherit" size="2rem" /></div> :
                  <div className="performance_content--leaderboard">
                    {
                      performance.performance_salesforce.map((salesRep, index) => {
                        const imagePath = require(`../assets/pics/${salesRep.user.image_name}`)
                        let medalImage = null
                        switch (index) {
                          case 0:
                            medalImage = require(`../assets/pictos/medalGold.png`)
                            break
                          case 1:
                            medalImage = require(`../assets/pictos/medalSilver.png`)
                            break
                          case 2:
                            medalImage = require(`../assets/pictos/medalBrons.png`)
                            break
                        }

                        return (
                          <div className="sales_rep__fiche" key={index} onClick={() => handleClickDetail(salesRep)}>
                            {
                              medalImage &&
                              <img className="medal" src={medalImage} />
                            }
                            <img
                              key={index}
                              src={imagePath}
                              alt={salesRep.user?.first_name || 'Sales Representative STOQUP'}
                            />
                            <div className="name">{salesRep.user?.first_name} {salesRep.user?.last_name}</div>
                            <div className="data">
                              <h3>visits</h3>
                              <p>{salesRep.total_visits}</p>
                            </div>
                            <div className="data">
                              <h3>unique</h3>
                              <p>{salesRep.total_unique_visits}</p>
                            </div>
                            <div className="data">
                              <h3>Clients</h3>
                              <p>{salesRep.total_win_clients}</p>
                            </div>
                            <div className="data">
                              <h3>Hit rate</h3>
                              <p>{(salesRep.hit_rate * 100).toFixed(0)}%</p>
                            </div>
                            <div className="data">
                              <h3>Listings</h3>
                              <p>{salesRep.total_win_products}</p>
                            </div>
                            <div className="data">
                              <h3>sales</h3>
                              <p>{salesRep.total_sales ? salesRep.total_sales : 0}</p>
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

export default SalesforcePageBrandOwnerPerformance