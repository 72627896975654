import React, {useState, useEffect} from "react"
import { Link } from "react-router-dom"
import httpClient from "../httpClient"

import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined'
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined'
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined'
import GroupIcon from '@mui/icons-material/Group'
import PaymentsIcon from '@mui/icons-material/Payments'
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined'
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined'
import DescriptionIcon from '@mui/icons-material/Description'
import { current } from "@reduxjs/toolkit"

function MenuBar() {
  const [user, setUser] = useState({email: "", id: ""})
  const [error, setError] = useState(false)
  
  const fetchUser = async () => {
    const responseUser = await httpClient.get(process.env.REACT_APP_API_URL + "/api/@me?type=full")
    setUser(responseUser.data)
  }

  useEffect(() => {
    fetchUser()
      .catch( err => {
        console.log(err)
        setError(true)
      })
  }, [])

  const handleClickMenuItem = (e) => {
    const menu = e.currentTarget.parentElement.nextSibling
    const asideMenu = document.querySelector("#template__aside")
    const allSubMenus = document.querySelectorAll("#sub-menu")

    if(!menu) return
    if(menu.id !== "sub-menu") return allSubMenus.forEach(menu => menu.classList.remove("active"))

    menu.classList.toggle("active")
    asideMenu.classList.remove("mini")
  }

  return (
    <div className="primary__menu">

      <Link to="/admin/home">
        <div className="menu__item" onClick={handleClickMenuItem}><AnalyticsOutlinedIcon/><p className="menu__title">Dashboard</p></div>
      </Link>
      <Link to="/admin/sales">
        <div className="menu__item" onClick={handleClickMenuItem}><ShoppingCartOutlinedIcon/><p className="menu__title">Sales</p></div>
      </Link>
      <Link to="/customers">
        <div className="menu__item" onClick={handleClickMenuItem}><PeopleAltOutlinedIcon/><p className="menu__title">Customers</p></div>
      </Link>
      <Link to="/admin/users">
        <div className="menu__item" onClick={handleClickMenuItem}><GroupIcon/><p className="menu__title">Users</p></div>
      </Link>
      <Link to="/admin/home">
        <div className="menu__item" onClick={handleClickMenuItem}><PaymentsIcon/><p className="menu__title">Payments</p></div>
      </Link>
      <Link to="/admin/invoices">
        <div className="menu__item" onClick={handleClickMenuItem}><DescriptionIcon/><p className="menu__title">Invoices</p></div>
      </Link>
      

    </div>
)}

export default MenuBar
