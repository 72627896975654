import React, { useState, useEffect, useRef } from "react"
import httpClient from "../httpClient"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { addUserInfo } from "../store/user"
import "./HomePageBrandOwner.css"
import Header from "../components/Header"
import MenuBar from "../components/MenuBar"
import ComingSoonOverlay from "../components/placeholders/ComingSoonOverlay"
import TotalSales from "../components/TotalSales"
import TodoToday from "../components/TodoToday"
import BestSellers from "../components/BestSellers"
import MySales from "../components/MySales"
import LoadingSpinner from "../components/placeholders/LoadingSpinner"
import CircularProgress from '@mui/material/CircularProgress'
import { askPermissionAndSubscribe } from "../components/helpers/ServiceWorker"

function HomePageBrandOwner() {
  const [user, setUser] = useState({ email: "", id: "" })
  const [error, setError] = useState(false)
  const [activeClients, setActiveClients] = useState({})
  const [bestClients, setBestClients] = useState([])
  const [loadingBestClients, setLoadingBestClients] = useState(true)

  const dispatch = useDispatch()
  const userInfo = useSelector(state => state.user)
  const previousUserIdRef = useRef()

  const fetchUser = async () => {
    const responseUser = await httpClient.get(process.env.REACT_APP_API_URL + "/api/@me?type=full")
    setUser(responseUser.data)
  }

  const fetchActiveClients = async () => {
    const responseActiveClients = await httpClient.get(process.env.REACT_APP_API_URL + "/api/orders/report?user=&type=activeClients")
    setActiveClients(responseActiveClients.data)
  }

  const fetchBestClients = async () => {
    setLoadingBestClients(true)
    const responseBestClients = await httpClient.get(process.env.REACT_APP_API_URL + "/api/orders/report?user=&type=bestClients")
    setBestClients(responseBestClients.data)
    setLoadingBestClients(false)
  }

  useEffect(() => {
    fetchUser()
      .catch(err => {
        console.log(err)
        setError(true)
      })

    fetchActiveClients()
      .catch(err => {
        console.log(err)
        setError(true)
      })

    fetchBestClients()
      .catch(err => {
        console.log(err)
        setError(true)
      })

    document.getElementById("header-title").classList.remove("hide")
  }, [])

  useEffect(() => {
    if (userInfo.id && userInfo.id !== previousUserIdRef.current) {
      previousUserIdRef.current = userInfo.id
      askPermissionAndSubscribe(userInfo.id)
    }
  }, [userInfo])

  function roundTo(n, place) {
    return +(Math.round(n + "e+" + place) + "e-" + place);
  }

  const activeUsersPerctChange = roundTo(((activeClients[0] / activeClients[1]) - 1) * 100, 2)

  return (
    <div className="template">
      {error ? window.location.href = "/" : ""}

      <Header />
      <MenuBar />
      <div className="template__body">
        <div className="body__home-brandowner">
          <div className="body__section no-scrollbar" id="section1">
            <TotalSales />
          </div>
          <div className="body__section no-scrollbar" id="section2">
            <BestSellers />
          </div>
          <div className="body__section no-scrollbar" id="section3">
            <MySales />
          </div>
          <div className="body__section no-scrollbar" id="section4">
            <h2 className="flex">active clients</h2>
            <div className="indicator__container">
              <div className="big_number">{activeClients[0]}</div>
              <div className="indicator">
                <div className={isNaN(activeUsersPerctChange) ? "hide" : activeUsersPerctChange <= 0 ? "indicator__arrow-down" : "indicator__arrow-up"}></div>
                <p className={isNaN(activeUsersPerctChange) ? "indicator__number hide" : activeUsersPerctChange <= 0 ? "indicator__number red" : "indicator__number green"}>{`${activeUsersPerctChange}%`}</p>
              </div>
            </div>
          </div>

          <div className="body__section no-scrollbar" id="section6">
            <h2 className="flex">best clients</h2>
            <div className="ranking no-scrollbar">
              {
                loadingBestClients ?
                <div className='circular_loading_spinner__wrapper'><CircularProgress color='inherit' size="2rem" /></div> :
                  <>
                    {
                      bestClients.length == 0 ?
                        <p>No sales recorded yet</p>
                        :
                        bestClients.map((client, index) => {
                          return <div className="podium__element--best-clients" key={index}>
                            <div className="podium__element--counter">{index + 1}</div>
                            <div>{client.business_name}</div>
                          </div>
                        })
                    }
                  </>
              }
            </div>

          </div>
          <div className="body__section" id="section5">
            <TodoToday />
          </div>

        </div>
      </div>
    </div>
  )
}
export default HomePageBrandOwner