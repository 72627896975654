import React from "react";

function ProfilePicMan1() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
      <circle cx="64" cy="64" r="64" fill="#1597ff"></circle>
      <path
        fill="#f85863"
        d="M64 128c18.2 0 34.6-7.6 46.3-19.8-6.7-13.4-21.5-22.5-46.3-22.5s-39.6 9.1-46.3 22.5C29.4 120.4 45.8 128 64 128z"
      ></path>
      <path
        fill="#fff"
        d="M52.6 71.6v17.1c0 6.1 5 11.1 11.1 11.1s11.1-5 11.1-11.1V71.6z"
      ></path>
      <path
        fill="#01277b"
        d="M52.6 73.6v6.6c3.1 2.9 6.8 5 11.1 6.2s8 1 11.1-.2V73.6z"
      ></path>
      <g fill="#fff">
        <circle cx="42.3" cy="61.3" r="6.3"></circle>
        <circle cx="85.1" cy="61.3" r="6.3"></circle>
      </g>
      <ellipse cx="63.6" cy="42.9" fill="#01277b" rx="29" ry="29.7"></ellipse>
      <ellipse cx="63.7" cy="56" fill="#fff" rx="22" ry="27.5"></ellipse>
      <g fill="#01277b">
        <ellipse
          cx="59.9"
          cy="27.9"
          rx="17"
          ry="13.4"
          transform="rotate(-5.73 60.219 27.981)"
        ></ellipse>
        <ellipse
          cx="81.8"
          cy="29.1"
          rx="13.5"
          ry="10"
          transform="rotate(-6.78 82.097 29.056)"
        ></ellipse>
      </g>
      <g fill="#01277b">
        <circle cx="54.2" cy="52.2" r="2.1"></circle>
        <circle cx="73.2" cy="52.2" r="2.1"></circle>
        <path d="M73.2 62c0 5.3-4.3 9.5-9.5 9.5-5.3 0-9.5-4.3-9.5-9.5z"></path>
      </g>
    </svg>
  );
}

export default ProfilePicMan1;