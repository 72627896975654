import React, { useState, useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { store } from '../store/store'
import { useDispatch, useSelector } from 'react-redux'
import httpClient from '../httpClient'
import Header from '../components/Header'
import MenuBar from '../components/MenuBar'
import './InvoicesPage.css'
import { Link } from 'react-router-dom'
import TableCheckmarkHeader from '../components/TableCheckmarkHeader'
import TableCheckmarkRow from '../components/TableCheckmarkRow'
import Label from '../components/Label'
import AdminInvoice from '../components/AdminInvoice'
import AdminInvoiceAnnexe from '../components/AdminInvoiceAnnexe'
import LoadingSpinnerOverlay from '../components/placeholders/LoadingSpinnerOverlay'

function InvoicesPage() {
  const [user, setUser] = useState({ email: "", id: "" })
  const [error, setError] = useState(false)
  const [invoices, setInvoices] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [sendInvoices, setSendInvoices] = useState(false)

  const userInfo = useSelector(state => state.user.userInfo)

  const fetchInvoices = async () => {
    const responseSubscribedUsers = await httpClient.get(process.env.REACT_APP_API_URL + "/api/admin/get-invoices")
    setInvoices(responseSubscribedUsers.data)
  }

  useEffect(() => {
    fetchInvoices()
      .catch(err => {
        console.log(err)
      })
  }, [])

  const changePaymentStatus = async (status) => {
    const selectedInvoices = selectedRows.map(row => {
      return row.getAttribute('data-invoice-id')
    })

    const reponse = await httpClient.post(process.env.REACT_APP_API_URL + `/api/invoices/change-payment-status?status=${status}`, { selectedInvoices: selectedInvoices })
    console.log(reponse.data)

    setSelectedRows([])
    fetchInvoices()
  }

  const sendInvoice = async () => {
    setSendInvoices(true)
    const sendInvoicesButton = document.querySelector("#send-invoices-button")

    sendInvoicesButton.disabled = true

    const selectedInvoices = selectedRows.map(row => {
      return row.getAttribute('data-invoice-id')
    })

    const reponse = await httpClient.post(process.env.REACT_APP_API_URL + `/api/invoices/send-invoice`, { selectedInvoices: selectedInvoices })
    console.log(reponse.data)

    fetchInvoices()
    sendInvoicesButton.disabled = false
    setSelectedRows([])
    setSendInvoices(false)
  }

  return (
    <div className="template">
      {error ? window.location.href = "/" : null}
      {userInfo.type != "admin" ? window.location.href = "/" : null}

      <Header>
        <div className={`header__options--right ${selectedRows.length == 0 ? "hide" : ""}`} id="header__options--invoices-admin">
          <button className='options-button-header medium_small' id='send-invoices-button' onClick={sendInvoice}>
            {
              sendInvoices ?
                <LoadingSpinnerOverlay /> :
                <div>send invoice(s)</div>
            }
          </button>
          <button className='red-action-button' onClick={() => changePaymentStatus("unpaid")}>set as unpaid</button>
          <button className='green-action-button' onClick={() => changePaymentStatus("paid")}>set as paid</button>
        </div>
      </Header>
      <MenuBar />
      <div className="template__body">
        <div className="body__invoices-admin">
          <div className="body__section" id='body-section-admin-users-1'>
            <div className="table">
              <div className="table__header-7">
                <TableCheckmarkHeader
                  id="table-checkmark-header-invoices"
                  selectedRows={selectedRows}
                  setSelectedRows={setSelectedRows}
                />
                <div className="table__header--field">Number</div>
                <div className="table__header--field">Date</div>
                <div className="table__header--field">Customer name</div>
                <div className="table__header--field">Total Amount</div>
                <div className="table__header--field">Type</div>
                <div className="table__header--field">Status</div>
              </div>
              <div className='table__rows_container'>

                {
                  invoices.map((invoice, index) => {
                    return (
                      <Link to={`/admin/invoice/${invoice.invoice_id}`} target="_blank" key={index} >
                        <div className="table__row-7" id='checkmark-row' key={index} data-invoice-id={invoice.invoice_id}>
                          <TableCheckmarkRow
                            index={index}
                            selectedRows={selectedRows}
                            setSelectedRows={setSelectedRows}
                            id={`invoices_adimin-${index}`}
                          />
                          <div className="table__row--field">{invoice.invoice_number}</div>
                          <div className="table__row--field">{invoice.invoice_date}</div>
                          <div className="table__row--field">{invoice.invoice_customer.business_name}</div>
                          <div className="table__row--field">€ {invoice.invoice_amount}</div>
                          <div className="table__row--field">{invoice.invoice_type}</div>
                          <div className="table__row--field relative">
                            {
                              !invoice.irregularities ? (
                                invoice.invoice_status == "To be paid" ?
                                  <Label fontSize="0.70rem" labelColor="red" title="To be paid" /> :
                                  invoice.invoice_status == "Paid" ?
                                    <Label fontSize="0.70rem" labelColor="green" title="Paid" /> :
                                    invoice.invoice_status == "To be send" ?
                                      <Label fontSize="0.70rem" labelColor="blue" title="To be send" /> : null
                              ) : <Label fontSize="0.70rem" labelColor="red" title="Error in calculation" />

                            }
                          </div>
                          <div></div>
                        </div>
                      </Link>
                    )
                  })
                }

              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  )
}

export default InvoicesPage