import React, { useState, useEffect } from 'react'
import { useDispatch } from "react-redux"
import './DateRangeFilter.css'
import Flatpickr from 'react-flatpickr'
import "../styling/flatpickr_dark.css"
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import ClearIcon from '@mui/icons-material/Clear'

function DateRangeFilter({ filters, updateFilters, date }) {

  const dispatch = useDispatch()

  const clearDate = () => {
    const updatedFilters = {...filters}
    updatedFilters["dateFilter"] = null

    dispatch(updateFilters(updatedFilters))
  }

  const handleSetDate = (date) => {
    const updatedFilters = {...filters};
    // Convert Date objects to Unix timestamps to be compatible with Redux
    updatedFilters["dateFilter"] = date.map(d => d.getTime())
  
    dispatch(updateFilters(updatedFilters))
  }

  return (
    <div className={`flatpickr_wrapper ${date ? "filter_active" : ""}`}>
      {
        date == null &&
        <p>Filter on date</p>
      }
      <Flatpickr
        data-enable-time
        value={date ? date.map(d => new Date(d)) : null}
        onChange={date => handleSetDate(date)}
        options={{
          mode: 'range',
          dateFormat: 'd-m-Y',
          enableTime: false,
        }}
      />
      {
        date == null ?
          <CalendarMonthIcon /> :
          <ClearIcon onClick={clearDate} />
      }
    </div>
  )
}

export default DateRangeFilter