import React from "react"
import { useSelector, useDispatch } from "react-redux"
import "./NotificationTop.css"

export function showNotification(type) {

  const errorBox = document.querySelector("#errorBox")

  errorBox.classList.add(type)
  errorBox.classList.add("active")
  setTimeout(() => {
    errorBox.classList.remove("active")
  }, 4000);
  setTimeout(() => {
    errorBox.classList.remove("active")
    errorBox.classList.remove(type)
  }, 5000);
}

export function NotificationTop() {
  const message = useSelector(state => state.notification)

  return (
    <div className='error' id="errorBox">{message}</div>
)}
