import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux"
import httpClient from "../httpClient"
import './PopOutWindowCreateShipment.css'
import CloseIcon from '@mui/icons-material/Close'
import DropdownMultiselect from './DropdownMultiselect'

export function PopOutWindowCreateShipment({ popupID, onClose }) {
  const [warehouseOwners, setWarehouseOwners] = useState([])
  const [myTeam, setMyTeam] = useState([])

  PopOutWindowCreateShipment.defaultProps = {
    onClose: () => console.log(""),
    overflow: "scroll",
  }

  useEffect(() => {
    const popup = document.getElementById(popupID)
    window.addEventListener('keydown', e => {
      if (e.key === 'Escape') {
        if (popup.classList.contains("active")) {
          closePopupWindow()
        }
      }
    })
  }, [])

  const closePopupWindow = () => {
    const popup = document.getElementById(popupID)
    popup.classList.remove("active")
    onClose()
  }

  const handleSave = async () => {

  }

  return (
    <div className='popout__container' id={popupID}>
      <div className='popout__box' id={popupID}>
        <CloseIcon id="popup__close-icon" onClick={closePopupWindow} />
        <div className="popup__content_wrapper">

          <button className="popup__options-button" id="popup__save_inbound--btn" onClick={handleSave}>Save</button>
          <div className="form__section">
          </div>
        </div>
      </div>
    </div>
  )
}