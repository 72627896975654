import React, { useState, useEffect } from 'react'
import './NotificationPopup.css'
import CloseIcon from '@mui/icons-material/Close'
import { useSelector, useDispatch } from "react-redux"

function NotificationPopupSelectWarehouse({ isActive, setIsActive, warehouses, handleClickWarehouseItem }) {

  useEffect(() => {


    window.addEventListener('keydown', e => {
      if (e.key === 'Escape') {
        closePopup()
      }
    })
  }, [])

  const closePopup = () => {
    setIsActive(false)
  }

  return (
    <div className={`notification-popup__overlay--popup-notification select-warehouse ${isActive ? "active" : ""}`} id={`"notification-popup-box-select-warehouse`}>
      <div className={`notification-popup__container ${isActive ? "active" : ""}`} id='notification-popup__container-select-warehouse'>
        <CloseIcon id="popup__close-icon" onClick={() => closePopup()} />
        <div className="notification-popup__elements">
          <div className="notification-popup__elements__title">
            <h3>Select the warehouse:</h3>
          </div>
          <div className="notification-popup__elements__text">
            {
              warehouses.map((warehouse, index) => {
                return (
                  <div className="notification-popup__elements__text_item" key={index} onClick={() => handleClickWarehouseItem(warehouse.id)}>{warehouse.name}</div>
                )
              })
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotificationPopupSelectWarehouse