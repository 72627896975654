import React, { useState, useEffect } from 'react'
import httpClient from '../httpClient'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import './MySales.css'
import LoadingSpinner from './placeholders/LoadingSpinner'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2'
import CircularProgress from '@mui/material/CircularProgress'

function MySales() {
  const [lastMonthSales, setLastMonthSales] = useState([])
  const [thisMonthSales, setThisMonthSales] = useState([])
  const [salesReportPeriod, setSalesReportPeriod] = useState("this month")
  const [loading, setLoading] = useState(true)

  const dispatch = useDispatch()
  const isMobile = useSelector(state => state.global.isMobile)
  const userInfo = useSelector(state => state.user.userInfo)

  const fetchLastMonthSales = async () => {
    setLoading(true)
    const responseLastMonthSales = await httpClient.get(process.env.REACT_APP_API_URL + `/api/orders/report?user=&type=lastMonthSales`)
    setLastMonthSales(responseLastMonthSales.data)
    setLoading(false)
  }

  const fetchThisMonthSales = async () => {
    setLoading(true)
    const responseThisMonthSales = await httpClient.get(process.env.REACT_APP_API_URL + `/api/orders/report?user=&type=thisMonthSales`)
    setThisMonthSales(responseThisMonthSales.data)
    setLoading(false)
  }


  useEffect(() => {
    fetchThisMonthSales()
    fetchLastMonthSales()
  }, [])

  const setPeriod = (event) => {
    const value = event.target.innerHTML
    setSalesReportPeriod(value)

    const filters = document.getElementsByClassName("sales-period-filter")
    Array.from(filters).forEach(filter => filter.classList.remove("underlined"))

    event.target.classList.add("underlined")
  }

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  )

  const options = {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 1.1, 
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        display: false,
      },
      x: {
        display: true,
      },
    },
  }


  const labelsThisMonth = thisMonthSales.map(item => item[0])
  const dataThisMonth = thisMonthSales.map(item => item[1])

  const labelsLastMonth = lastMonthSales.map(item => item[0])
  const dataLastMonth = lastMonthSales.map(item => item[1])

  const chartDataThisMonth = {
    labels: labelsThisMonth,
    datasets: [
      {
        label: 'Dataset 1',
        data: dataThisMonth,
        borderColor: 'rgba(85, 193, 178, 1)',
        backgroundColor: 'rgba(85, 193, 178, 0.25)',
      },
    ],
  }

  const chartDataLastMonth = {
    labels: labelsLastMonth,
    datasets: [
      {
        label: 'Dataset 1',
        data: dataLastMonth,
        borderColor: 'rgba(85, 193, 178, 1)',
        backgroundColor: 'rgba(85, 193, 178, 0.25)',
      },
    ],
  }

  return (
    <div className={`body__section_content__wrapper ${isMobile ? "mobile" : ""}`} id='my-sales-wrapper'>
      <div className="flex">
        <h2>sales</h2>
        <p className="sales-period-filter" onClick={setPeriod}>last month</p>
        <p className="sales-period-filter underlined" onClick={setPeriod}>this month</p>
      </div>
      <div className="my_sales__graph">
        {
          loading ?
            <div className='circular_loading_spinner__wrapper'><CircularProgress color='inherit' size="2rem" /></div>
            :
            <>
              {/* {
                !isMobile &&
                (
                  <div className="podium__container">
                    {
                      salesReportPeriod == "this month" ?
                        thisMonthSales.length == 0 ?
                          <p>No sales recorded yet</p>
                          :
                          Array.from(thisMonthSales).map
                            ((product, index) => {
                              return (
                                <div key={index} className="podium__element">
                                  <div className="podium__element--product">{product[0]}</div>
                                  <div className="podium__element--amount">{product[1]}</div>
                                </div>
                              )
                            }) :
                        lastMonthSales.length == 0 ?
                          <p>No sales recorded yet</p>
                          :
                          Array.from(lastMonthSales).map
                            ((product, index) => {
                              return (
                                <div key={index} className="podium__element">
                                  <div className="podium__element--product">{product[0]}</div>
                                  <div className="podium__element--amount">{product[1]}</div>
                                </div>
                              )
                            })
                    }
                  </div>
                )
              } */}
              <div className={`chart__container ${isMobile && "is_mobile"}`}>
                {
                  (thisMonthSales.length != 0 || lastMonthSales.length != 0) ?
                    <>
                      {
                        salesReportPeriod == "this month" ?
                          <Bar options={options} data={chartDataThisMonth} /> :
                          <Bar options={options} data={chartDataLastMonth} />
                      }
                    </> :
                    <p>No sales recorded yet</p>
                }
              </div>
            </>
        }
      </div>
    </div>
  )
}

export default MySales