import React, { useState, useEffect } from 'react'
import './Pagination.css'
import { useSelector, useDispatch } from "react-redux"
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

function Pagination({ id, page, setPage, totalPages, stateType, filters }) {
  const [visiblePages, setVisiblePages] = useState([1, 2, 3]) // Default visible pages

  const dispatch = useDispatch()

  const handlePagination = (event) => {
    const element = event.currentTarget

    if (element.classList.contains("grayed-out")) {
      return
    }

    const type = element.dataset.type

    if (stateType == "redux") {
      const newFilterState = {...filters}
      switch (type) {
        case "previous":
          newFilterState.page = newFilterState.page - 1
          break
        case "next":
          newFilterState.page = newFilterState.page + 1
          break
        case "goto":
          newFilterState.page = parseInt(element.dataset.page)
          break
      }

      dispatch(setPage(newFilterState))
      
    } else {
      switch (type) {
        case "previous":
          setPage(page - 1)
          break
        case "next":
          setPage(page + 1)
          break
        case "goto":
          setPage(parseInt(element.dataset.page))
          break
      }
    }
  }

  const handleEllipsisClickNext = () => {
    const nextPageSet = visiblePages[0] + 3
    if (nextPageSet <= totalPages - 1) {
      setVisiblePages([nextPageSet, nextPageSet + 1, nextPageSet + 2].filter(p => p <= totalPages))
    }
  }

  const handleEllipsisClickPrev = () => {
    const prevPageSet = visiblePages[0] - 3
    if (prevPageSet >= 1) {
      setVisiblePages([prevPageSet, prevPageSet + 1, prevPageSet + 2].filter(p => p <= totalPages))
    }
  }

  useEffect(() => {

    if (typeof page !== 'number' || typeof totalPages !== 'number' || isNaN(page) || isNaN(totalPages)) {
      return
    }

    if (totalPages <= 3) {
      setVisiblePages(Array.from({ length: totalPages }, (_, i) => i + 1))
    } else if (page <= 3) {
      setVisiblePages([1, 2, 3])
    } else if (page >= totalPages - 2) {
      setVisiblePages([totalPages - 2, totalPages - 1, totalPages])
    } else {
      setVisiblePages([page - 1, page, page + 1])
    }
  }, [page, totalPages])

  const renderPageButtons = () => {
    const buttons = []

    // Render left ellipsis if there are more than 4 pages and we're not at the start
    if (totalPages > 4 && visiblePages[0] > 3) {
      buttons.push(
        <button
          key="ellipsis-left"
          className="pagination__btn"
          onClick={handleEllipsisClickPrev}
        >
          ...
        </button>
      )
    }

    // Render visible pages
    visiblePages.forEach((p) => {
      if (!isNaN(p)) { // Add this check to ensure p is a valid number
        buttons.push(
          <button
            key={`page-${p}`} // Use a string key with a prefix
            className={`pagination__btn ${page === p ? "selected" : ""}`}
            onClick={handlePagination}
            data-type="goto"
            data-page={p}
          >
            {p}
          </button>
        )
      }
    })

    // Render right ellipsis if there are more than 4 pages and we're not at the end
    if (totalPages > 4 && visiblePages[visiblePages.length - 1] < totalPages - 1) {
      buttons.push(
        <button
          key="ellipsis-right"
          className="pagination__btn"
          onClick={handleEllipsisClickNext}
        >
          ...
        </button>
      )
    }

    // Render last page if it's not already in the visiblePages
    if (totalPages > 3 && visiblePages.indexOf(totalPages) === -1) {
      buttons.push(
        <button
          key={totalPages}
          className={`pagination__btn ${page === totalPages ? "selected" : ""}`}
          onClick={handlePagination}
          data-type="goto"
          data-page={totalPages}
        >
          {totalPages}
        </button>
      )
    }

    return buttons
  }

  return (
    <div className={`pagination ${totalPages <= 1 ? "hide" : ""}`} id={id}>
      <ArrowBackIosIcon
        className={`pagination__btn ${page === 1 ? "grayed-out" : ""}`}
        onClick={handlePagination}
        data-type="previous"
        id="prevPage"
      />
      {renderPageButtons()}
      <ArrowForwardIosIcon
        className={`pagination__btn ${page === totalPages ? "grayed-out" : ""}`}
        onClick={handlePagination}
        data-type="next"
        id="nextPage"
      />
    </div>
  )
}

export default Pagination
