import { configureStore, createAsyncThunk } from '@reduxjs/toolkit'
import { combineReducers } from "redux"
import createIndexedDBStorage from 'redux-persist-indexeddb-storage'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  createMigrate,
} from 'redux-persist'
import httpClient from '../httpClient'
import userReducer from './user'
import miniMenuReducer from './miniMenu'
import notificationReducer from './notification'
import customerPageReducer from './customerPage'
import salesPageBrandOwnerReducer from './salesPageBrandOwner'
import salesPipePageReducer from './salesPipePage'
import visitReportReducer from './visitReport'
import activitiesPageReducer from './activitiesPage'
import globalReducer from './global'
import performanceReducer from './performancePage'
import productsPageReducer from './productsPage'
import activitiesPage from './activitiesPage'
import salesPipePage from './salesPipePage'

// Define migrations
const migrations = {
  1: (state) => {
    // Initial version, no changes needed
    return state
  },
  2: (state) => {
    return {
      ...state,
      activitiesPage: {
        ...state.activitiesPage,
        activitiesList: state.activitiesPage?.activitiesList || [],
        totalPagesActivities: state.activitiesPage?.totalPagesActivities || null,
        filters: {
          ...state.activitiesPage?.filters,
          page: state.activitiesPage?.filters?.page || 1,
        },
      },
      salesPageBrandOwner: {
        ...state.salesPageBrandOwner,
        filters: {
          ...state.salesPageBrandOwner?.filters,
          page: state.salesPageBrandOwner?.filters?.page || 1,
          listType: state.salesPageBrandOwner?.filters?.listType || "new",
        },
        orderAmounts: state.salesPageBrandOwner?.orderAmounts || {
          'new': 0,
          'stoqup': 0,
          'brandowner': 0,
          'delivered': 0,
          'toInvoice': 0,
          'completed': 0,
          'all': 0
        },
        totalPagesOrders: state.salesPageBrandOwner?.totalPagesOrders || null,
      },
      salesPipePage: {
        ...state.salesPipePage,
        filters: {
          ...state.salesPipePage?.filters,
          page: state.salesPipePage?.filters?.page || 1,
        },
        salesPipe: state.salesPipePage?.salesPipe || {},
        salesPipeCounts: state.salesPipePage?.salesPipeCounts || {},
      }
    }
  },
  3: (state) => {
    return {
      ...state,
      customerPage: {
        ...state.customerPage,
        filters: {
          ...state.CustomersPage?.filters,
          mapBounds: { "ne": {}, "sw": {} }
        },
        mapView: { "state": false, "center": { lat: 51.0482532, lng: 3.728389399999999 } }
      }
    }
  },
  5: (state) => {
    return {
      ...state,
      customerPage: {
        ...state.customerPage,
        mobilePage: 1
      },
      activitiesPage: {
        ...state.activitiesPage,
        mobilePage: 1
      },
      salesPageBrandOwner: {
        ...state.salesPageBrandOwner,
        mobilePage: 1
      },
    }
  },
  6: (state) => {
    return {
      ...state,
      salesPipePage: {
        ...state.salesPipePage,
        needsRefresh: false,
      },
    }
  },
  7: (state) => {
    return {
      ...state,
      customerPage: {
        ...state.customerPage,
        filters: {
          ...state.customerPage?.filters,
          sortBy: state.customerPage?.filters?.sortBy || 'name',
          sortOrder: state.customerPage?.filters?.sortOrder || 'asc',
        },
        sortOptions: state.customerPage?.sortOptions || [
          { value: 'name', label: 'Name' },
          { value: 'city', label: 'City' },
          { value: 'lastOrderDate', label: 'Last Order Date' },
        ],
        mapTotalRecords: state.customerPage?.mapTotalRecords || 0,
        mapHasMore: state.customerPage?.mapHasMore || true,
        mapPage: state.customerPage?.mapPage || 1,
      },
    }
  },
  8: (state) => {
    return {
      ...state,
      user: {
        ...state.user,
        myTeam: state.user?.myTeam || null,
      },
    }
  },
}

// Create the IndexedDB storage
const storage = createIndexedDBStorage({
  name: 'stoqup-db', // Specify a name for your IndexedDB database
  storeName: 'persist-store' // Specify a name for your object store within IndexedDB
})

const persistConfig = {
  key: 'stoqup-app',
  version: 8, // Increment version number
  storage, // Use IndexedDB storage
  migrate: createMigrate(migrations, { debug: false }),
}

const reducers = combineReducers({
  user: userReducer,
  miniMenu: miniMenuReducer,
  notification: notificationReducer,
  customerPage: customerPageReducer,
  salesPageBrandOwner: salesPageBrandOwnerReducer,
  salesPipePage: salesPipePageReducer,
  visitReport: visitReportReducer,
  activitiesPage: activitiesPageReducer,
  global: globalReducer,
  performancePage: performanceReducer,
  productsPage: productsPageReducer,
})

const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})

export const persistor = persistStore(store)