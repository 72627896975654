import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
    name: "user",
    initialState: {
      userInfo: {},
      customer_types: null,
      myTeam: null
    },
    reducers: {
      addUserInfo: (state, action) => {
        state.userInfo = action.payload
      },
      addCustomerTypes: (state, action) => {
        state.customer_types = action.payload
      },
      addMyTeam: (state, action) => {
        state.myTeam = action.payload
      },
      resetStateUser: (state) => {
        return {
          userInfo: {},
          customer_types: null,
          myTeam: null
        }
      }
    }
})

export const { addUserInfo, addCustomerTypes, addMyTeam, resetStateUser } = userSlice.actions
export default userSlice.reducer