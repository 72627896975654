import { createSlice } from "@reduxjs/toolkit";

export const productsPageSlice = createSlice({
  name: "productPageState",
  initialState: {
    products: {},
    filters: {
      "searchWord": "",
    },
  },
  reducers: {
    updateFilters: (state, action) => {
      state.filters = action.payload;
    },

    updateProducts: (state, action) => {
      const products = action.payload
    
      state.products = products
    },

    resetStateProductsPage: (state) => {
      state.filters = {
        "searchWord": "",
      }
      state.products = {}
    },
  },
})

export const { updateFilters, resetStateProductsPage, updateProducts} = productsPageSlice.actions
export default productsPageSlice.reducer