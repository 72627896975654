import React, { useState, useEffect, useRef } from 'react'
import httpClient from '../../httpClient'
import { useSelector, useDispatch } from 'react-redux'
import './PopupwindowCreateCustomerMobile.css'
import { PiCaretLeftThin } from "react-icons/pi"
import { MdOutlineArrowDropDown } from "react-icons/md"
import FormDropdownPopup from './FormDropdownPopup'
import AlertPopupMobile from './AlertPopupMobile'
import CircularProgress from '@mui/material/CircularProgress'
import { IoClose } from "react-icons/io5"
import { usePopup } from './context/NotificationPopupContext'
import LoadingSpinner from '../placeholders/LoadingSpinner'
import { TfiMicrosoftAlt } from "react-icons/tfi"
import CheckBox from '../CheckBox'

function PopupwindowCreateCustomerMobile({ fetchCustomers, setCustomer, isActive, setIsActive }) {
  const [newCustomer, setNewCustomer] = useState({
    "business_name": "",
    "email": "",
    "first_name": "",
    "last_name": "",
    "tel_number": "",
    "type": "",
    "sub_type": "",
    "sales_email": "",
    "bio": "",
    "company": "",
    "invoice_email": "",
    "VAT_number": "",
    "invoice_address_street": "",
    "invoice_address_nr": "",
    "invoice_address_pc": "",
    "invoice_address_place": "",
    "invoice_address_country": "Belgium",
    "delivery_address_street": "",
    "delivery_address_nr": "",
    "delivery_address_pc": "",
    "delivery_address_place": "",
    "delivery_address_country": "Belgium",
    "create_in_business_central": true,
    "is_supplier": false
  })
  const [loadingSaveButton, setLoadingSaveButton] = useState(false)
  const [dropdownPopupIsOpen, setDropdownPopupIsOpen] = useState(false)
  const [dropdownList, setDropdownList] = useState([])
  const [dropdownID, setdropdownID] = useState("")
  const [alertPopupIsActive, setAlertPopupIsActive] = useState(false)
  const [alertType, setAlertType] = useState("notification")
  const [alertMessage, setAlertMessage] = useState("")
  const [loadingInputBoxes, setLoadingInputBoxes] = useState(false)

  const [businessNameDropdownIsActive, setBusinessNameDropdownIsActive] = useState(true)
  const [loadingBusinessNameDropdown, setLoadingBusinessNameDropdown] = useState(true)
  const [businessNameSelected, setBusinessNameSelected] = useState(false)
  const [googleBusinessSearchResults, setGoogleBusinessSearchResults] = useState([])
  const [debouncedBusinessName, setDebouncedBusinessName] = useState('')
  const [searchRequestLimitReached, setSearchRequestLimitReached] = useState(false)

  const dispatch = useDispatch()
  const saveButtonRef = useRef(null)
  const createCustomerPopupRef = useRef(null)

  const customerTypesArray = useSelector(state => state.user.customer_types)
  const userInfo = useSelector(state => state.user.userInfo)

  const arrayOfTypes = Object.keys(customerTypesArray)
  const arrayOfSubTypes = customerTypesArray[newCustomer.type] || []

  const { showPopup } = usePopup()

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedBusinessName(newCustomer.business_name)
    }, 750) // 750ms delay

    if (!newCustomer.business_name) {
      setBusinessNameSelected(false)
      setBusinessNameDropdownIsActive(true)
      setGoogleBusinessSearchResults([])
    }

    return () => {
      clearTimeout(timerId)
    }
  }, [newCustomer.business_name])

  useEffect(() => {
    if (newCustomer.type == "wholesaler") {
      setNewCustomer(prev => ({
        ...prev,
        is_supplier: true
      }))
    }
  }, [newCustomer.type])

  useEffect(() => {
    const fetchBusinessName = async () => {
      if (!debouncedBusinessName) {
        return
      }

      if (!businessNameSelected) {
        try {
          const response = await httpClient.get(`${process.env.REACT_APP_API_URL}/api/user/text-search?textQuery=${debouncedBusinessName}`)

          if (response.status == 200) {
            setGoogleBusinessSearchResults(response.data.data.places)
          } else {
            setBusinessNameDropdownIsActive(false)
            console.log(response.data)
          }
        } catch (error) {
          console.log(error)
          if (error.response.status == 423) {
            setSearchRequestLimitReached(true)
          }
        }
      }

      setLoadingBusinessNameDropdown(false)

    }

    fetchBusinessName()
  }, [debouncedBusinessName])

  const handleClosePopup = () => {
    setIsActive(false)

    Object.keys(newCustomer).forEach(key => {
      if (
        newCustomer[key] == 'invoice_address_country' |
        newCustomer[key] == 'delivery_address_country'
      ) {
        newCustomer[key] = 'Belgium'
      } else {
        newCustomer[key] = ''
      }
    })
  }

  const handleSaveNewCustomer = async () => {

    setLoadingSaveButton(true)

    const button = saveButtonRef.current
    button.disabled = true

    const emailValidation = /\S+@\S+\.\S+/

    if (
      newCustomer.business_name == ""
    ) {
      setAlertType("error")
      setAlertMessage("Business name is required")
      setAlertPopupIsActive(true)

      button.disabled = false
      setLoadingSaveButton(false)
      return
    }
    if (newCustomer.email) {
      if (!emailValidation.test(newCustomer.email)) {
        setAlertType("error")
        setAlertMessage("Incorrect email value")
        setAlertPopupIsActive(true)

        button.disabled = false
        setLoadingSaveButton(false)
        return
      }
    }

    if (newCustomer.sales_email) {
      if (!emailValidation.test(newCustomer.email)) {
        setAlertType("error")
        setAlertMessage("Incorrect email value")
        setAlertPopupIsActive(true)

        button.disabled = false
        setLoadingSaveButton(false)
        return
      }
    }

    const cleanState = () => {
      Object.keys(newCustomer).forEach(key => {
        if (
          newCustomer[key] == 'invoice_address_country' |
          newCustomer[key] == 'delivery_address_country'
        ) {
          newCustomer[key] = 'Belgium'
        } else if (newCustomer[key] == 'create_in_business_central') {
          newCustomer[key] = true
        } else {
          newCustomer[key] = ''
        }
      })
    }

    const updateCustomer = async (customerId) => {
      const requestData = {
        ...newCustomer,
        "id": customerId
      }

      try {
        const response = await httpClient.post(`${process.env.REACT_APP_API_URL}/api/user/update`, requestData)
        if (response.status === 200) {
          fetchCustomers()
          setCustomer && setCustomer(response.data)
          setIsActive(false)
          cleanState()

          setAlertType("success")
          setAlertMessage("Customer has been updated")
          setAlertPopupIsActive(true)

          button.disabled = false
          setLoadingSaveButton(false)
          return
        }
      } catch (err) {
        console.log(err)
      }
    }

    try {
      const response = await httpClient.post(process.env.REACT_APP_API_URL + "/api/user/create", newCustomer)
      if (response.status === 200) {
        fetchCustomers()
        setCustomer && setCustomer(response.data)
        setIsActive(false)
        cleanState()

        setAlertType("success")
        setAlertMessage("Customer has been created")
        setAlertPopupIsActive(true)
      }

      if (response.status === 409) {

        showPopup({
          isActive: true,
          title: `Customer with this email address & delivery address already exists under the name of '${response.data.customer_name}'`,
          message: "Do you want to overwrite the old data with the new data?",
          actionButtonFn: () => updateCustomer(response.data.customer_id),
          actionButtonText: "Overwrite",
          actionButtonColor: "green",
        })
      }

      if (response.status == 403) {
        setAlertType("error")
        setAlertMessage("You have reached your free user limit of 10 customers. Upgrade your account.")
        setAlertPopupIsActive(true)
      }

    } catch (err) {
      console.log(err)
    }

    button.disabled = false
    setLoadingSaveButton(false)
  }

  const handleOnchangeInput = (e) => {
    const thisInputElement = e.currentTarget
    const value = thisInputElement.value

    if (thisInputElement.name == 'business_name') {
      setLoadingBusinessNameDropdown(true)
    }

    const phoneRegex = /^[0-9-()+\s]+$/

    // Check if the value matches the phone number format
    if (thisInputElement.name === 'tel_number' && value && !phoneRegex.test(value)) {
      return
    }

    const newCustomerState = { ...newCustomer }
    newCustomerState[thisInputElement.name] = value
    setNewCustomer(newCustomerState)
  }

  const handleSetNewCustomerState = (id, value) => {
    const newCustomerObj = { ...newCustomer }

    switch (id) {
      case "customer-type":
        newCustomerObj["type"] = value
        newCustomerObj["sub_type"] = null
        break

      case "customer-sub-type":
        newCustomerObj["sub_type"] = value
        break
    }

    setNewCustomer(newCustomerObj)
  }

  const handleOnClickPopup = (e, type) => {

    switch (type) {
      case "type":
        setDropdownList(arrayOfTypes)
        setdropdownID("customer-type")
        setDropdownPopupIsOpen(true)
        break

      case "sub_type":
        setDropdownList(arrayOfSubTypes)
        setdropdownID("customer-sub-type")
        setDropdownPopupIsOpen(true)
        break
    }
  }

  const handleDuplicate = (e, type) => {
    e.preventDefault()

    let newCustomerObj = { ...newCustomer }

    switch (type) {
      case "company_name":
        newCustomerObj.company_name = newCustomer.business_name
        break

      case "invoice_email":
        newCustomerObj.invoice_email = newCustomer.email
        break

      case "address":
        newCustomerObj.delivery_address_street = newCustomer.invoice_address_street
        newCustomerObj.delivery_address_nr = newCustomer.invoice_address_nr
        newCustomerObj.delivery_address_pc = newCustomer.invoice_address_pc
        newCustomerObj.delivery_address_place = newCustomer.invoice_address_place
        newCustomerObj.delivery_address_country = newCustomer.invoice_address_country
        break
    }

    setNewCustomer(newCustomerObj)
  }

  const handleOnClickSearchResult = async (searchResult) => {

    setLoadingInputBoxes(true)

    setBusinessNameDropdownIsActive(false)
    setBusinessNameSelected(true)
    setGoogleBusinessSearchResults([])

    const customerState = { ...newCustomer }
    customerState.business_name = searchResult.displayName.text
    customerState.tel_number = searchResult.nationalPhoneNumber

    const addressParts = searchResult.formattedAddress.split(', ')

    if (addressParts.length === 3) {
      const streetAndNumber = addressParts[0].split(' ')
      const street = streetAndNumber.slice(0, -1).join(' ')
      const number = streetAndNumber[streetAndNumber.length - 1]

      const postalAndPlace = addressParts[1].split(' ')
      const postalCode = postalAndPlace[0]
      const place = postalAndPlace.slice(1).join(' ')

      const country = addressParts[2]

      customerState.delivery_address_street = street
      customerState.delivery_address_nr = number
      customerState.delivery_address_pc = postalCode
      customerState.delivery_address_place = place
      customerState.delivery_address_country = country
    } else {
      // Handle cases where the address does not fit the expected structure
      console.error('Address format is not as expected:', searchResult.formattedAddress)
    }

    // try to find the email address, type & subtype and short description

    const response = await httpClient.post(process.env.REACT_APP_API_URL + `/api/ai/find-customer-info`, searchResult)
    const emailAddresses = response.data.email_addresses

    if (emailAddresses) {
      customerState.email = emailAddresses[0]
    }

    customerState.bio = response.data.short_description
    customerState.type = response.data.type
    customerState.sub_type = response.data.sub_type

    setNewCustomer(customerState)
    setLoadingInputBoxes(false)
  }

  const handleBusinessCentralToggle = () => {
    setNewCustomer(prev => ({
      ...prev,
      create_in_business_central: !prev.create_in_business_central
    }))
  }

  const handleIsSupplier = (value) => {
    setNewCustomer(prev => ({
      ...prev,
      is_supplier: !prev.is_supplier
    }))
  }

  return (
    <div className={`popup_window__container ${isActive ? "active" : ""}`} id='popup-window-create-customer' ref={createCustomerPopupRef}>

      <FormDropdownPopup
        id={dropdownID}
        list={dropdownList}
        isOpen={dropdownPopupIsOpen}
        setIsOpen={setDropdownPopupIsOpen}
        setValue={handleSetNewCustomerState}
      />

      <AlertPopupMobile
        type={alertType}
        message={alertMessage}
        isActive={alertPopupIsActive}
        setIsActive={setAlertPopupIsActive}
      />

      <div className='popup_header'>
        <div className='go_back' onClick={handleClosePopup}><PiCaretLeftThin /></div>
        <button className='green-button-header' onClick={handleSaveNewCustomer} ref={saveButtonRef}>
          {
            loadingSaveButton ?
              <CircularProgress color="inherit" size={"1rem"} /> :
              <>SAVE</>
          }
        </button>
      </div>
      <div className='popup_body'>

        <div className="api_options">
          <div
            className={`business-central-icon ${newCustomer.create_in_business_central ? 'clicked' : ''} ${!userInfo.api_partners.includes('BusinessCentral') ? "hide" : ""}`}
            onClick={handleBusinessCentralToggle}
          >
            <TfiMicrosoftAlt />
          </div>
        </div>

        <form>

          <h1>Details</h1>

          <div className='input_field--mobile'>
            <input className={`input_text--mobile ${loadingInputBoxes ? "loading" : ""}`} type='text' placeholder='Business name' name='business_name' value={newCustomer.business_name} onChange={handleOnchangeInput} disabled={loadingInputBoxes} />
            <div className={`dropdown_field ${(businessNameDropdownIsActive & newCustomer.business_name != '') ? "" : "hide"}`}>
              {
                searchRequestLimitReached ?
                  <div className="limit" onClick={() => setBusinessNameDropdownIsActive(false)}><p>🤖 You've reached your monthly search limit.</p> <IoClose /></div> :

                  loadingBusinessNameDropdown ?
                    <div className='circular_loading_spinner__wrapper'><CircularProgress color='inherit' size="1rem" /></div>
                    :
                    <>
                      {
                        googleBusinessSearchResults.map((result, index) => {
                          const addressParts = result.formattedAddress.split(', ')
                          const place = addressParts.length >= 3 ? addressParts[addressParts.length - 2].split(' ')[1] : ''

                          return (
                            <div className="dropdown_field__item" key={index} onClick={() => handleOnClickSearchResult(result)}>
                              <div>{result.displayName.text}</div>
                              <div>{result.primaryType}</div>
                              <div>{place}</div>
                            </div>
                          )
                        })
                      }
                    </>
              }
            </div>
          </div>

          <input className={`input_text--mobile ${loadingInputBoxes ? "loading" : ""}`} type='email' placeholder='Email' name='email' value={newCustomer.email} onChange={handleOnchangeInput} disabled={loadingInputBoxes} />
          <input className={`input_text--mobile ${loadingInputBoxes ? "loading" : ""}`} type='tel' placeholder='Telephone number' name='tel_number' value={newCustomer.tel_number} onChange={handleOnchangeInput} disabled={loadingInputBoxes} />
          <div className='input_field--combo-5050'>
            <input className={`input_text--mobile ${loadingInputBoxes ? "loading" : ""}`} type='text' placeholder='First name' name='first_name' value={newCustomer.first_name} onChange={handleOnchangeInput} disabled={loadingInputBoxes} />
            <input className={`input_text--mobile ${loadingInputBoxes ? "loading" : ""}`} type='text' placeholder='Last name' name='last_name' value={newCustomer.last_name} onChange={handleOnchangeInput} disabled={loadingInputBoxes} />
          </div>
          <div className={`form_dropdown--mobile ${loadingInputBoxes ? "loading" : ""}`} name='type' onClick={(e) => handleOnClickPopup(e, "type")}>
            {
              newCustomer.type ?
                <p>{newCustomer.type}</p> :
                <p className='placeholder'>Type</p>
            }
            <MdOutlineArrowDropDown />
          </div>
          <div className={`form_dropdown--mobile ${loadingInputBoxes ? "loading" : ""}`} name='sub_type' onClick={(e) => handleOnClickPopup(e, "sub_type")}>
            {
              newCustomer.sub_type ?
                <p>{newCustomer.sub_type}</p> :
                <p className='placeholder'>Sub type</p>
            }
            <MdOutlineArrowDropDown />
          </div>
          {
            newCustomer.is_supplier ?
              <input className={`input_text--mobile ${loadingInputBoxes ? "loading" : ""}`} type='email' placeholder='Sales email' name='sales_email' value={newCustomer.sales_email} onChange={handleOnchangeInput} disabled={loadingInputBoxes} /> :
              null
          }
          <textarea className={`input_text--mobile textarea ${loadingInputBoxes ? "loading" : ""}`} type='text' placeholder='Bio' name='bio' value={newCustomer.bio} onChange={handleOnchangeInput} disabled={loadingInputBoxes} />

          <h1>Invoicing</h1>
          <div className='input_wth_button--wrapper'>
            <input className={`input_text--mobile ${loadingInputBoxes ? "loading" : ""}`} type='text' placeholder='Company name' name='company_name' value={newCustomer.company_name} onChange={handleOnchangeInput} disabled={loadingInputBoxes} />
            <button className='form_option_button' onClick={(e) => handleDuplicate(e, "company_name")}>Duplicate</button>
          </div>
          <div className='input_wth_button--wrapper'>
            <input className={`input_text--mobile ${loadingInputBoxes ? "loading" : ""}`} type='email' placeholder='Invoice email' name='invoice_email' value={newCustomer.invoice_email} onChange={handleOnchangeInput} disabled={loadingInputBoxes} />
            <button className='form_option_button' onClick={(e) => handleDuplicate(e, "invoice_email")}>Duplicate</button>
          </div>
          <input className={`input_text--mobile ${loadingInputBoxes ? "loading" : ""}`} type='text' placeholder='VAT number' name='VAT_number' value={newCustomer.VAT_number} onChange={handleOnchangeInput} disabled={loadingInputBoxes} />
          <div className='input_field--combo-7030--mobile'>
            <input className={`input_text--mobile ${loadingInputBoxes ? "loading" : ""}`} type='text' placeholder='Street' name='invoice_address_street' value={newCustomer.invoice_address_street} onChange={handleOnchangeInput} disabled={loadingInputBoxes} />
            <input className={`input_text--mobile ${loadingInputBoxes ? "loading" : ""}`} type='text' placeholder='Nr' name='invoice_address_nr' value={newCustomer.invoice_address_nr} onChange={handleOnchangeInput} disabled={loadingInputBoxes} />
          </div>
          <div className='input_field--combo-3070--mobile'>
            <input className={`input_text--mobile ${loadingInputBoxes ? "loading" : ""}`} type='text' placeholder='Postal code' name='invoice_address_pc' value={newCustomer.invoice_address_pc} onChange={handleOnchangeInput} disabled={loadingInputBoxes} />
            <input className={`input_text--mobile ${loadingInputBoxes ? "loading" : ""}`} type='text' placeholder='City' name='invoice_address_place' value={newCustomer.invoice_address_place} onChange={handleOnchangeInput} disabled={loadingInputBoxes} />
          </div>
          <input className={`input_text--mobile ${loadingInputBoxes ? "loading" : ""}`} type='text' placeholder='Country' name='invoice_address_country' value={newCustomer.invoice_address_country} onChange={handleOnchangeInput} disabled={loadingInputBoxes} />

          <h1>Delivery</h1>
          <div className='input_field--combo-7030--mobile'>
            <input className={`input_text--mobile ${loadingInputBoxes ? "loading" : ""}`} type='text' placeholder='Street' name='delivery_address_street' value={newCustomer.delivery_address_street} onChange={handleOnchangeInput} disabled={loadingInputBoxes} />
            <input className={`input_text--mobile ${loadingInputBoxes ? "loading" : ""}`} type='text' placeholder='Nr' name='delivery_address_nr' value={newCustomer.delivery_address_nr} onChange={handleOnchangeInput} disabled={loadingInputBoxes} />
          </div>
          <div className='input_field--combo-3070--mobile'>
            <input className={`input_text--mobile ${loadingInputBoxes ? "loading" : ""}`} type='text' placeholder='Postal code' name='delivery_address_pc' value={newCustomer.delivery_address_pc} onChange={handleOnchangeInput} disabled={loadingInputBoxes} />
            <input className={`input_text--mobile ${loadingInputBoxes ? "loading" : ""}`} type='text' placeholder='City' name='delivery_address_place' value={newCustomer.delivery_address_place} onChange={handleOnchangeInput} disabled={loadingInputBoxes} />
          </div>
          <input className={`input_text--mobile ${loadingInputBoxes ? "loading" : ""}`} type='text' placeholder='Country' name='delivery_address_country' value={newCustomer.delivery_address_country} onChange={handleOnchangeInput} disabled={loadingInputBoxes} />
          <button className='form_option_button' onClick={(e) => handleDuplicate(e, "address")}>Duplicate</button>


          <div className="other_options">
            <CheckBox
              id="customers__is_supplier"
              text="Is supplier"
              state={newCustomer.is_supplier}
              setData={handleIsSupplier}
              style="mobile"
              textPosition="left"
            />
          </div>
        </form>
      </div>
    </div>
  )
}

export default PopupwindowCreateCustomerMobile