import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from "react-redux"
import './PopOutWindowCreateOrder.css'
import CloseIcon from '@mui/icons-material/Close'
import DropDownInputCustomer from './DropDownInputCustomer'
import httpClient from "../httpClient"
import AddProductForSales from './AddProductForSales'
import { toast } from "react-toastify"

export function PopOutWindowCreateOrder({
  popupID, onClose, selectedCustomer,
  selectedCustomerID, setSelectedCustomerID,
  setNewCustomerPopupIsActive, setSelectedCustomer
}) {
  const [customers, setCustomers] = useState([])
  const [selectedProducts, setSelectedProducts] = useState([])
  const [countArray, setCountArray] = useState([1])
  const [products, setProducts] = useState([])
  const [materials, setMaterials] = useState([])
  const [resetChildState, setResetChildState] = useState(false)
  const [localSelectedCustomer, setLocalSelectedCustomer] = useState("")
  const [warehouses, setWarehouses] = useState([])

  const parentDivRef = useRef(null)
  const userInfo = useSelector(state => state.user.userInfo)
  const requester = userInfo.type

  const materialIDArray = [... new Set(selectedProducts.map(material => material.materialID))]
  const productIDArray = [... new Set(selectedProducts.map(product => product.productID))]
  const filteredMaterials = materials.filter(material => !materialIDArray.includes(String(material.id)))
  const filteredProducts = products.filter(product => !productIDArray.includes(String(product.id)))

  const fetchProducts = async () => {
    const responseProducts = await httpClient.get(process.env.REACT_APP_API_URL + "/api/get-my-products?requester=" + requester)

    setProducts(responseProducts.data.filter(product => product.category != "POSM"))
    setMaterials(responseProducts.data.filter(product => product.category == "POSM"))
  }

  const fetchCustomers = async () => {
    const responseCustomers = await httpClient.get(process.env.REACT_APP_API_URL + "/api/users/get?requester=" + requester)
    setCustomers(responseCustomers.data)
  }

  const fetchWarehouses = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/brandowner/warehouses")
    const data = response.data

    const newWarehousesArray = data.map(warehouse => {
      return (
        {
          'id': warehouse.id,
          'name': warehouse.name
        }
      )
    })
    setWarehouses(newWarehousesArray)
  }

  useEffect(() => {
    const popup = document.getElementById(popupID)
    window.addEventListener('keydown', e => {
      if (e.key === 'Escape') {
        if (popup.classList.contains("active")) {
          closePopupWindow()
        }
      }
    })

    fetchCustomers()
    fetchProducts()
    fetchWarehouses()
  }, [])

  useEffect(() => {
    selectedCustomer && setLocalSelectedCustomer(selectedCustomer)
  },[selectedCustomer])

  const handleSaveOrder = async () => {
    const popoutDocument = document.querySelector(`#${popupID}`)
    const saveButton = popoutDocument.querySelector("#save-btn-create-order")
    saveButton.disabled = true

    const data = {
      "customer": selectedCustomerID,
      "products": selectedProducts,
    }

    let ok = true

    const productDropdowns = popoutDocument.querySelectorAll(".product__dropdown__container .input_field__input_box--text .dropdown-input--text_field")


    Array.from(productDropdowns).forEach(item => {
      if (item.innerHTML == "Select product") {
        item.parentElement.classList.add("red-border")
        ok = false
      }
    })

    const customerField = popoutDocument.querySelectorAll("input_field__input_box--text input-dropdown")
    Array.from(customerField).forEach(item => {
      if (item.value == "") {
        item.classList.add("red-border")
        ok = false
      }
    })

    if (!ok) {
      toast.error("Please fill out all fields", {theme: "dark"})
      saveButton.disabled = false
    } else {
      const response = await toast.promise(
        httpClient.post(process.env.REACT_APP_API_URL + "/api/salesforce/order/send", data),
        {
          success: "Order has been registered",
          pending: "Registering order...",
          error: "Something went wrong"
        }
      )
      console.log(response.data)

      // Clean up
      setSelectedProducts([])
      setCountArray([1])
      const checkboxes = popoutDocument.querySelectorAll(".checkbox--box")
      Array.from(checkboxes).forEach(checkbox => {
        checkbox.classList.remove("checked")
      })
      setResetChildState(true)
    }
    saveButton.disabled = false

  }

  const closePopupWindow = () => {
    const popup = document.getElementById(popupID)
    popup.classList.remove("active")
    onClose()
  }

  const handleOnClickDropdownItem = (event) => {
    setLocalSelectedCustomer(event.currentTarget.firstChild.textContent)
    setSelectedCustomerID(event.currentTarget.getAttribute("id"))
  }

  const DeleteProductLine = (event) => {
    const identifier = event.currentTarget.parentElement.parentElement.id
    setCountArray(currentArray => currentArray.filter(element => element != identifier))
    const index = identifier - 1

    const updatedArray = [...selectedProducts]
    updatedArray.splice(index, 1)
    setSelectedProducts(updatedArray)
  }

  const handleOnClickExtraProduct = () => {
    const inputs = document.getElementsByClassName("dropdown-input--text_field")
    let ok = true
    Array.from(inputs).forEach(element => {
      if (element.innerHTML == "Select product") {
        element.parentElement.classList.add("red-border")
        ok = false
      }
    })

    ok && setCountArray([...countArray, countArray.length + 1])
  }

  return (
    <div className='popout__container' id={popupID}>
      <div className='popout__box' id={popupID}>
        <CloseIcon id="popup__close-icon" onClick={closePopupWindow} />
        <div className='popout__header'>
          <button className='popup__options-button' id='save-btn-create-order' onClick={handleSaveOrder}>SAVE</button>
        </div>
        <div className='popout__body' ref={parentDivRef}>

          <div>
            <DropDownInputCustomer
              idList={"sales__dropdownList--customer"}
              placeholderText="Select the customer"
              listArray={customers}
              onClickItem={handleOnClickDropdownItem}
              value={localSelectedCustomer}
              setValue={setLocalSelectedCustomer}
              createNewCustomer={setNewCustomerPopupIsActive}
            />
            <div className="salespage--titles">
              <div className="salespage--title">Product</div>
              <div className="salespage--title">Amount</div>
              <div className="checkboxes">
                <div>Already delivered</div>
                <div>On Consignation</div>
              </div>
              <div className="salespage--title">Supplier</div>
              <div className="salespage--title">Comment</div>
            </div>
            {
              countArray.map((count, index) => {
                return <AddProductForSales
                  onClickDelete={DeleteProductLine}
                  key={index}
                  index={index}
                  identifier={count}
                  setValue={setSelectedProducts}
                  value={selectedProducts}
                  listArray={filteredProducts}
                  listArray2={filteredMaterials}
                  customerID={selectedCustomerID}
                  parentDivRef={parentDivRef}
                  resetState={resetChildState}
                  warehouses={warehouses}
                  />
              })
            }
            <button className="more-products--btn" onClick={handleOnClickExtraProduct}>+</button>
          </div>

        </div>
      </div>
    </div>
  )
}

PopOutWindowCreateOrder.defaultProps = {
  onClose: () => console.log(""),
  overflow: "scroll",
}