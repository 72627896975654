import { createSlice } from "@reduxjs/toolkit";

export const salesPageBrandOwnerSlice = createSlice({
  name: "salesPageBrandOwnerState",
  initialState: {
    filters: {
      page: 1,
      listType: "new",
      searchWord: "",
      statusFilter: "",
      supplierFilter: "",
      warehouseFilter: "",
    },
    mobilePage: 1,
    ordersList: [],
    orderAmounts: {
      'new': 0,
      'stoqup': 0,
      'brandowner': 0,
      'delivered': 0,
      'toInvoice': 0,
      'completed': 0,
      'all': 0
    },
    totalPagesOrders: null,
  },
  reducers: {
    updateFilters: (state, action) => {
      state.filters = action.payload
    },
    updateOrderAmounts: (state, action) => {
      state.orderAmounts = action.payload
    },
    updateOrderList: (state, action) => {
      const ordersList = action.payload

      // Check if orderList is an array
      if (Array.isArray(ordersList)) {
        state.ordersList = ordersList
      } else {
        state.ordersList = [] // Set to an empty array if not an array
      }

    },
    updateMobilePage: (state, action) => {
      return {
        ...state,
        mobilePage: action.payload
      }
    },
    updateTotalPagesOrders: (state, action) => {
      return {
        ...state,
        totalPagesOrders: action.payload
      };
    },
    resetStateSalesPageBrandOwner: (state) => {
      return {
        ...state,
        filters: {
          page: 1,
          listType: "new",
          searchWord: "",
          statusFilter: "",
          supplierFilter: "",
          warehouseFilter: "",
        },
        mobilePage: 1,
        ordersList: [],
        orderAmounts: {
          'new': 0,
          'stoqup': 0,
          'brandowner': 0,
          'delivered': 0,
          'toInvoice': 0,
          'completed': 0,
          'all': 0
        },
        totalPagesOrders: null,
      }
    },
  },
})

export const {
  updateFilters,
  updateOrderList,
  updateOrderAmounts,
  updateTotalPagesOrders,
  resetStateSalesPageBrandOwner,
  updateMobilePage
} = salesPageBrandOwnerSlice.actions
export default salesPageBrandOwnerSlice.reducer