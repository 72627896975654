import React, { useEffect, useRef, useState } from "react"
import "./DropDownVendor.css"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Label from "./Label"
import AddIcon from '@mui/icons-material/Add'

function DropDownVendor({
  idList, parentDivRef, index, onClickItem, value, placeholderText, listArray, inherit,
  includeCreateNewSupplier, createNewCustomer, setCreateNewSupplier
}) {
  const [searchValueSupplier, setSearchValueSupplier] = useState("")
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false)

  const ref = useRef(null)
  const refDropdownVendors = useRef(null)
  let key = 0

  const onClickOutside = () => {
    setDropdownIsOpen(false)
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Adding check to see if the target is not the search bar or a descendant of the search bar
      const searchBar = document.querySelector(`#search-bar--vendor_dropdown--${index}`)

      if (ref.current && !ref.current.contains(event.target) && !searchBar.contains(event.target)) {
        onClickOutside && onClickOutside()
      }
    }
    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    }
  }, [onClickOutside, index])

  useEffect(() => {
    let dropdownElement = refDropdownVendors.current

    if (parentDivRef) {
      const parentDivElement = parentDivRef.current
      const dropdownRect = dropdownElement.getBoundingClientRect()
      const parentDivRect = parentDivElement.getBoundingClientRect()

      const { top: dropdownTop, height: dropdownHeight } = dropdownRect
      const { top: parentDivTop, height: parentDivHeight } = parentDivRect

      const dropdownBottom = dropdownTop + dropdownHeight
      const parentDivBottom = parentDivTop + parentDivHeight

      if (dropdownBottom > parentDivBottom) {
        // Set max height of dropdown menu
        const inputBox = document.getElementById(`dropdown-box-sales-products-${index}`)
        const inputBoxRect = inputBox.getBoundingClientRect()
        const { bottom: inputBoxBottom, height: inputBoxHeight } = inputBoxRect
        const maxHeight = `${parentDivHeight - inputBoxHeight}px`
        dropdownElement.style.maxHeight = maxHeight

        // Calculate the amount to scroll by
        const scrollAmount = dropdownBottom - parentDivBottom

        // Scroll the parent div with smooth animation
        parentDivElement.scrollBy({
          top: scrollAmount,
          behavior: 'smooth',
        })
      }
    }
  }, [dropdownIsOpen])

  const handleClickDropdown = () => {
    setDropdownIsOpen(!dropdownIsOpen)

    const searchBar = document.getElementById(`search-bar--vendor_dropdown--${index}`)
    searchBar.focus()
  }

  const handleOnclickItem = (event) => {
    setDropdownIsOpen(!dropdownIsOpen)
    onClickItem(event)
  }

  const handleOnClickCreateCustomer = () => {
    createNewCustomer(true)
    setDropdownIsOpen(false)
  }

  return (
    <div className="vendor__dropdown__container sales flex--vert" ref={ref} data-vendor-id={value?.vendor_id} >
      <span className="input_field__input_box--text sales" onClick={handleClickDropdown}>
        <p className="dropdown-input--text_field flex">{value?.vendor_name == "" ? placeholderText : value?.vendor_name}</p>
        <KeyboardArrowDownIcon />
      </span>
      <ul className={`input_field__dropdown ${dropdownIsOpen ? "active" : ""} ${inherit && "inherit"}`} id={idList} key={key++} ref={refDropdownVendors}>
        <input
          className="search-bar dropdown"
          id={`search-bar--vendor_dropdown--${index}`}
          placeholder="Search..."
          value={searchValueSupplier}
          onChange={event => setSearchValueSupplier(event.currentTarget.value)}
          autoComplete="off"
          onClick={event => event.stopPropagation()}
        />
        {
          includeCreateNewSupplier &&
          <button className="button_create_new" onClick={handleOnClickCreateCustomer}>Create new supplier<AddIcon /></button>
        }
        {listArray &&
          listArray
            .filter(supplier => supplier.company_name.toLowerCase().includes(searchValueSupplier.toLowerCase()))
            .map((item, index) => {
              return (
                <li className='supplier__item' key={index} onClick={handleOnclickItem} data-vendor-id={item.id}>
                  <div className='supplier__item__company_name'>{item.company_name}</div>
                  <div>
                    {
                      item.account_id?.type == 'brandowner' ?
                        <div className='label__vendors'><Label title="Brandowner" labelColor="blue" /></div> :
                        item.is_pseudo ?
                          <div className='label__vendors'><Label title="Added by customer" labelColor="green" /></div> :
                          <div className='label__vendors'></div>
                    }
                  </div>
                </li>
              )
            })
        }
      </ul>
    </div>
  )
}

export default DropDownVendor

DropDownVendor.defaultProps = {
  inherit: false,
  includeCreateNewSupplier: false
}
