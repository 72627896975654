import React, { useState, useEffect } from 'react'
import './LoadingSpinnerOverlay.css'

function LoadingSpinner() {
    return (
        <div className='loading_spinner_overlay'>
            <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </div>

    )
}

export default LoadingSpinner