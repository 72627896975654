import React from "react"
import "./CheckBoxStatic.css"

function CheckBoxStatic({ onClick, labelDisabled, state, widthHeight, text, style }) {

  return (
    <div className={`check-box-static__container ${style}`} onClick={onClick}>
      <p className={labelDisabled ? "checkbox--text disabled" : "checkbox--text"}>{text}</p>
      <div className="check-box-static--box__container" style={{ height: widthHeight, width: widthHeight }}>
        <div className={state ? "check-box-static--box checked" : "check-box-static--box"} style={{ height: widthHeight, width: widthHeight }}></div>
        <span className={state ? "checkmark checked" : "checkmark"}>
          <div className="checkmark_stem"></div>
          <div className="checkmark_kick"></div>
        </span>
      </div>
    </div>
  )
}

export default CheckBoxStatic

CheckBoxStatic.defaultProps = {
  widthHeight: "20px",
  style: "normal"
}


