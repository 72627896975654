import React, { useState, useEffect, useRef } from 'react'
import './Dropdown.css'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

function Dropdown({ fontStyle, state, marginTop, fontSize, setState, height, width, idList, index, placeholder, listArray, inherit, title, onClickFn, stateType, stateValueName, searchBar }) {
  const [selectedValue, setSelectedValue] = useState()
  const [searchValue, setSearchValue] = useState("")

  let top = 0
  if (!inherit) {
    const inputBox = document.querySelector('#dropdown-input')
    if (inputBox) {
      top = inputBox.clientHeight + 5

      const someElement = document.querySelector(`#${idList}`)
      if (someElement) {
        someElement.style.top = `${top}px`
      }
    } else {
      console.log('Input box not found')
    }
  }

  useEffect(() => {

    if (stateType == "object") {
      setSelectedValue(state[stateValueName])
    } else {
      if (typeof state === 'object') {
        setSelectedValue(state.name)
      } else {
        setSelectedValue(state)
      }
    }

  }, [state])

  const ref = useRef(null)

  // const onClickOutside = () => {
  //   const dropdownList = document.querySelectorAll(`#${idList}`)

  //   Array.from(dropdownList).forEach(dropdown => {
  //     dropdown.classList.remove("active")
  //   })
  // }

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (ref.current && !ref.current.contains(event.target)) {
  //       onClickOutside && onClickOutside()
  //     }
  //   }
  //   document.addEventListener("click", handleClickOutside, true)

  //   return () => {
  //     document.removeEventListener("click", handleClickOutside, true)
  //   }
  // }, [onClickOutside])

  const handleClickDropdown = (event) => {
    const dropdownList = event.currentTarget.nextSibling
    dropdownList.classList.toggle("active")
  }

  const onclickMenuItem = (event, data) => {
    const dropdownList = event.currentTarget.parentNode

    if (typeof listArray[0] === 'object') {
      setSelectedValue(event.currentTarget.childNodes[0].textContent)

      // set state
      setState(event.currentTarget.childNodes[0].textContent, index, event.currentTarget.getAttribute('data-index'))
    }

    if (typeof listArray[0] === 'string' || typeof listArray[0] === 'number') {

      const value = event.currentTarget.textContent
      setSelectedValue(value)

      //  set state
      if (stateType == "object") {
        const prevState = { ...state }
        prevState[stateValueName] = value
        setState(prevState)
      } else {
        setState(value)
      }
    }

    if (onClickFn) {
      onClickFn(data)
    }

    dropdownList.classList.remove("active")
  }

  if (!listArray) {
    return <p>Loading...</p>
  }

  return (
    <div className="dropdown__container-dropdown flex--vert" id={`dropdown__${index}`} style={{ width, marginTop }} ref={ref} key={index}>
      {
        title &&
        <div className="dropdown__title">{title}</div>
      }
      <span className={`input_field__input_box--text dropdown flex classic`} id='dropdown-input' style={{ height, marginTop }} onClick={handleClickDropdown}>
        <p className="dropdown-input--text_field" style={{ fontSize, fontStyle }}>{selectedValue}</p>
        <KeyboardArrowDownIcon />
      </span>
      <ul className={`input_field__dropdown classic ${inherit && "inherit"}`} style={{ "top": top }} id={idList} key={index}>
        {
          searchBar ?
            <input
              className="search-bar dropdown"
              id={`search-bar--${index}`}
              placeholder="Search..."
              value={searchValue}
              onChange={event => setSearchValue(event.currentTarget.value)}
              autoComplete="off"
              onClick={event => event.stopPropagation()}
            /> : null
        }
        {
          typeof listArray[0] === 'object' && listArray[0] !== null && (
            listArray
            .filter(item => item.name.toLowerCase().includes(searchValue.toLowerCase()))
            .map((item, index) => (
              <li className='dropdown__item' key={index} data-index={item.id} style={{ fontSize }} onClick={(e) => onclickMenuItem(e, item)}>
                {
                  Object.entries(item).map((entry, index) => {
                    if (entry[0] != "id") {
                      return (
                        <div key={index}>
                          {entry[1]}
                        </div>
                      )
                    }
                  })
                }
              </li>
            ))
          )
        }

        {
          (typeof listArray[0] === 'string' || typeof listArray[0] === 'number') && (
            listArray
            .filter(item => item.toLowerCase().includes(searchValue.toLowerCase()))
            .map((item, index) => {
              return (
                <li className='dropdown__item--single' key={index} style={{ fontSize }} onClick={(e) => onclickMenuItem(e, item)}>
                  {item}
                </li>
              )
            })
          )
        }

      </ul>
    </div>
  )
}

export default Dropdown

Dropdown.defaultProps = {
  marginTop: "5px",
  fontStyle: "normal",
  stateType: "normal",
  inherit: false,
  title: null,
  onClickFn: null,
  searchBar: false
}