import { createSlice } from "@reduxjs/toolkit";

export const activitiesPageSlice = createSlice({
  name: "activitiesPageState",
  initialState: {
    filters: {
      page: 1,
      searchWord: "",
      typeFilter: "",
      ownerFilter: { "id": null, "name": "" },
      doneFilter: true,
      priorityFilter: "",
      listType: "all",
      sortFilter: "default",
      sortAscending: false,
    },
    mobilePage: 1,
    activitiesList: [],
    totalPagesActivities: null
  },
  reducers: {
    updateFilters: (state, action) => {
      state.filters = action.payload;
    },
    updateTotalPagesActivities: (state, action) => {
      return {
        ...state,
        totalPagesActivities: action.payload
      };
    },
    updateMobilePage: (state, action) => {
      return {
        ...state,
        mobilePage: action.payload
      }
    },
    updateActivitiesList: (state, action) => {
      const activitiesList = action.payload;
      return {
        ...state,
        activitiesList: Array.isArray(activitiesList) ? activitiesList : []
      };
    },

    resetStateActivitiesPage: (state) => {
      return {
        ...state,
        filters: {
          page: 1,
          listType: "all",
          searchWord: "",
          typeFilter: "",
          ownerFilter: { "id": null, "name": "" },
          doneFilter: true,
          priorityFilter: "",
          listType: "all",
          sortFilter: "default",
          sortAscending: false,
        },
        mobilePage: 1,
        activitiesList: [],
        totalPagesActivities: null,
      }
    },
  }
})

export const {
  updateFilters,
  updateTotalPagesActivities,
  updateActivitiesList,
  resetStateActivitiesPage,
  updateMobilePage
} = activitiesPageSlice.actions
export default activitiesPageSlice.reducer