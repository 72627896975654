import React, { useState } from 'react'
import httpClient from "../../httpClient"
import './SalesPipePageMobile.css'
import { useSelector, useDispatch } from "react-redux"
import MenuBarMobile from '../../components/mobile/MenuBarMobile'
import TotalSales from '../../components/TotalSales'
import TodoToday from '../../components/TodoToday'

function SalesPipePageMobile() {

  return (
    <div className='template--mobile'>
      <div className='template__body--mobile' id='mobile-salespipe-page'>

      </div>

      <MenuBarMobile />
    </div>
  )
}

export default SalesPipePageMobile