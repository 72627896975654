import React, { useState, useEffect } from 'react'
import './AdminInvoiceAnnexe.css'
import httpClient from "../httpClient"
import { useSelector, useDispatch } from "react-redux"
import { addUserInfo } from "../store/user"
import Header from "./Header"
import MenuBar from "./MenuBar"
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import EmailIcon from '@mui/icons-material/Email'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import LoadingSpinner from './placeholders/LoadingSpinner'
import SavingsIcon from '@mui/icons-material/Savings'

function AdminInvoicesPage({ id }) {

  const [user, setUser] = useState({ email: "", id: "" })
  const [error, setError] = useState(false)
  const [invoiceDetails, setInvoiceDetails] = useState([])

  const dispatch = useDispatch()
  const userInfo = useSelector(state => state.user.userInfo)

  const fetchUser = async () => {
    const responseUser = await httpClient.get(process.env.REACT_APP_API_URL + "/api/@me?type=full")
    setUser(responseUser.data)
  }

  const fetchInvoiceDetails = async () => {
    const responseInvoice = await httpClient.get(process.env.REACT_APP_API_URL + "/api/admin/invoice/get-shipping-details?invoiceID=" + id)
    setInvoiceDetails(responseInvoice.data)
  }

  useEffect(() => {
    fetchUser()
      .catch(err => {
        console.log(err)
      })

    fetchInvoiceDetails()
      .catch(err => {
        console.log(err)
      })

  }, [])

  const stoqupLogoWhite = require(`../assets/logos/Stoqup_logo_black.png`)

  const options = { year: 'numeric', month: 'numeric', day: 'numeric' }

  return (
    <div className='template__invoice-admin-annexe' id='invoice-admin-annexe' >
      {
        invoiceDetails.length != 0 ? (
          <>
            <div className='invoice__header flex'>
              <img src={stoqupLogoWhite} />
            </div>

            <div className='invoice__details--annexe'>
              <div className="table__header-4">
                <p className='table__header--field'>ID</p>
                <p className='table__header--field'>DATE</p>
                <p className='table__header--field'>CUSTOMER</p>
                <p className='table__header--field align-right'><b>TOTAL COST</b></p>
              </div>
              {
                invoiceDetails[0].map((order, index) => {

                  let total_order_cost = 0
                  if (order.extra_skus_cost) {
                    total_order_cost = order.extra_skus_cost + order.international_extra
                  } else {
                    total_order_cost += order.international_extra
                  }

                  if (!order.custom_pricing) {
                    Array.from(order.items).forEach(item => {
                      return total_order_cost += item.price
                    })
                  } else if (order.custom_pricing == "Pomton") {
                    Array.from(order.items).forEach(item => {
                      return total_order_cost += item.price * item.quantity
                    })
                  }

                  return (
                    <React.Fragment key={index}>
                      <div className='table__row-4 background' key={index}>
                        <div className='table__row--field'>{order.order_id}</div>
                        <div className='table__row--field'>{new Date(order.order_date).toLocaleDateString()}</div>
                        <div className='table__row--field'>{(order.customer_business_name != "" & order.customer_business_name != null) ? order.customer_business_name : order.customer_name}</div>
                        <div className='table__row--field align-right'><b>€ {total_order_cost.toFixed(2)}</b></div>
                      </div>
                      <div>
                        {
                          order.items.map((item, index) => {
                            return (
                              <div className='table__secondairy-row-4' key={index}>
                                <div className='table__secondairy-row--field'>{item.quantity}</div>
                                <div className='table__secondairy-row--field'>{item.product}</div>
                                <div className='table__secondairy-row--field align-right'>€{item.price}</div>
                              </div>
                            )
                          })
                        }
                      </div>
                      {
                        order.skus_in_order > 1 &&
                        <div className='table__secondairy-row-4'>
                          <div className='table__secondairy-row--field'>{order.skus_in_order - 1}</div>
                          <div className='table__secondairy-row--field'>Extra SKUs</div>
                          <div className='table__secondairy-row--field align-right'>€{order.extra_skus_cost}</div>
                        </div>
                      }
                      {
                        order.international == "Yes" &&
                        <div className='table__secondairy-row-4'>
                          <div className='table__secondairy-row--field'>1</div>
                          <div className='table__secondairy-row--field'>Extra international</div>
                          <div className='table__secondairy-row--field align-right'>€{order.international_extra}</div>
                        </div>
                      }
                    </React.Fragment>
                  )
                })
              }
            </div>

          </>
        ) : null}
    </div>
  )
}

export default AdminInvoicesPage