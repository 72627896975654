import React, {useState, useEffect} from 'react'
import './LoadingOverlay.css'
import LoadingSpinner from './LoadingSpinner'

function LoadingOverlay() {
    return (
        <div className='loading-overlay__container'>
            <div className='loading__spinner'><LoadingSpinner/></div>
        </div>
    )
}

export default LoadingOverlay