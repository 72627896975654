import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux"
import httpClient from "../httpClient"
import './PopOutWindowAddDeleteWarehouse.css'
import CloseIcon from '@mui/icons-material/Close'
import DropdownMultiselect from './DropdownMultiselect'
import { toast } from "react-toastify"
import DropdownWarehouse from './DropdownWarehouse'

export function PopOutWindowAddDeleteWarehouse({ popupID, onClose, type, warehouses, fetchInventory }) {
  const [warehouseOwners, setWarehouseOwners] = useState([])
  const [myTeam, setMyTeam] = useState([])
  const [selectedWarehouse, setSelectedWarehouse] = useState()
  const [selectedWarehouseID, setSelectedWarehouseID] = useState()
  const [selectedIcon, setSelectedIcon] = useState("")

  const iconArray = [
    "warehouse_picto_boxes.png",
    "warehouse_picto_other.png",
    "warehouse_picto_pallets.png",
    "warehouse_picto_rack_pallets.png",
    "warehouse_picto_truck.png"
  ]

  PopOutWindowAddDeleteWarehouse.defaultProps = {
    onClose: () => console.log(""),
    overflow: "scroll",
  }

  const fetchMyTeam = async () => {
    const responseMyTeam = await httpClient.get(process.env.REACT_APP_API_URL + "/api/brandowner/myteam?type=full")

    const teamMembers = responseMyTeam.data.map(member => {
      if (!member.is_owner) {
        return ({
          "id": member.salesforce_id,
          "name": member.salesforce_name,
        })
      }
    }).filter(Boolean)
    setMyTeam(teamMembers)

    const brandowners = responseMyTeam.data.map(member => {
      if (member.is_owner) {
        return ({
          "id": member.salesforce_id,
          "name": member.salesforce_name,
          "is_owner": true,
        })
      }
    }).filter(Boolean)
    setWarehouseOwners(brandowners)
  }

  useEffect(() => {
    const popup = document.getElementById(popupID)
    window.addEventListener('keydown', e => {
      if (e.key === 'Escape') {
        if (popup.classList.contains("active")) {
          closePopupWindow()
        }
      }
    })

    fetchMyTeam()
  }, [])

  const closePopupWindow = () => {
    const popup = document.getElementById(popupID)
    popup.classList.remove("active")
    onClose()
  }

  const handleSave = async () => {

    if (type == "add") {

      const form = document.querySelector(`#${popupID} .form__section`)
      const inputElements = form.querySelectorAll('input')
      let data = {}

      inputElements.forEach(element => (
        data[element.getAttribute('name')] = element.value
      ))

      data['icon'] = selectedIcon

      data['warehouse_owners'] = warehouseOwners

      if (!data['name']) {
        toast.error('Name is obligatory', { theme: 'dark' })
        return
      }

      const response = await toast.promise(
        (httpClient.post(process.env.REACT_APP_API_URL + "/api/brandowner/warehouse/create", data)),
        {
          pending: "Saving new warehouse...",
          success: `New warehouse created!`,
          error: "Something went wrong"
        }
      )
      console.log(response.data)
      closePopupWindow()
      fetchInventory()
      inputElements.forEach(element => (
        element.value = null
      ))
    } else if (type == "delete") {
      const data = {
        "name": selectedWarehouse,
        "id": selectedWarehouseID
      }


      try {
        const response = await httpClient.post(process.env.REACT_APP_API_URL + "/api/brandowner/warehouse/delete", data)
        console.log(response)

        if (response.status === 200) {
          toast.success(`${selectedWarehouse} has been deleted`);
          closePopupWindow();
          setSelectedWarehouse();
          setSelectedWarehouseID();
          fetchInventory();
        } else if (response.status === 403) {
          toast.error(response.data, { theme: "dark" });
        } else {
          toast.error("Something went wrong", { theme: "dark" });
        }
      } catch (error) {
        console.log(error);
        // Handle errors that occur during the fetch process, not response errors
        toast.error("Something went wrong", { theme: "dark" });
      }

    }
  }

  const setState = (name, id, type = "add") => {
    if (type == "add") {
      setWarehouseOwners(preState => {
        return (
          [...preState, {
            "id": id,
            "name": name
          }]
        )
      })
    } else if (type == "remove") {
      const newState = warehouseOwners.filter(owner => owner.id != id)
      setWarehouseOwners(newState)
    }
  }

  const setStateWarehouse = (type, value, id) => {
    setSelectedWarehouse(value)
    setSelectedWarehouseID(id)
  }

  const setStateWarehouseDetails = (e) => {

  }

  const handleSelectIcon = (e) => {
    const thisElement = e.currentTarget
    const imageName = thisElement.getAttribute('data-image')
    const iconImages = document.querySelectorAll('.warehouse_img_parent')

    iconImages.forEach(img => img.classList.remove('selected'))
    thisElement.classList.add('selected')

    setSelectedIcon(imageName)
  }

  return (
    <div className='popout__container' id={popupID}>
      <div className='popout__box' id={popupID}>
        <CloseIcon id="popup__close-icon" onClick={closePopupWindow} />
        <div className="popup__content_wrapper">
          <button className="popup__options-button" id="popup__save_inbound--btn" onClick={handleSave}>Save</button>
          <div className="form__section">
            {
              type == "add" &&
              <>
                <h2 className="form__section_title">Warehouse name</h2>
                <input className="input_field__input_box--text" id="new_warehouse_name" type="text" name="name" />

                <h2 className="form__section_title">address</h2>
                <input className="input_field__input_box--text" id="new_warehouse_address" type="text" name="address" />

                <div className='input_field--combo-3-colmn'>
                  <div className='input_field'>
                    <h2 className="form__section_title">city</h2>
                    <input className="input_field__input_box--text" id="new_warehouse_city" type="text" name="city" />
                  </div>
                  <div className='input_field'>
                    <h2 className="form__section_title">postal code</h2>
                    <input className="input_field__input_box--text" id="new_warehouse_zip_code" type="text" name="zip_code" />
                  </div>
                  <div className='input_field'>
                    <h2 className="form__section_title">country</h2>
                    <input className="input_field__input_box--text" id="new_warehouse_country" type="text" name="country" />
                  </div>
                </div>

                <h2 className="form__section_title">Order email <p>(Orders for this warehouse will be emailed to this address)</p></h2>
                <input className="input_field__input_box--text" id="new_warehouse_order_email" type="text" name="order_email" />

                <h2 className="form__section_title">Warehouse owners</h2>
                <DropdownMultiselect
                  listArray={myTeam.filter(teamMember =>
                    !warehouseOwners.some(warehouseOwner => warehouseOwner?.id === teamMember?.id)
                  )}
                  state={warehouseOwners}
                  setState={setState}
                />

                <h2 className="form__section_title">Picto</h2>
                <div className='warehouse_pictos'>
                  {
                    iconArray.map((icon, index) => {
                      let image
                      try {
                        image = require(`../assets/pictos/${icon}`)
                      } catch {
                        image = require(`../assets/pics/image-not-found-general.png`)
                      }

                      return (
                        <div className='warehouse_img_parent' data-image={icon} key={index} onClick={handleSelectIcon}>
                          <img className="warehouse_img" src={image} />
                        </div>
                      )
                    })
                  }
                </div>
              </>
            }
            {
              type == "delete" &&
              <>
                <DropdownWarehouse
                  idList="create_inbound__dropdown--warehouse"
                  listArray={warehouses ? warehouses : []}
                  width="100%"
                  height={40}
                  fontSize="0.75rem"
                  placeholder="Select warehouse to delete"
                  state={selectedWarehouse}
                  setState={setStateWarehouse}
                  marginTop={"2.5px"}
                  type="from"
                />
              </>
            }
          </div>
        </div>
      </div>
    </div>
  )
}