import React, { useState, useEffect } from 'react'
import './Slider.css'

function Slider({ size, idNr, defaultState, position, onClickFunction, text, fontSize, state, style, theme }) {
    const [isRightPosition, setIsRightPosition] = useState(true);
    const [localState, setLocalState] = useState(defaultState)

    

    Slider.defaultProps = {
        fontSize: "0.6rem",
        size: 1,
        idNr: "0",
        position: "right",
        style: "",
        theme: "",
        defaultState: false,
    }

    const heightBar = `${12 * size}px`
    const widthBar = `${25 * size}px`
    const heightBall = `${8 * size}px`
    const widthBall = `${8 * size}px`

    useEffect(() => {
        // const sliderBar = document.getElementById(`slider-bar-${idNr}`)
        // const sliderBall = document.getElementById(`slider-ball-${idNr}`)
        // console.log(text, defaultState)

        // if (defaultState) {
        //     sliderBar.classList.add("green")
        //     sliderBall.classList.add("right")
        // }
        // if (position == "left") {
        //     setIsRightPosition(false)
        // }

        setLocalState(defaultState)
    }, [defaultState])


    useEffect(() => {
        setLocalState(state)
    }, [state])

    const handleOnclick = () => {
        setLocalState(!localState)
        onClickFunction(idNr, !localState)
    }

    return (
        <div className={`slider ${theme}`} id='slider' onClick={handleOnclick}>
            {
                isRightPosition ?
                    <>
                        <div className={`slider__text ${style}`} style={{ fontSize: fontSize }} >{text}</div>
                        <div className={`slider__bar ${localState ? "green" : ""}`} id={`slider-bar-${idNr}`} style={{ height: heightBar, width: widthBar }} >
                            <div className={`slider__ball ${localState ? "right" : ""}`} id={`slider-ball-${idNr}`} style={{ height: heightBall, width: widthBall }} ></div>
                        </div>
                    </>
                    :
                    <>
                        <div className={`slider__bar`} id={`slider-bar-${idNr}`} style={{ height: heightBar, width: widthBar }} >
                            <div className='slider__ball' id={`slider-ball-${idNr}`} style={{ height: heightBall, width: widthBall }} ></div>
                        </div>
                        <div className={`slider__text`} style={{ fontSize: fontSize }} >{text}</div>
                    </>

            }
        </div >
    )
}

export default Slider