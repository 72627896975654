import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filters: {
    page: 1,
    sortFilter: "business_name",
    sortAscending: true,
    filterB2C: true,
    searchWord: "",
    cityFilter: "",
    postalCodeFilter: "",
    typeFilter: "",
    subTypeFilter: "",
    customersListType: "",
    owner: {"id": null, "name": ""},
    mapBouns: {"ne": {}, "sw": {} }
  },
  mobilePage: 1,
  mapView: { "state": false, "center": { lat: 51.0482532, lng: 3.728389399999999 }},
  customerList: [],
  totalPagesCustomers: null,
  arrayOfCitiesCustomers: [],
  arrayOfPostalCodes: [],
  mapCustomers: [],
  mapTotalRecords: 0,
  mapHasMore: true,
  mapPage: 1,
};

const customerPageSlice = createSlice({
  name: "customerPageState",
  initialState,
  reducers: {
    updateFilters: (state, action) => {
      return {
        ...state,
        filters: action.payload
      };
    },
    updateTotalPagesCustomers: (state, action) => {
      return {
        ...state,
        totalPagesCustomers: action.payload
      };
    },
    updateMapView: (state, action) => {
      return {
        ...state,
        mapView: action.payload
      }
    },
    updateMobilePage: (state, action) => {
      return {
        ...state,
        mobilePage: action.payload
      }
    },
    updateCustomerList: (state, action) => {
      const customerList = action.payload;
      return {
        ...state,
        customerList: Array.isArray(customerList) ? customerList : []
      };
    },
    updateArrayOfCitiesCustomers: (state, action) => {
      const arrayOfCitiesCustomers = action.payload;
      return {
        ...state,
        arrayOfCitiesCustomers: Array.isArray(arrayOfCitiesCustomers) ? arrayOfCitiesCustomers : []
      };
    },
    updateArrayOfPostalCodes: (state, action) => {
      const arrayOfPostalCodes = action.payload;
      return {
        ...state,
        arrayOfPostalCodes: Array.isArray(arrayOfPostalCodes) ? arrayOfPostalCodes : []
      };
    },
    resetStateCustomerPage: (state) => {
      return {
        ...state,
        filters: {
          page: 1,
          sortFilter: "business_name",
          sortAscending: true,
          filterB2C: true,
          searchWord: "",
          cityFilter: "",
          postalCodeFilter: "",
          typeFilter: "",
          subTypeFilter: "",
          customersListType: "",
          owner: {"id": null, "name": ""},
          mapBouns: {"ne": {}, "sw": {} }
        },
        mobilePage: 1,
        mapView: { "state": false, "center": { lat: 51.0482532, lng: 3.728389399999999 }},
        customerList: [],
        totalPagesCustomers: null,
        arrayOfCitiesCustomers: [],
        arrayOfPostalCodes: [],
        mapCustomers: [],
        mapTotalRecords: 0,
        mapHasMore: true,
        mapPage: 1,
      };
    },
    resetStateCustomersList: (state) => {
      return {
        ...state,
        customerList: [],
        totalPagesCustomers: null,
      };
    },
    updateMapCustomers: (state, action) => {
      state.mapCustomers = action.payload;
    },
    updateMapTotalRecords: (state, action) => {
      state.mapTotalRecords = action.payload;
    },
    updateMapHasMore: (state, action) => {
      state.mapHasMore = action.payload;
    },
    updateMapPage: (state, action) => {
      state.mapPage = action.payload;
    },
  },
});

export const { 
  updateFilters, 
  updateCustomerList, 
  resetStateCustomerPage, 
  resetStateCustomersList, 
  updateTotalPagesCustomers, 
  updateArrayOfCitiesCustomers,
  updateArrayOfPostalCodes,
  updateMapView,
  updateMobilePage,
  updateMapCustomers,
  updateMapTotalRecords,
  updateMapHasMore,
  updateMapPage
} = customerPageSlice.actions;
export default customerPageSlice.reducer;
