import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import httpClient from "../httpClient"
import "./TotalSales.css"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import LoadingSpinner from './placeholders/LoadingSpinner'
import CircularProgress from '@mui/material/CircularProgress'

function TotalSales() {
  const [orderTotals, setOrderTotals] = useState([])
  const [loading, setLoading] = useState(true)

  const isMobile = useSelector(state => state.global.isMobile)

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
  )

  const userInfo = useSelector(state => state.user.userInfo)

  const fetchOrderTotals = async () => {
    setLoading(true)
    const responseOrderTotals = await httpClient.get(process.env.REACT_APP_API_URL + `/api/orders/report?user=&type=totalSales`)
    setOrderTotals(responseOrderTotals.data)
    setLoading(false)
  }

  useEffect(() => {
    fetchOrderTotals()
  }, [])

  const roundUpToNearest50 = (num) => {
    return Math.ceil(num / 50) * 50;
  }

  const today = new Date()
  const months = []

  for (let i = 0; i < 12; i++) {
    const date = new Date(today.getFullYear(), today.getMonth() - i, 1)
    const monthName = date.toLocaleString('en-US', { month: 'short' }).replace(/\.$/, '')
    months.push(monthName);
  }

  const labels = months.reverse()
  const highstSales = [...orderTotals].sort((a, b) => b - a)[0]

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      title: {
        display: false,
        text: "MY TOTAL SALES (items)",
      },
    },
    scales: {
      y:
      {
        min: 0,
        max: roundUpToNearest50(highstSales + (highstSales * 0.5)),
        stepSize: 1,
      },
      x:
      {

      },
    },
  }

  const data = {
    labels,
    datasets: [
      {
        label: 'All bottles',
        fill: true,
        data: orderTotals,
        borderColor: 'rgba(85, 193, 178, 1)',
        backgroundColor: 'rgba(85, 193, 178, 0.25)',
      },
    ]
  }

  return (
    <div className={`body__section_content__wrapper ${isMobile ? "mobile" : ""}`}>
      <h2 className="flex">my total sales <p> (units)</p></h2>
      {
        loading ?
        <div className='circular_loading_spinner__wrapper'><CircularProgress color='inherit' size="2rem" /></div> :
          <>
            {
              orderTotals.every(element => element == 0) ?
                <div className="centered">No sales recorded yet</div>
                : null
            }
            <div className="chart">
              <Line options={options} data={data} />
            </div>
          </>
      }
    </div>
  )
}

export default TotalSales