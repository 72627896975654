import { createSlice } from "@reduxjs/toolkit";

export const performancePageSlice = createSlice({
  name: "performancePageState",
  initialState: {
    allTimePerformance: [],
    performance: {},
    performanceDetail: {},
    filters: {
      "ranking_type": "by sales",
      "period": "this month",
      "hide_inactive": true,
      "detail_customer_types": []
    },
  },
  reducers: {
    updateFilters: (state, action) => {
      state.filters = action.payload;
    },

    updatePerformance: (state, action) => {
      const performance = action.payload
    
      state.performance = performance
    },

    updatePerformanceDetail: (state, action) => {
      const performanceDetail = action.payload
    
      state.performanceDetail = performanceDetail
    },

    updateAllTimePerformance: (state, action) => {
      const allTimePerformance = action.payload;
    
      // Check if customerList is an array
      if (Array.isArray(allTimePerformance)) {
        state.allTimePerformance = allTimePerformance;
      } else {
        state.allTimePerformance = []; // Set to an empty array if not an array
      }
    },

    resetStatePerformancePage: (state) => {
      state.filters = {
        "ranking_type": "by sales",
        "period": "this month",
        "hide_inactive": true
      }
      state.allTimePerformance = []
      state.performance = {}
    },
  },
})

export const { updateFilters, resetStatePerformancePage, updatePerformance, updateAllTimePerformance, updatePerformanceDetail } = performancePageSlice.actions
export default performancePageSlice.reducer