import React, { useEffect, useRef, useState } from "react"
import "./DropDownProduct.css"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

function DropDownProduct({ idList, listArray, listArray2, index, parentDivRef, onClickItem, value, placeholderText, inherit }) {
  const [selectedListName, setSelectedListName] = useState("products")
  const [selectedList, setSelectedList] = useState([])
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [searchValue, setSearchValue] = useState("")
  const [localSelectedState, setLocalSelectedState] = useState("")

  const ref = useRef(null)
  let key = 0

  const refDropdownProducts = useRef(null)

  const onClickOutside = () => {
    setIsDropdownOpen(false)
  }

  const handleToggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen)
  }

  useEffect(() => {
    setLocalSelectedState(value)
  }, [value])

  useEffect(() => {
    setSelectedList(listArray)
  }, [listArray])

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Adding check to see if the target is not the search bar or a descendant of the search bar
      const searchBar = document.querySelector(`#search-bar--${index}`)

      if (ref.current && searchBar && !ref.current.contains(event.target) && !searchBar.contains(event.target)) {
        onClickOutside && onClickOutside()
      }
    }
    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    }
  }, [onClickOutside, index])

  useEffect(() => {
    if (listArray2) {
      const productsTab = document.getElementById('products-list-tab')
      const materialsTab = document.getElementById('materials-list-tab')
      const dropdownList = document.getElementById(idList)

      if (selectedListName == "products") {
        materialsTab.classList.remove("selected")
        productsTab.classList.add("selected")
      } else if (selectedListName == "materials") {
        productsTab.classList.remove("selected")
        materialsTab.classList.add("selected")
      }
    }

  }, [selectedListName])

  useEffect(() => {
    let dropdownElement = refDropdownProducts.current

    if (parentDivRef) {
      const parentDivElement = parentDivRef.current
      const dropdownRect = dropdownElement.getBoundingClientRect()
      const parentDivRect = parentDivElement.getBoundingClientRect()

      const { top: dropdownTop, height: dropdownHeight } = dropdownRect
      const { top: parentDivTop, height: parentDivHeight } = parentDivRect

      const dropdownBottom = dropdownTop + dropdownHeight
      const parentDivBottom = parentDivTop + parentDivHeight

      if (dropdownBottom > parentDivBottom) {
        // Calculate the amount to scroll by
        const scrollAmount = dropdownBottom - parentDivBottom

        // Scroll the parent div with smooth animation
        parentDivElement.scrollBy({
          top: scrollAmount,
          behavior: 'smooth',
        })
      }
    }

    const searchBar = document.querySelector(`#search-bar--${index}`)
    if (searchBar) {
      searchBar.focus()
    }
  }, [isDropdownOpen, selectedListName])

  const handleClickTab = (event) => {
    event.stopPropagation()
    const listType = event.currentTarget.innerHTML
    const dropdownList = event.currentTarget.closest(".input_field__dropdown")
    setSelectedListName(listType)

    switch (listType) {
      case "products":
        setSelectedList(listArray)
        break
      case "materials":
        setSelectedList(listArray2)
        break
    }
  }

  const onClickListItem = (event, productName, productVolume) => {
    onClickItem(event, selectedListName)
    setLocalSelectedState(`${productName} ${productVolume}`)
  }

  return (
    <div className="product__dropdown__container flex--vert" onClick={handleToggleDropdown} ref={ref}>
      <span className="input_field__input_box--text sales" id={`dropdown-box-sales-products-${index}`}>
        <p className="dropdown-input--text_field flex">{value == "" | value == undefined ? placeholderText : localSelectedState}</p>
        <KeyboardArrowDownIcon />
      </span>
      <ul className={`input_field__dropdown ${isDropdownOpen ? 'active' : ''} ${inherit && "inherit"}`} key={key++} ref={refDropdownProducts}>
        {
          listArray2 ?
            <div>
              <div className="dropdown__list__tabs">
                <div className="dropdown__list__tab selected" id="products-list-tab" onClick={handleClickTab}>products</div>
                <div className="dropdown__list__tab" id="materials-list-tab" onClick={handleClickTab}>materials</div>
              </div>
              <input
                className="search-bar dropdown"
                id={`search-bar--${index}`}
                placeholder="Search..."
                value={searchValue}
                onChange={event => setSearchValue(event.currentTarget.value)}
                autoComplete="off"
                onClick={event => event.stopPropagation()}
              />
              {
                selectedList
                  .filter(item => Object.values(item).some(value => String(value).toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())))
                  .map((item, index) => {
                    return (
                      <li key={item.id} onClick={(e) => onClickListItem(e, item.name, item.volume)} id={item.id} data-list-type={selectedListName}>
                        <div>{item.name}</div>
                        <div>{item.volume}</div>
                      </li>
                    )
                  })
              }
            </div>
            :
            listArray.map((item, index) => {
              return (
                <li key={item.id} onClick={onClickItem} id={item.id}>
                  <div>{item.name}</div>
                  <div>{item.volume}</div>
                </li>
              )
            })
        }
      </ul>
    </div>
  )
}

export default DropDownProduct

DropDownProduct.defaultProps = {
  inherit: false
}
