import React, { useEffect, useState, useRef } from 'react'
import httpClient from "../../httpClient"
import './SalesPageBrandownerMobile.css'
import { useSelector, useDispatch } from "react-redux"
import MenuBarMobile from '../../components/mobile/MenuBarMobile'
import TotalSales from '../../components/TotalSales'
import TodoToday from '../../components/TodoToday'
import { resetStateSalesPageBrandOwner, updateFilters, updateOrderList, updateMobilePage } from "../../store/salesPageBrandOwner"
import CircularProgress from '@mui/material/CircularProgress'
import { PiCaretDoubleDownThin } from "react-icons/pi"
import { PiCaretRightThin } from "react-icons/pi"
import { CiFilter } from "react-icons/ci"
import { PiPlusThin } from "react-icons/pi"
import DoneIcon from '@mui/icons-material/Done'
import { RxCross1 } from "react-icons/rx"
import Label from '../../components/Label'
import PopupwindowFiltersMobile from '../../components/mobile/PopupwindowFiltersMobile'
import PopupwindowOrderDetailMobile from '../../components/mobile/PopupwindowOrderDetailMobile'
import PopupwindowCreateOrderMobile from '../../components/mobile/PopupwindowCreateOrderMobile'

function SalesPageBrandownerMobile() {
  const [loading, setLoading] = useState(false)
  const [loadingList, setLoadingList] = useState(false)
  const [searchWordFilter, setSearchWordFilter] = useState("")
  const [orderListType, setOrderListType] = useState("all")
  // const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(25)
  const [hasMore, setHasMore] = useState(true)
  const [filtersPopupIsActive, setFiltersPopupIsActive] = useState(false)
  const [createOrderPopupIsActive, setCreateOrderPopupIsActive] = useState(false)
  const [orderDetailPopupIsActive, setOrderDetailPopupIsActive] = useState(false)
  const [selectedOrder, setSelectedOrder] = useState(null)
  const [selectedOrderID, setSelectedOrderID] = useState(null)

  const dispatch = useDispatch()
  const user = useSelector(state => state.user.userInfo)
  const filters = useSelector(state => state.salesPageBrandOwner.filters)
  const orders = useSelector(state => state.salesPageBrandOwner.ordersList)
  const suppliersArray = ["Brandowner", "My salesforce", "STOQUP", "Wholesaler"]
  const page = useSelector(state => state.salesPageBrandOwner.mobilePage)
  
  const scrollForMoreRef = useRef(null)
  const previousFilters = useRef(filters)

  const fetchOrders = async () => {
    if (loadingList) return // Prevent multiple calls
    setLoadingList(true)

    try {
      const data = {
        filters
      }

      const responseOrders = await httpClient.post(process.env.REACT_APP_API_URL + `/api/brandowner/orders/get?type=${orderListType}&page=${page}&limit=${limit}&cumulativePagination=${true}`, data)
      dispatch(updateOrderList(responseOrders.data.orders))
      const hasMoreData = responseOrders.data.has_more

      if (!hasMoreData) {
        setHasMore(false)
      } else {
        setHasMore(true)
        dispatch(updateMobilePage(page + 1))
      }
    } catch (error) {
      console.error('Error fetching orders:', error)
    } finally {
      setLoading(false)
      setLoadingList(false)
    }
  }

  useEffect(() => {
    const handleIntersect = (entries) => {
      if (entries[0].isIntersecting && hasMore && !loadingList) {
        fetchOrders()
      }
    }

    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.95 // Trigger when the entire element is in view
    }

    const observer = new IntersectionObserver(handleIntersect, options)

    if (scrollForMoreRef.current) {
      observer.observe(scrollForMoreRef.current)
    }

    return () => {
      if (scrollForMoreRef.current) {
        observer.unobserve(scrollForMoreRef.current)
      }
    }
  }, [hasMore, loadingList])

  useEffect(() => {
    if (orders.length == 0) {
      fetchOrders()
    }
  }, [])

  const resetSalesPageState = () => {
    dispatch(resetStateSalesPageBrandOwner())
    dispatch(updateMobilePage(1))
  }

  useEffect(() => {
    const previousFiltersDict = previousFilters.current

    // Compare the current filters with the previous filters
    if (JSON.stringify(previousFiltersDict) !== JSON.stringify(filters)) {
      if (previousFiltersDict.page == filters.page) {
        // if page did not change, page has to be reset to 1 (because a filter changed)
        dispatch(updateMobilePage(1))
        setLoading(true)
      }

      fetchOrders()
        .then(() => {
          setLoading(false)
        })
        .catch(error => {
          console.error('Error fetching activities:', error)
          setLoading(false)
        })

      // Update previous filters to current filters
      previousFilters.current = filters
    }
  }, [filters])

  const handleOnClickOrder = (e, orderID) => {
    setOrderDetailPopupIsActive(true)
    const order = orders.find(order => order.id == orderID)
    setSelectedOrderID(orderID)
    setSelectedOrder(order)
  }

  return (
    <div className='template--mobile'>

      <PopupwindowCreateOrderMobile
        id={"popup-window--create-order-mobile"}
        isActive={createOrderPopupIsActive}
        setIsActive={setCreateOrderPopupIsActive}
        fetchOrders={fetchOrders}
      />

      <PopupwindowOrderDetailMobile
        id={"popup-window--order-detail-mobile"}
        isActive={orderDetailPopupIsActive}
        setIsActive={setOrderDetailPopupIsActive}
        selectedOrder={selectedOrder}
        orderListType={orderListType}
        user={user}
        selectedOrderID={selectedOrderID}
        orders={orders}
        fetchOrders={fetchOrders}
      />

      <PopupwindowFiltersMobile
        id={"popup-window-filters--sales-page-brandowners"}
        isActive={filtersPopupIsActive}
        setIsActive={setFiltersPopupIsActive}
        filters={filters}
        updateFilters={updateFilters}
        refreshState={resetSalesPageState}
        suppliersArray={suppliersArray}
      />

      <div className='template__body--mobile' id='mobile-sales-page--brandowner'>
        <div className='body__section--transparent' id='header--mobile'>
          <h1>Sales</h1>
          <div className='header__buttons'>
            <div className='header__button' onClick={() => setFiltersPopupIsActive(!filtersPopupIsActive)}><CiFilter /></div>
            <div className='header__button' onClick={() => setCreateOrderPopupIsActive(true)}><PiPlusThin /></div>
          </div>
        </div>

        <div className='body__section--transparent' id='sales-list'>
          {
            loading ?
              <div className='circular_loading_spinner__wrapper'><CircularProgress color='inherit' size="2rem" /></div>
              :
              <div className='table no-scrollbar'>
                {
                  orders.map((order, index) => {
                    let color = "grey"
                    let title = "N/A"
                    if (!order.products.every(product => product.readyForDelivery == true)) {
                      title = "To accept"
                      color = "grey"
                    } else if (!order.products.every(product => product.delivered == true)) {
                      title = "To deliver"
                      color = "blue"
                    } else if (!order.products.every(product => product.invoiced == true)) {
                      title = "To invoice"
                      color = "gold"
                    } else if (order.products.every(product => product.invoiced == true)) {
                      title = "Done"
                      color = "green"
                    }

                    return (
                      <div className='table__row_wrapper' key={index} onClick={(e) => handleOnClickOrder(e, order.id)} >
                        <div className='table__row-5--mobile'>
                          <div className='table__row--field'>{new Date(order.date).toLocaleDateString()}</div>
                          <div className='table__row--field'>{order.customer.business_name != null ? order.customer.business_name : `${order.customer.first_name} ${order.customer.last_name}`}</div>
                          <div className='table__row--field'>{order.sales_by}</div>
                          <div className='table__row--field'>{order.supplier}</div>
                          <div className='table__row--field'><Label title={title} labelColor={color} highContrast={true} /></div>
                        </div>
                        <div className='table__row--field--arrow'><PiCaretRightThin /></div>
                      </div>
                    )
                  })
                }
                <div className="scroll_for_more__element--mobile" ref={scrollForMoreRef}>
                  {
                    hasMore ?
                      <>
                        {
                          loadingList ?
                            <CircularProgress color="inherit" size="2rem" /> :
                            <>
                              {
                                orders.length > 0 &&
                                <div className='scroll_for_more__text'><PiCaretDoubleDownThin /> Scroll for more <PiCaretDoubleDownThin /></div>
                              }
                            </>
                        }
                      </> : null
                  }
                </div>
              </div>
          }
        </div>
      </div>

      <MenuBarMobile />
    </div>
  )
}

export default SalesPageBrandownerMobile