import React, { useState, useEffect } from 'react'
import './AdminInvoice.css'
import httpClient from "../httpClient"
import { useSelector, useDispatch } from "react-redux"
import { addUserInfo } from "../store/user"
import Header from "./Header"
import MenuBar from "./MenuBar"
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import EmailIcon from '@mui/icons-material/Email'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import LoadingSpinner from './placeholders/LoadingSpinner'
import SavingsIcon from '@mui/icons-material/Savings'

function AdminInvoicesPage(props) {

  const [user, setUser] = useState({ email: "", id: "" })
  const [error, setError] = useState(false)
  const [invoice, setInvoice] = useState(null)
  const [invoiceDate, setInvoiceDate] = useState(new Date())
  const [subTotal, setSubTotal] = useState(0)
  const [VAT, setVAT] = useState(0)
  const [totalDiscount, setTotalDiscount] = useState(0)
  const [totalDue, setTotalDue] = useState(0)
  const [hasDiscount, setHasDiscount] = useState(false)

  const dispatch = useDispatch()
  const userInfo = useSelector(state => state.user.userInfo)

  const fetchUser = async () => {
    const responseUser = await httpClient.get(process.env.REACT_APP_API_URL + "/api/@me?type=full")
    setUser(responseUser.data)
  }

  const fetchInvoice = async () => {
    const responseInvoice = await httpClient.get(process.env.REACT_APP_API_URL + "/api/admin/get-invoice/" + props.id)
    setInvoice(responseInvoice.data)
    setInvoiceDate(new Date(responseInvoice.data.invoice_date))

    let subTotalVar = 0
    let VATVar = 0
    let discount = 0
    responseInvoice.data.items.forEach(invoiceItem => {
      subTotalVar = subTotalVar + invoiceItem.item_price * invoiceItem.item_quantity
      let lineTotal = invoiceItem.item_price * invoiceItem.item_quantity

      if (invoiceItem.item_discount_percentage != null & invoiceItem.item_discount_percentage != 0.00) {

        discount = discount + invoiceItem.item_price * invoiceItem.item_quantity * invoiceItem.item_discount_percentage
        subTotalVar = subTotalVar - discount
        VATVar = VATVar + (lineTotal - (lineTotal * invoiceItem.item_discount_percentage)) * invoiceItem.item_VAT_percentage
        setHasDiscount(true)
      } else {
        VATVar = VATVar + invoiceItem.item_price * invoiceItem.item_quantity * invoiceItem.item_VAT_percentage
        console.log(invoiceItem.item_price * invoiceItem.item_quantity * invoiceItem.item_VAT_percentage)
      }
    })
    setSubTotal(subTotalVar.toFixed(2))
    setVAT(VATVar.toFixed(2))
    setTotalDiscount(discount.toFixed(2))
    const total = subTotalVar + VATVar
    setTotalDue(total.toFixed(2))

  }

  useEffect(() => {
    fetchUser()
      .catch(err => {
        console.log(err)
      })

    fetchInvoice()
      .catch(err => {
        console.log(err)
      })

  }, [])

  const stoqupLogoWhite = require(`../assets/logos/Stoqup_logo_black.png`)

  const options = { year: 'numeric', month: 'numeric', day: 'numeric' }

  return (
    <div className='template__invoice-admin' id='invoice-admin' >
      {
        invoice != null ? (
          <>
            <div className='invoice__header flex'>
              <img src={stoqupLogoWhite} />
              <div className='invoice__details'>
                <h1>INVOICE</h1>
                <div className='flex'>
                  <h4>invoice nr: </h4><p># {invoice.invoice_number}</p>
                </div>
                <div className='flex'>
                  <h4>invoice date: </h4><p>{invoiceDate.toLocaleDateString(options)}</p>
                </div>
                <div className='flex'>
                  <h4>due date: </h4><p>{new Date(invoiceDate.getTime() + 15 * 24 * 60 * 60 * 1000).toLocaleDateString()}</p>
                </div>
              </div>
            </div>
            <div className='invoice__invoicing_details'>
              <div className='invoice__details--client'>
                <h2>YOUR INFORMATION</h2>
                <p>{invoice.invoice_customer.company_name}</p>
                <p>{invoice.invoice_customer.VAT_number}</p>
                <div className='flex'>
                  <p>{invoice.invoice_customer.invoice_address_street}</p><p>{invoice.invoice_customer.invoice_address_nr}</p>
                </div>
                <div className='flex'>
                  <p>{invoice.invoice_customer.invoice_address_pc}</p><p>{invoice.invoice_customer.invoice_address_place}</p>
                </div>
              </div>
              <div className='invoice__details--stoqup'>
                <h2>OUR INFORMATION</h2>
                <p>{user.company_name}</p>
                <p>{user.VAT_number}</p>
                <div className='flex'>
                  <p>{user.invoice_address_street}</p><p>{user.invoice_address_nr}</p>
                </div>
                <div className='flex'>
                  <p>{user.invoice_address_pc}</p><p>{user.invoice_address_place}</p>
                </div>
              </div>
            </div>
            <div className='invoice__details--order'>
              <div className={`table__header-${hasDiscount ? "6" : "5"}`}>
                <p className='table__header--field'>PRODUCT</p>
                <p className='table__header--field'>QUANTITY</p>
                <p className='table__header--field'>PRICE</p>
                {
                  hasDiscount &&
                  <p className='table__header--field'>DISCOUNT</p>
                }
                <p className='table__header--field'>VAT %</p>
                <p className='table__header--field'>TOTAL</p>
              </div>
              {invoice.items.map((invoiceItem, index) => {
                return (
                  <div className={`table__row-${hasDiscount ? "6" : "5"} no-background`} key={index}>
                    <div className='table__row--field'>{invoiceItem.item_title}</div>
                    <div className='table__row--field'>{invoiceItem.item_quantity}</div>
                    <div className='table__row--field'>€ {invoiceItem.item_price}</div>
                    {
                      hasDiscount &&
                      <div className='table__row--field'>{invoiceItem.item_discount_percentage * 100}%</div>
                    }
                    <div className='table__row--field'>{invoiceItem.item_VAT_percentage * 100}%</div>
                    <div className='table__row--field'>€ {invoiceItem.item_price * invoiceItem.item_quantity - (invoiceItem.item_price * invoiceItem.item_quantity * invoiceItem.item_discount_percentage)}</div>
                  </div>
                )
              })}

              <div className='invoice_totals'>
                <div className='flex'>
                  <h4>Subtotal</h4>
                  <p>€ {subTotal}</p>
                </div>
                <div className='flex'>
                  <h4>VAT</h4>
                  <p>€ {VAT}</p>
                </div>
                <div className='flex'>
                  <h4>TOTAL DUE</h4>
                  <p>€ {totalDue}</p>
                </div>
              </div>
            </div>
            <div className='invoice__footer flex'>
              <div className="contact-info">
                <div className='contact-info--element'>
                  <div><WhatsAppIcon /></div>
                  <div>{user.tel_number}</div>
                </div>
                <div className='contact-info--element'>
                  <div><SavingsIcon /></div>
                  <div>{user.bank_IBAN}</div>
                </div>
                <div className='contact-info--element'>
                  <div><EmailIcon /></div>
                  <div>{user.email}</div>
                </div>
                <div className='contact-info--element'>
                  <div><LocationOnIcon /></div>
                  <div>{user.delivery_address_street} {user.delivery_address_nr}, {user.delivery_address_pc} {user.delivery_address_place}</div>.
                </div>
              </div>
              <div className='invoice__footer--payment'>
                <p>Thank you for your business. Please make your payment within 15 days of the invoice date to the <br /> bank account <b>{user.bank_IBAN}</b> with reference of the invoice number <b>{invoice.invoice_number}</b>.</p>
              </div>
            </div>
          </>
        ) : <div className='loading_spinner_admin_invoice'><LoadingSpinner /></div>}
    </div>
  )
}

export default AdminInvoicesPage