import React, { useState, useEffect, useRef } from "react"
import httpClient from "../httpClient"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import './ActivitiesPage.css'
import Header from "../components/Header"
import MenuBar from "../components/MenuBar"
import { PopOutWindowCreateActivity } from "../components/PopOutWindowCreateActivity"
import { PopOutWindowCreateActivityReport } from "../components/PopOutWindowCreateActivityReport"
import PopupWindowCreateNewCustomer from "../components/PopupWindowCreateNewCustomer"
import { formatDate, formatDateWithTime } from "../components/helpers/FormatDate"
import { activity_types } from '../assets/Data'
import CheckBox from "../components/CheckBox"
import TableCheckmarkHeader from '../components/TableCheckmarkHeader'
import TableCheckmarkRow from '../components/TableCheckmarkRow'
import CircularProgress from '@mui/material/CircularProgress'
import LoadingSpinner from "../components/placeholders/LoadingSpinner"
import SearchBarFilter from "../components/SearchBarFilter"
import { updateFilters, updateActivitiesList, updateTotalPagesActivities, resetStateActivitiesPage } from "../store/activitiesPage"
import DropDownFilter from "../components/DropDownFilter"
import DateRangeFilter from "../components/DateRangeFilter"
import Slider from "../components/Slider"
import Pagination from "../components/Pagination"
import EditIcon from '@mui/icons-material/Edit'
import { BiSortAlt2 } from "react-icons/bi";

function ActivitiesPage() {
  const [user, setUser] = useState({ email: "", id: "" })
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [customers, setCustomers] = useState([])
  const [newCustomerPopupIsActive, setNewCustomerPopupIsActive] = useState(false)
  const [selectedCustomer, setSelectedCustomer] = useState("")
  const [selectedCustomerID, setSelectedCustomerID] = useState("")
  const [limit, setLimit] = useState(20)
  const [selectedRows, setSelectedRows] = useState([])
  const [okToSetAsDone, setOkToSetAsDone] = useState(false)
  const [selectedActivity, setSelectedActivity] = useState(null)
  const [myBrands, setMyBrands] = useState([])
  const [editActivity, setEditActivity] = useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const userInfo = useSelector(state => state.user.userInfo)
  const filters = useSelector(state => state.activitiesPage.filters)
  const totalPages = useSelector(state => state.activitiesPage.totalPagesActivities)
  const activities = useSelector(state => state.activitiesPage.activitiesList)
  const myTeam = useSelector(state => state.user.myTeam)

  console.log(myTeam)

  const activityTypesArray = Object.keys(activity_types)
  const prioritiesArray = [
    { id: 1, name: "1: important & urgent" },
    { id: 2, name: "2: important & not urgent" },
    { id: 3, name: "3: not important & urgent" },
    { id: 4, name: "4: not important & not urgent" }
  ]
  const now = new Date()

  const previousFilters = useRef(filters)

  const fetchUser = async () => {
    const responseUser = await httpClient.get(process.env.REACT_APP_API_URL + "/api/@me?type=full")
    setUser(responseUser.data)
  }

  const fetchCustomers = async () => {
    const responseCustomers = await httpClient.get(process.env.REACT_APP_API_URL + "/api/users/get")
    setCustomers(responseCustomers.data)
  }

  const fetchActivities = async () => {
    setLoading(true)

    const data = {
      filters,
    }

    const response = await httpClient.post(process.env.REACT_APP_API_URL + `/api/activities/get?page=${filters.page}&limit=${limit}&listType=${filters.listType}`, data)
    
    dispatch(updateActivitiesList(response.data.activities))
    dispatch(updateTotalPagesActivities(response.data.pages))
    setLoading(false)
  }

  const fetchMyBrands = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/salesforce/get-my-brandowners`)

    setMyBrands(response.data)
  }

  useEffect(() => {
    fetchUser()
      .catch(err => {
        console.log(err)
        setError(true)
      })

    fetchCustomers()
      .catch(err => {
        console.log(err)
        setError(true)
      })

    if (!activities.length) {
      fetchActivities()
        .catch(err => {
          console.log(err)
          setError(true)
        })
    }

    fetchMyBrands()
      .catch(err => {
        console.log(err)
        setError(true)
      })

  }, [])
  
  useEffect(() => {
    const previousFiltersDict = previousFilters.current
    
    // Compare the current filters with the previous filters
    if (JSON.stringify(previousFiltersDict) !== JSON.stringify(filters)) {
      if (previousFiltersDict.page == filters.page) {
        // if page did not change, page has to be reset to 1 (because a filter changed)
        dispatch(updateFilters({...filters, page: 1}))
      }

      fetchActivities()
        .catch(err => {
          console.log(err)
        })

      // Update previous filters to current filters
      previousFilters.current = filters
    }
  }, [filters])

  const handleOnMouseOver = (event) => {
    const headerItem = event.currentTarget
    const headerItemNumber = event.currentTarget.children[0]

    headerItem.classList.add("hovered")
    headerItemNumber.classList.add("hovered")
  }

  const handleOnMouseOut = (event) => {
    const headerItem = event.currentTarget
    const headerItemNumber = event.currentTarget.children[0]

    headerItem.classList.remove("hovered")
    headerItemNumber.classList.remove("hovered")
  }

  const handleOnCLickHeaderItem = (event) => {
    const headerItem = event.currentTarget
    const headerItemNumber = event.currentTarget.children[0]
    const allHeaderItems = document.getElementsByClassName("body__section__header_item")
    const allHeaderItemNumbers = document.getElementsByClassName("header_item--number")

    setLoading(true)

    allHeaderItemNumbers.forEach(item => {
      item.classList.remove("selected")
    })
    allHeaderItems.forEach(item => {
      item.classList.remove("selected")
    })

    headerItem.classList.add("selected")
    headerItemNumber.classList.add("selected")

    const newFilterState = {...filters}

    switch (event.currentTarget.getAttribute("data-id")) {
      case "header-next-week":
        newFilterState.listType = "next-week"
        break
      case "header-this-week":
        newFilterState.listType = "this-week"
        break
      case "header-today":
        newFilterState.listType = "today"
        break
      case "header-overdue":
        newFilterState.listType = "overdue"
        break
      case "header-all":
        newFilterState.listType = "all"
        break
    }

    dispatch(updateFilters(newFilterState))
  }

  const handleCreateActivity = () => {
    const popout = document.querySelector("#popout-create-activity")
    popout.classList.add("active")
  }

  const handleEdit = (event, id) => {
    event.stopPropagation()

    setEditActivity(true)

    const activity = activities.find(obj => obj.id == id)
    setSelectedActivity(activity)

    const popout = document.querySelector("#popout-create-activity")
    popout.classList.add("active")
  }

  const handlePreCheckDone = async (id) => {
    const activity = activities.find(obj => obj.id == id)
    setSelectedActivity(activity)

    if (activity.done) {
      setLoading(true)

      const data = {
        "ids": [id]
      }

      const response = await httpClient.post(process.env.REACT_APP_API_URL + `/api/activities/change-done-status?id=${id}`, data)
      console.log(response.data)

      fetchActivities()
      setLoading(false)

    } else {
      const popout = document.querySelector("#popout-create-activity-report")
      popout.classList.add("active")
    }

  }

  const handleOnClickFilterItem = (event) => {

    const filter = event.currentTarget.textContent
    const filterObject = JSON.parse(event.currentTarget.getAttribute("data-item"))

    const updatedFiltersObj = { ...filters }

    switch (event.target.parentElement.id) {
      case "activities__dropdownList--type":
        updatedFiltersObj["typeFilter"] = filter
        break
      case "activities__dropdownList--owner":
        updatedFiltersObj["ownerFilter"] = filterObject
        break
      case "activities__dropdownList--priority":
        updatedFiltersObj["priorityFilter"] = filterObject
        break
    }

    dispatch(updateFilters(updatedFiltersObj))
  }

  const handleHideOwnerless = () => {
    const updatedFilters = { ...filters }
    updatedFilters["doneFilter"] = !filters.doneFilter

    dispatch(updateFilters(updatedFilters))
  }

  const handleClickRow = (event) => {
    // Find the closest parent row to the clicked element
    const parentRow = event.target.closest('.table__row-9')

    // If a parent row is found
    if (parentRow) {
      // Find the next sibling element which should be the .activity__detail div
      const detailDiv = parentRow.nextElementSibling

      // Check if the next sibling is actually the .activity__detail div
      if (detailDiv && detailDiv.classList.contains('activity__detail')) {
        detailDiv.classList.toggle('hide')
      }
    }
  }

  const handleSetSort = (event) => {
    const value = event.currentTarget.firstChild.textContent

    const newFilterData = { ...filters }

    switch (value) {
      case "Due date":
        console.log("hello")
        newFilterData["sortAscending"] = !filters.sortAscending
        break
    }

    dispatch(updateFilters(newFilterData))
  }

  const onClickCustomer = (e, customerID) => {
    e.stopPropagation()

    const link = `/customer/${customerID}`
    navigate(link)
    
  }

  return (
    <div className="template">
      {/* {error ? window.location.href = "/" : null} */}

      <button className="floating__button--bottom-right" id="add--btn" onClick={handleCreateActivity} />

      <PopOutWindowCreateActivity
        popupID={"popout-create-activity"}
        customers={customers}
        selectedCustomerID={selectedCustomerID}
        setSelectedCustomerID={setSelectedCustomerID}
        selectedCustomer={selectedCustomer}
        setSelectedCustomer={setSelectedCustomer}
        setNewCustomerPopupIsActive={setNewCustomerPopupIsActive}
        fetchActivities={fetchActivities}
        myBrands={myBrands}
        editActivity={editActivity}
        setEditActivity={setEditActivity}
        selectedActivity={selectedActivity}
        myTeam={myTeam || []}
      />

      <PopOutWindowCreateActivityReport
        selectedActivity={selectedActivity}
        setSelectedActivity={setSelectedActivity}
        popupID={"popout-create-activity-report"}
        customers={customers}
        selectedCustomerID={selectedCustomerID}
        setSelectedCustomerID={setSelectedCustomerID}
        setNewCustomerPopupIsActive={setNewCustomerPopupIsActive}
        fetchActivities={fetchActivities}
        myBrands={myBrands}
      />

      <Header>
        <div className={`header__options`}>
          <div className="header__option--slider"><Slider size={1.2} text="Hide done" onClickFunction={handleHideOwnerless} state={filters.doneFilter} defaultState={true} /></div>
        </div>

        <div className={`header__options--right-template ${(selectedRows.length == 0) ? "" : "hide"}`} id="header__options--activities">
          <SearchBarFilter
            id="activities__search-bar--table"
            filters={filters}
            updateFilters={updateFilters}
            placeholder="Search..."
          />
          <DropDownFilter
            idList="activities__dropdownList--type"
            placeholderText="Filter on type"
            listArray={activityTypesArray}
            onClickItem={handleOnClickFilterItem}
            value={filters.typeFilter}
            reduxState={filters}
            filter={"typeFilter"}
            setValue={updateFilters}
            dropdownType={"activitiesPage"}
            widthSize={"medium"}
          />
          <DropDownFilter
            idList="activities__dropdownList--priority"
            placeholderText="Filter on priority"
            listArray={prioritiesArray}
            onClickItem={handleOnClickFilterItem}
            value={filters.priorityFilter}
            reduxState={filters}
            filter={"priorityFilter"}
            setValue={updateFilters}
            dropdownType={"activitiesPage"}
            widthSize={"medium"}
          />

          {
            (userInfo.type == "brandowner" | userInfo.is_pro_account) ?
              <>
                <DropDownFilter
                  idList="activities__dropdownList--owner"
                  placeholderText="Filter on owner"
                  listArray={myTeam || []}
                  onClickItem={handleOnClickFilterItem}
                  value={filters.ownerFilter}
                  reduxState={filters}
                  filter={"ownerFilter"}
                  setValue={updateFilters}
                  dropdownType={"activitiesPage"}
                  widthSize={"medium"}
                />
              </> : null
          }
        </div>
      </Header>
      <MenuBar />
      <div className="template__body">

        <div className="body__activities">

          <PopupWindowCreateNewCustomer
            customers={customers}
            isActive={newCustomerPopupIsActive}
            setIsActive={setNewCustomerPopupIsActive}
            fetchCustomers={fetchCustomers}
            setSelectedCustomer={setSelectedCustomer}
            setSelectedCustomerID={setSelectedCustomerID}
          />

          <div className="body__section__header">
            <div className="body__section__header_item selected" data-id="header-all" onClick={handleOnCLickHeaderItem} onMouseOver={handleOnMouseOver} onMouseOut={handleOnMouseOut}>All <div className="header_item--number"></div></div>
            <div className="body__section__header_item" data-id="header-overdue" onClick={handleOnCLickHeaderItem} onMouseOver={handleOnMouseOver} onMouseOut={handleOnMouseOut}>Overdue <div className="header_item--number"></div></div>
            <div className="body__section__header_item" data-id="header-today" onClick={handleOnCLickHeaderItem} onMouseOver={handleOnMouseOver} onMouseOut={handleOnMouseOut}>Today <div className="header_item--number"></div></div>
            <div className="body__section__header_item" data-id="header-this-week" onClick={handleOnCLickHeaderItem} onMouseOver={handleOnMouseOver} onMouseOut={handleOnMouseOut}>This week <div className="header_item--number"></div></div>
            <div className="body__section__header_item" data-id="header-next-week" onClick={handleOnCLickHeaderItem} onMouseOver={handleOnMouseOver} onMouseOut={handleOnMouseOut}>Next week <div className="header_item--number"></div></div>
          </div>

          <div className="body__section" id="activities-list">
            {
              loading ?
                <LoadingSpinner /> :
                <div className="table">
                  <div className="table__header-9">
                    <TableCheckmarkHeader
                      id={`table-checkmark-header-activities`}
                      selectedRows={selectedRows}
                      setSelectedRows={setSelectedRows}
                    />
                    <div className="table__header--field flex--center">Type</div>
                    <div className="table__header--field">Customer</div>
                    <div className="table__header--field">Title</div>
                    <div className="table__header--field">Owner</div>
                    <div className="table__header--field" onClick={handleSetSort}><p>Due date</p> <BiSortAlt2 /></div>
                    <div className="table__header--field flex--center">Priority</div>
                    <div className="table__header--field flex--center">Done</div>
                    <div className="table__header--field flex--center"></div>
                  </div>
                  <div className="table__rows_container">
                    {
                      activities.map((activity, index) => {

                        let isOverdue = false
                        if (new Date(activity.planned_date) < now & !activity.done) {
                          isOverdue = true
                        }

                        return (
                          <div key={index}>
                            <div className={`table__row-9 ${isOverdue ? "overdue" : activity.done ? "done" : ""}`} id='checkmark-row' data-activity-id={activity.id} onClick={handleClickRow}>
                              <TableCheckmarkRow
                                index={index}
                                id={`table-checkmark-row-activities-${index}`}
                                selectedRows={selectedRows}
                                setSelectedRows={setSelectedRows}
                              />
                              <div className="table__row--field flex--center"><img src={activity_types[activity.type]?.picto} /></div>
                              <div className="table__row--field" onClick={(e) => onClickCustomer(e, activity.customer_id.id)}>{activity.customer.business_name}</div>
                              <div className="table__row--field">{activity.title}</div>
                              <div className="table__row--field">{activity.sales_rep_id.first_name} {activity.sales_rep_id.last_name}</div>
                              <div className="table__row--field">{formatDateWithTime(activity.planned_date, true)}</div>
                              <div className="table__row--field flex--center">{activity.priority}</div>
                              <div className="table__row--field flex--center">
                                <CheckBox
                                  index={activity.id}
                                  state={activity.done}
                                  width={"20px"}
                                  height={"20px"}
                                  margin={"0"}
                                  setData={handlePreCheckDone}
                                  style={"hybrid"}
                                  type={"controlled"}
                                />
                              </div>
                              <div className="table__row--field flex--center" onClick={(e) => handleEdit(e, activity.id)}><EditIcon /></div>
                            </div>
                            <div className="activity__detail hide">
                              <div className={`activity__detail--comment ${activity.comment == "" ? "hide" : ""}`}>
                                <h3>Comment</h3>
                                {activity.comment}
                              </div>
                              {
                                activity.done &&
                                activity.report.map((report, index) => (
                                  <div key={index}>
                                    <h3>Report for {report.brandowner_id.business_name}</h3>
                                    {report.report}
                                  </div>
                                ))
                              }
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                  <Pagination
                    id="pagination-activities-page-list"
                    stateType="redux"
                    filters={filters}
                    page={filters.page}
                    setPage={updateFilters}
                    totalPages={totalPages}
                  />
                </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default ActivitiesPage