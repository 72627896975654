import React, { useState, useEffect } from 'react'
import './PopOutWindowInventoryChange.css'
import CloseIcon from '@mui/icons-material/Close'
import Dropdown from './Dropdown'
import DropdownWarehouse from './DropdownWarehouse'
import { toast } from "react-toastify"
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'

export function PopOutWindowInventoryChange({
  popupID, onClose, handleSaveInbound,
  warehouses, productsAndMaterials,
  addProductCounter, setAddProductCounter,
  createInboundSelectedProducts, setCreateInboundSelectedProducts,
  createInboundComment, setCreateInboundComment,
  fromWarehouseIsStoqup, setFromWarehouseIsStoqup,
  selectedWarehouses, setSelectedWarehouses,
  inventoryChangeReason, setInventoryChangeReason
}) {
  const [selectedToWarehouse, setSelectedToWarehouse] = useState(selectedWarehouses.to.name)
  const [selectedProducts, setSelectedProducts] = useState(createInboundSelectedProducts)

  PopOutWindowInventoryChange.defaultProps = {
    onClose: () => console.log(""),
    overflow: "scroll",
  }

  useEffect(() => {
    const popup = document.getElementById(popupID)
    window.addEventListener('keydown', e => {
      if (e.key === 'Escape') {
        if (popup.classList.contains("active")) {
          closePopupWindow()
        }
      }
    })
  }, [])

  const handleOnClickExtraProduct = () => {
    setAddProductCounter(addProductCounter + 1)
  }

  const closePopupWindow = () => {
    const popup = document.getElementById(popupID)
    popup.classList.remove("active")
    onClose()
  }

  const setState = (itemName, indexDropdown, productID) => {

    const value = {
      productID: productID,
      name: itemName,
      quantity: 0,
    }

    if (createInboundSelectedProducts[indexDropdown] === undefined) {
      const newArray = [...createInboundSelectedProducts, value]
      setCreateInboundSelectedProducts(newArray)
    } else {
      const newArray = [...createInboundSelectedProducts]
      newArray[indexDropdown] = value
      setCreateInboundSelectedProducts(newArray)
    }

    // target the dropdown and select the amount box
    const dropdown = document.querySelector(`#dropdown__${indexDropdown}`)
    const amountBox = dropdown.nextElementSibling
    amountBox.select()
  }

  const setStateReason = (value) => {
    setInventoryChangeReason(value)
  }

  const setStateWarehouses = (type, name = null, id = null) => {
    const updatedObj = selectedWarehouses
    if (type == "from") {
      updatedObj.from = { "name": name, "id": id }
    } else if (type == "to") {
      setSelectedToWarehouse(name)
      updatedObj.to = { "name": name, "id": id }
    }

    setSelectedWarehouses(updatedObj)
  }

  const handleOnclickDeleteProductline = (e) => {
    if (addProductCounter > 1) {
      const index = e.currentTarget.parentNode.dataset.index
      const newArray = [...createInboundSelectedProducts]
      newArray.splice(index, 1)
      setCreateInboundSelectedProducts(newArray)

      setAddProductCounter(addProductCounter - 1)
    }
  }

  const handleOnChangeAmount = (e) => {
    try {
      const index = e.currentTarget.parentNode.dataset.index
      const newArray = [...createInboundSelectedProducts]
      newArray[index].quantity = e.currentTarget.value
      setCreateInboundSelectedProducts(newArray)
    } catch {
    }
  }

  const saveInboundPreCheck = () => {

    const productDropdowns = document.getElementsByClassName("create_inbound__add_product-container")
    const warehouseDropdowns = document.getElementsByClassName("dropdown__container-dropdown--warehouse")
    let ok = true

    productDropdowns.forEach(element => {
      const dropdownBox = element.childNodes[0].firstChild
      const amountBox = element.childNodes[1]

      if (dropdownBox.textContent == undefined | dropdownBox.textContent == "") {
        dropdownBox.classList.add("red-border")
        ok = false
      }

      if (amountBox.value == 0) {
        amountBox.classList.add("red-border")
        ok = false
      }
    })

    if (Array.from(warehouseDropdowns).every(dropdown => (dropdown.firstChild.textContent == "" | dropdown.firstChild.textContent == undefined))) {
      ok = false
    }

    if (ok) {
      if (selectedWarehouses.from.name == "STOQUP" & selectedWarehouses.to.name != null) {
        setFromWarehouseIsStoqup(true)
      } else {
        handleSaveInbound()
      }
    } else {
      toast.error("Please fill out all fields", { theme: "dark" })
    }
  }

  return (
    <div className='popout__container inventory_change' id={popupID}>


      <div className='popout__box' id={popupID}>

        <CloseIcon id="popup__close-icon" onClick={closePopupWindow} />

        <div className="popup__content_wrapper">

          <button className="popup__options-button" id="popup__save_inbound--btn" onClick={saveInboundPreCheck}>Save</button>
          <div className="form__section" id="create_inbound__container">
            <h2 className="form__section_title">From warehouse</h2>
            <DropdownWarehouse
              idList="create_inbound__dropdown--warehouse"
              listArray={warehouses}
              width="100%"
              height={40}
              fontSize="0.75rem"
              placeholder="From warehouse (leave blank if none)"
              state={selectedWarehouses.from.name}
              setState={setStateWarehouses}
              marginTop={"2.5px"}
              type="from"
            />
            <h2 className="form__section_title">To warehouse</h2>
            <DropdownWarehouse
              idList="create_inbound__dropdown--warehouse"
              listArray={warehouses}
              width="100%"
              height={40}
              fontSize="0.75rem"
              placeholder="To warehouse (leave blank if none)"
              state={selectedWarehouses.to.name}
              setState={setStateWarehouses}
              marginTop={"2.5px"}
              type="to"
            />
            <h2 className="form__section_title">Products</h2>
            <div className="create_inbound__form">
              {
                Array(addProductCounter).fill(0).map((i, index) => {
                  return (
                    <div className="create_inbound__add_product-container" key={index} data-index={index}>
                      <Dropdown
                        index={index}
                        idList="create_inbound__dropdown--product"
                        listArray={productsAndMaterials}
                        width="100%"
                        height={40}
                        fontSize="0.75rem"
                        placeholder="Select a product or material"
                        state={createInboundSelectedProducts[index]}
                        setState={setState}
                        marginTop={"2.5px"}
                        searchBar={true}
                      />
                      <input className="input_field__input_box--text product" value={createInboundSelectedProducts[index] ? createInboundSelectedProducts[index].quantity : 0} onChange={handleOnChangeAmount} />
                      <div className="delete" onClick={handleOnclickDeleteProductline}><DeleteOutlineOutlinedIcon id="delete-icon" /></div>
                    </div>
                  )
                })
              }
              <button className="more-products--btn" onClick={handleOnClickExtraProduct}>+</button>
            </div>
            <h2 className="form__section_title">Reason</h2>
            <Dropdown
              idList="create_inbound__dropdown--reason"
              listArray={["Stock up", "Samples", "Free goods", "Correction", "Marketing", "Other"]}
              width="100%"
              height={40}
              fontSize="0.75rem"
              placeholder="Select a product or material"
              state={inventoryChangeReason}
              setState={setStateReason}
              marginTop={"2.5px"}
            />


            <textarea className="input_field__input_box--text big" value={createInboundComment} onChange={(event) => setCreateInboundComment(event.target.value)} placeholder="Add a comment (optional)"></textarea>

          </div>
        </div>

      </div>
    </div>
  )
}