import React, { useState, useEffect } from 'react'
import './Label.css'

function Label({simple, labelColor, bright, fontSize, fontWeight, title, highContrast}) {

  Label.defaultProps = {
    labelColor: "grey",
    title: "no title",
    fontSize: "0.6rem",
    simple: false,
    bright: false,
    highContrast: false,
    fontWeight: "300"
  }

  return (
    <div className={`label ${simple ? "" : labelColor} ${bright ? "bright" : null} ${highContrast ? "high-contrast" : null}`} >
      <div className={`ball ${labelColor} ${bright ? "bright" : null}`}></div>
      <h4 style={simple ? {fontWeight: fontWeight, fontSize: fontSize } : null}>{title}</h4>
    </div>
  )
}

export default Label