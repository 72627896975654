import React, { useState, useEffect } from "react"
import httpClient from "../httpClient"
import { useSelector, useDispatch } from "react-redux"
import './ProductsPageBrandowner.css'
import Header from "../components/Header"
import MenuBar from "../components/MenuBar"
import { PopOutWindowCreateProduct } from "../components/PopOutWindowCreateProduct"
import Pagination from "../components/Pagination"
import LoadingSpinner from "../components/placeholders/LoadingSpinner"
import { updateFilters, updateProducts, resetStateProductsPage } from "../store/productsPage"
import SearchBarFilter from "../components/SearchBarFilter"

function ProductsPageBrandowner() {
  const [user, setUser] = useState({ email: "", id: "" })
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)
  const [productPopupIsOpen, setProductPopupIsOpen] = useState(false)
  const [updateProduct, setUpdateProduct] = useState(false)
  const [products, setProducts] = useState([])
  const [selectedProduct, setSelectedProduct] = useState({})
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(15)
  const [totalPages, setTotalPages] = useState()

  const dispatch = useDispatch()

  const userInfo = useSelector(state => state.user.userInfo)
  const filters = useSelector(state => state.productsPage.filters)

  const fetchProducts = async () => {
    setLoading(true)

    const data = {
      filters
    }

    const response = await httpClient.post(process.env.REACT_APP_API_URL + `/api/brandowner/products?page=${page}&limit=${limit}`, data)
    setProducts(response.data.products)
    setTotalPages(response.data.total_pages)

    setLoading(false)
  }

  useEffect(() => {
    fetchProducts()
      .catch(err => {
        console.log(err)
        setError(true)
      })
  }, [])

  useEffect(() => {
    fetchProducts()
  }, [page])

  useEffect(() => {

    fetchProducts()
      .catch(err => {
        console.log(err)
        setError(true)
      })

  }, [filters])

  const resetProductsState = () => {
    setPage(1)
    fetchProducts()
      .catch(err => {
        console.log(err)
        setError(true)
      })
  }

  const handleCreateNewProduct = () => {
    setUpdateProduct(false)
    setProductPopupIsOpen(!productPopupIsOpen)
  }

  const handleClickProduct = (e, index) => {
    setSelectedProduct(products[index])
    setProductPopupIsOpen(true)
    setUpdateProduct(true)
  }

  return (
    <div className="template">
      {/* {error ? window.location.href = "/" : null} */}

      <Header>
        <div className="header__options--right">
          <SearchBarFilter
            id={'searchbar--products'}
            placeholder="Search product..."
            filters={filters}
            updateFilters={updateFilters}
          />
        </div>
      </Header>
      <MenuBar />
      <div className="template__body">

        <PopOutWindowCreateProduct
          popupID={'popout--create-new-product'}
          isActive={productPopupIsOpen}
          setIsActive={setProductPopupIsOpen}
          isUpdate={updateProduct}
          maxHeight={"75%"}
          fetchProducts={fetchProducts}
          resetProductsState={resetProductsState}
          selectedProduct={selectedProduct}
        />

        <button className="floating__button--bottom-right" id="add--btn" onClick={handleCreateNewProduct} />
        <div className="body__brandowner_products">
          <div className={`body__section big`} id="my-products-table">
            {
              loading ?
                <LoadingSpinner /> :
                <>
                  <div className="table" id="products--table">
                    <div className="table__header-6">
                      <div className="table__header--field"></div>
                      <div className="table__header--field">SKU</div>
                      <div className="table__header--field">Product name</div>
                      <div className="table__header--field">Volume</div>
                      <div className="table__header--field">Category</div>
                      <div className="table__header--field">Price</div>
                    </div>
                    <div className="table__rows_container">
                      {
                        products.map((product, index) => {
                          let image_link = require('../assets/pics/image-not-found-general.png')
                          if (product.direct_link) {
                            image_link = product.direct_link
                          }
                          return (
                            <div className="table__row-6" key={index} onClick={(e) => handleClickProduct(e, index)}>
                              <div className="table__row--field"><img src={image_link} /></div>
                              <div className="table__row--field">{product.sku}</div>
                              <div className="table__row--field">{product.name}</div>
                              <div className="table__row--field">{product.volume}</div>
                              <div className="table__row--field">{product.category}</div>
                              <div className="table__row--field">€{product.price}</div>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                  <Pagination
                    id={'pagination--products-page'}
                    page={page}
                    setPage={setPage}
                    totalPages={totalPages}
                  />
                </>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductsPageBrandowner