import React from 'react';
import { Navigate, useLocation } from 'react-router-dom'

export function ProtectedRoute({ children, isAuthenticated }) {
    const location = useLocation()

    if (!isAuthenticated) {
        // Redirect to the login page and store the intended location
        return <Navigate to="/" state={{ from: location }} replace />
    }

    return children
}