import React, { useState, useEffect, useRef } from "react"
import httpClient from "../httpClient"
import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import DropDownInputCustomer from "../components/DropDownInputCustomer"
import { changeNotificationMessage } from "../store/notification"
import { NotificationTop, showNotification } from "../components/NotificationTop"
import { NotificationPopupMessage, showNotificationPopupMessage } from "../components/NotificationPopupMessage"
import { showNotificationPopup } from "../components/NotificationPopup"
import "./SalesPageBrandOwner.css"
import Header from "../components/Header"
import MenuBar from "../components/MenuBar"
import AddProductForSales from "../components/AddProductForSales"
import Slider from "../components/Slider"
import { closePopupWindow, PopupWindow } from "../components/PopupWindow"
import CheckBox from "../components/CheckBox"
import CheckBoxStatic from "../components/CheckBoxStatic"
import LoadingSpinner from "../components/placeholders/LoadingSpinner"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import DoneIcon from '@mui/icons-material/Done'
import { RxCross1 } from "react-icons/rx"
import { Link } from "react-router-dom"
import { Skeleton } from "@mui/material"
import isEqual from 'lodash/isEqual'
import LoadingOverlay from "../components/placeholders/LoadingOverlay"
import Label from "../components/Label"
import { exportToExcel } from "../components/helpers/Exports"
import { formatDateExportTitle } from "../components/helpers/FormatDate"
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import Dropdown from '../components/Dropdown'
import { couriers } from "../assets/Data"
import SearchHeader from "../components/SearchHeader"
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import DropDownFilter from "../components/DropDownFilter"
import { resetStateSalesPageBrandOwner, updateFilters, updateOrderAmounts, updateOrderList, updateTotalPagesOrders } from "../store/salesPageBrandOwner"
import PopupWindowCreateNewCustomer from "../components/PopupWindowCreateNewCustomer"
import LoadingSpinnerOverlay from "../components/placeholders/LoadingSpinnerOverlay"
import { toast } from "react-toastify"
import DropdownButton from "../components/DropdownButton"
import TableCheckmarkHeader from '../components/TableCheckmarkHeader'
import TableCheckmarkRow from '../components/TableCheckmarkRow'
import Pagination from "../components/Pagination"

function SalesPageBrandOwner() {
  const [user, setUser] = useState({ email: "", id: "" })
  const [loading, setLoading] = useState(false)
  const [loadingProgressStep, setLoadingProgressStep] = useState(false)
  const [loadingExport, setLoadingExport] = useState(false)
  const [error, setError] = useState(false)
  const [selectedCustomer, setSelectedCustomer] = useState("")
  const [selectedCustomerID, setSelectedCustomerID] = useState("")
  const [selectedProducts, setSelectedProducts] = useState([])
  const [countArray, setCountArray] = useState([1])
  const [customers, setCustomers] = useState([])
  const [products, setProducts] = useState([])
  const [materials, setMaterials] = useState([])
  const [selectedOrder, setSelectedOrder] = useState([])
  const [selectedOrderData, setSelectedOrderData] = useState()
  const [progressChangesArray, setProgressChangesArray] = useState([])
  const [hideCompleted, setHideCompleted] = useState(true)
  const [comment, setComment] = useState("")
  const [showPopup, setShowPopup] = useState(true)
  const [orderEvents, setOrderEvents] = useState([])
  const [updatedTrackingID, setUpdatedTrackingID] = useState()
  const [updatedShippingCode, setUpdatedShippingCode] = useState()
  const [progressChangeOverwrite, setProgressChangeOverwrite] = useState(false)
  const [progressStepStage, setProgressStepStage] = useState("")
  const [progressStepOrderID, setProgressStepOrderID] = useState("")
  const [searchWordFilter, setSearchWordFilter] = useState("")
  const [limit, setLimit] = useState(20)
  const [tableBig, setTableBig] = useState(false)
  const [newCustomerPopupIsActive, setNewCustomerPopupIsActive] = useState(false)
  const [resetProductDropdowns, setResetProductDropdowns] = useState(false)
  const [popupWindowSalesDetailIsOpen, setPopupWindowSalesDetailIsOpen] = useState(false)
  const [warehouses, setWarehouses] = useState([])
  const [selectedWarehouse, setSelectedWarehouse] = useState()
  const [selectedRows, setSelectedRows] = useState([])

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const inputRef1 = useRef(null)
  const inputRef2 = useRef(null)
  const acceptButtonDropdownRef = useRef(null)
  const userInfo = useSelector(state => state.user)
  const filters = useSelector(state => state.salesPageBrandOwner.filters)
  const orders = useSelector(state => state.salesPageBrandOwner.ordersList)
  const orderAmounts = useSelector(state => state.salesPageBrandOwner.orderAmounts)
  const totalPages = useSelector(state => state.salesPageBrandOwner.totalPagesOrders)
  const orderListType = filters.listType

  let key = 0
  let key2 = 1

  const dateOptions = {
    timeZone: 'Europe/Brussels',
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric"
  }

  const productIDArray = [... new Set(selectedProducts.map(product => product.productID))]
  const filteredProducts = products.filter(product => !productIDArray.includes(String(product.id)))
  const materialIDArray = [... new Set(selectedProducts.map(material => material.materialID))]
  const filteredMaterials = materials.filter(material => !materialIDArray.includes(String(material.id)))

  const requester = userInfo.type

  const previousFilters = useRef(filters)
  const parentDivRef = useRef(null)

  const fetchUser = async () => {
    const responseUser = await httpClient.get(process.env.REACT_APP_API_URL + "/api/@me?type=sales-info")
    setUser(responseUser.data)
  }

  const fetchCustomers = async () => {
    const responseCustomers = await httpClient.get(process.env.REACT_APP_API_URL + "/api/users/get?requester=" + requester)
    setCustomers(responseCustomers.data)
  }

  const fetchProducts = async () => {
    const responseProducts = await httpClient.get(process.env.REACT_APP_API_URL + "/api/get-my-products?requester=" + requester)

    setProducts(responseProducts.data.filter(product => product.category != "POSM"))
    setMaterials(responseProducts.data.filter(product => product.category == "POSM"))
  }

  const fetchOrders = async () => {
    setLoading(true)

    const data = {
      filters
    }
    const responseOrders = await httpClient.post(process.env.REACT_APP_API_URL + `/api/brandowner/orders/get?requester=${requester}&type=${orderListType}&page=${filters.page}&limit=${limit}`, data)
    dispatch(updateOrderList(responseOrders.data.orders))
    dispatch(updateOrderAmounts(responseOrders.data.amounts))
    dispatch(updateTotalPagesOrders(responseOrders.data.total_pages))

    setLoading(false)
  }

  const fetchOrderEvents = async (orderID) => {
    const responseOrderEvents = await httpClient.get(process.env.REACT_APP_API_URL + "/api/brandowner/order/get-order-events?orderID=" + orderID)
    setOrderEvents(responseOrderEvents.data)
  }

  const fetchWarehouses = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/brandowner/warehouses")
    const data = response.data

    const newWarehousesArray = data.map(warehouse => {
      return (
        {
          'id': warehouse.id,
          'name': warehouse.name
        }
      )
    })
    setWarehouses(newWarehousesArray)
  }

  useEffect(() => {

    fetchUser()
      .catch(err => {
        console.log(err)
        setError(true)
      })

    fetchCustomers()
      .catch(err => {
        console.log(err)
      })

    fetchProducts()
      .catch(err => {
        console.log(err)
      })

    if (!orders.length) {
      fetchOrders()
        .catch(err => {
          console.log(err)
        })
    }

    fetchWarehouses()
      .catch(err => {
        console.log(err)
      })
  }, [])

  useEffect(() => {
    const previousFiltersDict = previousFilters.current

    // Compare the current filters with the previous filters
    if (JSON.stringify(previousFiltersDict) !== JSON.stringify(filters)) {
      if (previousFiltersDict.page == filters.page) {
        // if page did not change, page has to be reset to 1 (because a filter changed)
        dispatch(updateFilters({ ...filters, page: 1 }))
      }

      fetchOrders()
        .catch(err => {
          console.log(err)
        })

      // Update previous filters to current filters
      previousFilters.current = filters
    }
  }, [filters])

  useEffect(() => {
    setUpdatedTrackingID(selectedOrder.tracking_id)
    setSelectedOrderData(orders.filter(order => order.id == selectedOrder.id)[0])
  }, [selectedOrder])

  useEffect(() => {
    if (inputRef1.current) {
      inputRef1.current.focus()
    }
  }, [updatedTrackingID])

  useEffect(() => {
    if (inputRef2.current) {
      inputRef2.current.focus()
    }
  }, [updatedShippingCode])

  const deleteOrder = async () => {
    const data = {
      orderIDs: [selectedOrder.id]
    }

    try {
      const response = await httpClient.post(process.env.REACT_APP_API_URL + `/api/brandowner/order/delete`, data)

      if (response.status == 200) {
        toast.success("Order has been deleted")

        console.log(response.data)
        setPopupWindowSalesDetailIsOpen(false)
        fetchOrders()
      }

      if (response.status == 401) {
        toast.error(`${response.data}`, { theme: 'dark' })
      }

    } catch (error) {
      console.log(error)
    }
  }

  const deleteOrderAll = async () => {
    const orderIDs = []

    selectedRows.forEach(row => {
      const orderID = row.getAttribute('data-orderid')
      orderIDs.push(orderID)
    })

    const dataDeleteAll = {
      orderIDs
    }

    try {
      const response = await httpClient.post(process.env.REACT_APP_API_URL + `/api/brandowner/order/delete`, dataDeleteAll)

      if (response.status == 200) {
        toast.success("Order has been deleted")

        console.log(response.data)
        setPopupWindowSalesDetailIsOpen(false)
        fetchOrders()
      }

    } catch (error) {
      if (error.response.status == 401) {
        toast.error(`${error.response.data}`, { theme: 'dark' })
      }
    }
  }

  const handleOnClickDropdownItem = (event) => {
    setSelectedCustomer(event.currentTarget.firstChild.textContent)
    setSelectedCustomerID(event.currentTarget.getAttribute("id"))
  }

  const handleOnClickExtraProduct = () => {
    const inputs = document.getElementsByClassName("dropdown-input--text_field")
    let ok = true
    Array.from(inputs).forEach(element => {
      if (element.innerHTML == "Select product") {
        element.parentElement.classList.add("red-border")
        ok = false
      }
    })

    ok && setCountArray([...countArray, countArray.length + 1])
  }

  const DeleteProductLine = (event) => {
    const identifier = event.currentTarget.parentElement.parentElement.id
    console.log(identifier)
    setCountArray(currentArray => currentArray.filter(element => element != identifier))
    const index = identifier - 1

    const updatedArray = [...selectedProducts]
    updatedArray.splice(index, 1)
    setSelectedProducts(updatedArray)
  }

  const handleCreateOrder = async () => {
    const saveButton = document.getElementById("sales__btn-save")
    saveButton.disabled = true

    const data = {
      "customer": selectedCustomerID,
      "products": selectedProducts,
    }
    let ok = true

    const productDropdowns = document.querySelectorAll(".product__dropdown__container .input_field__input_box--text")

    Array.from(productDropdowns).forEach(item => {
      if (item.innerHTML == "Select product") {
        item.parentElement.classList.add("red-border")
        ok = false
      }
    })

    const customerField = document.getElementsByClassName("input_field__input_box--text input-dropdown")
    Array.from(customerField).forEach(item => {
      if (item.value == "") {
        item.classList.add("red-border")
        ok = false
      }
    })

    if (!ok) {
      toast.error("Please fill out all fields", { theme: "dark" })
      saveButton.disabled = false
    } else {
      const response = await toast.promise(
        httpClient.post(process.env.REACT_APP_API_URL + "/api/salesforce/order/send", data),
        {
          success: "Order has been registered",
          pending: "Registering order...",
          error: "Something went wrong"
        }
      )
      console.log(response.data)

      // Clean up
      setSelectedProducts([])
      setCountArray([1])
      setComment("")
      setSelectedCustomer("")
      const checkboxes = document.querySelectorAll(".checkbox--box")
      Array.from(checkboxes).forEach(checkbox => {
        checkbox.classList.remove("checked")
      })
      setResetProductDropdowns(true)

      fetchOrders()
    }
    saveButton.disabled = false
  }

  const handleOnClickExpand = () => {
    const newSalesForm = document.getElementById("new-sales-form-brandowner")
    const allOrdersTable = document.getElementById("sales-history-table-brandowner")

    newSalesForm.classList.toggle("hide")
    allOrdersTable.classList.toggle("big")
    setTableBig(!tableBig)
  }

  function findChangedRowIndex(oldData, newData) {
    for (let i = 0; i < oldData.length; i++) {
      if (!isEqual(oldData[i], newData[i])) {
        return oldData[i].id;
      }
    }
    return -1;
  }

  const handleClickProgressStep = async (event) => {
    const parentElement = event.currentTarget.parentElement.parentElement
    const orderID = parentElement.getAttribute("data-orderid")
    setProgressStepOrderID(orderID)
    const progressStage = event.currentTarget.getAttribute("data-progressstage")
    setProgressStepStage(progressStage)
    const thisOrder = orders.find(order => order.id == orderID)

    // if user is pro & supplier is Stoqup, give a warning

    if (
      user.is_distribution_partner &
      user.type != "admin" &
      thisOrder.supplier == "STOQUP" &
      (progressStage == "Ready for delivery" | progressStage == "Delivered") &
      !progressChangeOverwrite
    ) {

      const message = "This order is being handled by STOQUP, delivery status can not be changed"
      dispatch(changeNotificationMessage(message))
      showNotificationPopupMessage()

      return
    }

    parentElement.classList.add("removing")

    setTimeout(() => {
      setLoading(true)
    }, 400);
    actionProgressStep(orderID, progressStage)
  }

  const actionProgressStep = async (orderID, progressStage) => {

    const bodySection = document.querySelector("#sales-history-table-brandowner")
    bodySection.classList.add("overflow-hidden")

    const data = {
      orderIDs: [orderID]
    }

    const response = await httpClient.post(process.env.REACT_APP_API_URL + "/api/brandowner/order/change-status?progressStage=" + progressStage + "&requester=" + userInfo.type, data)
    console.log(response.data)

    const oldData = orders

    await fetchOrders()
    const changedRowIndex = findChangedRowIndex(oldData, orders)

    if (changedRowIndex !== -1) {
      setTimeout(() => {
        const changedRow = document.querySelector(`[data-orderid="${orderID}"]`)
        try {
          changedRow.classList.add("changed-row")
        } catch {
        }
      }, 500)
    }

    bodySection.classList.remove("overflow-hidden")
  }

  const handleHideCompleted = () => {
    setHideCompleted(!hideCompleted)
  }

  const copyTextOnClick = (event) => {
    const text = event.currentTarget.innerHTML
    navigator.clipboard.writeText(text)
  }

  const showOrderDetails = (order) => {
    setPopupWindowSalesDetailIsOpen(true)
    setSelectedOrder(order)
    fetchOrderEvents(order.id)
  }

  const handleOnClickCheckDelivery = async (orderItemID, vendorType) => {

    if (vendorType == "STOQUP") {
      const message = "This order is being handled by STOQUP, delivery status can not be changed"
      dispatch(changeNotificationMessage(message))
      showNotificationPopupMessage()

      return
    }

    const reponse = await httpClient.get(process.env.REACT_APP_API_URL + "/api/brandowner/order/change-status-to-delivered?orderItemID=" + orderItemID + "&requester=" + userInfo.type)
    console.log(reponse.data)

    toast.success("Changes have been saved")

    fetchOrderEvents(selectedOrder.id)
    fetchOrders()
  }

  function PopupElements() {

    return (
      <div className="popup-elements">
        <div className="client">
          <div className="popup_window__sub-title">Client</div>
          <div className="column2">
            <div className="client-info-item">
              <h2>Business Name</h2>
              <p onClick={copyTextOnClick}>{selectedOrder.customer.business_name}</p>
            </div>
            <div className="client-info-item">
              <h2>Delivery Address</h2>
              <p onClick={copyTextOnClick}>{selectedOrder.customer.delivery_address_street} {selectedOrder.customer.delivery_address_nr}</p>
              <div className="double-field-30-70">
                <p onClick={copyTextOnClick}>{selectedOrder.customer.delivery_address_pc}</p>
                <p onClick={copyTextOnClick}>{selectedOrder.customer.delivery_address_place}</p>
              </div>
            </div>
            <div className="client-info-item">
              <h2>Contact Info</h2>
              <p onClick={copyTextOnClick}>{selectedOrder.customer.first_name} {selectedOrder.customer.last_name}</p>
              <p onClick={copyTextOnClick}>{selectedOrder.customer.email}</p>
              <p onClick={copyTextOnClick}>{selectedOrder.customer.tel_number}</p>
            </div>
          </div>

          <div className="column2">
            <div className="client-info-item">
              <h2>Company Name</h2>
              <p onClick={copyTextOnClick}>{selectedOrder.customer.company_name}</p>
            </div>
            <div className="client-info-item">
              <h2>Invoicing Address</h2>
              <p onClick={copyTextOnClick}>{selectedOrder.customer.invoice_address_street} {selectedOrder.customer.invoice_address_nr}</p>
              <div className="double-field-30-70">
                <p onClick={copyTextOnClick}>{selectedOrder.customer.invoice_address_pc}</p>
                <p onClick={copyTextOnClick}>{selectedOrder.customer.invoice_address_place}</p>
              </div>
            </div>
            <div className="client-info-item">
              <h2>Invoice Info</h2>
              <p onClick={copyTextOnClick}>{selectedOrder.customer.VAT_number}</p>
              <p onClick={copyTextOnClick}>{selectedOrder.customer.invoice_email}</p>
            </div>
          </div>
        </div>
        {
          userInfo.type == "admin" &&
          <div className="tracking">
            <div className="popup_window__sub-title">Tracking</div>
            <div className="client-tracking-info">
              <input
                ref={inputRef1}
                className={`input_field__input_box--text--inrow ${selectedOrder.tracking_id ? "disabled" : ""}"`}
                id={`tracking_code_${selectedOrder.id}`}
                value={updatedTrackingID}
                onChange={(e) => setUpdatedTrackingID(e.target.value)}
                placeholder={"Add tracking code"}
              />
              <Dropdown
                placeholder={selectedOrder.shipping_company_code != "" ? selectedOrder.shipping_company_code : "Add courier"}
                state={updatedShippingCode}
                setState={setUpdatedShippingCode}
                listArray={couriers}
                height={"25px"}
                marginTop={"0px"}
                fontStyle={"normal"}
              />

              <button className="secondairy-button" onClick={() => updateTrackingCode(selectedOrder.id)}>Save</button>
            </div>
          </div>
        }

        {
          (selectedOrderData && selectedOrderData.promos) ?

            <div className="promos">
              <div className="popup_window__sub-title">Promos</div>
              {
                selectedOrderData.promos.map((promo, index) => {
                  return (
                    <div className="promo_item" key={index}>{promo.promo_id.description} </div>
                  )
                })
              }
            </div> : null
        }

        <div className="order">
          <div className="popup_window__sub-title">Order</div>
          <div className="table__secondairy-header-6">
            <div className="table__secondairy-header--field">Amount</div>
            <div className="table__secondairy-header--field">SKU</div>
            <div className="table__secondairy-header--field">Product</div>
            <div className="table__secondairy-header--field">Volume</div>
            <div className="table__secondairy-header--field">Supplier</div>
            <div className="table__secondairy-header--field">Delivered</div>
          </div>
          {
            selectedOrderData != null &&
            selectedOrderData.products.length != 0 &&
            selectedOrderData.products.map(product => {
              return (
                <div key={key++}>
                  <div className="sales__orders--product-rows" key={key++}>
                    <div className="table__secondairy-row--field" onClick={copyTextOnClick}>{product.quantity}</div>
                    <div className="table__secondairy-row--field" onClick={copyTextOnClick}>{product.sku}</div>
                    <div className="table__secondairy-row--field" onClick={copyTextOnClick}>{product.product}</div>
                    <div className="table__secondairy-row--field" onClick={copyTextOnClick}>{product.volume}</div>
                    <div className="table__secondairy-row--field" onClick={copyTextOnClick}>{product.supplier}</div>
                    <div className="table__secondairy-row--field" onClick={copyTextOnClick}><div onClick={() => handleOnClickCheckDelivery(product.order_item_id, product.vendor_id?.type)} ><CheckBoxStatic labelDisabled={true} state={product.delivered} /></div></div>
                  </div>
                </div>
              )
            })
          }
        </div>

        <div className="history no-scrollbar">
          <div className="popup_window__sub-title">History</div>
          <div className="timeline__container">

            {
              orderEvents.map(event => {
                return (
                  <div className="timeline__event" key={key2++}>
                    <div className="timeline__event--date">{event.timestamp}</div>
                    <div className={
                      event.event_label == "CREATED" ?
                        "timeline__event--label blue" :
                        event.event_label == "DELIVERED" ?
                          "timeline__event--label green" :
                          event.event_label == "CANCELLATION" ?
                            "timeline__event--label red" :
                            "timeline__event--label grey"
                    }>
                      <div className="ball"></div>
                      <h4>{event.event_label}</h4>
                    </div>
                    <div className="timeline__event--comment">{event.event_description}</div>
                  </div>
                )
              })
            }

          </div>
        </div>
      </div>
    )
  }

  const updateTrackingCode = async (orderID) => {
    let trackingID = updatedTrackingID
    let shippingCode = updatedShippingCode

    if (updatedTrackingID == undefined | updatedTrackingID == null) {
      trackingID = selectedOrder.tracking_id
    } else if (updatedShippingCode == undefined | updatedShippingCode == null) {
      shippingCode = selectedOrder.shipping_company_code
    }
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/brandowner/order/change-tracking-code?orderID=" + orderID + "&trackingID=" + trackingID + "&shippingCode=" + shippingCode)

    if (response.data['error']) {
      const message = response.data['error']
      dispatch(changeNotificationMessage(message))
      showNotificationPopupMessage()
    } else if (response.data['success']) {
      const message = "We have checked the order and it has already been delivered."
      const errorType = "green"
      dispatch(changeNotificationMessage(message))
      showNotification(errorType)
    } else {
      const message = "Tracking information successfully added"
      const errorType = "green"
      dispatch(changeNotificationMessage(message))
      showNotification(errorType)
    }

    fetchOrders()
  }

  const handleExportAllSales = async () => {

    setLoadingExport(true)
    const data = {
      filters: {
        search_word: searchWordFilter,
        statusFilter: filters.statusFilter
      },
    }
    const responseOrders = await httpClient.post(process.env.REACT_APP_API_URL + `/api/brandowner/orders/get?requester=${requester}&type=${orderListType}&limit=${limit}`, data)

    const now = Date.now()
    const fileName = `STOQUP_export_sales_all_${formatDateExportTitle(now)}`
    const exportData = []

    responseOrders.data.forEach(order => {
      const orderRow = {
        order_id: order.id,
        date: order.date,
        sales_by: order.sales_by,
        type: order.type,
        customer_business_name: order.customer.business_name,
        customer_contact_email: order.customer.contact_email,
        customer_contact_name: order.customer.contact_name,
        customer_contact_tel: order.customer.contact_tel,
        customer_delivery_address_street: order.customer.delivery_address_street,
        customer_delivery_address_nr: order.customer.delivery_address_nr,
        customer_delivery_address_pc: order.customer.delivery_address_pc,
        customer_delivery_address_place: order.customer.delivery_address_place,
        supplier: order.supplier,
        status: order.status,
      }

      order.products.forEach(product => {
        orderRow[`${product.product} ${product.volume}`] = product.quantity
      })

      order.promos.forEach((promo, index) => {
        orderRow[`promo ${index + 1}`] = promo.promo_id.description
      })

      exportData.push(orderRow)
    })

    exportToExcel(exportData, fileName)
    setLoadingExport(false)
  }

  const handleOnMouseOver = (event) => {
    const headerItem = event.currentTarget
    const headerItemNumber = event.currentTarget.children[0]

    headerItem.classList.add("hovered")
    headerItemNumber.classList.add("hovered")
  }

  const handleOnMouseOut = (event) => {
    const headerItem = event.currentTarget
    const headerItemNumber = event.currentTarget.children[0]

    headerItem.classList.remove("hovered")
    headerItemNumber.classList.remove("hovered")
  }

  const handleOnCLickHeaderItem = (event) => {
    const headerItem = event.currentTarget
    const headerItemNumber = event.currentTarget.children[0]
    const allHeaderItems = document.getElementsByClassName("body__section__header_item")
    const allHeaderItemNumbers = document.getElementsByClassName("header_item--number")

    setLoading(true)

    allHeaderItemNumbers.forEach(item => {
      item.classList.remove("selected")
    })
    allHeaderItems.forEach(item => {
      item.classList.remove("selected")
    })

    headerItem.classList.add("selected")
    headerItemNumber.classList.add("selected")

    const newFiltersState = { ...filters }

    switch (event.currentTarget.getAttribute("data-id")) {
      case "header-new":
        newFiltersState.listType = "new"
        break
      case "header-stoqup-orders":
        newFiltersState.listType = "stoqup"
        break
      case "header-my-orders":
        newFiltersState.listType = "toDeliver"
        break
      case "header-to-invoice":
        newFiltersState.listType = "toInvoice"
        break
      case "header-completed":
        newFiltersState.listType = "completed"
        break
      case "header-all":
        newFiltersState.listType = "all"
        break
    }

    dispatch(updateFilters(newFiltersState))
  }

  const handleDeleteOrder = (type = 'normal') => {

    const actionButtonOnClick = type === 'all' ? deleteOrderAll : deleteOrder

    showNotificationPopup({
      title: 'Are you sure?',
      message: "Deleting an order can not be undone",
      actionButtonOnClick: () => actionButtonOnClick(),
      actionButtonText: 'Yes, delete',
      popupButtonColor: 'red',
    })
  }

  const handleOnclickActionButtonNew = async (item, event) => {
    let type = ''

    type = event.currentTarget.getAttribute("data-type")
    if (!type) {
      type = 'acceptWarehouse'
    }

    const parentElement = event.currentTarget.closest("#row-field--accept-actions")
    const orderID = parentElement.getAttribute("data-order-id")
    const selectedOrder = orders.find(order => order.id == orderID)
    let warehouseID = null

    setSelectedOrder(selectedOrder)
    setSelectedCustomerID(selectedOrder.customer.id)

    if (type == 'decline') {
      handleDeleteOrder()
      return
    }

    if (type == 'acceptWarehouse') {
      warehouseID = item

      if (
        !selectedOrder.customer.delivery_address_nr |
        !selectedOrder.customer.delivery_address_street |
        !selectedOrder.customer.delivery_address_pc |
        !selectedOrder.customer.delivery_address_place
      ) {
        showNotificationPopup({
          title: "Customer's address is incomplete",
          message: "We are missing the delivery address details for this customer. Add them first in the customer fiche.",
          actionButtonOnClick: () => navigate(`/customer/${selectedOrder.customer.user_id.id}`),
          actionButtonText: 'Go to customer',
          popupButtonColor: 'green',
        })
        return
      }
    }

    const data = {
      orderIDs: [orderID]
    }

    try {
      const response = await httpClient.post(process.env.REACT_APP_API_URL + `/api/brandowner/order/accept?type=${type}&warehouseID=${warehouseID}`, data)
      console.log(response.data)
    } catch (error) {
      if (error.response && error.response.status == 500) {
        toast.error(error.response.data, { theme: "dark" })
      }
    }

    fetchOrders()
  }

  const handleOnClickItem = (event, data) => {

    const updatedFilters = { ...filters }

    switch (event.target.parentElement.id) {
      case "sales_brandowners__dropdown--status":
        updatedFilters.statusFilter = data
        break

      case "sales_brandowners__dropdown--supplier":
        updatedFilters.warehouseFilter = data
        break
    }

    updatedFilters.page = 1
    dispatch(updateFilters(updatedFilters))
  }

  const onClickSetStatusDropdownButton = async (value) => {
    setLoading(true)

    const orderIDs = []

    selectedRows.forEach(row => {
      const orderID = row.getAttribute('data-orderid')
      orderIDs.push(orderID)
    })

    const data = {
      orderIDs
    }

    const response = await httpClient.post(process.env.REACT_APP_API_URL + "/api/brandowner/order/change-status?progressStage=" + value + "&requester=" + userInfo.type, data)
    console.log(response.data)

    setSelectedRows([])
    fetchOrders()
  }

  const handleOnclickActionButtonAll = async (warehouseID = null, type = 'warehouseAccept') => {
    setLoading(true)

    const orderIDs = []

    selectedRows.forEach(row => {
      const orderID = row.getAttribute('data-orderid')
      orderIDs.push(orderID)
    })

    const data = {
      orderIDs
    }

    if (type == 'decline') {
      handleDeleteOrder('all')
      return
    }

    try {
      const response = await httpClient.post(process.env.REACT_APP_API_URL + `/api/brandowner/order/accept?type=${type}&warehouseID=${warehouseID}`, data)
      console.log(response.data)
    } catch (error) {
      if (error.response && error.response.status == 500) {
        toast.error(error.response.data, { theme: "dark" })
      }
    }

    fetchOrders()
  }

  return (
    <div className="template">
      {/* {error ? window.location.href = "/" : ""} */}

      <button className="floating__button--bottom-right" id="add--btn" onClick={handleOnClickExpand} />

      <Header>
        {
          !popupWindowSalesDetailIsOpen & !newCustomerPopupIsActive ?
            <button className="options-button-header" onClick={handleExportAllSales}>Export <ExitToAppIcon />{loadingExport && <LoadingSpinnerOverlay />}</button>
            : null
        }

        <div className={`header__options--right ${newCustomerPopupIsActive ? "hide" : ""}`}>
          {
            selectedRows.length > 0 & !popupWindowSalesDetailIsOpen ?
              <>
                {
                  orderListType != "new" ?
                    <>
                      <DropdownButton
                        id="action-status"
                        title="Change status"
                        valuesArray={["Ready for delivery", "Delivered", "Invoiced"]}
                        onClickFn={onClickSetStatusDropdownButton}
                        disabled={loading}
                      />
                    </> :
                    <>
                      <button className="green-action-button" data-type="accept" onClick={() => handleOnclickActionButtonAll(null, 'accept')}>Accept all</button>
                      <DropdownButton
                        id='dropdown-btn-order-warehouse'
                        title={'accept & send to all'}
                        style='green'
                        valuesArray={warehouses}
                        value={selectedWarehouse}
                        setValue={setSelectedWarehouse}
                        onClickFn={handleOnclickActionButtonAll}
                      />
                      <button className="red-action-button" data-type="decline" onClick={() => handleOnclickActionButtonAll(null, 'decline')}>Decline all</button>
                    </>

                }
              </> : null
          }
          {(tableBig & !newCustomerPopupIsActive & !popupWindowSalesDetailIsOpen) ?
            <button className={`green-button-header`} id="sales__btn-save" onClick={handleCreateOrder}>SAVE</button>
            : null
          }
          {
            popupWindowSalesDetailIsOpen ?
              <button className={`red-button-header`} onClick={handleDeleteOrder}>DELETE ORDER</button>
              : null
          }
          {
            selectedRows.length == 0 & !tableBig ?
              <>
                {
                  !popupWindowSalesDetailIsOpen &
                    !tableBig ?
                    <div className={`header__options--right-template`} id="header__options--activities">
                      <SearchHeader
                        stateType="redux"
                        filter="searchWord"
                        reduxState={filters}
                        searchWord={filters.searchWord}
                        setSearchWord={updateFilters}
                      />
                      <DropDownFilter
                        idList="sales_brandowners__dropdown--status"
                        placeholderText="Filter on status"
                        listArray={["Ready for delivery", "Not ready for delivery", "Delivered", "Not delivered", "Invoiced", "Not invoiced"]}
                        onClickItem={handleOnClickItem}
                        value={filters.statusFilter}
                        filter={"statusFilter"}
                        setValue={updateFilters}
                        dropdownType="salesPageBrandowner"
                      />
                      <DropDownFilter
                        idList="sales_brandowners__dropdown--supplier"
                        placeholderText="Filter on warehouse"
                        listArray={warehouses}
                        onClickItem={handleOnClickItem}
                        value={filters.warehouseFilter}
                        filter={"warehouseFilter"}
                        setValue={updateFilters}
                        dropdownType="salesPageBrandowner"
                      />
                    </div> : null
                }

              </> : null
          }
        </div>

      </Header>
      <MenuBar />

      <NotificationTop />
      <NotificationPopupMessage />

      <div className="template__body">

        <div className="body__sales--brandowner">

          <PopupWindow
            popupID="sales_brandowner--client-info--popup"
            isActive={popupWindowSalesDetailIsOpen}
            setIsActive={setPopupWindowSalesDetailIsOpen}
          >
            {selectedOrder != undefined && selectedOrder.length != 0 && <PopupElements />}
          </PopupWindow>
          <PopupWindowCreateNewCustomer
            customers={customers}
            isActive={newCustomerPopupIsActive}
            setIsActive={setNewCustomerPopupIsActive}
            fetchCustomers={fetchCustomers}
            setSelectedCustomer={setSelectedCustomer}
            setSelectedCustomerID={setSelectedCustomerID}
          />

          <div className="body__section hide no-scrollbar" id="new-sales-form-brandowner">
            <div className="form__section" ref={parentDivRef}>
              <h1>New order</h1>
              <div className="new-sales-form">
                <DropDownInputCustomer
                  idList={"sales__dropdownList--customer"}
                  placeholderText="Select the customer"
                  listArray={customers}
                  onClickItem={handleOnClickDropdownItem}
                  value={selectedCustomer}
                  setValue={setSelectedCustomer}
                  createNewCustomer={setNewCustomerPopupIsActive}
                />
                <div className="salespage--titles">
                  <div className="salespage--title">Product</div>
                  <div className="salespage--title">Amount</div>
                  <div className="checkboxes">
                    <div>Already delivered</div>
                    <div>On Consignation</div>
                  </div>
                  <div className="salespage--title">Supplier</div>
                  <div className="salespage--title">Comment</div>
                </div>
                {
                  countArray.map((count, index) => {
                    return <AddProductForSales
                      onClickDelete={DeleteProductLine}
                      key={index}
                      index={index}
                      identifier={count}
                      setValue={setSelectedProducts}
                      value={selectedProducts}
                      listArray={filteredProducts}
                      listArray2={filteredMaterials}
                      customerID={selectedCustomerID}
                      parentDivRef={parentDivRef}
                      resetState={resetProductDropdowns}
                      warehouses={warehouses}
                    />
                  })
                }
                <button className="more-products--btn" onClick={handleOnClickExtraProduct}>+</button>
              </div>

            </div>
          </div>

          <div className="body__section__header">
            <div className={`body__section__header_item ${orderListType == "new" ? "selected" : ""}`} data-id="header-new" onClick={handleOnCLickHeaderItem} onMouseOver={handleOnMouseOver} onMouseOut={handleOnMouseOut}>New Orders <div className="header_item--number selected">{orderAmounts.new}</div></div>
            <div className={`body__section__header_item ${orderListType == "toDeliver" ? "selected" : ""}`} data-id="header-my-orders" onClick={handleOnCLickHeaderItem} onMouseOver={handleOnMouseOver} onMouseOut={handleOnMouseOut}>To be delivered <div className="header_item--number">{orderAmounts.toDeliver}</div></div>
            <div className={`body__section__header_item ${orderListType == "toInvoice" ? "selected" : ""}`} data-id="header-to-invoice" onClick={handleOnCLickHeaderItem} onMouseOver={handleOnMouseOver} onMouseOut={handleOnMouseOut}>To be invoiced <div className="header_item--number">{orderAmounts.toInvoice}</div></div>
            <div className={`body__section__header_item ${orderListType == "completed" ? "selected" : ""}`} data-id="header-completed" onClick={handleOnCLickHeaderItem} onMouseOver={handleOnMouseOver} onMouseOut={handleOnMouseOut}>Completed <div className="header_item--number">{orderAmounts.completed}</div></div>
            <div className={`body__section__header_item ${orderListType == "all" ? "selected" : ""}`} data-id="header-all" onClick={handleOnCLickHeaderItem} onMouseOver={handleOnMouseOver} onMouseOut={handleOnMouseOut}>All <div className="header_item--number">{orderAmounts.all}</div></div>
          </div>
          <div className="body__section big overflow-hidden" id="sales-history-table-brandowner">
            {
              loading ? <LoadingSpinner /> :
                <div className={`table ${tableBig ? "small" : ""}`}>
                  <div className={`table__header-11 ${orderListType}`} id="table__header-sales-brandowners">
                    <TableCheckmarkHeader
                      id={`table-checkmark-header-sales--brandowner`}
                      selectedRows={selectedRows}
                      setSelectedRows={setSelectedRows}
                    />
                    <div className="table__header--field">ID</div>
                    <div className="table__header--field">Date</div>
                    <div className="table__header--field">Customer</div>
                    <div className="table__header--field">Sales by</div>
                    {
                      orderListType != "new" && (
                        <>
                          <div className="table__header--field">Ready for delivery</div>
                          <div className="table__header--field">Delivered</div>
                          {
                            orderListType != "stoqup" && orderListType != "brandowner" && (
                              <div className="table__header--field">{userInfo.type == "admin" ? "Tracking status" : "Invoiced"}</div>
                            )
                          }
                        </>
                      )
                    }
                    <div className="table__header--field">Supplier</div>
                    {
                      orderListType != "new" ?
                        <div className="table__header--field">Warehouse</div> : <div className="table__header--field"></div>
                    }
                    <div className="table__header--field">Items</div>

                  </div>
                  <div className="table__rows_container sales">
                    {loadingProgressStep && <LoadingOverlay />}
                    {
                      orders
                        .map((order, index) => {

                          const allSame = (items) => {
                            return items.every(item => item === items[0]);
                          }

                          const products = order.products

                          // Extracting vendor IDs and names, assuming vendor_id might be null
                          const vendorIds = products.map(product => product.vendor_id ? product.vendor_id.id : null)

                          let supplier = null

                          if (vendorIds.every(id => id === null)) {
                            supplier = null
                          } else if (allSame(vendorIds)) {
                            supplier = products[0].vendor_id.company_name
                          } else {
                            supplier = "Various suppliers"
                          }

                          // Extracting warehouse IDs, assuming that warehouse_id can also be null
                          const warehouseIds = products.map(product => product.warehouse_id ? product.warehouse_id.id : null)

                          let warehouse = null

                          if (warehouseIds.every(id => id === null)) {
                            warehouse = null
                          } else if (allSame(warehouseIds)) {
                            warehouse = products[0].warehouse_id.name
                          } else {
                            warehouse = "Various warehouses"
                          }

                          return (
                            <span className={`table__row-11 ${orderListType} ${!order.internal_order ? "not-allowed" : ""}`} id='checkmark-row' data-key={index} data-orderid={order.id} key={index}>
                              <TableCheckmarkRow
                                index={index}
                                id={`table-checkmark-row-sales--brandowner-${index}`}
                                selectedRows={selectedRows}
                                setSelectedRows={setSelectedRows}
                              />
                              <div className="table__row--field" onClick={() => showOrderDetails(order)}>{order.id}</div>
                              <div className="table__row--field" onClick={() => showOrderDetails(order)}>{new Date(order.date).toLocaleDateString()}</div>
                              <div className="table__row--field" onClick={() => showOrderDetails(order)}>{order.type == "B2C" ? `${order.customer.first_name} ${order.customer.last_name}` : order.customer.business_name}</div>
                              <div className="table__row--field" onClick={() => showOrderDetails(order)}>{order.sales_by}</div>
                              {
                                orderListType != "new" && (
                                  <>
                                    <div className="table__row--field--uncenter">
                                      {
                                        order.internal_order ?
                                          <div className={order.products.every(product => product.readyForDelivery == true) ? "progress__tracker--first" : "progress__tracker--first unfinished"} onClick={handleClickProgressStep} data-progressstage={"Ready for delivery"}>
                                            <div className="progress__tracker_circle">{<DoneIcon className={!order.products.every(product => product.readyForDelivery == true) ? "faded-away" : "visible"} />}</div>
                                          </div> :
                                          <div className={"progress__tracker--first disabled"} disabled>
                                            <div className="progress__tracker_circle"><RxCross1 className="disabled_icon" /></div>
                                          </div>
                                      }
                                    </div>
                                    <div className="table__row--field--uncenter">
                                      {
                                        order.internal_order ?
                                          <div className={order.products.every(product => product.delivered == true) ? `progress__tracker${userInfo.type == "admin" | orderListType == "stoqup" | orderListType == "brandowner" ? '--last' : ''}` : `progress__tracker${userInfo.type == "admin" | orderListType == "stoqup" | orderListType == "brandowner" ? '--last' : ''} unfinished`} onClick={handleClickProgressStep} data-progressstage={"Delivered"}>
                                            <div className="progress__tracker_circle">{<DoneIcon className={!order.products.every(product => product.delivered == true) ? "faded-away" : "visible"} />}</div>
                                          </div> :
                                          <div className={`progress__tracker${userInfo.type == "admin" | orderListType == "stoqup" | orderListType == "brandowner" ? '--last' : ''} disabled`} disabled>
                                            <div className="progress__tracker_circle"><RxCross1 className="disabled_icon" /></div>
                                          </div>
                                      }

                                    </div>
                                    {
                                      orderListType != "stoqup" && orderListType != "brandowner" && (
                                        <>
                                          <div className="table__row--field--uncenter">
                                            {
                                              order.internal_order ?
                                                <div className={order.products.every(product => product.invoiced == true) ? "progress__tracker--last" : "progress__tracker--last unfinished"} onClick={handleClickProgressStep} data-progressstage={"Invoiced"}>
                                                  <div className="progress__tracker_circle">
                                                    <DoneIcon className={order.products.every(product => product.invoiced == true) ? "visible" : "faded-away"} />
                                                  </div>
                                                </div> :
                                                <div className={"progress__tracker--last disabled"} disabled>
                                                  <div className="progress__tracker_circle"><RxCross1 className="disabled_icon" /></div>
                                                </div>

                                            }
                                          </div>
                                        </>
                                      )
                                    }
                                  </>
                                )
                              }
                              <div className="table__row--field" onClick={() => showOrderDetails(order)}>{supplier}</div>
                              {
                                orderListType != "new" ?
                                  <div className="table__row--field" onClick={() => showOrderDetails(order)}>{warehouse}</div> : <div className="table__row--field"></div>
                              }
                              <div className="table__row--field" onClick={() => showOrderDetails(order)}>{order.products.reduce((acc, product) => acc + product.quantity, 0)}</div>
                              {
                                orderListType == "new" && (
                                  <div className="table__row--field flex-align-right" data-order-id={order.id} id="row-field--accept-actions">
                                    <button className="green-action-button" data-type="accept" onClick={(e) => handleOnclickActionButtonNew(null, e)}>Accept</button>
                                    <DropdownButton
                                      id={`dropdown-btn-order-warehouse`}
                                      ref={acceptButtonDropdownRef}
                                      title={'accept & send to'}
                                      style='green'
                                      valuesArray={warehouses}
                                      value={selectedWarehouse}
                                      setValue={setSelectedWarehouse}
                                      onClickFn={handleOnclickActionButtonNew}
                                      index={index}
                                    />
                                    <button className="red-action-button" data-type="decline" onClick={(e) => handleOnclickActionButtonNew(null, e)}>Decline</button>
                                  </div>
                                )
                              }
                            </span>
                          )
                        })
                    }
                  </div>
                </div>
            }
            <Pagination
              id="pagination-sales-brandowner-page-list"
              stateType="redux"
              filters={filters}
              page={filters.page}
              setPage={updateFilters}
              totalPages={totalPages}
            />
          </div>
        </div>
      </div>
    </div >
  )
}

export default SalesPageBrandOwner