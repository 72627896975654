import React, { useState, useEffect, useRef } from "react"
import httpClient from "../httpClient"
import { useSelector, useDispatch } from "react-redux"

import DropDownInputCustomer from "../components/DropDownInputCustomer"
import { changeNotificationMessage } from "../store/notification"
import { NotificationTop, showNotification } from "../components/NotificationTop"
import "./SalesPage.css"
import Header from "../components/Header"
import MenuBar from "../components/MenuBar"
import AddProductForSales from "../components/AddProductForSales"
import AddProductForSalesUpdate from "../components/AddProductForSalesUpdate"
import CheckBox from "../components/CheckBox"
import LoadingSpinner from "../components/placeholders/LoadingSpinner"
import UnfoldMoreOutlinedIcon from '@mui/icons-material/UnfoldMoreOutlined'
import UnfoldLessOutlinedIcon from '@mui/icons-material/UnfoldLessOutlined'
import EditIcon from '@mui/icons-material/Edit'
import { updateFilters, updateOrderList, updateTotalPagesOrders } from "../store/salesPageBrandOwner"
import { NotificationPopupMessage, showNotificationPopupMessage } from "../components/NotificationPopupMessage"
import { closePopupWindow, PopupWindow } from "../components/PopupWindow"
import PopupWindowCreateNewCustomer from "../components/PopupWindowCreateNewCustomer"
import { toast } from "react-toastify"
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import isEqual from 'lodash/isEqual'
import DoneIcon from '@mui/icons-material/Done'
import { RxCross1 } from "react-icons/rx"
import DropDownFilter from "../components/DropDownFilter"
import { NotificationPopup, showNotificationPopup, closePopupNotification } from "../components/NotificationPopup"
import NotificationPopupSelectWarehouse from "../components/NotificationPopupSelectWarehouse"
import Pagination from "../components/Pagination"

function SalesPage() {
  const [user, setUser] = useState({ email: "", id: "" })
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selectedCustomer, setSelectedCustomer] = useState("")
  const [selectedCustomerID, setSelectedCustomerID] = useState("")
  const [selectedProducts, setSelectedProducts] = useState([])
  const [countArray, setCountArray] = useState([1])
  const [customers, setCustomers] = useState([])
  const [products, setProducts] = useState([])
  const [materials, setMaterials] = useState([])
  // const [orders, setOrders] = useState([])
  const [tableBig, setTableBig] = useState(false)
  const [alreadyDelivered, setAlreadyDelivered] = useState(false)
  const [onConsignation, setOnConsignation] = useState(false)
  const [comment, setComment] = useState("")
  const [updateOrder, setUpdateOrder] = useState(false)
  const [selectedOrderDetail, setSelectedOrderDetail] = useState()
  const [newCustomerPopupIsActive, setNewCustomerPopupIsActive] = useState(false)
  // const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(20)
  const [resetChildState, setResetChildState] = useState(false)
  const [progressStepStage, setProgressStepStage] = useState("")
  const [progressStepOrderID, setProgressStepOrderID] = useState("")
  const [popupWindowSalesDetailIsOpen, setPopupWindowSalesDetailIsOpen] = useState(false)
  const [warehouses, setWarehouses] = useState([])
  const [selectedWarehouseForDelivery, setSelectedWarehouseForDelivery] = useState(null)
  // const [filters, setFilters] = useState({
  //   "warehouseFilter": "",
  //   "statusFilter": ""
  // })
  const [notificationSelectWarehouseIsActive, setNotificationSelectWarehouseIsActive] = useState(false)

  const dispatch = useDispatch()
  const userInfo = useSelector(state => state.user.userInfo)
  const filters = useSelector(state => state.salesPageBrandOwner.filters)
  const totalPages = useSelector(state => state.salesPageBrandOwner.totalPagesOrders)
  const orders = useSelector(state => state.salesPageBrandOwner.ordersList)

  const page = filters.page
  const productIDArray = [... new Set(selectedProducts.map(product => product.productID))]
  const filteredProducts = products.filter(product => !productIDArray.includes(String(product.id)))
  const materialIDArray = [... new Set(selectedProducts.map(material => material.materialID))]
  const filteredMaterials = materials.filter(material => !materialIDArray.includes(String(material.id)))

  const requester = userInfo.type

  const parentDivRef = useRef(null)
  const previousFilters = useRef(filters)

  const fetchUser = async () => {
    const responseUser = await httpClient.get(process.env.REACT_APP_API_URL + "/api/@me?type=full")
    setUser(responseUser.data)
  }

  const fetchCustomers = async () => {
    const responseCustomers = await httpClient.get(process.env.REACT_APP_API_URL + "/api/users/get?requester=" + requester)
    setCustomers(responseCustomers.data)
  }

  const fetchProducts = async () => {
    const responseProducts = await httpClient.get(process.env.REACT_APP_API_URL + "/api/get-my-products?requester=" + requester)

    setProducts(responseProducts.data.filter(product => product.category != "POSM"))
    setMaterials(responseProducts.data.filter(product => product.category == "POSM"))
  }

  const fetchOrders = async () => {
    setLoading(true)
    const responseOrders = await httpClient.post(process.env.REACT_APP_API_URL + `/api/salesforce/orders/get?page=${page}&limit=${limit}`, filters)
    console.log(responseOrders.data)

    dispatch(updateOrderList(responseOrders.data.orders))
    dispatch(updateTotalPagesOrders(responseOrders.data.total_pages))
    setLoading(false)
  }

  const fetchWarehouses = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/brandowner/warehouses")
    const data = response.data

    const newWarehousesArray = data.map(warehouse => {
      return (
        {
          'id': warehouse.id,
          'name': warehouse.name
        }
      )
    })
    setWarehouses(newWarehousesArray)

    // if there is only 1 warehouse option, already set the selected warehouse for when delivery status gets changed
    if (newWarehousesArray.length == 1) {
      setSelectedWarehouseForDelivery(newWarehousesArray[0].id)
    }
  }

  useEffect(() => {
    fetchUser()
      .catch(err => {
        console.log(err)
        setError(true)
      })

    fetchCustomers()
      .catch(err => {
        console.log(err)
      })

    fetchProducts()
      .catch(err => {
        console.log(err)
      })

    fetchWarehouses()
      .catch(err => {
        console.log(err)
      })


    if (orders.length == 0) {
      fetchOrders()
        .catch(err => {
          console.log(err)
        })
    }
  }, [])

  const handleOnClickDropdownItem = (event) => {
    setSelectedCustomer(event.currentTarget.firstChild.textContent)
    setSelectedCustomerID(event.currentTarget.getAttribute("id"))
  }

  useEffect(() => {
    document.getElementById("sales__dropdownList--customer").classList.remove("active")
  }, [selectedCustomerID])

  useEffect(() => {
    const previousFiltersDict = previousFilters.current

    // Compare the current filters with the previous filters
    if (JSON.stringify(previousFiltersDict) !== JSON.stringify(filters)) {
      if (previousFiltersDict.page == filters.page) {
        // if page did not change, page has to be reset to 1 (because a filter changed)
        dispatch(updateFilters({ ...filters, page: 1 }))
      }

      fetchOrders()
        .catch(err => {
          console.log(err)
        })

      // Update previous filters to current filters
      previousFilters.current = filters
    }
  }, [filters])

  const deleteOrder = async () => {

    closePopupNotification('deleteOrderSalesforce')

    const data = {
      orderIDs: [selectedOrderDetail.id]
    }

    try {
      const response = await httpClient.post(process.env.REACT_APP_API_URL + `/api/brandowner/order/delete`, data)

      if (response.status == 200) {
        toast.success("Order has been deleted")

        console.log(response.data)
        setPopupWindowSalesDetailIsOpen(false)
        fetchOrders()
      }

    } catch (error) {
      if (error.response.status == 401) {
        toast.error(`${error.response.data}`, { theme: 'dark' })
      }
    }
  }

  const handleOnClickExtraProduct = () => {
    const inputs = document.getElementsByClassName("dropdown-input--text_field")
    let ok = true
    Array.from(inputs).forEach(element => {
      if (element.innerHTML == "Select product") {
        element.parentElement.classList.add("red-border")
        ok = false
      }
    })

    ok && setCountArray([...countArray, countArray.length + 1])
  }

  const DeleteProductLine = (event) => {
    const identifier = event.currentTarget.parentElement.parentElement.id
    setCountArray(currentArray => currentArray.filter(element => element != identifier))
    const index = identifier - 1

    const updatedArray = [...selectedProducts]
    updatedArray.splice(index, 1)
    setSelectedProducts(updatedArray)
  }

  const handleCreateOrder = async () => {
    document.getElementById("sales__btn-save").disabled = true

    const data = {
      "customer": selectedCustomerID,
      "products": selectedProducts,
      "alreadyDelivered": alreadyDelivered,
      "onConsignation": onConsignation,
      "comment": comment
    }
    let ok = true

    const productDropdowns = document.getElementsByClassName("dropdown-input--text_field")
    Array.from(productDropdowns).forEach(item => {
      if (item.innerHTML == "Select product" | item.innerHTML == "") {
        item.parentElement.classList.add("red-border")
        ok = false
      }
    })

    const customerField = document.getElementsByClassName("input_field__input_box--text input-dropdown")
    Array.from(customerField).forEach(item => {
      if (item.value == "") {
        item.classList.add("red-border")
        ok = false
      }
    })

    if (!ok) {
      toast.error("Please fill out all fields", { theme: "dark" })
      document.getElementById("sales__btn-save").disabled = false
    } else {
      const response = await toast.promise(
        httpClient.post(process.env.REACT_APP_API_URL + "/api/salesforce/order/send", data),
        {
          success: "Order succesfully recorded. In 1 hour your order will be send to the suppliers.",
          pending: "Sending order to suppliers...",
          error: "Something went wrong"
        }
      )
      console.log(response.data)

      setResetChildState(!resetChildState)
      setSelectedCustomer("")
      setCountArray([1])

      const dropdowns = document.getElementsByClassName("dropdown-input--text_field")
      dropdowns.forEach(dropdown => {
        dropdown.innerHTML = ""
      })

      const checkBoxes = document.getElementsByClassName('checkbox--box')
      checkBoxes.forEach(checkbox => checkbox.classList.remove("checked"))
      Array.from(productDropdowns)[0].innerHTML = "Select product"
      fetchOrders()
    }

    document.getElementById("sales__btn-save").disabled = false
  }

  const handleOnClickTableRow = (event) => {
    setUpdateOrder(true)
    setPopupWindowSalesDetailIsOpen(true)

    const orderID = event.currentTarget.parentElement.getAttribute("data-orderid")
    const order = orders.find(order => order.id == orderID)
    setSelectedOrderDetail(order)
  }

  const handleOnClickExpand = () => {
    const salesHistory = document.getElementById("sales-history-table")
    const newSalesForm = document.getElementById("new-sales-form")

    newSalesForm.classList.toggle("hide")
    salesHistory.classList.toggle("big")

    setTableBig(!tableBig)
  }

  function PopupElements() {
    const [productRowsCount, setProductRowsCount] = useState(0)

    useEffect(() => {
      setProductRowsCount(selectedOrderDetail.products.length)
    })

    let orderCanBeUpdated = false
    if (selectedOrderDetail.products.some(product => {
      if (product.is_partnered_product & !product.is_ready_for_delivery) {
        return true
      } else {
        return false
      }
    })) {
      orderCanBeUpdated = true
    }
    selectedOrderDetail.products.forEach(product => {
      if (!product.is_partnered_product & product.is_in_temp_orders) {
        orderCanBeUpdated = true
      }
    })

    const dateOptions = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric"
    }

    const handleOnClickExtraProductUpdate = () => {
      const newProductLine = {
        comment: "",
        is_delivered: false,
        is_partnered_product: false,
        is_ready_for_delivery: false,
        on_consignation: false,
        product: "",
        productID: null,
        quantity: 6,
        volume: "",
        vendor: "",
      }

      const newState = {
        ...selectedOrderDetail,
        products: [...selectedOrderDetail.products, newProductLine]
      }
      setSelectedOrderDetail(newState)
    }

    const copyTextOnClick = (event) => {
      const text = event.currentTarget.innerHTML
      navigator.clipboard.writeText(text)
    }


    return (
      <div className="popup-elements">

        <div className="client">
          <div className="popup_window__sub-title">Client</div>
          <div className="column2">
            <div className="client-info-item">
              <h2>Business Name</h2>
              <p onClick={copyTextOnClick}>{selectedOrderDetail.customer.business_name}</p>
            </div>
            <div className="client-info-item">
              <h2>Delivery Address</h2>
              <p onClick={copyTextOnClick}>{selectedOrderDetail.customer.delivery_address_street} {selectedOrderDetail.customer.delivery_address_nr}</p>
              <div className="double-field-30-70">
                <p onClick={copyTextOnClick}>{selectedOrderDetail.customer.delivery_address_pc}</p>
                <p onClick={copyTextOnClick}>{selectedOrderDetail.customer.delivery_address_place}</p>
              </div>
            </div>
            <div className="client-info-item">
              <h2>Contact Info</h2>
              <p onClick={copyTextOnClick}>{selectedOrderDetail.customer.first_name} {selectedOrderDetail.customer.last_name}</p>
              <p onClick={copyTextOnClick}>{selectedOrderDetail.customer.email}</p>
              <p onClick={copyTextOnClick}>{selectedOrderDetail.customer.tel_number}</p>
            </div>
          </div>

          <div className="column2">
            <div className="client-info-item">
              <h2>Company Name</h2>
              <p onClick={copyTextOnClick}>{selectedOrderDetail.customer.company_name}</p>
            </div>
            <div className="client-info-item">
              <h2>Invoicing Address</h2>
              <p onClick={copyTextOnClick}>{selectedOrderDetail.customer.invoice_address_street} {selectedOrderDetail.customer.invoice_address_nr}</p>
              <div className="double-field-30-70">
                <p onClick={copyTextOnClick}>{selectedOrderDetail.customer.invoice_address_pc}</p>
                <p onClick={copyTextOnClick}>{selectedOrderDetail.customer.invoice_address_place}</p>
              </div>
            </div>
            <div className="client-info-item">
              <h2>Invoice Info</h2>
              <p onClick={copyTextOnClick}>{selectedOrderDetail.customer.VAT_number}</p>
              <p onClick={copyTextOnClick}>{selectedOrderDetail.customer.invoice_email}</p>
            </div>
          </div>
        </div>

        <div className="order">
          <div className="popup_window__sub-title">Order</div>
          <div className="table__secondairy-header-6">
            <div className="table__secondairy-header--field">Amount</div>
            <div className="table__secondairy-header--field">SKU</div>
            <div className="table__secondairy-header--field">Product</div>
            <div className="table__secondairy-header--field">Volume</div>
            <div className="table__secondairy-header--field">Supplier</div>
            <div className="table__secondairy-header--field">Warehouse</div>
          </div>
          {
            selectedOrderDetail != null &&
            selectedOrderDetail.products.length != 0 &&
            selectedOrderDetail.products.map((product, index) => {
              return (
                <div key={index}>
                  <div className="sales__orders--product-rows" key={index}>
                    <div className="table__secondairy-row--field" onClick={copyTextOnClick}>{product.quantity}</div>
                    <div className="table__secondairy-row--field" onClick={copyTextOnClick}>{product.sku}</div>
                    <div className="table__secondairy-row--field" onClick={copyTextOnClick}>{product.product}</div>
                    <div className="table__secondairy-row--field" onClick={copyTextOnClick}>{product.volume}</div>
                    <div className="table__secondairy-row--field" onClick={copyTextOnClick}>{product.vendor_id?.company_name}</div>
                    <div className="table__secondairy-row--field" onClick={copyTextOnClick}>{product.warehouse_id?.name}</div>
                  </div>
                </div>
              )
            })
          }
        </div>

        {/* <div className="history no-scrollbar">
          <div className="popup_window__sub-title">History</div>
          <div className="timeline__container">

            <div className="timeline__event">
              <div className="timeline__event--date">{new Date(selectedOrderDetail.date).toLocaleString('eng-US', dateOptions)}</div>
              <div className="timeline__event--label blue"><div className="ball"></div><h4>CREATED</h4></div>
              <div className="timeline__event--comment">{`Order created by ${selectedOrderDetail.sales_by}`}</div>
            </div>

            {
              orderEvents.map(event => {
                return (
                  <div className="timeline__event" key={key2++}>
                    <div className="timeline__event--date">{new Date(event.timestamp).toLocaleString('eng-US', dateOptions)}</div>
                    <div className={
                      event.event_label == "CREATED" ?
                        "timeline__event--label blue" :
                        event.event_label == "DELIVERED" ?
                          "timeline__event--label green" :
                          event.event_label == "CANCELLATION" ?
                            "timeline__event--label red" :
                            "timeline__event--label grey"
                    }>
                      <div className="ball"></div>
                      <h4>{event.event_label}</h4>
                    </div>
                    <div className="timeline__event--comment">{event.event_description}</div>
                  </div>
                )
              })
            }

          </div>
        </div> */}
      </div>
    )
  }

  function findChangedRowIndex(oldData, newData) {
    for (let i = 0; i < oldData.length; i++) {
      if (!isEqual(oldData[i], newData[i])) {
        return oldData[i].id;
      }
    }
    return -1;
  }

  const actionProgressStep = async (orderID, stage, warehouseID = null) => {

    const bodySection = document.querySelector("#sales-history-table")
    bodySection.classList.add("overflow-hidden")

    const data = {
      "orderIDs": [orderID]
    }

    const response = await httpClient.post(process.env.REACT_APP_API_URL + `/api/brandowner/order/change-status?progressStage=${stage}&warehouseID=${warehouseID}`, data)
    console.log(response.data)

    const oldData = orders


    await fetchOrders()
    const changedRowIndex = findChangedRowIndex(oldData, orders)

    const changedRow = document.querySelector(`[data-orderid="${orderID}"]`)

    if (changedRowIndex !== -1) {
      setTimeout(() => {
        const changedRow = document.querySelector(`[data-orderid="${orderID}"]`)
        try {
          changedRow.classList.add("changed-row")
        } catch {
        }
      }, 500)
    }

    if (warehouses.length > 1) {
      setSelectedWarehouseForDelivery(null)
    }

    bodySection.classList.remove("overflow-hidden")
  }

  const handleClickWarehouseItem = (warehouseID) => {
    setSelectedWarehouseForDelivery(warehouseID)

    setNotificationSelectWarehouseIsActive(false)

    actionProgressStep(progressStepOrderID, progressStepStage, warehouseID)
  }

  const handleClickProgressStep = async (event, stage, orderID, warehouseID) => {
    const parentElement = event.currentTarget.parentElement.parentElement

    setProgressStepOrderID(orderID)
    setProgressStepStage(stage)

    if (selectedWarehouseForDelivery == null & warehouseID == null) {

      setNotificationSelectWarehouseIsActive(true)
      return
    }

    parentElement.classList.add("removing")

    setTimeout(() => {
      setLoading(true)
    }, 400)

    actionProgressStep(orderID, stage)
  }

  const handleDeleteOrder = () => {

    showNotificationPopup({
      id: 'delete-order-popup',
      title: 'Are you sure?',
      message: 'Deleting an order can not be undone',
      actionButtonOnClick: () => deleteOrder(),
      actionButtonText: 'yes',
      popupButtonColor: 'red'
    })
  }

  const handleOnClickItem = (event, data) => {

    const updatedFilters = { ...filters }

    switch (event.target.parentElement.id) {
      case "sales_brandowners__dropdown--status":
        updatedFilters.statusFilter = data
        break

      case "sales_brandowners__dropdown--supplier":
        updatedFilters.warehouseFilter = data
        break
    }

    updatedFilters.page = 1
    dispatch(updateFilters(updatedFilters))
  }

  return (
    <div className="template">
      {error ? window.location.href = "/" : ""}

      <Header>
        {
          (!popupWindowSalesDetailIsOpen &
            !tableBig) ?
            <button className={`green-button-header`} id="sales__btn-save" onClick={handleCreateOrder}>{"SAVE"}</button>
            : null
        }
        {
          popupWindowSalesDetailIsOpen ?
            <button className={`red-button-header`} onClick={handleDeleteOrder}>DELETE ORDER</button>
            : null
        }
        {
          (tableBig &
            !popupWindowSalesDetailIsOpen) ?
            <div className="header__options--right">
              <DropDownFilter
                idList="sales_brandowners__dropdown--status"
                placeholderText="Filter on status"
                listArray={["Ready for delivery", "Not ready for delivery", "Delivered", "Not delivered", "Invoiced", "Not invoiced"]}
                onClickItem={handleOnClickItem}
                value={filters.statusFilter}
                filter={"statusFilter"}
                setValue={updateFilters}
                dropdownType="salesPageBrandowner"
              />
              <DropDownFilter
                idList="sales_brandowners__dropdown--supplier"
                placeholderText="Filter on warehouse"
                listArray={warehouses}
                onClickItem={handleOnClickItem}
                value={filters.warehouseFilter}
                filter={"warehouseFilter"}
                setValue={updateFilters}
                dropdownType="salesPageBrandowner"
              />
            </div> : null
        }
      </Header>
      <MenuBar />

      <NotificationTop />
      <NotificationPopupMessage />

      <NotificationPopupSelectWarehouse
        isActive={notificationSelectWarehouseIsActive}
        setIsActive={setNotificationSelectWarehouseIsActive}
        warehouses={warehouses}
        handleClickWarehouseItem={handleClickWarehouseItem}
      />

      <div className="template__body">

        <div className="body__sales">

          <PopupWindow
            popupID="sales__order_details--popup"
            onClose={() => setUpdateOrder(false)}
            isActive={popupWindowSalesDetailIsOpen}
            setIsActive={setPopupWindowSalesDetailIsOpen}
          >
            {selectedOrderDetail != undefined && <PopupElements />}
          </PopupWindow>
          <PopupWindowCreateNewCustomer
            customers={customers}
            isActive={newCustomerPopupIsActive}
            setIsActive={setNewCustomerPopupIsActive}
            fetchCustomers={fetchCustomers}
            setSelectedCustomer={setSelectedCustomer}
            setSelectedCustomerID={setSelectedCustomerID}
          />

          <div className="body__section" id="new-sales-form" >
            <div className="form__section no-scrollbar hght100" ref={parentDivRef}>
              <h1>New order</h1>
              <div>
                <DropDownInputCustomer
                  idList={"sales__dropdownList--customer"}
                  placeholderText="Select the customer"
                  listArray={customers}
                  onClickItem={handleOnClickDropdownItem}
                  value={selectedCustomer}
                  setValue={setSelectedCustomer}
                  createNewCustomer={setNewCustomerPopupIsActive}
                />
                <div className="salespage--titles">
                  <div className="salespage--title">Product</div>
                  <div className="salespage--title">Amount</div>
                  <div className="checkboxes">
                    <div>Already delivered</div>
                    <div>On Consignation</div>
                  </div>
                  <div className="salespage--title">Supplier</div>
                  <div className="salespage--title">Comment</div>
                </div>
                {
                  countArray.map((count, index) => {
                    return <AddProductForSales
                      onClickDelete={DeleteProductLine}
                      key={index}
                      index={index}
                      identifier={count}
                      setValue={setSelectedProducts}
                      value={selectedProducts}
                      listArray={filteredProducts}
                      listArray2={filteredMaterials}
                      customerID={selectedCustomerID}
                      parentDivRef={parentDivRef}
                      resetState={resetChildState}
                      warehouses={warehouses}
                    />
                  })
                }
                <button className="more-products--btn" onClick={handleOnClickExtraProduct}>+</button>
              </div>

            </div>
          </div>

          <div className="body__section" id="sales-history-table">

            {tableBig ? <UnfoldLessOutlinedIcon className="unfold-icon" onClick={handleOnClickExpand} /> : <UnfoldMoreOutlinedIcon className="unfold-icon" onClick={handleOnClickExpand} />}
            {
              loading ?
                <LoadingSpinner /> :
                <div className="table">
                  <div className="table__header-8">
                    <div className="table__header--field">ID</div>
                    <div className="table__header--field">Date</div>
                    <div className="table__header--field">Customer</div>
                    <div className="table__header--field">Ready for delivery</div>
                    <div className="table__header--field">Delivered</div>
                    <div className="table__header--field">Supplier</div>
                    <div className="table__header--field">Warehouse</div>
                    <div></div>
                  </div>

                  <div className="table__rows_container sales">
                    {
                      orders
                        // .filter(order => {
                        //   // const statusCondition
                        //   let statusCondition
                        //   switch (filters.statusFilter) {
                        //     case "Ready for delivery":
                        //       statusCondition = order.products.every(product => product.is_ready_for_delivery == true)
                        //       break
                        //     case "Not ready for delivery":
                        //       statusCondition = !order.products.every(product => product.is_ready_for_delivery == true)
                        //       break
                        //     case "Delivered":
                        //       statusCondition = order.products.every(product => product.is_delivered == true)
                        //       break
                        //     case "Not delivered":
                        //       statusCondition = !order.products.every(product => product.is_delivered == true)
                        //       break
                        //   }

                        //   // Check if warehouseFilter is provided and non-empty
                        //   const warehouseCondition = filters.warehouseFilter && filters.warehouseFilter.id &&
                        //     order.products.some(product =>
                        //       product.warehouse_id && product.warehouse_id.id === filters.warehouseFilter.id
                        //     )

                        //   // Apply warehouse filter only if warehouseFilter is non-empty
                        //   return (!filters.statusFilter || statusCondition) & (!filters.warehouseFilter || warehouseCondition)
                        // })
                        .map((order, index) => {
                          let orderCanBeUpdated = false
                          if (order.products.some(product => {
                            if (product.is_partnered_product & !product.is_ready_for_delivery) {
                              return true
                            } else {
                              return false
                            }
                          })) {
                            orderCanBeUpdated = true
                          }
                          order.products.forEach(product => {
                            if (!product.is_partnered_product & product.is_in_temp_orders) {
                              orderCanBeUpdated = true
                            }
                          })

                          const allSame = (items) => {
                            return items.every(item => item === items[0]);
                          }

                          const products = order.products

                          // Extracting vendor IDs and names, assuming vendor_id might be null
                          const vendorIds = products.map(product => product.vendor_id ? product.vendor_id.id : null)

                          let supplier = null

                          if (vendorIds.every(id => id === null)) {
                            supplier = null
                          } else if (allSame(vendorIds)) {
                            supplier = products[0].vendor_id.company_name
                          } else {
                            supplier = "Various suppliers"
                          }

                          // Extracting warehouse IDs, assuming that warehouse_id can also be null
                          const warehouseIds = products.map(product => product.warehouse_id ? product.warehouse_id.id : null)

                          let warehouse = null

                          if (warehouseIds.every(id => id === null)) {
                            warehouse = null
                          } else if (allSame(warehouseIds)) {
                            warehouse = products[0].warehouse_id.name
                          } else {
                            warehouse = "Various warehouses"
                          }

                          return (

                            <span className={`table__row-8`} key={index} data-orderid={order.id} >
                              <div className="table__row--field" onClick={handleOnClickTableRow} >{order.id}</div>
                              <div className="table__row--field" onClick={handleOnClickTableRow} >{new Date(order.date).toLocaleDateString()}</div>
                              <div className="table__row--field" onClick={handleOnClickTableRow} >{order.customer.business_name}</div>

                              <div className={`table__row--field--uncenter ${!order.internal_order ? "not-allowed" : ""}`}>
                                {
                                  order.internal_order ?
                                    <div className={order.products.every(product => product.is_ready_for_delivery == true) ? "progress__tracker--first" : "progress__tracker--first unfinished"} onClick={(e) => handleClickProgressStep(e, "Ready for delivery", order.id, products[0].warehouse_id?.id)} data-progressstage={"Ready for delivery"}>
                                      <div className="progress__tracker_circle">{<DoneIcon className={!order.products.every(product => product.is_ready_for_delivery == true) ? "faded-away" : "visible"} />}</div>
                                    </div> :
                                    <div className={"progress__tracker--first disabled"} disabled>
                                      <div className="progress__tracker_circle"><RxCross1 className="disabled_icon" /></div>
                                    </div>
                                }

                              </div>
                              <div className={`table__row--field--uncenter ${!order.internal_order ? "not-allowed" : ""}`}>
                                {
                                  order.internal_order ?
                                    <div className={order.products.every(product => product.is_delivered == true) ? "progress__tracker--last" : "progress__tracker--last unfinished"} onClick={(e) => handleClickProgressStep(e, "Delivered", order.id, products[0].warehouse_id?.id)} data-progressstage={"Delivered"}>
                                      <div className="progress__tracker_circle">{<DoneIcon className={!order.products.every(product => product.is_delivered == true) ? "faded-away" : "visible"} />}</div>
                                    </div> :
                                    <div className={"progress__tracker--last disabled"} disabled>
                                      <div className="progress__tracker_circle"><RxCross1 className="disabled_icon" /></div>
                                    </div>

                                }
                              </div>

                              <div className="table__row--field" onClick={handleOnClickTableRow} >{supplier}</div>
                              <div className="table__row--field" onClick={handleOnClickTableRow} >{warehouse}</div>

                              <div className="table__row--field--options" onClick={handleOnClickTableRow}>
                                <EditIcon className={`${orderCanBeUpdated ? "" : "disabled"}`} id={"editIconSalesPage"} data-can-update={orderCanBeUpdated} />
                              </div>
                            </span>
                          )
                        })
                    }
                  </div>

                </div>
            }
            <Pagination
              id="pagination-sales-brandowner-page-list"
              stateType="redux"
              filters={filters}
              page={filters.page}
              setPage={updateFilters}
              totalPages={totalPages}
            />
          </div>

        </div>
      </div>
    </div>
  )
}

export default SalesPage