import React, { useState, useEffect } from 'react'
import './TableCheckmarkHeader.css'

function TableCheckmarkHeader({ id, size, setSelectedRows, selectedRows }) {
  const [paddingTopValue, setPaddingTopValue] = useState("0px")
  const [paddingBottomValue, setPaddingBottomValue] = useState("0px")
  const [marginTopValue, setMarginTopValue] = useState("0px")
  const [marginBottomValue, setMarginBottomValue] = useState("0px")
  const [mouseOver, setMouseOver] = useState(false)
  const [clicked, setClicked] = useState(false)

  TableCheckmarkHeader.defaultProps = {
    size: 1
  }

  useEffect(() => {
    const thisCheckmarkElement = document.getElementById(`${id}`)

    const parentElement = thisCheckmarkElement.parentElement
    const computedStyle = window.getComputedStyle(parentElement)
    setPaddingTopValue(computedStyle.getPropertyValue('padding-top'))
    setPaddingBottomValue(computedStyle.getPropertyValue('padding-bottom'))
    setMarginTopValue(computedStyle.getPropertyValue('margin-top'))
    setMarginBottomValue(computedStyle.getPropertyValue('margin-bottom'))

  }, [])

  useEffect(() => {
    const thisCheckmarkElement = document.getElementById(`${id}`)
    const rowCheckmarkLines = document.getElementsByClassName('table_checkmark_row__line')
    const rowCheckmarkCircles = document.getElementsByClassName('table_checkmark_row__circle')

    if (mouseOver) {

      rowCheckmarkLines.forEach((checkmarkLine) => {
        checkmarkLine.classList.add('visible')
      })

      rowCheckmarkCircles.forEach((checkmarkCircle) => {
        checkmarkCircle.classList.add('hovered')
      })

      thisCheckmarkElement.children[1].classList.add("visible")
      thisCheckmarkElement.children[0].classList.add("hovered")
      thisCheckmarkElement.children[0].children[0].classList.add("hovered")
      thisCheckmarkElement.children[0].children[1].classList.add("hovered")

    } else {
      rowCheckmarkLines.forEach((checkmarkLine) => {
        checkmarkLine.classList.remove('visible')
      })

      rowCheckmarkCircles.forEach((checkmarkCircle) => {
        checkmarkCircle.classList.remove('hovered')
      })

      thisCheckmarkElement.children[1].classList.remove("visible")
      thisCheckmarkElement.children[1].classList.remove("hovered")
      thisCheckmarkElement.children[0].classList.remove("hovered")
      thisCheckmarkElement.children[0].children[0].classList.remove("hovered")
      thisCheckmarkElement.children[0].children[1].classList.remove("hovered")

    }

  }, [mouseOver])

  useEffect(() => {
    const thisCheckmarkElement = document.getElementById(`${id}`) 
    const thisCheckmarkTable = thisCheckmarkElement.parentElement.parentElement // this if there are more than 1 table on the document
    const rowCheckmarkLines = thisCheckmarkTable.querySelectorAll('.table_checkmark_row__line')
    const rowCheckmarkCircles = thisCheckmarkTable.querySelectorAll('.table_checkmark_row__circle')
    const checkMarkRows = thisCheckmarkTable.querySelectorAll('#checkmark-row')

    if (clicked) {
      rowCheckmarkLines.forEach((checkmarkLine) => {
        checkmarkLine.classList.remove('visible')
      })

      rowCheckmarkCircles.forEach((checkmarkCircle) => {
        checkmarkCircle.classList.add('selected')
        checkmarkCircle.classList.add('hovered')
      })

      setSelectedRows(Array.from(checkMarkRows))

      thisCheckmarkElement.children[1].classList.remove("visible")
      thisCheckmarkElement.children[0].classList.add("selected")
      thisCheckmarkElement.children[0].classList.remove("hovered")
      thisCheckmarkElement.children[0].children[0].classList.add("selected")
      thisCheckmarkElement.children[0].children[1].classList.add("selected")
      thisCheckmarkElement.children[0].children[0].classList.remove("hovered")
      thisCheckmarkElement.children[0].children[1].classList.remove("hovered")


    } else {
      rowCheckmarkLines.forEach((checkmarkLine) => {
        checkmarkLine.classList.remove('visible')
      })

      rowCheckmarkCircles.forEach((checkmarkCircle) => {
        checkmarkCircle.classList.remove('selected')
        checkmarkCircle.classList.remove('hovered')
      })

      thisCheckmarkElement.children[1].classList.remove("visible")
      thisCheckmarkElement.children[0].classList.remove("selected")
      thisCheckmarkElement.children[0].classList.remove("hovered")
      thisCheckmarkElement.children[0].children[0].classList.remove("selected")
      thisCheckmarkElement.children[0].children[1].classList.remove("selected")
      thisCheckmarkElement.children[0].children[0].classList.remove("hovered")
      thisCheckmarkElement.children[0].children[1].classList.remove("hovered")

      setSelectedRows([])

    }

  }, [clicked])

  useEffect(() => {
    const thisCheckmarkElement = document.getElementById(`${id}`)
    const rowCheckmarkLines = document.getElementsByClassName('table_checkmark_row__line')
    const rowCheckmarkCircles = document.getElementsByClassName('table_checkmark_row__circle')
    const checkMarkRows = document.querySelectorAll('#checkmark-row')
    
    if (selectedRows.length == 0) {
      rowCheckmarkLines.forEach((checkmarkLine) => {
        checkmarkLine.classList.remove('visible')
      })

      rowCheckmarkCircles.forEach((checkmarkCircle) => {
        checkmarkCircle.classList.remove('selected')
        checkmarkCircle.classList.remove('hovered')
      })

      thisCheckmarkElement.children[1].classList.remove("visible")
      thisCheckmarkElement.children[0].classList.remove("selected")
      thisCheckmarkElement.children[0].classList.remove("hovered")
      thisCheckmarkElement.children[0].children[0].classList.remove("selected")
      thisCheckmarkElement.children[0].children[1].classList.remove("selected")
      thisCheckmarkElement.children[0].children[0].classList.remove("hovered")
      thisCheckmarkElement.children[0].children[1].classList.remove("hovered")
    }
  }, [selectedRows])

  const lineStyle = {
    height: `calc(50% + ${paddingTopValue} + ${paddingBottomValue} + ${marginTopValue} + ${marginBottomValue})`,
    top: `50%`
  }

  const circleStyle = {
    height: `${17 * size}px`,
    width: `${17 * size}px`,
  }

  return (
    <div className='table_checkmark_header__container'
      id={id}
      onMouseOver={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
      onClick={() => setClicked(!clicked)}
    >
      <div className='table_checkmark_header__circle' style={circleStyle}>
        <div className="checkmark_stem"></div>
        <div className="checkmark_kick"></div>
      </div>
      <div className='table_checkmark_header__line' style={lineStyle}></div>
    </div>
  )
}

export default TableCheckmarkHeader