import React, { useState, useEffect } from "react"
import httpClient from "../httpClient"
import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import './InventoryPageBrandownerLogs.css'
import Header from "../components/Header"
import MenuBar from "../components/MenuBar"
import ComingSoonOverlay from "../components/placeholders/ComingSoonOverlay"
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import LoadingSpinner from "../components/placeholders/LoadingSpinner"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import CircularProgress from '@mui/material/CircularProgress'
import AddIcon from '@mui/icons-material/Add'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import { exportToExcel } from "../components/helpers/Exports"
import { formatDateExportTitle, formatDateWithTime } from "../components/helpers/FormatDate"

function InventoryPageBrandownerLogs() {
  const [user, setUser] = useState({ email: "", id: "" })
  const [error, setError] = useState(false)
  const [loadingWarehouse, setLoadingWarehouses] = useState(false)
  const [loadingData, setLoadingData] = useState(false)
  const [warehouses, setWarehouses] = useState([])
  const [logsTables, setLogsTables] = useState([])

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const userInfo = useSelector(state => state.user.userInfo)

  const fetchInventory = async () => {
    setLoadingWarehouses(true)

    const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/brandowner/inventory/get-inventory")

    const warehousesData = response.data.map(item => {
      return ({
        "id": item.warehouse_id.id,
        "name": item.warehouse_id.name,
      })
    })
    setWarehouses(warehousesData)

    setLoadingWarehouses(false)
  }

  useEffect(() => {
    fetchInventory()
  }, [])

  const handleGoBack = () => {
    navigate(-1)
  }

  const createLogsTable = (logs) => {
    return (
      <div className="table">
        <div className={`table__header-8`}>
          <div className="table__header--field">Timestamp</div>
          <div className="table__header--field">Product</div>
          <div className="table__header--field">Activity by</div>
          <div className="table__header--field">From</div>
          <div className="table__header--field">To</div>
          <div className="table__header--field">Change</div>
          <div className="table__header--field">Reason</div>
          <div className="table__header--field">Comment</div>
        </div>
        {
          logs.map((log, index) => (
            <div className={`table__row-8`} key={index}>
              <div className="table__row--field">{formatDateWithTime(log.timestamp)}</div>
              <div className="table__row--field">{log.product}</div>
              <div className="table__row--field">{log.activity_by}</div>
              <div className="table__row--field">{log.from_warehouse}</div>
              <div className="table__row--field">{log.to_warehouse}</div>
              <div className="table__row--field">{log.amount_change}</div>
              <div className="table__row--field">{log.reason}</div>
              <div className="table__row--field">{log.comment}</div>
            </div>
          ))
        }
      </div>
    )
  }

  const onClickWarehouse = async (event) => {
    setLoadingData(true)

    const logsDataElement = event.currentTarget.nextSibling
    const icon = event.currentTarget.childNodes[1]
    const warehouseID = event.currentTarget.getAttribute("data-warehouse-id")
    const index = event.currentTarget.parentElement.getAttribute("data-index")

    logsDataElement.classList.toggle("hide")
    icon.classList.toggle("selected")

    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/brandowner/inventory/get-logs-of-warehouse?warehouseID=${warehouseID}`)

    const logsTableElement = createLogsTable(response.data)
    
    const newArray = logsTables
    newArray[index] = logsTableElement
    setLogsTables(newArray)
    
    setLoadingData(false)
  }

  const handleExport = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/brandowner/inventory/get-logs-of-warehouse?type=full`)

    const now = Date.now()
    const fileName = `STOQUP_export_warehouse_logs_${formatDateExportTitle(now)}`

    exportToExcel(response.data, fileName)
  }


  return (
    <div className="template">
      {error ? window.location.href = "/" : null}

      <Header>
        <div className="back_button" onClick={handleGoBack}><KeyboardBackspaceIcon /> Go Back</div>
        <div className="header__options">
          <button className="options-button-header" id="report__btn-export" onClick={handleExport}>Export all <ExitToAppIcon/></button>
        </div>      
        </Header>
      <MenuBar />
      <div className="template__body">
        <div className="body__inventory_logs">
          <div className="body__section">
            {
              loadingWarehouse ?
                <LoadingSpinner />
                :
                warehouses.map((warehouse, index) => {
                  return (
                    <div key={index} data-index={index}>
                      <div className="inventory_logs__warehouse" onClick={onClickWarehouse} data-warehouse-id={warehouse.id}>
                        <p>{warehouse.name} </p>
                        <AddIcon />
                      </div>
                      <div className="inventory_logs__data hide">
                        {
                          loadingData ?
                            <div className="loading_spinner_circle"><CircularProgress id="circular-progress" color="inherit" size="2rem" /></div>
                            : logsTables[index] ? logsTables[index] : <div className="no_data">No data</div>
                        }
                      </div>
                    </div>
                  )
                })
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default InventoryPageBrandownerLogs