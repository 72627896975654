import React, { useState, useEffect } from "react"
import httpClient from "../httpClient"
import { useSelector, useDispatch } from "react-redux"
import { addUserInfo } from "../store/user"
import './SettingsPage.css'
import Header from "../components/Header"
import MenuBar from "../components/MenuBar"
import ComingSoonOverlay from "../components/placeholders/ComingSoonOverlay"
import { changeNotificationMessage } from "../store/notification"
import Slider from "../components/Slider"
import InputBox from "../components/InputBox"
import { toast } from "react-toastify"
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import EditIcon from '@mui/icons-material/Edit'
import DeleteOutline from "@mui/icons-material/DeleteOutline"
import { NotificationPopup, showNotificationPopup, closePopupNotification } from "../components/NotificationPopup"
import Dropdown from "../components/Dropdown"

function SettingsPage() {
  const [user, setUser] = useState({ email: "", id: "" })
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)
  const [menuType, setMenuType] = useState("ACCOUNT")
  const [changesMade, setChangesMade] = useState(false)

  const [autoSend, setAutoSend] = useState(false)
  const [minSalesAmountStar, setMinSalesAmountStar] = useState(6)
  const [minSalesAmountStarWholesaler, setMinSalesAmountStarWholesaler] = useState(60)
  const [noSalesDaysSlow, setNoSalesDaysSlow] = useState(90)

  const [reportItems, setReportItems] = useState([])
  const [reportItemTitles, setReportItemTitles] = useState([])
  const [addReportItem, setAddReportItem] = useState(false)
  const [addReportItemTitle, setAddReportItemTitle] = useState("")
  const [addReportItemType, setAddReportItemType] = useState("")
  const [addReportItemDropdownValues, setAddReportItemDropdownValues] = useState([null, null, null, null, null])
  const [okToDeleteReportItem, setokToDeleteReportItem] = useState(false)
  const [selectedReportID, setSelectedReportID] = useState(null)

  const [addAPIData, setAddAPIData] = useState({
    "serviceProvider": "",
    "api_key": "",
    "api_secret": ""
  })
  const [apiServices, setApiServices] = useState([])

  const [activitiesData, setActivitiesData] = useState({
    "calendarIntegrationIsActive": false,
    "calendarIntegrationEmail": "",
    "calendarIntegrationType": "All"
  })

  const dispatch = useDispatch()

  const userInfo = useSelector(state => state.user.userInfo)
  const requester = userInfo.type
  const business_name = userInfo.business_name
  const avatarArray = [
    "man1.png", "man2.png", "man3.png", "man4.png", "man5.png", "man6.png", "man7.png", "man8.png", "man9.png",
    "female1.png", "female2.png", "female3.png", "female4.png", "female5.png", "female6.png", "female7.png", "female8.png", "female9.png",
    "ninja.png", "panda.png", "rabbit.png", "sloth.png", "lama.png", "koala.png", "dragon.png", "dog.png", "bear.png"
  ]

  const fetchUser = async () => {
    const responseUser = await httpClient.get(process.env.REACT_APP_API_URL + "/api/@me?type=full")

    if (!activitiesData?.calendarIntegrationEmail) {
      setActivitiesData(prevState => ({
        ...prevState,
        "calendarIntegrationEmail": responseUser.data.email
      }))
    }
    setUser(responseUser.data)
  }

  const fetchSettings = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/settings/get?requester=${requester}`)
    try {
      setAutoSend(response.data[0].auto_send_orders_to_stoqup)
      setMinSalesAmountStar(response.data[0].star_min_order_amount)
      setMinSalesAmountStarWholesaler(response.data[0].star_min_order_amount_wholesaler)
      setNoSalesDaysSlow(response.data[0].slow_days_no_order)
    } catch {
      console.log("error in fetching settings")
    }

    setLoading(false)
  }

  const fetchReportItems = async () => {
    const responseReportItems = await httpClient.get(process.env.REACT_APP_API_URL + "/api/settings/custom-customer-data/get?requester=" + requester)
    setReportItems(responseReportItems.data[0][business_name])

    const arrayOfReportItemTitles = [...new Set(responseReportItems.data[0][business_name].map(reportItem => reportItem.item_title))]
    setReportItemTitles(arrayOfReportItemTitles)
  }

  const fetchAPIs = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/settings/apis/get")
    setApiServices(response.data)
  }

  const fetchActivitiesData = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/settings/calendar-integration")

    if (response.status == 200) {
      setActivitiesData(prevState => (
        {
          ...prevState,
          "calendarIntegrationIsActive": response.data.active,
          "calendarIntegrationEmail": response.data.email_address,
          "calendarIntegrationType": response.data.calendar_type
        })
      )
    }
  }

  useEffect(() => {
    fetchUser()
      .catch(err => {
        console.log(err)
        setError(true)
      })

    fetchSettings()
      .catch(err => {
        console.log(err)
      })

    fetchReportItems()
      .catch(err => {
        console.log(err)
      })

    fetchAPIs()
      .catch(err => {
        console.log(err)
      })
    fetchActivitiesData()
      .catch(err => {
        console.log(err)
      })

  }, [])

  useEffect(() => {
    if (okToDeleteReportItem) {
      handleDeleteReportItem()
    }
  }, [okToDeleteReportItem])

  useEffect(() => {
    if (addReportItemTitle != "" || addReportItemType != "") {
      setChangesMade(true)
    }

    if (addAPIData.serviceProvider || addAPIData.api_key || addAPIData.api_secret) {
      setChangesMade(true)
    }

    setChangesMade(true)
  }, [addReportItemTitle, addReportItemType, addAPIData, user])

  const handleClickMenu = (event) => {
    setMenuType(event.currentTarget.innerHTML)

    const menuButtons = document.querySelectorAll(".tertiairy_menu_title")
    menuButtons.forEach(button => {
      button.classList.remove("selected")
    })

    event.currentTarget.classList.add("selected")
  }

  const handleOnChangeSetting = () => {
    setChangesMade(true)
  }

  const saveSettings = async () => {

    if (menuType == "ORDERS") {

      const data = {
        "auto_send_orders_to_stoqup": autoSend
      }

      const response = await toast.promise(
        httpClient.post(process.env.REACT_APP_API_URL + "/api/settings/change", data),
        {
          success: "Settings change success",
          pending: "Updating settings...",
          error: "Something went wrong"
        }
      )
      console.log(response.data)

    }

    if (menuType == "CUSTOMER DATABASE") {

      if (addReportItemTitle == "" || addReportItemType == "") {
        toast.error("Please fill out all required info.", { theme: "dark" })
        return
      }

      const data = {
        "item_title": addReportItemTitle,
        "item_type": addReportItemType,
        "item_dropdown_values": addReportItemType != "dropdown" ? [null, null, null, null, null] : addReportItemDropdownValues
      }

      const response = await toast.promise(
        httpClient.post(process.env.REACT_APP_API_URL + "/api/brandowner/brand-report-items/create", data),
        {
          success: "Report Item successfully added",
          pending: "Creating report item...",
          error: "Something went wrong"
        }
      )
      console.log(response.data)

      setAddReportItemTitle("")
      setAddReportItemType("")
      setAddReportItemDropdownValues([null, null, null, null, null])
      setAddReportItem(false)
      fetchReportItems()
    }

    if (menuType == "SALES PIPE") {
      const data = {
        "auto_send_orders_to_stoqup": autoSend,
        "star_min_order_amount": minSalesAmountStar,
        "star_min_order_amount_wholesaler": minSalesAmountStarWholesaler,
        "slow_days_no_order": noSalesDaysSlow,
      }

      const response = await toast.promise(
        httpClient.post(process.env.REACT_APP_API_URL + `/api/settings/change?requester=${requester}`, data),
        {
          pending: "Saving settings...",
          success: `Settings have been saved!`,
          error: "Something went wrong"
        }
      )
      console.log(response.data)

      fetchSettings()
    }

    if (menuType == "APIs") {

      if (Object.values(addAPIData).some(value => !value)) {
        toast.error('Please complete all fields', { theme: "dark" })
        return
      }

      if (["Geru", "Webship"].includes(addAPIData.serviceProvider) &
        (!addAPIData.brand_name | !addAPIData.shop_name)
      ) {
        toast.error('Please complete all fields', { theme: "dark" })
        return
      }

      const response = await toast.promise(
        httpClient.post(process.env.REACT_APP_API_URL + `/api/settings/apis/add`, addAPIData),
        {
          pending: "Saving settings...",
          success: `Settings have been saved!`,
          error: "Something went wrong"
        }
      )
      console.log(response.data)

      const prevState = { ...addAPIData }
      prevState.serviceProvider = ""
      prevState.api_secret = ""
      prevState.api_key = ""
      setAddAPIData(prevState)

      fetchAPIs()
    }

    if (menuType == "ACCOUNT") {
      const response = await toast.promise(
        httpClient.post(process.env.REACT_APP_API_URL + `/api/@me/update`, user),
        {
          pending: "Saving settings...",
          success: `Settings have been saved!`,
          error: "Something went wrong"
        }
      )
      console.log(response.data)
      if (response.status == 200) {
        const updatedUserInfo = { ...userInfo, image_name: response.data.image_name }
        dispatch(addUserInfo(updatedUserInfo))
      }
    }

    if (menuType == "ACTIVITIES") {
      // if (!activitiesData.calendarIntegrationEmail | !activitiesData.calendarIntegrationType) {
      //   toast.error("Email and Calendar type are required", { theme: "dark" })
      //   return
      // }

      const response = await toast.promise(
        httpClient.post(process.env.REACT_APP_API_URL + `/api/settings/calendar-integration`, activitiesData),
        {
          pending: "Saving calendar integration...",
          success: `Calendar integration has been saved!`,
          error: "Something went wrong"
        }
      )
      console.log(response.data)

    }

    setChangesMade(false)
  }

  const handleOnClickSlider = async () => {
    setAutoSend(!autoSend)
    setChangesMade(true)
  }

  const handleOnChangeAddDropdownValue = (event) => {
    const dropdownValue = event.target.value
    const index = event.target.getAttribute("id")

    const currentArray = [...addReportItemDropdownValues]
    currentArray[index] = dropdownValue

    setAddReportItemDropdownValues(currentArray)
  }

  const handleDeleteReportItemPreCheck = (reportID) => {
    setSelectedReportID(reportID)
    showNotificationPopup({
      title: "Are you sure?",
      message: "If you delete this item, all data recorded will be lost.",
      actionButtonOnClick: () => notificationPopupFnDeleteReportItem(),
      actionButtonText: "YES",
      popupButtonColor: "red",
    })
  }

  const handleDeleteReportItem = async () => {
    const response = await httpClient.delete(process.env.REACT_APP_API_URL + `/api/settings/custom-customer-data/delete?itemID=${selectedReportID}`)
    console.log(response.data)

    fetchReportItems()
  }

  const notificationPopupFnDeleteReportItem = () => {
    setokToDeleteReportItem(true)
    closePopupNotification()
  }

  const ReportItem = ({ item, index, fetchReportItems, onDelete }) => {
    const [editedItem, setEditedItem] = useState(item)
    const [isEditing, setIsEditing] = useState(false)

    const toggleEdit = (state) => {
      setIsEditing(state)
    }

    const handleHover = (event, type) => {
      const target = event.currentTarget

      switch (type) {
        case "over":
          target.classList.add("hover")
          break
        case "out":
          target.classList.remove("hover")
          break
      }
    }

    const handleInputChange = (key, value) => {
      setEditedItem({ ...editedItem, [key]: value })
    }

    const handleSave = async () => {
      const response = await httpClient.put(process.env.REACT_APP_API_URL + `/api/settings/custom-customer-data/update`, editedItem)
      console.log(response.data)

      fetchReportItems()
      toggleEdit(false)
    }

    const handleCancel = () => {
      setEditedItem(item)
      toggleEdit(false)
    }

    return (
      <div className="report-item">
        {isEditing ? (
          <input
            className="add-report-item__title editing"
            value={editedItem.item_title}
            onChange={(e) => handleInputChange('item_title', e.target.value)}
          />
        ) : (
          <div className="add-report-item__title">{item.item_title}</div>
        )}
        {
          isEditing ?
            <>
              <div className="type_selector">
                <h4>Type:</h4>
                <div className="flex">
                  <div
                    className={editedItem.item_type == "input" ? "pick-option-button selected" : "pick-option-button"}
                    onClick={(e) => handleInputChange('item_type', e.target.innerHTML.toLowerCase())}
                    onMouseOver={(event) => handleHover(event, "over")}
                    onMouseLeave={(event) => handleHover(event, "out")}
                  >
                    Input
                  </div>
                  <div
                    className={editedItem.item_type == "checkmark" ? "pick-option-button selected" : "pick-option-button"}
                    onClick={(e) => handleInputChange('item_type', e.target.innerHTML.toLowerCase())}
                    onMouseOver={(event) => handleHover(event, "over")}
                    onMouseLeave={(event) => handleHover(event, "out")}
                  >
                    Checkmark
                  </div>
                  <div
                    className={editedItem.item_type == "dropdown" ? "pick-option-button selected" : "pick-option-button"}
                    onClick={(e) => handleInputChange('item_type', e.target.innerHTML.toLowerCase())}
                    onMouseOver={(event) => handleHover(event, "over")}
                    onMouseLeave={(event) => handleHover(event, "out")}
                  >
                    Dropdown
                  </div>
                </div>
              </div>
            </> :
            <>
              <div className="type_selector">
                <h4>Type:</h4>
                <div className="flex">
                  <div className={`${item.item_type == "input" ? "pick-option-button selected" : "pick-option-button"}`} >Input</div>
                  <div className={`${item.item_type == "checkmark" ? "pick-option-button selected" : "pick-option-button"}`} >Checkmark</div>
                  <div className={`${item.item_type == "dropdown" ? "pick-option-button selected" : "pick-option-button"}`} >Dropdown</div>
                </div>
              </div>
            </>
        }

        {editedItem.item_type == "dropdown" && (
          <DropdownValues
            item={editedItem}
            index={index}
            isEditing={isEditing}
            onValueChange={handleInputChange}
          />
        )}

        <div className="report-item-action__buttons">
          {isEditing ? (
            <>
              <button className="mini_action__buttons--save" onClick={handleSave}>Save</button>
              <button className="mini_action__buttons--delete" onClick={handleCancel}>Cancel</button>
            </>
          ) : (
            <>
              <button className="mini_action__buttons--edit" onClick={() => toggleEdit(true)}><EditIcon /></button>
              <button className="mini_action__buttons--delete" onClick={() => onDelete(item.item_id)}><DeleteOutline /></button>
            </>
          )}
        </div>
      </div>
    )
  }

  const DropdownValues = ({ item, index, isEditing, onValueChange }) => {
    return (
      <div className="dropdown_values">
        {Array.from({ length: 5 }, (_, dropdownIndex) => {
          const dropdownValueKey = `dropdown_value${dropdownIndex + 1}`
          return isEditing ? (
            <input
              className="dropdown_value"
              placeholder="Dropdown value"
              value={item[dropdownValueKey] || ''}  // Use empty string if value is null
              onChange={(e) => onValueChange(dropdownValueKey, e.target.value, index)}
              key={`${index}-${dropdownIndex}`}
            />
          ) : (
            item[dropdownValueKey] != null && (
              <div key={`${index}-${dropdownIndex}`} className="dropdown_value">
                {item[dropdownValueKey]}
              </div>
            )
          )
        })}
      </div>
    )
  }

  const onchangeAPIData = (e, type) => {
    const name = e.currentTarget.getAttribute('name')

    const prevState = { ...addAPIData }
    prevState[name] = e.currentTarget.value
    setAddAPIData(prevState)
  }

  const setStateUser = (e) => {
    const name = e.currentTarget.getAttribute('name')

    const prevState = { ...user }
    prevState[name] = e.currentTarget.value
    setUser(prevState)
  }

  const handleSelectAvatar = (e) => {
    const thisElement = e.currentTarget
    const imageName = thisElement.getAttribute('data-image')
    const avatarImages = document.querySelectorAll('.avatar_img')

    avatarImages.forEach(img => img.classList.remove('selected'))
    thisElement.classList.add('selected')

    const prevState = { ...user }
    prevState.image_name = imageName
    setUser(prevState)
  }

  const handleSetActivityState = () => {
    setActivitiesData(prevState => ({
      ...prevState,
      "calendarIntegrationIsActive": !prevState.calendarIntegrationIsActive
    }))
  }

  return (
    <div className="template">
      {error ? window.location.href = "/" : null}

      <Header>
        <button className={`green-button-header ${changesMade ? "" : "hide"}`} id="settings__btn-save" onClick={saveSettings}>SAVE</button>
      </Header>

      <MenuBar />
      <div className="template__body">
        <div className="body__settings">
          <div className="body__section">

            {
              userInfo.type == "brandowner" | userInfo.is_pro_account ?
                <>
                  <button className="tertiairy_menu_title selected" onClick={handleClickMenu}>ACCOUNT</button>
                  <button className="tertiairy_menu_title" onClick={handleClickMenu}>ORDERS</button>
                  <button className="tertiairy_menu_title" onClick={handleClickMenu}>SALES PIPE</button>
                  <button className="tertiairy_menu_title" onClick={handleClickMenu}>ACTIVITIES</button>
                  <button className="tertiairy_menu_title" onClick={handleClickMenu}>CUSTOMER DATABASE</button>
                  <button className="tertiairy_menu_title" onClick={handleClickMenu}>APIs</button>
                </> : null
            }

            {
              userInfo.type == "salesforce" ?
                <>
                  <button className="tertiairy_menu_title selected" onClick={handleClickMenu}>ACCOUNT</button>
                  <button className="tertiairy_menu_title" onClick={handleClickMenu}>ACTIVITIES</button>

                </> : null
            }

          </div>

          <div className="body__section" id="body-section-settings">

            {
              userInfo.type == "brandowner" | userInfo.is_pro_account ?

                <>
                  {
                    menuType == "ACCOUNT" &&
                    <div className="settings_details__container" id="settings_account">
                      <h1>ACCOUNT DETAILS</h1>

                      <div className="settings_forms__wrapper">
                        <div className="settings_form">
                          <h2>Contact</h2>
                          <div className="input_field">
                            <label className="input_field__label" htmlFor="business_name">Brand name</label>
                            <input className="input_field__input_box--text" id="business_name" type="text" name="business_name" value={user.business_name} onChange={setStateUser} />
                          </div>
                          <div className="input_field">
                            <label className="input_field__label" htmlFor="business_name">Contact email</label>
                            <input className="input_field__input_box--text" id="email" type="email" name="email" value={user.email} onChange={setStateUser} />
                          </div>
                          <div className="input_field">
                            <label className="input_field__label" htmlFor="tel_number">Tel number</label>
                            <input className="input_field__input_box--text" id="tel_number" type="text" name="tel_number" value={user.tel_number} onChange={setStateUser} />
                          </div>
                          <div className="input_field--combo-7030">
                            <div className="input_field">
                              <label className="input_field__label" htmlFor="delivery_address_street">Street</label>
                              <input className="input_field__input_box--text" id="delivery_address_street" type="text" name="delivery_address_street" value={user.delivery_address_street} onChange={setStateUser} />
                            </div>
                            <div className="input_field">
                              <label className="input_field__label" htmlFor="delivery_address_nr">Number</label>
                              <input className="input_field__input_box--text" id="delivery_address_nr" type="text" name="delivery_address_nr" value={user.delivery_address_nr} onChange={setStateUser} />
                            </div>
                          </div>
                          <div className="input_field--combo-3070">
                            <div className="input_field">
                              <label className="input_field__label" htmlFor="delivery_address_pc">Postal code</label>
                              <input className="input_field__input_box--text" id="delivery_address_pc" type="text" name="delivery_address_pc" value={user.delivery_address_pc} onChange={setStateUser} />
                            </div>
                            <div className="input_field">
                              <label className="input_field__label" htmlFor="delivery_address_place">City</label>
                              <input className="input_field__input_box--text" id="delivery_address_place" type="text" name="delivery_address_place" value={user.delivery_address_place} onChange={setStateUser} />
                            </div>
                          </div>
                          <div className="input_field">
                            <label className="input_field__label" htmlFor="invoice_address_nr">Country</label>
                            <input className="input_field__input_box--text" id="delivery_address_country" type="text" name="delivery_address_country" value={user.delivery_address_country} onChange={setStateUser} />
                          </div>
                        </div>

                        <div className="settings_form">
                          <h2>Accounting</h2>
                          <div className="input_field">
                            <label className="input_field__label" htmlFor="company_name">Company name</label>
                            <input className="input_field__input_box--text" id="company_name" type="text" name="company_name" value={user.company_name} onChange={setStateUser} />
                          </div>
                          <div className="input_field">
                            <label className="input_field__label" htmlFor="invoice_email">Invoice email</label>
                            <input className="input_field__input_box--text" id="invoice_email" type="email" name="invoice_email" value={user.invoice_email} onChange={setStateUser} />
                          </div>
                          <div className="input_field">
                            <label className="input_field__label" htmlFor="VAT_number">VAT number</label>
                            <input className="input_field__input_box--text" id="VAT_number" type="email" name="VAT_number" value={user.VAT_number} onChange={setStateUser} />
                          </div>
                          <div className="input_field--combo-7030">
                            <div className="input_field">
                              <label className="input_field__label" htmlFor="invoice_address_street">Street</label>
                              <input className="input_field__input_box--text" id="invoice_address_street" type="text" name="invoice_address_street" value={user.invoice_address_street} onChange={setStateUser} />
                            </div>
                            <div className="input_field">
                              <label className="input_field__label" htmlFor="invoice_address_nr">Number</label>
                              <input className="input_field__input_box--text" id="invoice_address_nr" type="text" name="invoice_address_nr" value={user.invoice_address_nr} onChange={setStateUser} />
                            </div>
                          </div>
                          <div className="input_field--combo-3070">
                            <div className="input_field">
                              <label className="input_field__label" htmlFor="delivery_address_pc">Postal code</label>
                              <input className="input_field__input_box--text" id="invoice_address_pc" type="text" name="invoice_address_pc" value={user.invoice_address_pc} onChange={setStateUser} />
                            </div>
                            <div className="input_field">
                              <label className="input_field__label" htmlFor="delivery_address_place">City</label>
                              <input className="input_field__input_box--text" id="invoice_address_place" type="text" name="invoice_address_place" value={user.invoice_address_place} onChange={setStateUser} />
                            </div>
                          </div>
                          <div className="input_field">
                            <label className="input_field__label" htmlFor="invoice_address_nr">Country</label>
                            <input className="input_field__input_box--text" id="invoice_address_country" type="text" name="invoice_address_country" value={user.invoice_address_country} onChange={setStateUser} />
                          </div>
                        </div>

                        <div className="settings_form">
                          <h2>Personal</h2>
                          <div className="input_field--combo-5050">
                            <div className="input_field">
                              <label className="input_field__label" htmlFor="first_name">First name</label>
                              <input className="input_field__input_box--text" id="customers__first_name" type="text" name="first_name" value={user.first_name} onChange={setStateUser} />
                            </div>
                            <div className="input_field">
                              <label className="input_field__label" htmlFor="last_name">Last name</label>
                              <input className="input_field__input_box--text" id="customers__last_name" type="text" name="last_name" value={user.last_name} onChange={setStateUser} />
                            </div>
                          </div>
                          <label className="input_field__label" htmlFor="last_name">Avatar</label>
                          <div className="avatars_container">
                            {
                              avatarArray.map((avatar, index) => {
                                let image
                                try {
                                  image = require(`../assets/pics/${avatar}`)
                                } catch {
                                  image = require(`../assets/pics/no_image_found.png`)
                                }

                                return (
                                  <img className="avatar_img" src={image} key={index} data-image={avatar} onClick={handleSelectAvatar} />
                                )
                              })
                            }
                          </div>
                        </div>
                      </div>

                    </div>
                  }

                  {
                    menuType == "ORDERS" && (
                      <>
                        <div className="settings_item">
                          <Slider
                            text="Auto send orders to STOQUP"
                            fontSize="0.8rem"
                            size={1.3}
                            state={autoSend}
                            onClickFunction={handleOnClickSlider}
                          />
                        </div>
                      </>
                    )
                  }

                  {
                    menuType == "SALES PIPE" &&
                    <>
                      <div className="settings_item">
                        <InputBox
                          id={"settings_item-slowlist-days"}
                          text="Slow outlet: days with no orders"
                          textPosition="left"
                          inputBoxSize={25}
                          inputType="number"
                          value={noSalesDaysSlow}
                          setValue={setNoSalesDaysSlow}
                          onChangeFunction={handleOnChangeSetting}
                        />
                      </div>
                      <div className="settings_item">
                        <InputBox
                          id={"settings_item-starlist-min-order-amount"}
                          text="Star outlet: minimum sales amount (items/month)"
                          textPosition="left"
                          inputBoxSize={25}
                          inputType="number"
                          value={minSalesAmountStar}
                          setValue={setMinSalesAmountStar}
                          onChangeFunction={handleOnChangeSetting}
                        />
                      </div>
                      <div className="settings_item">
                        <InputBox
                          id={"settings_item-starlist-min-order-amount-wholesalers"}
                          text="Star outlet: minimum sales amount (items/month) wholesalers"
                          textPosition="left"
                          inputBoxSize={25}
                          inputType="number"
                          value={minSalesAmountStarWholesaler}
                          setValue={setMinSalesAmountStarWholesaler}
                          onChangeFunction={handleOnChangeSetting}
                        />
                      </div>
                    </>
                  }

                  {
                    menuType == "ACTIVITIES" &&
                    <div className="settings_details__container" id="settings_activities">
                      <h1>Calendar integration</h1>
                      <div className="settings_item--25">
                        <Slider
                          fontSize={"0.8rem"}
                          size={1.3}
                          state={activitiesData.calendarIntegrationIsActive}
                          text={"Active"}
                          onClickFunction={handleSetActivityState}
                        />
                        {/* <label className="input_field__label">Calendar type</label>
                        <Dropdown
                          listArray={["All"]}
                          inherit={true}
                          state={activitiesData}
                          setState={setActivitiesData}
                          stateType="object"
                          stateValueName="calendarIntegrationType"
                          marginTop={"5px"}
                        />
                      </div>
                      <div className="settings_item--50">
                        <InputBox
                          id={"settings_item-activities-integration--email"}
                          text="Email address"
                          textPosition="vert"
                          value={activitiesData.calendarIntegrationEmail}
                          setValue={setActivitiesData}
                          stateType="object"
                          stateValueName="calendarIntegrationEmail"
                          onChangeFunction={handleOnChangeSetting}
                        />*/}
                      </div>
                    </div>
                  }

                  {
                    menuType == "CUSTOMER DATABASE" &&
                    <div className="settings_customer_details wdth50">
                      <h1>My custom customer data</h1>
                      {reportItems.map((item, index) => (
                        <ReportItem
                          item={item}
                          index={item}
                          onDelete={handleDeleteReportItemPreCheck}
                          fetchReportItems={fetchReportItems}
                          key={index}
                        />
                      ))}
                      {addReportItem && (
                        // <AddReportItem
                        //   addReportItemType={addReportItemType}
                        //   setAddReportItemType={setAddReportItemType}
                        //   addReportItemDropdownValues={addReportItemDropdownValues}
                        //   handleOnChangeAddDropdownValue={handleOnChangeAddDropdownValue}
                        //   addReportItemTitle={addReportItemTitle}
                        //   setAddReportItemTitle={setAddReportItemTitle}
                        // />
                        <div className="report-item">
                          <input className="add-report-item__title editing" placeholder="Add report item title" value={addReportItemTitle} onChange={event => setAddReportItemTitle(event.target.value)} />
                          <div className="type_selector">
                            <h4>Type:</h4>
                            <div className="flex">
                              <div className={addReportItemType == "input" ? "pick-option-button selected" : "pick-option-button"} onClick={event => setAddReportItemType(event.target.innerHTML.toLowerCase())}>Input</div>
                              <div className={addReportItemType == "checkmark" ? "pick-option-button selected" : "pick-option-button"} onClick={event => setAddReportItemType(event.target.innerHTML.toLowerCase())}>Checkmark</div>
                              <div className={addReportItemType == "dropdown" ? "pick-option-button selected" : "pick-option-button"} onClick={event => setAddReportItemType(event.target.innerHTML.toLowerCase())}>Dropdown</div>
                            </div>
                          </div>
                          {
                            addReportItemType == "dropdown" &&
                            <div className="dropdown_values flex--vert">
                              <input className="dropdown_value" placeholder="Dropdown value" value={addReportItemDropdownValues[0]} onChange={handleOnChangeAddDropdownValue} id={0} />
                              <input className="dropdown_value" placeholder="Dropdown value" value={addReportItemDropdownValues[1]} onChange={handleOnChangeAddDropdownValue} id={1} />
                              <input className="dropdown_value" placeholder="Dropdown value" value={addReportItemDropdownValues[2]} onChange={handleOnChangeAddDropdownValue} id={2} />
                              <input className="dropdown_value" placeholder="Dropdown value" value={addReportItemDropdownValues[3]} onChange={handleOnChangeAddDropdownValue} id={3} />
                              <input className="dropdown_value" placeholder="Dropdown value" value={addReportItemDropdownValues[4]} onChange={handleOnChangeAddDropdownValue} id={4} />
                            </div>
                          }
                        </div>

                      )}
                      <button className="more-products--btn" onClick={() => setAddReportItem(true)}>+</button>
                    </div>
                  }

                  {
                    menuType == "APIs" &&
                    <div className="settings_details__container" id="settings_apis">
                      <h1>Add API service</h1>

                      <div className="settings_form">
                        <label className="input_field__label">Service provider</label>
                        <Dropdown
                          listArray={["Webship", "Geru"]}
                          inherit={true}
                          state={addAPIData}
                          setState={setAddAPIData}
                          stateType="object"
                          stateValueName="serviceProvider"
                          marginTop={"5px"}
                        />

                        {
                          ["Geru", "Webship"].includes(addAPIData.serviceProvider) &&
                          <>
                            <div className="input_field">
                              <label className="input_field__label">Brand name</label>
                              <input className="input_field__input_box--text" name="brand_name" value={addAPIData.brand_name} onChange={onchangeAPIData} />
                            </div>

                            <div className="input_field">
                              <label className="input_field__label">Shop name</label>
                              <input className="input_field__input_box--text" name="shop_name" value={addAPIData.shop_name} onChange={onchangeAPIData} />
                            </div>
                          </>
                        }

                        <div className="input_field">
                          <label className="input_field__label">API Key</label>
                          <input className="input_field__input_box--text" name="api_key" value={addAPIData.api_key} onChange={onchangeAPIData} />
                        </div>

                        <div className="input_field">
                          <label className="input_field__label">API Secret</label>
                          <input className="input_field__input_box--text" name="api_secret" value={addAPIData.api_secret} onChange={onchangeAPIData} />
                        </div>
                      </div>

                      <h1>My API services</h1>
                      <div className="api_services__wrapper">
                        {
                          apiServices.map((api, index) => (
                            <div className="api_service" key={index}>
                              <h4>{api.serviceProvider}</h4>

                              <label>API KEY</label>
                              <div className="api_key">{api.api_key}</div>

                              <label>API SECRET</label>
                              <div className="api_key">{api.api_secret}</div>
                            </div>
                          ))
                        }
                      </div>
                    </div>
                  }
                </> : null
            }

            {
              userInfo.type == 'salesforce' ?
                <>
                  {
                    menuType == "ACCOUNT" &&
                    <div className="settings_details__container" id="settings_account">
                      <h1>ACCOUNT DETAILS</h1>

                      <div className="settings_forms__wrapper">
                        <div className="settings_form">
                          <h2>Contact</h2>
                          <div className="input_field">
                            <label className="input_field__label" htmlFor="business_name">Brand name</label>
                            <input className="input_field__input_box--text" id="business_name" type="text" name="business_name" value={user.business_name} onChange={setStateUser} />
                          </div>
                          <div className="input_field">
                            <label className="input_field__label" htmlFor="business_name">Contact email</label>
                            <input className="input_field__input_box--text" id="email" type="email" name="email" value={user.email} onChange={setStateUser} />
                          </div>
                          <div className="input_field">
                            <label className="input_field__label" htmlFor="tel_number">Tel number</label>
                            <input className="input_field__input_box--text" id="tel_number" type="text" name="tel_number" value={user.tel_number} onChange={setStateUser} />
                          </div>
                          <div className="input_field--combo-7030">
                            <div className="input_field">
                              <label className="input_field__label" htmlFor="delivery_address_street">Street</label>
                              <input className="input_field__input_box--text" id="delivery_address_street" type="text" name="delivery_address_street" value={user.delivery_address_street} onChange={setStateUser} />
                            </div>
                            <div className="input_field">
                              <label className="input_field__label" htmlFor="delivery_address_nr">Number</label>
                              <input className="input_field__input_box--text" id="delivery_address_nr" type="text" name="delivery_address_nr" value={user.delivery_address_nr} onChange={setStateUser} />
                            </div>
                          </div>
                          <div className="input_field--combo-3070">
                            <div className="input_field">
                              <label className="input_field__label" htmlFor="delivery_address_pc">Postal code</label>
                              <input className="input_field__input_box--text" id="delivery_address_pc" type="text" name="delivery_address_pc" value={user.delivery_address_pc} onChange={setStateUser} />
                            </div>
                            <div className="input_field">
                              <label className="input_field__label" htmlFor="delivery_address_place">City</label>
                              <input className="input_field__input_box--text" id="delivery_address_place" type="text" name="delivery_address_place" value={user.delivery_address_place} onChange={setStateUser} />
                            </div>
                          </div>
                          <div className="input_field">
                            <label className="input_field__label" htmlFor="invoice_address_nr">Country</label>
                            <input className="input_field__input_box--text" id="delivery_address_country" type="text" name="delivery_address_country" value={user.delivery_address_country} onChange={setStateUser} />
                          </div>
                        </div>

                        <div className="settings_form">
                          <h2>Accounting</h2>
                          <div className="input_field">
                            <label className="input_field__label" htmlFor="company_name">Company name</label>
                            <input className="input_field__input_box--text" id="company_name" type="text" name="company_name" value={user.company_name} onChange={setStateUser} />
                          </div>
                          <div className="input_field">
                            <label className="input_field__label" htmlFor="invoice_email">Invoice email</label>
                            <input className="input_field__input_box--text" id="invoice_email" type="email" name="invoice_email" value={user.invoice_email} onChange={setStateUser} />
                          </div>
                          <div className="input_field">
                            <label className="input_field__label" htmlFor="VAT_number">VAT number</label>
                            <input className="input_field__input_box--text" id="VAT_number" type="email" name="VAT_number" value={user.VAT_number} onChange={setStateUser} />
                          </div>
                          <div className="input_field--combo-7030">
                            <div className="input_field">
                              <label className="input_field__label" htmlFor="invoice_address_street">Street</label>
                              <input className="input_field__input_box--text" id="invoice_address_street" type="text" name="invoice_address_street" value={user.invoice_address_street} onChange={setStateUser} />
                            </div>
                            <div className="input_field">
                              <label className="input_field__label" htmlFor="invoice_address_nr">Number</label>
                              <input className="input_field__input_box--text" id="invoice_address_nr" type="text" name="invoice_address_nr" value={user.invoice_address_nr} onChange={setStateUser} />
                            </div>
                          </div>
                          <div className="input_field--combo-3070">
                            <div className="input_field">
                              <label className="input_field__label" htmlFor="delivery_address_pc">Postal code</label>
                              <input className="input_field__input_box--text" id="invoice_address_pc" type="text" name="invoice_address_pc" value={user.invoice_address_pc} onChange={setStateUser} />
                            </div>
                            <div className="input_field">
                              <label className="input_field__label" htmlFor="delivery_address_place">City</label>
                              <input className="input_field__input_box--text" id="invoice_address_place" type="text" name="invoice_address_place" value={user.invoice_address_place} onChange={setStateUser} />
                            </div>
                          </div>
                          <div className="input_field">
                            <label className="input_field__label" htmlFor="invoice_address_nr">Country</label>
                            <input className="input_field__input_box--text" id="invoice_address_country" type="text" name="invoice_address_country" value={user.invoice_address_country} onChange={setStateUser} />
                          </div>
                        </div>

                        <div className="settings_form">
                          <h2>Personal</h2>
                          <div className="input_field--combo-5050">
                            <div className="input_field">
                              <label className="input_field__label" htmlFor="first_name">First name</label>
                              <input className="input_field__input_box--text" id="customers__first_name" type="text" name="first_name" value={user.first_name} onChange={setStateUser} />
                            </div>
                            <div className="input_field">
                              <label className="input_field__label" htmlFor="last_name">Last name</label>
                              <input className="input_field__input_box--text" id="customers__last_name" type="text" name="last_name" value={user.last_name} onChange={setStateUser} />
                            </div>
                          </div>
                          <label className="input_field__label" htmlFor="last_name">Avatar</label>
                          <div className="avatars_container">
                            {
                              avatarArray.map((avatar, index) => {
                                let image
                                try {
                                  image = require(`../assets/pics/${avatar}`)
                                } catch {
                                  image = require(`../assets/pics/no_image_found.png`)
                                }

                                return (
                                  <img className="avatar_img" src={image} key={index} data-image={avatar} onClick={handleSelectAvatar} />
                                )
                              })
                            }
                          </div>
                        </div>
                      </div>

                    </div>
                  }
                </> : null
            }

            {
              menuType == "ACTIVITIES" &&
              <div className="settings_details__container" id="settings_activities">
                <h1>Calendar integration</h1>
                <div className="settings_item--25">
                  <Slider
                    fontSize={"0.8rem"}
                    size={1.3}
                    state={activitiesData.calendarIntegrationIsActive}
                    text={"Active"}
                    onClickFunction={handleSetActivityState}
                  />
                  {/* <label className="input_field__label">Calendar type</label>
                        <Dropdown
                          listArray={["All"]}
                          inherit={true}
                          state={activitiesData}
                          setState={setActivitiesData}
                          stateType="object"
                          stateValueName="calendarIntegrationType"
                          marginTop={"5px"}
                        />
                      </div>
                      <div className="settings_item--50">
                        <InputBox
                          id={"settings_item-activities-integration--email"}
                          text="Email address"
                          textPosition="vert"
                          value={activitiesData.calendarIntegrationEmail}
                          setValue={setActivitiesData}
                          stateType="object"
                          stateValueName="calendarIntegrationEmail"
                          onChangeFunction={handleOnChangeSetting}
                        />*/}
                </div>
              </div>
            }

          </div>

        </div>
      </div>
    </div>
  )
}

export default SettingsPage