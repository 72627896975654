import React from "react";

function LoadingLogo() {
  return (
    <>
      <style>
        {`
          @keyframes slide-reveal {
            0%, 100% { transform: translateX(-100%); }
            50% { transform: translateX(100%); }
          }
          .word {
            font-family: neue-haas-unica, sans-serif;
            font-weight: 900;
            font-style: normal;
            font-size: 48px;
            fill: white;
          }
          .reveal-rect {
            animation: slide-reveal 8s infinite ease-in-out;
          }
        `}
      </style>
      
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 100">
        <defs>
          <linearGradient id="fade-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" style={{ stopColor: '#000000', stopOpacity: 1 }} />
            <stop offset="50%" style={{ stopColor: '#000000', stopOpacity: 0 }} />
            <stop offset="100%" style={{ stopColor: '#000000', stopOpacity: 1 }} />
          </linearGradient>
        </defs>
        
        <rect width="100%" height="100%" fill="#000000" rx="10" ry="10"/>
        
        <g transform="translate(200, 60)"> {/* Centered the text group */}
          <text className="word" textAnchor="middle">STOQUP.</text> {/* Added textAnchor for centering */}
        </g>
        
        <rect className="reveal-rect" x="-200" y="0" width="800" height="100" fill="url(#fade-gradient)" rx="10" ry="10"/>
      </svg>
    </>
  );
}

export default LoadingLogo;