import React, {useState, useEffect} from "react"
import httpClient from "../httpClient"

import "./SalesforcePageBrandOwner.css"
import Header from "../components/Header"
import MenuBar from "../components/MenuBar"

function SalesforcePageBrandOwner() {
  const [user, setUser] = useState({email: "", id: ""})
  const [error, setError] = useState(false)

  const fetchUser = async () => {
    const responseUser = await httpClient.get(process.env.REACT_APP_API_URL + "/api/@me?type=full")
    setUser(responseUser.data)
  }

  useEffect(() => {
    fetchUser()
      .catch( err => {
        console.log(err)
        setError(true)
      })
  }, [])

  return (
    <div className="template">
      {error ? window.location.href = "/" : ""}

      <Header/>
      <MenuBar/>
      <div className="template__body">
        <div className="body__salesforce--brandowner">
          <div className="body__section" id="section1"></div>
          <div className="body__section" id="section2"></div>
          <div className="body__section" id="section3"></div>
          <div className="body__section" id="section4"></div>
        </div>
      </div>
    </div>
)}

export default SalesforcePageBrandOwner