import React, { useState, useEffect } from "react"
import './TodoToday.css'
import { useNavigate } from "react-router-dom"
import httpClient from "../httpClient"
import { useSelector, useDispatch } from "react-redux"
import LoadingSpinner from './placeholders/LoadingSpinner'
import { formatDate } from "./helpers/FormatDate"
import { activity_types } from "../assets/Data"
import CircularProgress from '@mui/material/CircularProgress'

function TodoToday() {
  const [activities, setActivities] = useState([])
  const [loadingActivities, setLoadingActivities] = useState(true)

  const navigate = useNavigate()

  const isMobile = useSelector(state => state.global.isMobile)
  const noTaskPicto = require(`../assets/pictos/no-task.png`)
  const now = new Date()

  const fetchActivities = async () => {
    setLoadingActivities(true)

    const data = {
      filters: {
        doneFilter: true,
      },
    }

    const response = await httpClient.post(process.env.REACT_APP_API_URL + `/api/activities/get?listType=today`, data)
    setActivities(response.data.activities)
    setLoadingActivities(false)
  }

  useEffect(() => {
    fetchActivities()
  }, [])

  const handleNavigateActivities = () => {
    if (isMobile) {
      navigate("/m/activities")
    } else {
      navigate("/activities")
    }
  }

  const handleClickRow = (event) => {
    // Find the closest parent row to the clicked element
    const parentRow = event.target.closest('.table__row-5')

    // If a parent row is found
    if (parentRow) {
      // Find the next sibling element which should be the .activity__detail div
      const detailDiv = parentRow.nextElementSibling

      // Check if the next sibling is actually the .activity__detail div
      if (detailDiv && detailDiv.classList.contains('activity__detail')) {
        detailDiv.classList.toggle('hide')
      }
    }
  }

  return (
    <div className={`body__section_content__wrapper ${isMobile ? "mobile" : ""}`}>
      <h2 className="flex clickable" onClick={handleNavigateActivities}>to do today</h2>
      <div className="" id="dashboard-activities-list">
        {
          loadingActivities ?
            <div className='circular_loading_spinner__wrapper'><CircularProgress color='inherit' size="2rem" /></div> :
            activities.length != 0 ?
              <>
                <div className="table__rows_container--scroll-auto no-scrollbar">
                  {
                    activities.map((activity, index) => {

                      let isOverdue = false
                      if (new Date(activity.planned_date) < now & !activity.done) {
                        isOverdue = true
                      }

                      return (
                        <div key={index}>
                          <div className={`table__row-4 ${isOverdue ? "overdue" : activity.done ? "done" : ""}`} id='checkmark-row' data-activity-id={activity.id} onClick={handleClickRow}>
                            <div className="table__row--field flex--center"><img src={activity_types[activity.type]?.picto} /></div>
                            <div className="table__row--field">{activity.customer_id.business_name}</div>
                            <div className="table__row--field">{activity.title}</div>
                            <div className="table__row--field">{activity.sales_rep_id.first_name} {activity.sales_rep_id.last_name}</div>
                          </div>
                          <div className="activity__detail hide">
                            <div className={`activity__detail--comment ${activity.comment == "" ? "hide" : ""}`}>
                              <h3>Comment</h3>
                              {activity.comment}
                            </div>
                            {
                              activity.done &&
                              activity.report.map((report, index) => (
                                <div key={index}>
                                  <h3>Report for {report.brandowner_id.business_name}</h3>
                                  {report.report}
                                </div>
                              ))
                            }
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              </> :
              <div className="no_task__container">
                <img src={noTaskPicto} />
                <p>No more tasks for today!</p>
              </div>
        }
      </div>

    </div>
  )
}

export default TodoToday