import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux"
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'
import "./SearchBarFilter.css"

function SearchBarFilter({ id, filters, updateFilters, placeholder, stateKey, stateType }) {
	const [searchWord, setSearchWord] = useState(filters.searchWord)
	const [debouncedSearchWord, setDebouncedSearchWord] = useState(searchWord)

	const dispatch = useDispatch()

	useEffect(() => {
		setSearchWord(filters.searchWord)
	}, [filters])

	// Debouncing logic
	useEffect(() => {
		const timerId = setTimeout(() => {
			setDebouncedSearchWord(searchWord)
		}, 750) // 750ms delay

		return () => {
			clearTimeout(timerId)
		}
	}, [searchWord])

	// Set redux state after debouncing
	useEffect(() => {

		if (stateType == 'redux') {
			const updatedFilters = { ...filters }
			updatedFilters[stateKey] = debouncedSearchWord
			dispatch(updateFilters(updatedFilters))
		}

		if (stateType == 'usestate-object') {
			const updatedFilters = { ...filters }
			updatedFilters[stateKey] = debouncedSearchWord
			updateFilters(updatedFilters)
		}

	}, [debouncedSearchWord])

	const handleSearchWord = (event) => {
		const rawValue = event.target.value
		const normalizedValue = rawValue.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
		setSearchWord(normalizedValue)
	}

	const handleClearSearchBar = () => {
		if (filters.searchWord == "") {
			return
		} else {
			setSearchWord("")

			if (stateType == 'redux') {
				const updatedFilters = { ...filters }
				updatedFilters[stateKey] = ""

				dispatch(updateFilters(updatedFilters))
			}

			if (stateType == 'usestate-object') {
				const updatedFilters = { ...filters }
				updatedFilters[stateKey] = ""
				updateFilters(updatedFilters)
			}
		}
	}

	return (
		<div className={`search-bar--filter ${searchWord ? "filter_active" : ""}`} id={id} onClick={handleClearSearchBar}>
			<input placeholder={placeholder} value={searchWord} onChange={handleSearchWord} />
			{filters.searchWord == "" ? <SearchIcon /> : <ClearIcon />}
		</div>
	)
}

SearchBarFilter.defaultProps = {
	placeholder: "Search...",
	stateType: "redux",
	stateKey: "searchWord"
}

export default SearchBarFilter