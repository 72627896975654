import { persistStore } from 'redux-persist'
import { store } from '../../store/store'

export const purgePersist = () => {
  const persistor = persistStore(store)
  persistor.purge().then(() => {
    console.log('Persisted state has been purged.')
  }).catch(err => {
    console.error('Failed to purge persisted state:', err)
  })
}