import React, { useState, useEffect } from 'react'
import './AdminInvoicePage.css'
import httpClient from "../httpClient"
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux"
import { addUserInfo } from "../store/user"
import Header from "../components/Header"
import MenuBar from "../components/MenuBar"
import AdminInvoice from "../components/AdminInvoice"
import AdminInvoiceAnnexe from "../components/AdminInvoiceAnnexe"
import html2pdf from 'html2pdf.js'

function AdminInvoicesPage() {

  const [user, setUser] = useState({ email: "", id: "" })
  const [error, setError] = useState(false)
  const [invoice, setInvoice] = useState(null)

  const dispatch = useDispatch()
  const userInfo = useSelector(state => state.user.userInfo)

  const { invoiceID } = useParams()

  const fetchInvoice = async () => {
    const responseInvoice = await httpClient.get(process.env.REACT_APP_API_URL + "/api/admin/get-invoice/" + invoiceID)
    setInvoice(responseInvoice.data)
  }

  useEffect(() => {
    fetchInvoice()
      .catch(err => {
        console.log(err)
      })
  }, [])

  const generatePDF = () => {
    const invoiceElement  = document.getElementById('invoice-admin')
    const invoiceAnnexeElement  = document.getElementById('invoice-admin-annexe')

    const options = {
      filename: `STOQUP_invoice_${invoice.invoice_number}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 3 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    }

    const element = document.createElement('div');
    element.appendChild(invoiceElement .cloneNode(true));
    element.appendChild(invoiceAnnexeElement .cloneNode(true));

    html2pdf().set(options).from(element).save();
  }

  return (
    <div className="template">
      {error ? window.location.href = "/" : null}
      {userInfo.type != "admin" ? window.location.href = "/" : null}

      <Header>
        <button className="green-button-header" onClick={generatePDF}>SAVE TO PDF</button>
      </Header>
      <MenuBar />
      <div className="template__body" id='template__body-invoice_page--admin'>
        <div className='body__admin-invoices-page'>
          <div className="body__invoice-admin">
            <AdminInvoice id={invoiceID} />
          </div>
          <div className="body__invoice-admin--annexe">
            <AdminInvoiceAnnexe id={invoiceID} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminInvoicesPage