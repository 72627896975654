import React, { useState, useEffect } from 'react'
import httpClient from '../httpClient'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import './BestSellers.css'
import LoadingSpinner from './placeholders/LoadingSpinner'
import CircularProgress from '@mui/material/CircularProgress'

import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement } from 'chart.js'
Chart.register(ArcElement);

function BestSellers() {
  const [bestsellers, setBestsellers] = useState([])
  const [loading, setLoading] = useState(true)

  const dispatch = useDispatch()
  const isMobile = useSelector(state => state.global.isMobile)
  const userInfo = useSelector(state => state.user.userInfo)

  const fetchBestsellers = async () => {
    setLoading(true)

    const responseOrderTotals = await httpClient.get(process.env.REACT_APP_API_URL + `/api/orders/report?type=bestsellers`)
    setBestsellers(responseOrderTotals.data)

    setLoading(false)
  }

  useEffect(() => {
    fetchBestsellers()
  }, [])


  const labels = (
    bestsellers.map(item => (
      item[0]
    ))
  )

  const data = (
    bestsellers.map(item => (
      item[1]
    ))
  )

  const chartData = {
    type: 'doughnut',
    labels: labels,
    datasets: [
      {
        data: data,
        backgroundColor: [
          'rgba(85, 193, 178, 1)',
          'rgba(85, 193, 178, 0.75)',
          'rgba(85, 193, 178, 0.5)',
          'rgba(85, 193, 178, 0.25)',
        ],
      },
    ],
  }

  const chartDataMobile = {
    type: 'doughnut',
    labels: labels,
    datasets: [
      {
        data: data,
        backgroundColor: [
          'rgba(85, 193, 178, 1)',
          'rgba(85, 193, 178, 0.75)',
          'rgba(85, 193, 178, 0.5)',
          'rgba(85, 193, 178, 0.25)',
        ],
        borderColor: [
          'rgba(235, 235, 235, 1)',
          'rgba(235, 235, 235, 1)',
          'rgba(235, 235, 235, 1)',
          'rgba(235, 235, 235, 1)',
        ]
      },
    ],
  }

  const chartOptions = {
    plugins: {
      legend: {
        display: false,
      },
    },
  }

  return (
    <div className={`body__section_content__wrapper ${isMobile ? "mobile" : ""}`} id='bestsellers-wrapper'>
      <h2 className="flex">bestsellers<p> (all time)</p></h2>

      <div className="bestsellers_doughnut">
        {
          loading ?
            <div className='circular_loading_spinner__wrapper'><CircularProgress color='inherit' size="2rem" /></div>
            :
            <>
              {/* {
                !isMobile && (
                  <div className="podium__container">
                    {
                      bestsellers.length == 0 ?
                        <p>No sales recorded yet</p> :
                        Array.from(bestsellers).map((product, index) => {
                          return (
                            <div key={index} className="podium__element">
                              <div className="podium__element--product">{product[0]}</div>
                              <div className="podium__element--amount">{product[1]}</div>
                            </div>
                          )
                        })
                    }
                  </div>
                )
              } */}
              {
                bestsellers.length == 0 ?
                  <p>No sales recorded yet</p> :
                  <div className={`doughnut_container ${isMobile && "is_mobile"}`}>
                    <Doughnut data={isMobile ? chartDataMobile : chartData} options={chartOptions} />
                  </div>
              }
            </>
        }
      </div>
    </div>
  )
}

export default BestSellers