import { isEqual } from 'lodash'

export function changeThemeColor(color) {
  const metaThemeColor = document.querySelector('meta[name=theme-color]')
  if (metaThemeColor) {
    metaThemeColor.setAttribute('content', color)
  } else {
    // If the meta tag doesn't exist, create it.
    const head = document.getElementsByTagName('head')[0]
    const meta = document.createElement('meta')
    meta.name = 'theme-color'
    meta.content = color
    head.appendChild(meta)
  }
}

export const urlBase64ToUint8Array = (base64String) => {
  const padding = '='.repeat((4 - base64String.length % 4) % 4)
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/')

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

export const normalizeString = (string) => {
  if (!string) {
    return null
  }

  // Normalize for cross-device compatibility
  const normalized = string.normalize('NFD')

  // Remove diacritics (special characters)
  const noDiacritics = normalized.replace(/[\u0300-\u036f]/g, "")

  // Remove spaces and apostrophes
  const noSpacesOrApostrophes = noDiacritics.replace(/['\u2018\u2019]/g, "").replace(/\s+/g, "")

  // Convert to lower case
  return noSpacesOrApostrophes.toLowerCase()
}

export const deepEqual = (obj1, obj2) => isEqual(obj1, obj2)