import React, { useState, useEffect, useRef } from 'react'
import httpClient from '../../httpClient'
import { useSelector, useDispatch } from 'react-redux'
import './PopupwindowOrderDetailMobile.css'
import { PiCaretLeftThin } from "react-icons/pi"
import DoneIcon from '@mui/icons-material/Done'
import { RxCross1 } from "react-icons/rx"
import CircularProgress from '@mui/material/CircularProgress'
import AlertPopupMobile from './AlertPopupMobile'
import CheckBox from '../CheckBox'
import ActionPopupMobile from './ActionPopupMobile'

function PopupwindowOrderDetailMobile({ id, isActive, setIsActive, selectedOrderID, orderListType, user, fetchOrders, orders }) {
  const [selectedOrder, setSelectedOrder] = useState(null)
  const [loadingProgressStep, setLoadingProgressStep] = useState({
    "Ready for delivery": false,
    "Delivered": false,
    "Invoiced": false,
  })
  const [loadingAccept, setLoadingAccept] = useState(false)
  const [alertType, setAlertType] = useState("notification")
  const [alertMessage, setAlertMessage] = useState("")
  const [alertPopupIsActive, setAlertPopupIsActive] = useState(false)
  const [warehouses, setWarehouses] = useState([])
  const [progressStageToSet, setProgressStageToSet] = useState(null)
  const [actionPopupIsActive, setActionPopupIsActive] = useState(false)

  const dispatch = useDispatch()
  const userInfo = useSelector(state => state.user.userInfo)
  const thisPopupContainerRef = useRef(null)
  const saveButtonRef = useRef(null)
  const toAcceptStatus = !selectedOrder?.products.every(product => product.readyForDelivery == true)

  const fetchWarehouses = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/brandowner/warehouses")
    const data = response.data

    const newWarehousesArray = data.map(warehouse => {
      return (
        {
          'id': warehouse.id,
          'name': warehouse.name
        }
      )
    })
    setWarehouses(newWarehousesArray)
  }

  useEffect(() => {
    fetchWarehouses()
  }, [])

  useEffect(() => {
    const order = orders.find(order => order.id == selectedOrderID)
    setSelectedOrder(order)
  }, [orders, selectedOrderID])

  const handleClosePopup = () => {
    setIsActive(false)
  }

  const handleClickProgressStep = async (progressStage, warehouseID) => {
    const orderID = selectedOrder.id
    const thisOrder = selectedOrder
    setProgressStageToSet(progressStage)
    let selectedWarehouseID = warehouseID

    // if loading in progress, return
    if (loadingProgressStep[progressStage]) {
      return
    }

    // if user is pro & supplier is Stoqup, give a warning
    if (
      user.is_pro_account &
      user.type != "admin" &
      thisOrder.supplier == "STOQUP" &
      (progressStage == "Ready for delivery" | progressStage == "Delivered")
    ) {
      setAlertType("error")
      setAlertMessage("Order delivery is controlled by STOQUP")
      setAlertPopupIsActive(true)

      return
    }

    if (!selectedWarehouseID) {
      if (warehouses.length > 1) {
        setActionPopupIsActive(true)
        return
      } else {
        selectedWarehouseID = warehouses[0].id
      }
    }

    const newLoadingObj = { ...loadingProgressStep }
    newLoadingObj[progressStage] = true
    setLoadingProgressStep(newLoadingObj)

    const data = {
      orderIDs: [orderID]
    }

    const response = await httpClient.post(process.env.REACT_APP_API_URL + `/api/brandowner/order/change-status?progressStage=${progressStage}&warehouseID=${selectedWarehouseID}`, data)
    console.log(response.data)

    fetchOrders()
      .then(() => {
        const newLoadingObj = { ...loadingProgressStep }
        newLoadingObj[progressStage] = false
        setLoadingProgressStep(newLoadingObj)
      })
  }

  const handleDeliveryStateProduct = async (orderItemID) => {
    const orderLine = selectedOrder.products.find(orderLine => orderLine.order_item_id == orderItemID)
    if (orderLine?.supplier == "STOQUP") {
      setAlertType("error")
      setAlertMessage("Order delivery is controlled by STOQUP, changes have not been saved")
      setAlertPopupIsActive(true)

      return
    }

    const reponse = await httpClient.get(process.env.REACT_APP_API_URL + "/api/brandowner/order/change-status-to-delivered?orderItemID=" + orderItemID)
    console.log(reponse.data)
  }

  const handleOnclickActionButtonNew = async (event) => {
    const type = event.currentTarget.getAttribute("data-type")
    const orderID = event.currentTarget.getAttribute("data-order-id")

    if (!user.is_pro_account & (type == "alwaysStoqup" | type == "stoqup")) {
      setAlertType("error")
      setAlertMessage("You do not have an active STOQUP distribution account.")
      setAlertPopupIsActive(true)
      return
    }

    setLoadingAccept(true)

    const orderItemIDs = orders.filter(order => order.id == orderID).flatMap((item) =>
      item.products.map((product) => product.order_item_id)
    )

    const data = {
      orderID,
      orderItemIDs
    }

    const response = await httpClient.post(`${process.env.REACT_APP_API_URL}/api/brandowner/order/accept?type=${type}&orderID=${orderID}`, data)
    console.log(response.data)

    fetchOrders()
      .then(() => {
        setLoadingAccept(false)
      })
  }

  const handleClickWarehouseItem = (warehouseID) => {
    setActionPopupIsActive(false)

    handleClickProgressStep(progressStageToSet, warehouseID)
  }

  return (
    <div className={`popup_window__container order-detail ${isActive ? "active" : ""}`} id={id} ref={thisPopupContainerRef}>

      <AlertPopupMobile
        type={alertType}
        message={alertMessage}
        isActive={alertPopupIsActive}
        setIsActive={setAlertPopupIsActive}
      />

      <ActionPopupMobile isActive={actionPopupIsActive} popupID="warehouse-select-popup-noti">
        <div className="notification-popup__elements--mobile">
          <div className="notification-popup__elements__title--mobile">
            <h3>Select the warehouse:</h3>
          </div>
          <div className="notification-popup__elements__text--mobile">
            {
              warehouses.map((warehouse, index) => {
                return (
                  <div className="notification-popup__elements__text_item--mobile" key={index} onClick={() => handleClickWarehouseItem(warehouse.id)}>{warehouse.name}</div>
                )
              })
            }
          </div>
        </div>
      </ActionPopupMobile>

      <div className='popup_header'>
        <div className='go_back' onClick={handleClosePopup}><PiCaretLeftThin /></div>
        {/* <button className='green-button-header' ref={saveButtonRef}>SAVE</button> */}
      </div>
      <div className='popup_body'>
        {
          loadingAccept ?
            <div className='circular_loading_spinner__wrapper'><CircularProgress color='inherit' size="2rem" /></div> :

            !toAcceptStatus | user.type != "brandonwer" ?
              <>
                <div className='progress__trackers_container'>
                  <div className="progress__tracker_wrapper">
                    <p>Ready for delivery</p>
                    {
                      selectedOrder?.internal_order ?
                        <div className={selectedOrder?.products.every(product => product.readyForDelivery == true) ? "progress__tracker--first" : "progress__tracker--first unfinished"} onClick={() => handleClickProgressStep("Ready for delivery", selectedOrder?.products[0].warehouse_id?.id)} data-progressstage={"Ready for delivery"}>
                          <div className="progress__tracker_circle">
                            {
                              loadingProgressStep["Ready for delivery"] ?
                                <CircularProgress color='inherit' size="1.25rem" /> :
                                <DoneIcon className={!selectedOrder?.products.every(product => product.readyForDelivery == true) ? "faded-away" : "visible"} />
                            }
                          </div>
                        </div> :
                        <div className={"progress__tracker--first disabled"} disabled>
                          <div className="progress__tracker_circle"><RxCross1 className="disabled_icon" /></div>
                        </div>
                    }
                  </div>
                  <div className="progress__tracker_wrapper">
                    <p>Delivered</p>
                    {
                      selectedOrder?.internal_order ?
                        <div className={selectedOrder?.products.every(product => product.delivered == true) ? `progress__tracker${userInfo.type == "salesforce" ? "--last" : ""}` : `progress__tracker${userInfo.type == "salesforce" ? "--last" : ""} unfinished`} onClick={() => handleClickProgressStep("Delivered", selectedOrder?.products[0].warehouse_id?.id)} data-progressstage={"Delivered"}>
                          <div className="progress__tracker_circle">
                            {
                              loadingProgressStep["Delivered"] ?
                                <CircularProgress color='inherit' size="1.25rem" /> :
                                <DoneIcon className={!selectedOrder?.products.every(product => product.delivered == true) ? "faded-away" : "visible"} />
                            }
                          </div>
                        </div> :
                        <div className={`progress__tracker disabled`} disabled>
                          <div className="progress__tracker_circle"><RxCross1 className="disabled_icon" /></div>
                        </div>
                    }

                  </div>

                  {
                    userInfo.type == "brandowner" &&
                    <div className="progress__tracker_wrapper">
                      <p>Invoiced</p>
                      {
                        selectedOrder?.internal_order ?
                          <div className={selectedOrder?.products.every(product => product.invoiced == true) ? "progress__tracker--last" : "progress__tracker--last unfinished"} onClick={() => handleClickProgressStep("Invoiced", selectedOrder?.products[0].warehouse_id?.id)} data-progressstage={"Invoiced"}>
                            <div className="progress__tracker_circle">
                              {
                                loadingProgressStep["Invoiced"] ?
                                  <CircularProgress color='inherit' size="1.25rem" /> :
                                  <DoneIcon className={selectedOrder?.products.every(product => product.invoiced == true) ? "visible" : "faded-away"} />
                              }
                            </div>
                          </div> :
                          <div className={"progress__tracker--last disabled"} disabled>
                            <div className="progress__tracker_circle"><RxCross1 className="disabled_icon" /></div>
                          </div>

                      }
                    </div>
                  }

                </div>
              </> :
              <>
                {
                  userInfo.type == "brandowner" &&
                  <div className='action_buttons--mobile'>
                    <button className="green-button-action" data-type="accept" data-order-id={selectedOrder?.id} onClick={handleOnclickActionButtonNew}>Accept</button>
                    <button className="red-button-action" data-type="decline" data-order-id={selectedOrder?.id} onClick={handleOnclickActionButtonNew}>Decline</button>
                    <button className="gray-button-action" data-type="stoqup" data-order-id={selectedOrder?.id} onClick={handleOnclickActionButtonNew}>Send to STOQUP</button>
                    <button className="gray-button-action" data-type="alwaysStoqup" data-order-id={selectedOrder?.id} onClick={handleOnclickActionButtonNew}>Always to STOQUP</button>
                  </div>
                }
              </>


        }

        <h2>Order</h2>

        <div className='order_detail_container'>
          {
            selectedOrder &&
            selectedOrder.products.map((orderLine, index) => (
              <div className='order_line' key={index}>
                <div id='quantity'>{orderLine.quantity}</div>
                <div id='product'>{orderLine.product} {orderLine.volume}</div>
                <div id='delivery'>
                  <CheckBox
                    setData={handleDeliveryStateProduct}
                    index={orderLine.order_item_id}
                    text={"Delivered"}
                    style={"mobile sales"}
                    state={orderLine.delivered}
                    textPosition={"left"}
                    margin={"0"}
                    width={25}
                    height={25}
                  />
                </div>
                <div id='supplier'>{orderLine.supplier}</div>
                <div id='comment'>{orderLine.comment ? orderLine.comment : "No comment"}</div>
              </div>
            ))
          }
        </div>

        <h2>Contact</h2>

        <input className='input_text--mobile' type='text' placeholder='Business name' value={selectedOrder?.customer.business_name} disabled />
        <div className='input_field--combo-5050--mobile'>
          <input className='input_text--mobile' type='text' placeholder='First name' value={selectedOrder?.customer.first_name} disabled />
          <input className='input_text--mobile' type='text' placeholder='Last name' value={selectedOrder?.customer.last_name} disabled />
        </div>
        <input className='input_text--mobile' type='text' placeholder='Email' value={selectedOrder?.customer.email} disabled />
        <input className='input_text--mobile' type='text' placeholder='Tel' value={selectedOrder?.customer.tel_number} disabled />

        <h2>Delivery</h2>

        <div className='input_field--combo-7030--mobile'>
          <input className='input_text--mobile' type='text' placeholder='Street' value={selectedOrder?.customer.delivery_address_street} disabled />
          <input className='input_text--mobile' type='text' placeholder='Nr' value={selectedOrder?.customer.delivery_address_nr} disabled />
        </div>
        <div className='input_field--combo-3070--mobile'>
          <input className='input_text--mobile' type='text' placeholder='Street' value={selectedOrder?.customer.delivery_address_pc} disabled />
          <input className='input_text--mobile' type='text' placeholder='Nr' value={selectedOrder?.customer.delivery_address_place} disabled />
        </div>
        <input className='input_text--mobile' type='text' placeholder='Nr' value={selectedOrder?.customer.delivery_address_country} disabled />

        <h2>Invoicing</h2>

        <input className='input_text--mobile' type='text' placeholder='Company name' value={selectedOrder?.customer.company_name} disabled />
        <input className='input_text--mobile' type='text' placeholder='VAT' value={selectedOrder?.customer.VAT_number} disabled />
        <div className='input_field--combo-7030--mobile'>
          <input className='input_text--mobile' type='text' placeholder='Street' value={selectedOrder?.customer.invoice_address_street} disabled />
          <input className='input_text--mobile' type='text' placeholder='Nr' value={selectedOrder?.customer.invoice_address_nr} disabled />
        </div>
        <div className='input_field--combo-3070--mobile'>
          <input className='input_text--mobile' type='text' placeholder='Street' value={selectedOrder?.customer.invoice_address_pc} disabled />
          <input className='input_text--mobile' type='text' placeholder='Nr' value={selectedOrder?.customer.invoice_address_place} disabled />
        </div>
        <input className='input_text--mobile' type='text' placeholder='Nr' value={selectedOrder?.customer.invoice_address_country} disabled />




      </div>
    </div>
  )
}

export default PopupwindowOrderDetailMobile

PopupwindowOrderDetailMobile.defaultProps = {
  id: "popup-window-mobile,"
}

