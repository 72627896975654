import { createSlice } from "@reduxjs/toolkit";

export const globalSlice = createSlice({
    name: "global",
    initialState: {
      isMobile: false,
    },
    reducers: {
      setIsMobile: (state, action) => {
        state.isMobile = action.payload
      },

      // Your existing reducer for other global updates
      updateGlobalState: (state, action) => {
        Object.assign(state, action.payload)
      },
    }
})

export const { updateGlobalState, setIsMobile } = globalSlice.actions
export default globalSlice.reducer