import { createSlice } from "@reduxjs/toolkit";

export const salesPipePageSlice = createSlice({
  name: "salesPipePageState",
  initialState: {
    filters: {
      page: 1,
      hideOwnerless: false,
      searchWord: "",
      tagFilter: "",
      typeFilter: "",
      subTypeFilter: "",
      dateFilter: null,
    },
    needsRefresh: false,
    salesPipe: {},
    salesPipeCounts: {}
  },
  reducers: {
    updateFilters: (state, action) => {
      state.filters = action.payload
    },
    updateSalesPipe: (state, action) => {
      state.salesPipe = action.payload
    },
    updateSalesPipeCounts: (state, action) => {
      state.salesPipeCounts = action.payload
    },
    updateSalesPipeNeedsRefresh: (state, action) => {
      state.needsRefresh = action.payload
    },
    resetStateSalesPipePage: (state) => {
      return {
        ...state,
        filters: {
          page: 1,
          hideOwnerless: false,
          searchWord: "",
          tagFilter: "",
          typeFilter: "",
          subTypeFilter: "",
          dateFilter: null,
        },
        needsRefresh: false,
        salesPipe: {},
        salesPipeCounts: {},
      }
    },
    resetStateSalesPipe: (state) => {
      return {
        ...state,
        salesPipe: {},
        salesPipeCounts: {},
      }
    },
  },
})

export const { 
  updateFilters, 
  updateSalesPipe, 
  updateSalesPipeCounts,
  resetStateSalesPipePage,
  resetStateSalesPipe,
  updateSalesPipeNeedsRefresh
} = salesPipePageSlice.actions
export default salesPipePageSlice.reducer