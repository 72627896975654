import React, { useState, useEffect } from "react"
import httpClient from "../httpClient"
import { useSelector, useDispatch } from "react-redux"
import './SubscriptionPlansPage.css'
import Header from "../components/Header"
import MenuBar from "../components/MenuBar"
import ComingSoonOverlay from "../components/placeholders/ComingSoonOverlay"
import VerifiedIcon from '@mui/icons-material/Verified';
import { NotificationPopupMessage, showNotificationPopupMessage } from "../components/NotificationPopupMessage"
import { changeNotificationMessage } from "../store/notification"
import { NotificationPopup, showNotificationPopup, closePopupNotification } from "../components/NotificationPopup"
import Slider from "../components/Slider"

function _BoilerplatePage() {
  const [user, setUser] = useState({ email: "", id: "" })
  const [error, setError] = useState(false)
  const [subscription, setSubscription] = useState([])
  const [currentSubscriptionIndex, setCurrentSubscriptionIndex] = useState()
  const [subscriptionUpradeToType, setSubscriptionUpradeToType] = useState("")
  const [subscriptionTypeYearly, setSubscriptionTypeYearly] = useState(false)

  const dispatch = useDispatch()

  const userInfo = useSelector(state => state.user.userInfo)

  const subscriptions = {
    "STARTER": {
      "name": "Starter",
      "price": "95",
      "features": [
        "Warehouse space: 3 shelves",
        "Inbounds: 1/month",
        "Shipping rates: See rates",
        "Service level: Whatsapp",
      ]
    },
    "PROFESSIONAL": {
      "name": "Professional",
      "price": "130",
      "features": [
        "Warehouse space: 2 pallets",
        "Inbounds: 1/month",
        "Shipping rates: See rates",
        "Service level: Whatsapp",
      ]
    },
    "COMPANY": {
      "name": "Company",
      "price": "220",
      "features": [
        "Warehouse space: 5 pallets",
        "Inbounds: 1/month",
        "Shipping rates: See rates",
        "Service level: Whatsapp+",
      ]
    },
    "ENTERPRISE": {
      "name": "Enterprise",
      "price": "380",
      "features": [
        "Warehouse space: 15 pallets",
        "Inbounds: 1/month",
        "Shipping rates: See rates",
        "Service level: Whatsapp+",
      ]
    }
  }

  const fetchUser = async () => {
    const responseUser = await httpClient.get(process.env.REACT_APP_API_URL + "/api/@me?type=full")
    setUser(responseUser.data)
  }

  const fetchSubscription = async () => {
    const responseSubscription = await httpClient.get(process.env.REACT_APP_API_URL + "/api/brandowner/get-subscription")
    setSubscription(responseSubscription.data)
    switch (responseSubscription.data.distribution_subscription_type) {
      case "STARTER":
        setCurrentSubscriptionIndex(0)
        break;
      case "PROFESSIONAL":
        setCurrentSubscriptionIndex(1)
        break;
      case "COMPANY":
        setCurrentSubscriptionIndex(2)
        break;
      case "ENTERPRISE":
        setCurrentSubscriptionIndex(3)
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    fetchUser()
      .catch(err => {
        console.log(err)
        setError(true)
      })

    fetchSubscription()
      .catch(err => {
        console.log(err)
        setError(true)
      })

  }, [])

  const SubscriptionPlan = (props) => {

    const Feature = (props) => {
      return (
        <div className="features__feature">
          <VerifiedIcon />
          <h3>{props.text}</h3>
        </div>
      )
    }

    const clickUpgrade = () => {
      setSubscriptionUpradeToType(Object.keys(subscriptions)[props.index])
      showNotificationPopup({
        title: "Upgrade subscription",
        message: "Are you sure you want to upgrade your subscription plan? This is irreversible and will be charged on your next invoice.",
        actionButtonOnClick: () => handleUpgrade(),
        actionButtonText: "Yes, upgrade",
      })
    }

    const handleDowngrade = () => {
      dispatch(changeNotificationMessage("Please contact us to downgrade your subscription plan at hello@stoqup.eu"))
      showNotificationPopupMessage()
    }

    return (
      <div className={`subscription_plan__container ${props.current ? "current" : null}`}>
        <div className="subscription_plan__header">
          <h1>{props.title}</h1>
        </div>

        <div className="subscription_plan__price">
          <h2>€{subscriptionTypeYearly ? props.price * 12 * 0.9 : props.price}</h2>
          <h3>{subscriptionTypeYearly ? "per year" : "per month"}</h3>
          <p>{subscriptionTypeYearly ? "Save 10%!" : ""}</p>
        </div>

        <div className="subscription_plan__features">
          {
            props.features.map((feature, index) => {
              return (
                <Feature key={index} text={feature} />
              )
            })
          }
        </div>

        <div className="subscription_plan__button">
          {
            !props.current ? 
              props.index < currentSubscriptionIndex ?
                <button className="downgrade" onClick={handleDowngrade}>Downgrade</button>
              :
              <button onClick={clickUpgrade}>Upgrade</button> 
            : null
          }
        </div>
      </div>
    )
  }

  const handleUpgrade = async () => {
    const data = {
      "subscription_type": subscriptionUpradeToType,
      "yearly": subscriptionTypeYearly
    }
    const response = await httpClient.post(process.env.REACT_APP_API_URL + "/api/brandowner/subscription/upgrade", data)
    console.log(response.data)
    closePopupNotification()
    fetchSubscription()
  }

  return (
    <div className="template">
      {error ? window.location.href = "/" : null}

      <Header />
      <MenuBar />

      <NotificationPopupMessage />

      <div className="template__body">
        <div className="yearly_slider">
          <Slider
            text="See yearly plans"
            defaultState={false}
            onClickFunction={() => setSubscriptionTypeYearly(!subscriptionTypeYearly)}
            fontSize="1rem"
            size={1.5}
          />
        </div>
        <div className="body__subscription_plans">
          {
            Object.keys(subscriptions).map((key, index) => {
              return (
                <SubscriptionPlan
                  key={index}
                  title={subscriptions[key].name}
                  price={subscriptions[key].price}
                  features={subscriptions[key].features}
                  index={index}
                  current={currentSubscriptionIndex === index}
                />
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

export default _BoilerplatePage