import React, { useState, useEffect } from 'react'
import './PopOutWindowCreateProduct.css'
import httpClient from '../httpClient'
import CloseIcon from '@mui/icons-material/Close'
import ImageUploader from "./ImageUploader"
import Slider from './Slider'
import { toast } from 'react-toastify'
import { showNotificationPopup, closePopupNotification } from './NotificationPopup'

export function PopOutWindowCreateProduct({
  popupID, isActive, setIsActive, onClose, maxHeight, isUpdate, fetchProducts, selectedProduct, resetProductsState
}) {
  const [newProductObj, setNewProductObj] = useState({})
  const [imageUrl, setImageUrl] = useState()

  useEffect(() => {
    const popup = document.getElementById(popupID)
    window.addEventListener('keydown', e => {
      if (e.key === 'Escape') {
        closePopupWindow()
      }
    })
  }, [])

  useEffect(() => {
    if (!isUpdate) {
      const resetState = () => {
        return Object.keys(selectedProduct).reduce((acc, key) => {
          if (typeof selectedProduct[key] === 'string') {
            acc[key] = ''
          } else if (typeof selectedProduct[key] === 'number') {
            acc[key] = 0
          } else if (typeof selectedProduct[key] === 'boolean') {
            acc[key] = false
          }
          return acc
        }, {})
      }

      setNewProductObj(resetState())
      setImageUrl(null)
    } else {
      let htmlString = selectedProduct["description"]

      // Use the DOMParser to parse the HTML string
      let parser = new DOMParser()
      let doc = parser.parseFromString(htmlString, "text/html")

      // Extract the text content, effectively removing the HTML tags
      let textOnly = doc.body.textContent || ""


      if (textOnly) {
        selectedProduct["description"] = textOnly
      } else {
        selectedProduct["description"] = ""
      }

      setNewProductObj(selectedProduct)
    }
  }, [selectedProduct, isUpdate])

  const closePopupWindow = () => {
    setIsActive(false)
    if (onClose) {
      onClose()
    }
  }

  const handleSetProduct = (e) => {
    const key = e.currentTarget.getAttribute('name')
    const value = e.currentTarget.type === 'number' ? parseInt(e.currentTarget.value) || 0 : e.currentTarget.value

    setNewProductObj(prevState => ({ ...prevState, [key]: value }))
  }

  const handleSaveProduct = async () => {
    if (!newProductObj.name || !newProductObj.volume || !newProductObj.sku) {
      toast.error("Name, sku and volume are obligatory", { theme: "dark" })
      return
    }

    try {
      if (isUpdate) {
        const response = await httpClient.put(process.env.REACT_APP_API_URL + "/api/brandowner/products", newProductObj)

        if (response.status === 200) {
          toast.success(`Product ${newProductObj.name} has been updated!`)
        } else if (response.status === 404) {
          toast.error(`Product not found`, { theme: "dark" })
        }
      } else {
        await toast.promise(httpClient.post(process.env.REACT_APP_API_URL + "/api/brandowner/products/add", newProductObj),
          {
            success: `Product ${newProductObj.name} has been created!`,
            pending: `Creating ${newProductObj.name}...`,
            error: "Something went wrong... 😢"
          }
        )
      }

      const resetState = () => {
        return Object.keys(newProductObj).reduce((acc, key) => {
          if (typeof newProductObj[key] === 'string') {
            acc[key] = ''
          } else if (typeof newProductObj[key] === 'number') {
            acc[key] = 0
          } else if (typeof newProductObj[key] === 'boolean') {
            acc[key] = false
          }
          return acc
        }, {})
      }

      setNewProductObj(resetState())
      setImageUrl(null)
      fetchProducts()
      setIsActive(false)
    } catch (error) {
      toast.error(error.response?.data || "Something went wrong...", { theme: "dark" })
    }
  }

  const setSlider = (id, value) => {
    setNewProductObj(prevState => {
      const newState = { ...prevState }

      switch (id) {
        case "product-is-posm":
          newState["is_POSM"] = value
          break
        case "product-is-bio":
          newState["is_bio"] = value
          break
        case "product-is-food":
          newState["is_food"] = value
          break
      }

      return newState
    })
  }

  const handleImageHover = (e) => {
    const imageWrapper = e.currentTarget
    const deleteButton = imageWrapper.querySelector('button')

    deleteButton.classList.toggle('visible')
  }

  const handleDeleteImage = () => {
    setNewProductObj(prevState => ({ ...prevState, direct_link: '' }))
  }

  const deleteProduct = async () => {
    setIsActive(false)

    try {
      await toast.promise(httpClient.post(process.env.REACT_APP_API_URL + "/api/brandowner/products/delete", newProductObj),
        {
          success: `Product ${newProductObj.name} has been deleted!`,
          pending: `Deleting ${newProductObj.name}...`,
          error: "Something went wrong... 😢"
        }
      )
      resetProductsState()
    } catch (error) {
      toast.error(error.response?.data || "Something went wrong...", { theme: "dark" })
    }
  }

  const handleDeleteProduct = async () => {
    showNotificationPopup({
      title: "Are you sure?",
      actionButtonOnClick: () => deleteProduct(),
      actionButtonText: "yes, delete",
      popupButtonColor: "red"
    })
  }

  return (
    <div className={`popout__container ${isActive ? "active" : ""}`} id={popupID}>

      <div className='popout__box' style={{ maxHeight }} id={popupID}>
        <CloseIcon id="popup__close-icon" onClick={closePopupWindow} />

        <div className='popout__header'>
          <div className='popout_buttons'>
            <button className='popup__options-button' id='save-btn-create-activity' onClick={handleSaveProduct}>SAVE</button>
            {
              isUpdate &&
              <button className='red-button-header' onClick={handleDeleteProduct}>DELETE</button>
            }
          </div>

        </div>

        <div className='popout__body'>

          <h2>{isUpdate ? "Update product" : "Add new product"}</h2>

          <div className="form__section">
            <div id='grid-box_one'>
              {
                newProductObj.direct_link ?
                  <div className='image_wrapper' onMouseEnter={handleImageHover} onMouseLeave={handleImageHover}>
                    <button onClick={handleDeleteImage}>Delete</button>
                    <img src={newProductObj.direct_link} />
                  </div>
                  :
                  <ImageUploader
                    id={'image-uploader'}
                    state={newProductObj}
                    setState={setNewProductObj}
                    imageUrl={imageUrl}
                    setImageUrl={setImageUrl}
                  />
              }
            </div>
            <div className="input_field">
              <label className="input_field__label" htmlFor="name">Product name</label>
              <input className="input_field__input_box--text" id="product_name" type="text" name="name" value={newProductObj?.name} onChange={handleSetProduct} />
            </div>
            <div className="input_field">
              <label className="input_field__label" htmlFor="name">SKU</label>
              <input className="input_field__input_box--text" id="product_name" type="text" name="sku" value={newProductObj?.sku} onChange={handleSetProduct} />
            </div>
            <div className='input_field--combo-5050'>
              <div className="input_field">
                <label className="input_field__label" htmlFor="name">Volume (in cl)</label>
                <input className="input_field__input_box--text" id="product_name" type="number" name="volume" value={newProductObj?.volume || 0} onChange={handleSetProduct} />
              </div>
              <div className="input_field">
                <label className="input_field__label" htmlFor="name">Unit</label>
                <input className="input_field__input_box--text" id="product_name" type="text" name="sku_unit" value={newProductObj?.sku_unit} onChange={handleSetProduct} placeholder='Bottle, Box, Pallet, ...' />
              </div>
            </div>

          </div>

          <div className='form__section'>
            <Slider
              state={newProductObj?.isPOSM}
              idNr="product-is-posm"
              text={"Is POSM"}
              size={1.5}
              fontSize={"0.7rem"}
              onClickFunction={setSlider}
              defaultState={newProductObj?.is_POSM}
            />
            <Slider
              state={newProductObj?.isBio}
              idNr="product-is-bio"
              text={"Is bio"}
              size={1.5}
              fontSize={"0.7rem"}
              onClickFunction={setSlider}
              defaultState={newProductObj?.is_bio}
            />
            <Slider
              state={newProductObj?.isFood}
              idNr="product-is-food"
              text={"Is food"}
              size={1.5}
              fontSize={"0.7rem"}
              onClickFunction={setSlider}
              defaultState={newProductObj?.is_food}
            />
          </div>

          <div className="form__section">
            <div className="input_field">
              <label className="input_field__label" htmlFor="description">Description</label>
              <textarea className="input_field__input_box--text" style={{ "minHeight": "100px" }} id="product_description" name="description" value={newProductObj?.description} onChange={handleSetProduct} />
            </div>
          </div>

        </div>

      </div>
    </div>
  )
}

PopOutWindowCreateProduct.defaultProps = {
  overflow: "scroll",
  maxHeight: "75%",
}
