import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux"
import './SearchHeader.css'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'

function SearchHeader({searchWord, setSearchWord, setPage, width, placeholder, stateType, reduxState, filter}) {

  const dispatch = useDispatch()

  const handleSearchWord = (event) => {
    const value = event.target.value

    if (stateType == "redux") {
      const updatedFilters = {...reduxState}
      updatedFilters[filter] = value

      dispatch(setSearchWord(updatedFilters))
    } else {
      setSearchWord(value)
    }
    setPage && setPage(1)
  }

  const handleClearSearchBar = () => {
    if (searchWord == "") {
      return
    }

    if (stateType == "redux") {
      const updatedFilters = {...reduxState}
      updatedFilters[filter] = ''

      dispatch(setSearchWord(updatedFilters))
    } else {
      setSearchWord('')
    }
  }

  return (
    <div className="search-bar-header" onClick={handleClearSearchBar} style={{width}}>
      <input placeholder={placeholder} value={searchWord} onChange={handleSearchWord} />
      {!searchWord ? <SearchIcon /> : <ClearIcon />}
    </div>

  )
}

export default SearchHeader

SearchHeader.defaultProps = {
  stateType: 'normal',
  width: "200px",
  searchWord: "",
  placeholder: "Search ..."
}