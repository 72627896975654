import React from "react";

function ProfilePicFemale2() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
      <circle cx="64" cy="64" r="64" fill="#f7b718"></circle>
      <circle cx="58.5" cy="72.9" r="27.9" fill="#f85863"></circle>
      <path
        fill="#f85863"
        d="M97.7 67c.6 1.8 1 3.7 1 5.7 0 9.3-7.5 16.8-16.8 16.8S65 82 65 72.7c0-8.6 6.4-15.6 14.7-16.7-1.4-1.2-2.5-2.8-3.1-4.6-3.9 7.4-11.6 12.4-20.5 12.4-7.4 0-14-3.5-18.2-8.9-.3.3-.7.6-1.1.9 3.4.7 6 3.7 6 7.4 0 1-.2 2-.6 2.9 6 1.8 10.3 7.4 10.3 14 0 8.1-6.6 14.7-14.7 14.7s-14.7-6.6-14.7-14.7c0-5 2.5-9.4 6.3-12-1.1-1.3-1.8-3-1.8-4.9 0-2.4 1.1-4.5 2.8-5.9-4.5-.6-7.9-4.4-7.9-9 0-5 4.1-9.1 9.1-9.1h.7c.1-2 .7-3.8 1.7-5.4 2.9-9.3 11.7-16.1 22-16.1h.2c.8-4.2 4.5-7.3 8.9-7.3 3.6 0 6.7 2.1 8.2 5.2.9-.2 1.8-.3 2.7-.3 5.7 0 10.5 3.6 12.2 8.7 3.7.7 6.6 3.9 6.6 7.9 0 2.1-.8 4.1-2.2 5.5 3.6 1.9 6 5.7 6 10 0 1.6-.3 3.2-.9 4.5 3.3.9 5.7 3.9 5.7 7.5.1 3.7-2.4 6.7-5.7 7.6z"
      ></path>
      <path
        fill="#01277b"
        d="M94.7 83.7c-3.3-.5-24.8-9.5-53.5-1.6l-5.9 1.6c-7 1.9-11.9 8.3-11.9 15.5v14.1c11 9.2 25.1 14.7 40.6 14.7 16.3 0 31.2-6.1 42.5-16.2V99.3c0-7.3-4.8-13.6-11.8-15.6z"
      ></path>
      <path
        fill="#fff"
        d="M67.1 91.3h-4.4c-5.1 0-9.2-4.1-9.2-9.2v-32c0-5.1 4.1-9.2 9.2-9.2h4.4c5.1 0 9.2 4.1 9.2 9.2V82c.1 5.1-4.1 9.3-9.2 9.3z"
      ></path>
      <path
        fill="#01277b"
        d="M76.4 79V60.1c0-3.1-1.3-6.4-3.4-9.2s-4.9-5-8.1-6.1c-6.3-2.2-11.5 1.2-11.5 7.6v18.9c7.2 9.9 13.4 14.6 23 7.7z"
      ></path>
      <path
        fill="#fff"
        d="M69.4 76.7c-2.8 1.7-6.3 1.7-9 0-13.5-8.2-14.4-18.1-12-33.1.8-5.2 5.6-8.3 10.4-7.8 4.1.4 8.2.4 12.3 0 4.9-.5 9.5 2.7 10.3 7.5 2.6 15.5 1.2 25.3-12 33.4z"
      ></path>
      <g>
        <circle cx="79.7" cy="37" r="10.3" fill="#f85863"></circle>
      </g>
      <g>
        <circle cx="81.2" cy="48.2" r="3.1" fill="#f85863"></circle>
      </g>
      <g>
        <circle cx="82.6" cy="56.1" r="5.1" fill="#fff"></circle>
        <circle cx="47.2" cy="56.1" r="5.1" fill="#fff"></circle>
      </g>
      <g>
        <path
          fill="#01277b"
          d="M56 60c-4.1 0-7.4-3.3-7.4-7.4s3.3-7.4 7.4-7.4 7.4 3.3 7.4 7.4S60.1 60 56 60zm0-14.3c-3.8 0-6.9 3.1-6.9 6.9s3.1 6.9 6.9 6.9 6.9-3.1 6.9-6.9-3.1-6.9-6.9-6.9zM73.8 60c-4.1 0-7.4-3.3-7.4-7.4s3.3-7.4 7.4-7.4 7.4 3.3 7.4 7.4c.1 4.1-3.3 7.4-7.4 7.4zm0-14.3c-3.8 0-6.9 3.1-6.9 6.9s3.1 6.9 6.9 6.9 6.9-3.1 6.9-6.9-3.1-6.9-6.9-6.9z"
        ></path>
        <path
          fill="#01277b"
          d="M66.9 52.6h-.5c0-.8-.7-1.5-1.5-1.5s-1.5.7-1.5 1.5h-.5c0-1.1.9-2 2-2s2 .9 2 2z"
        ></path>
        <g>
          <circle cx="72.9" cy="52.6" r="1.6" fill="#01277b"></circle>
          <circle cx="56" cy="52.6" r="1.6" fill="#01277b"></circle>
        </g>
        <g>
          <path fill="#01277b" d="M56 45.1h17.8v.5H56z"></path>
        </g>
      </g>
      <g>
        <path
          fill="#01277b"
          d="M64.9 69.9c-.4 0-.9 0-1.3-.1-.3-.1-.5-.3-.4-.6s.3-.5.6-.4c.4.1.7.1 1.1.1 2.5 0 4.6-1.6 5.4-3.9.1-.3.4-.4.7-.3s.4.4.3.7c-.9 2.6-3.5 4.5-6.4 4.5z"
        ></path>
      </g>
    </svg>
  );
}

export default ProfilePicFemale2;