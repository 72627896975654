import { createSlice } from "@reduxjs/toolkit";

export const notificationSlice = createSlice({
    name: "notification",
    initialState: "",
    reducers: {
      changeNotificationMessage: (state, action) => {
        return state = action.payload 
      }
    }
})

export const { changeNotificationMessage } = notificationSlice.actions
export default notificationSlice.reducer