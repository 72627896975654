import React, { useState, useEffect } from 'react'
import './PopOutWindowSalesPipeTags.css'
import httpClient from "../httpClient"
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

export const closePopupWindow = (popupID, onClose = null) => {
  const popup = document.getElementById(popupID)
  popup.classList.remove("active")
  onClose != null && onClose()
}

export function PopOutWindowSalesPipeTags({ popupID, onClose, tags, selectedRows, setSelectedRows, refreshData, setRefresh, refresh, selectedStage }) {
  const [tagSearchValue, setTagSearchValue] = useState("")
  const [createTag, setCreateTag] = useState(false)

  const filteredTags = tags.filter(tag => tag.tag.toLocaleLowerCase().includes(tagSearchValue.toLocaleLowerCase()))

  PopOutWindowSalesPipeTags.defaultProps = {
    onClose: () => console.log(""),
  }

  useEffect(() => {
    const popup = document.getElementById(popupID)
    window.addEventListener('keydown', e => {
      if (e.key === 'Escape') {
        if (popup.classList.contains("active")) {
          closePopupWindow()
        }
      }
    })
  }, [])

  const closePopupWindow = () => {
    const popup = document.getElementById(popupID)
    popup.classList.remove("active")
    onClose()
    setCreateTag(false)
    setTagSearchValue("")
  }

  const setTags = async (tagID) => {

    const salesPipeIDs = []

    selectedRows.forEach(element => {
      salesPipeIDs.push(element.getAttribute("data-sales-pipe-id"))
    })

    const data = {
      salesPipeIDs,
      selectedStage,
      tagID
    }

    const response = await httpClient.post(process.env.REACT_APP_API_URL + `/api/sales-pipe/set-tag`, data)
    console.log(response.data)
  }

  const handleOnclickCreateTag = () => {
    setCreateTag(true)
  }

  const handleOnClickTagColor = async (event) => {
    const thisColorElement = event.currentTarget
    const color = thisColorElement.getAttribute("data-color")

    const data = {
      tagName: tagSearchValue,
      tagColor: color
    }

    const response = await httpClient.post(process.env.REACT_APP_API_URL + `/api/sales-pipe/create-tag`, data)

    await setTags(response.data.id)
    setSelectedRows([])
    setCreateTag(false)
    closePopupWindow()
    setRefresh(!refresh)
    refreshData()
  }

  const handleOnClickTag = async (event) => {
    const thisTagElement = event.currentTarget
    const tagID = thisTagElement.getAttribute("data-tag-id")

    await setTags(tagID)
    setSelectedRows([])
    setCreateTag(false)
    closePopupWindow()
    setRefresh(!refresh)
    refreshData()
  }

  return (
    <div className='popout__container' id={popupID}>
      <div className='popout__box no-scrollbar'>
        <CloseIcon id="popup__close-icon" onClick={closePopupWindow} />
        <div className="create_tag_container">
          <input className="input_field__input_box--text big" maxLength="15" placeholder="Search or create tag..." onChange={(event) => setTagSearchValue(event.currentTarget.value)} value={tagSearchValue} />
          <button className={`green-action-button ${filteredTags.length == 0 ? "" : "hide"}`} onClick={handleOnclickCreateTag}>Create tag</button>
        </div>
        <div className={`tag_color_options_container ${createTag ? "" : "hide"}`}>
          <h3>Select tag color:</h3>
          <div className='color_options'>
            <div className='tag_color normal' data-color="normal" onClick={handleOnClickTagColor}></div>
            <div className='tag_color blue' data-color="blue" onClick={handleOnClickTagColor}></div>
            <div className='tag_color green' data-color="green" onClick={handleOnClickTagColor}></div>
            <div className='tag_color red' data-color="red" onClick={handleOnClickTagColor}></div>
            <div className='tag_color gold' data-color="gold" onClick={handleOnClickTagColor}></div>
          </div>
        </div>
        <div className="tag_list">
          <div data-tag-id={null} onClick={handleOnClickTag} className={`tag_list--item delete ${filteredTags.length == 0 ? "hide" : ""}`}>
            <p>Delete tag</p><DeleteOutlineIcon/>
          </div>
          {
            filteredTags
              .map((tag, index) => (
                <div key={index} data-tag-id={tag.id} onClick={handleOnClickTag} className={`tag_list--item ${tag.tag_color_name}`}>
                  {tag.tag}
                </div>
              ))
          }
        </div>
      </div>
    </div>
  )
}