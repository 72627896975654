import React from "react";

function Stop() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      enableBackground="new 0 0 512 512"
      viewBox="0 0 682.667 682.667"
    >
      <defs>
        <clipPath id="a" clipPathUnits="userSpaceOnUse">
          <path
            fill="#1e1e1e"
            d="M0 512h512V0H0z"
            data-original="#000000"
          ></path>
        </clipPath>
      </defs>
      <g clipPath="url(#a)" transform="matrix(1.33333 0 0 -1.33333 0 682.667)">
        <path
          fill="#ffddcf"
          d="M167.84 422.409c0 16.236-13.162 29.398-29.398 29.398-16.237 0-29.399-13.162-29.399-29.398V211.433L75.4 284.728c-6.5 14.162-21.739 22.153-37.085 19.447-18.548-3.271-30.978-20.901-27.827-39.47l8.145-48.003A244.467 244.467 0 0186.968 84.561 118.289 118.289 0 01170.525 50h63.225a110.48 110.48 0 01110.48 110.48v215.194c0 16.237-13.162 29.399-29.398 29.399-16.236 0-29.399-13.162-29.399-29.399v47.318c0 16.236-13.162 29.398-29.398 29.398-16.236 0-29.398-13.162-29.398-29.398v49.61c0 16.236-13.162 29.398-29.399 29.398-16.236 0-29.398-13.162-29.398-29.398v-46.51"
          data-original="#ffddcf"
        ></path>
        <path
          fill="none"
          stroke="#1e1e1e"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="20"
          d="M344.23 293.708v81.966c0 16.236-13.162 29.398-29.398 29.398h0c-16.237 0-29.399-13.162-29.399-29.398v-90.181"
          data-original="#000000"
        ></path>
        <path
          fill="none"
          stroke="#1e1e1e"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="20"
          d="M285.434 288.058v134.934c0 16.237-13.162 29.399-29.398 29.399h0c-16.237 0-29.399-13.162-29.399-29.399V285.494M226.637 350.185v122.417c0 16.236-13.162 29.398-29.398 29.398h0c-16.237 0-29.399-13.162-29.399-29.398v-121.8"
          data-original="#000000"
        ></path>
        <path
          fill="#d43535"
          d="M470 170H178c-17.673 0-32-14.327-32-32V42c0-17.673 14.327-32 32-32h292c17.673 0 32 14.327 32 32v96c0 17.673-14.327 32-32 32"
          data-original="#f5737f"
        ></path>
        <path
          fill="none"
          stroke="#1e1e1e"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="20"
          d="M369.004 10H470c17.673 0 32 14.327 32 32v96c0 17.673-14.327 32-32 32H178c-17.673 0-32-14.327-32-32V42c0-17.673 14.327-32 32-32h101.001"
          data-original="#000000"
        ></path>
        <path
          fill="none"
          stroke="#1e1e1e"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="20"
          d="M217.52 130h-11.5c-11.046 0-20-8.954-20-20h0c0-11.046 8.954-20 20-20h4M210.021 90h0c11.046 0 20-8.954 20-20h0c0-11.046-8.954-20-20-20h-14.5M422 106.092V50M442 90h-20v40h20c11.046 0 20-8.954 20-20h0c0-11.046-8.954-20-20-20zM286 130V50M270 130h32M362 130h0c-11.046 0-20-8.954-20-20V70c0-11.046 8.954-20 20-20h0c11.046 0 20 8.954 20 20v40c0 11.046-8.954 20-20 20zM344.23 170v123.708M167.84 285.493v136.916c0 16.236-13.162 29.398-29.398 29.398h0c-16.237 0-29.399-13.162-29.399-29.398V211.433L75.4 284.727c-6.5 14.162-21.739 22.153-37.085 19.447h0c-18.548-3.27-30.978-20.901-27.827-39.47l8.145-48.002A244.466 244.466 0 0186.968 84.56h0a118.291 118.291 0 0159.029-31.99"
          data-original="#000000"
        ></path>
        <path
          fill="none"
          stroke="#1e1e1e"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="20"
          d="M109.06 211.43h0c28.194 0 52.308-17.465 62.114-42.166M324 10h0"
          data-original="#000000"
        ></path>
      </g>
    </svg>
  );
}

export default Stop;