import React, { useEffect, useState, useCallback, useRef } from 'react'
import './CustomerMap.css'
import { GoogleMap, Marker } from '@react-google-maps/api'
import { useSelector, useDispatch } from "react-redux"
import { updateFilters, updateMapView } from "../store/customerPage"
import { useNavigate } from "react-router-dom"
import CircularProgress from '@mui/material/CircularProgress'
import { debounce } from 'lodash'

const customMapStyle = [
  {
    featureType: 'poi',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }] // Hide Points of Interest
  },
  {
    featureType: 'road',
    elementType: 'labels.icon',
    stylers: [{ visibility: 'off' }] // Hide Road Icons
  },
  {
    featureType: 'all',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#000000' }] // Change label text color
  },
  {
    featureType: 'all',
    elementType: 'labels.text.stroke',
    stylers: [{ color: '#ffffff' }] // Change label text stroke color
  },
]

function CustomerMap({ customers=[], loading, totalRecords, hasMore, onLoadMore, setMapPage }) {
  const [validCustomers, setValidCustomers] = useState([])

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const mapRef = useRef()
  const filters = useSelector(state => state.customerPage.filters)
  const mapView = useSelector(state => state.customerPage.mapView)
  const center = useSelector(state => state.customerPage.mapView.center)

  const containerStyle = {
    width: '100%',
    height: '100%'
  }

  useEffect(() => {
    const filteredCustomers = customers.filter(
      customer => customer.geo_location_latitude && customer.geo_location_longitude
    )

    setValidCustomers(filteredCustomers)
  }, [customers])

  const debouncedUpdateFilters = useCallback(
    debounce((newBounds) => {
      dispatch(updateFilters({
        ...filters,
        mapBounds: newBounds
      }))
    }, 1250),
    [filters, dispatch]
  )

  const previousCenterRef = useRef(center);

  const handleBoundsChanged = useCallback(() => {
    if (!mapRef.current) return

    const mapBounds = mapRef.current.getBounds()
    const ne = mapBounds.getNorthEast()
    const sw = mapBounds.getSouthWest()

    const bounds = {
      ne: { lat: ne.lat(), lng: ne.lng() },
      sw: { lat: sw.lat(), lng: sw.lng() }
    }

    // Use debounced function for updating filters
    debouncedUpdateFilters(bounds)

    // Reset mapPage to 1 when bounds change
    dispatch(setMapPage(1))

    // Update map view only if the center has changed
    const newCenter = mapRef.current.getCenter()
    const lat = newCenter.lat()
    const lng = newCenter.lng()

    const prevLat = previousCenterRef.current.lat
    const prevLng = previousCenterRef.current.lng

    if (lat !== prevLat || lng !== prevLng) {
      dispatch(updateMapView({
        ...mapView,
        center: { lat, lng },
      }))

      // Update the previous center ref
      previousCenterRef.current = { lat, lng }
    }
  }, [debouncedUpdateFilters, dispatch])

  const handleOnClickMarker = (customerId) => {
    navigate(`/customer/${customerId}`)
  }

  useEffect(() => {
    const mapContainer = document.querySelector('.google_map_container');
    
    const preventZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault();
      }
    };

    mapContainer.addEventListener('touchstart', preventZoom, { passive: false });
    mapContainer.addEventListener('touchmove', preventZoom, { passive: false });

    return () => {
      mapContainer.removeEventListener('touchstart', preventZoom);
      mapContainer.removeEventListener('touchmove', preventZoom);
    };
  }, []);

  return (
    <div className='google_map_container'>

      <div className={`loading_overlay ${loading ? "" : "hide"}`}>
        <CircularProgress color='inherit' />
      </div>

      <div className='map_ui_elements'>
        <div className='map_ui_element'>
          <p className='title'>Customers:</p>
          <p className='content'>{customers.length} of {totalRecords}</p>
        </div>
        {hasMore && (
          <button className='load_more_button' onClick={onLoadMore} disabled={loading}>
            Load more customers
          </button>
        )}
      </div>

      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={13}
        options={{
          styles: customMapStyle,
          disableDefaultUI: true,
          zoomControl: true,
          fullscreenControl: true,
          streetViewControl: false,
          mapTypeControl: false,
          scaleControl: false,
        }}
        onLoad={(map) => mapRef.current = map}
        onDragEnd={handleBoundsChanged}
        onZoomChanged={handleBoundsChanged}
      >
        {validCustomers.map((customer, index) => (
          <Marker
            key={index}
            position={{
              lat: Number(customer.geo_location_latitude),
              lng: Number(customer.geo_location_longitude)
            }}
            title={customer.business_name}
            onClick={() => handleOnClickMarker(customer.user_id.id)}
          />
        ))}
      </GoogleMap>
    </div>
  )
}

export default CustomerMap
