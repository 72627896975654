import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import httpClient from '../httpClient'
import Header from '../components/Header'
import MenuBar from '../components/MenuBar'
import './UsersPageAdmin.css'
import TableCheckmarkHeader from '../components/TableCheckmarkHeader'
import TableCheckmarkRow from '../components/TableCheckmarkRow'
import DropdownButton from '../components/DropdownButton'
import { ToastContainer, toast } from "react-toastify"
import "../styling/ReactToastify.css"
import Slider from '../components/Slider'
import Label from '../components/Label'
import DropDownFilter from '../components/DropDownFilter'
import LoadingSpinner from '../components/placeholders/LoadingSpinner'

function UsersPageAdmin() {
  const [user, setUser] = useState({ email: "", id: "" })
  const [error, setError] = useState(false)
  const [loading, setloading] = useState(true)
  const [subscriptions, setSubscripitions] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [generateInvoicePeriod, setgenerateInvoicePeriod] = useState("Last month")
  const [hideInactive, setHideInactive] = useState(true)
  const [subTypeFilter, setSubTypeFilter] = useState("Non free")

  const userInfo = useSelector(state => state.user.userInfo)

  const fetchSubscribedUsers = async () => {
    setloading(true)

    const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/admin/subscriptions")
    setSubscripitions(response.data)

    setloading(false)
  }

  useEffect(() => {
    fetchSubscribedUsers()
      .catch(err => {
        console.log(err)
      })
  }, [])

  const generateInvoice = async (period) => {

    const period_string = period.toLowerCase().replace(/ /g, '_')

    selectedRows.forEach(async row => {
      const userID = row.getAttribute('data-user-id')

      const response = await toast.promise(
        httpClient.get(process.env.REACT_APP_API_URL + "/api/admin/generate-invoice?clientID=" + userID + "&period=" + period_string),
        {
          pending: "Generation invoices..",
          success: "Invoice generated!",
          error: "Something went wrong"
        })
      console.log(response.data)
    })
  }

  const handleDeactivateUser = async (subID) => {
    const data = {
      'subscriptionID': subID,
    }

    const response = await httpClient.post(process.env.REACT_APP_API_URL + `/api/admin/subscriptions?type=deactivate`, data)
    console.log(response.data)

    fetchSubscribedUsers()
  }

  const onClickSubFilter = (e, data) => {
    console.log(data)
    setSubTypeFilter(data)
  }

  return (
    <div className="template">
      <Header>
        <div className="header__options">
          <div className="header__option--slider"><Slider size={1.2} text="Hide inactive" onClickFunction={() => setHideInactive(!hideInactive)} state={hideInactive} defaultState={true} /></div>
        </div>
        <div className={`header__options--right-template`} id="header__options--users-admin">
          {
            selectedRows.length != 0 ?
              <>
                <DropdownButton
                  title="Generate invoice"
                  valuesArray={["This month", "Last month", "All except this month", "All time"]}
                  onClickFn={generateInvoice}
                  onClickArgument1={"subscription"}
                />
              </> :
              <>
                <DropDownFilter
                  idList={"subscription-type-fitler--admin"}
                  placeholderText={"Filter on subscription type"}
                  widthSize={"large"}
                  listArray={["Non free", "All", "Distribution", "Software only", "Free user"]}
                  value={subTypeFilter}
                  setValue={setSubTypeFilter}
                  onClickItem={onClickSubFilter}
                />
              </>
          }
        </div>
      </Header>
      <MenuBar />

      <ToastContainer position="bottom-right" />

      <div className="template__body">
        <div className="body__users-admin">
          <div className="body__section" id='body-section-admin-users-1'>
            <div className="table">
              <div className="table__header-8">
                <TableCheckmarkHeader
                  id="table-checkmark-header-users"
                  selectedRows={selectedRows}
                  setSelectedRows={setSelectedRows}
                />
                <div className="table__header--field">Business name</div>
                <div className="table__header--field">Contact name</div>
                <div className="table__header--field">User type</div>
                <div className="table__header--field">Brandowner dashboards</div>
                <div className="table__header--field">Salesforce dashboards</div>
                <div className="table__header--field">Subscription type</div>
                <div className="table__header--field"></div>
              </div>
              {

                loading ? <LoadingSpinner /> :
                  <div className='table__rows_container'>

                    {
                      subscriptions
                        .filter(sub => sub.user_id.email != "brandowner@stoqup.eu")
                        .filter(sub => {
                          if (subTypeFilter == "Free user") {
                            return sub.user_id.is_free_user
                          } else if (subTypeFilter == "Non free") {
                            return !sub.user_id.is_free_user
                          } else if (subTypeFilter == "Distribution") {
                            return sub.distribution_subscription_active
                          } else if (subTypeFilter == "Software only") {
                            return !sub.distribution_subscription_active & !sub.user_id.is_free_user
                          } else {
                            return sub
                          }
                        })
                        .map((sub, index) => {

                          let labelColor = 'grey'
                          let title = 'N/A'

                          if (sub.distribution_subscription_active) {
                            labelColor = 'green'
                            title = 'Distribution'
                          } else if (!sub.distribution_subscription_active & !sub.user_id.is_free_user) {
                            labelColor = 'blue'
                            title = 'Software only'
                          } else if (sub.commission_partner) {
                            labelColor = 'purple'
                            title = 'Commission'
                          } else if (sub.user_id.is_free_user) {
                            labelColor = 'grey'
                            title = 'Free user'
                          }

                          return (
                            !hideInactive || sub.is_active ?
                              <div className="table__row-8" id='checkmark-row' key={index} data-user-id={sub.user_id.id}>
                                <TableCheckmarkRow
                                  index={index}
                                  selectedRows={selectedRows}
                                  setSelectedRows={setSelectedRows}
                                  id={`admin_users-${index}`}
                                />
                                <div className={`table__row--field ${sub.is_active ? "" : "grayed-out"}`}>{sub.user_id.business_name}</div>
                                <div className={`table__row--field ${sub.is_active ? "" : "grayed-out"}`}>{sub.user_id.first_name} {sub.user_id.last_name}</div>
                                <div className={`table__row--field ${sub.is_active ? "" : "grayed-out"}`}>{sub.user_id.type}</div>
                                <div className={`table__row--field ${sub.is_active ? "" : "grayed-out"}`}>{sub.brandowner_dashboards}</div>
                                <div className={`table__row--field ${sub.is_active ? "" : "grayed-out"}`}>{sub.salesforce_dashboards}</div>
                                <div className={`table__row--field ${sub.is_active ? "" : "grayed-out"}`}><Label labelColor={labelColor} title={title} /></div>
                                <div className={`table__row--field actions`}>
                                  {
                                    sub.is_active ?
                                      <button className='red-action-button' onClick={() => handleDeactivateUser(sub.id)}>Deactivate</button> :
                                      <button className='green-action-button' onClick={() => handleDeactivateUser(sub.id)}>Reactivate</button>

                                  }
                                </div>
                              </div> : null
                          )
                        })
                    }

                  </div>
              }
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default UsersPageAdmin