import React from "react";

function ProfilePicFemale1() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
      <circle cx="64" cy="64" r="64" fill="#f7b718"></circle>
      <circle cx="46.2" cy="14.1" r="11.8" fill="#f85863"></circle>
      <circle cx="55.3" cy="27.4" r="11.8" fill="#01277b"></circle>
      <path
        fill="#f85863"
        d="M39.8 66.3c0 13.5 10.9 24.4 24.4 24.4s24.4-10.9 24.4-24.4V40.9c0-13.5-10.9-24.4-24.4-24.4S39.8 27.4 39.8 40.9z"
      ></path>
      <path
        fill="#01277b"
        d="M64.1 77.8c-19.6 0-35.5 15.9-35.5 35.5v4C38.7 124.1 50.9 128 64 128s25.4-4 35.5-10.8v-4c0-19.5-15.9-35.4-35.4-35.4z"
      ></path>
      <path
        fill="#fff"
        d="M55.4 62.9v17.9c0 4.8 3.9 8.7 8.7 8.7s8.7-3.9 8.7-8.7V62.9z"
      ></path>
      <path
        fill="#01277b"
        d="M55.4 65.7v6.2c2.6 2.6 5.5 4.4 8.7 5.3s6.1.8 8.7-.3V65.7z"
      ></path>
      <path
        fill="#fff"
        d="M51.1 36.2c-2.8 3.6-4.5 8.8-4.5 15.3 0 14 7.8 23.1 17.5 23.1s17.5-9.2 17.5-23.1c0-6.5-1.7-11.7-4.5-15.3z"
      ></path>
      <path
        fill="#f85863"
        d="M48.1 33.9l-5.4 8.2c2.7 2.2 6.1 3.6 9.8 3.6 8.2 0 15-6.5 15.3-14.7z"
      ></path>
      <path
        fill="#f85863"
        d="M63.2 32.1c.9 5.5 5.6 9.8 11.4 9.8s10.5-4.2 11.4-9.8z"
      ></path>
      <circle cx="55.9" cy="51.8" r="2" fill="#01277b"></circle>
      <circle cx="72.2" cy="51.8" r="2" fill="#01277b"></circle>
      <g>
        <path
          fill="#01277b"
          d="M56.9 60.4c.2 3.8 3.3 6.9 7.2 6.9s7-3 7.2-6.9z"
        ></path>
      </g>
    </svg>
  );
}

export default ProfilePicFemale1;