import React, {useState, useEffect} from 'react'
import './SearchBar.css'
import { GoSearch } from "react-icons/go"
import { IoCloseOutline } from "react-icons/io5"

function SearchBar({ searchWord, setSearchWord, mobile, margin }) {

    return (
        <div className={`searchbar__container ${mobile ? "mobile" : null}`} style={{margin}}>
            <input type='text' value={searchWord} onChange={(e) => setSearchWord(e.currentTarget.value)} placeholder='Search...' />
            {
                searchWord ?
                <IoCloseOutline onClick={() => setSearchWord("")} /> :
                <GoSearch />
            }
        </div>
    )
}

export default SearchBar

SearchBar.defaultProps = {
    mobile: false,
    margin: "0",
}
