import React, { useState, useEffect, useRef } from 'react'
import httpClient from '../../httpClient'
import { useSelector, useDispatch } from 'react-redux'
import './AddProductForSalesMobile.css'
import { MdOutlineArrowDropDown } from "react-icons/md"
import { IoCloseOutline } from "react-icons/io5"
import CheckBox from '../CheckBox'
import Label from '../Label'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'

function AddProductForSalesMobile({
  index, selectedProducts, setSelectedProducts, products, materials,
  customerID, onClickDelete, number, warehouses
}) {
  const [productsDropdownIsOpen, setProductsDropdownIsOpen] = useState(false)
  const [supplierDropdownIsOpen, setSupplierDropdownIsOpen] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState("")
  const [selectedProductID, setSelectedProductID] = useState("")
  const [selectedSupplierID, setSelectedSupplierID] = useState(null)
  const [selectedSupplierName, setSelectedSupplierName] = useState("")
  const [selectedWarehouseID, setSelectedWarehouseID] = useState(null)
  const [selectedWarehouseName, setSelectedWarehouseName] = useState("")
  const [amount, setAmount] = useState(6)
  const [comment, setComment] = useState("")
  const [supplierOfSelectedProduct, setSupplierOfSelectedProduct] = useState(null)
  const [alreadyDelivered, setAlreadyDelivered] = useState(false)
  const [onConsignation, setOnConsignation] = useState(false)
  const [productType, setProductType] = useState("products")
  const [vendors, setVendors] = useState([])

  const dispatch = useDispatch()

  const fetchVendors = async () => {
    const reponseVendors = await httpClient.get(process.env.REACT_APP_API_URL + "/api/customer/get-vendors-of-product?productID=" + selectedProductID + "&customerID=" + customerID)
    setVendors(reponseVendors.data)
  }

  const fetchProductSupplier = async () => {
    const responseProducts = await httpClient.get(process.env.REACT_APP_API_URL + "/api/customer/get-supplier-of-product?productID=" + selectedProductID + "&customerID=" + customerID)
    setSupplierOfSelectedProduct(responseProducts.data)
    if (responseProducts.data) {
      setSelectedSupplierName(responseProducts.data.vendor_name)
      setSelectedSupplierID(responseProducts.data.vendor_id)
    }
  }

  useEffect(() => {
    if (warehouses.length == 1) {
      setSelectedWarehouseID(warehouses[0].id)
      setSelectedWarehouseName(warehouses[0].name)
    }
  }, [])

  useEffect(() => {
    if (alreadyDelivered) {
      setSelectedWarehouseID(warehouses[0].id)
      setSelectedWarehouseName(warehouses[0].name)
    }
  }, [alreadyDelivered])

  useEffect(() => {
    fetchVendors()
      .catch(err => {
        console.log(err)
      })

    fetchProductSupplier()
      .catch(err => {
        console.log(err)
      })

  }, [selectedProductID, customerID])

  useEffect(() => {

    if (selectedProducts[index]) {
      const currentArray = [...selectedProducts]
      currentArray[index] = { "productID": selectedProductID, "product": selectedProduct, "amount": amount, "alreadyDelivered": alreadyDelivered, "onConsignation": onConsignation, "comment": comment, "vendor": supplierOfSelectedProduct?.vendor_id, "type": productType, 'warehouse': selectedWarehouseID }
      setSelectedProducts(currentArray)
    } else {
      const currentArray = [...selectedProducts]
      currentArray.push({ "productID": selectedProductID, "product": selectedProduct, "amount": amount, "alreadyDelivered": alreadyDelivered, "onConsignation": onConsignation, "comment": comment, "vendor": supplierOfSelectedProduct?.vendor_id, "type": productType, 'warehouse': selectedWarehouseID })
      setSelectedProducts(currentArray)
    }

  }, [selectedProductID, amount, comment, supplierOfSelectedProduct, alreadyDelivered, onConsignation, selectedWarehouseID])

  useEffect(() => {

    let filteredArray = []

    if (productType == "products") {
      filteredArray = products.filter(product => {
        let string = `${product?.name} ${product?.volume}`
        return (
          (string.toLowerCase().includes(selectedProduct.toLowerCase()))
        )
      })
    } else if (productType == "materials") {
      filteredArray = materials.filter(product => {
        let string = `${product?.name} ${product?.volume}`
        return (
          (string.toLowerCase().includes(selectedProduct.toLowerCase()))
        )
      })
    }

    if (filteredArray.length === 1) {
      const product = filteredArray[0]
      setSelectedProduct(`${product.name} ${product.volume}`)
      setSelectedProductID(product.id)
      setProductsDropdownIsOpen(false)
    }
  }, [products, materials, selectedProduct, productType])

  const handleOnChangeInputProduct = (e) => {
    const value = e.currentTarget.value
    setSelectedProduct(value)
    setProductsDropdownIsOpen(true)
  }

  const handleClearInput = () => {
    setSelectedProduct("")
    setSelectedProductID(null)
    setProductsDropdownIsOpen(false)
  }

  const handleClearInputSupplier = () => {
    setSelectedSupplierName("")
    setSelectedSupplierID(null)
    setSupplierDropdownIsOpen(false)
  }

  const handleClearInputWarehouse = () => {
    setSelectedWarehouseName("")
    setSelectedWarehouseID(null)
    setSupplierDropdownIsOpen(false)
  }

  const handleOnClickItem = (e, productName, productVolume, productID) => {
    setSelectedProductID(productID)
    setSelectedProduct(`${productName} ${productVolume}`)
    setProductsDropdownIsOpen(false)
  }

  const handleOnClickItemSupplier = (e, vendorName, vendorID) => {
    setSelectedSupplierName(vendorName)
    setSelectedSupplierID(vendorID)
    setSupplierDropdownIsOpen(false)
    setSupplierOfSelectedProduct({ "vendor_id": vendorID, "vendor_name": vendorName })
  }

  const handleOnClickItemWarehouse = (e, warehouseName, warehouseID) => {
    setSelectedWarehouseName(warehouseName)
    setSelectedWarehouseID(warehouseID)
    setSupplierDropdownIsOpen(false)
  }

  return (
    <div className='order_line__container'>

      <div className='product_and_amount_wrapper'>

        <div className='dropdown_products__container'>
          <input className='input_text--mobile' placeholder='Product' value={selectedProduct} onChange={handleOnChangeInputProduct} />
          {
            selectedProduct != "" &&
            <IoCloseOutline onClick={handleClearInput} />
          }
          <ul className={`dropdown_list_container no-scrollbar ${productsDropdownIsOpen ? "" : "hide"}`}>
            <div className='product_type__choices'>
              <button className={`product_choice__button ${productType == "products" ? "selected" : ""}`} onClick={() => setProductType("products")}>Products</button>
              <button className={`product_choice__button ${productType == "materials" ? "selected" : ""}`} onClick={() => setProductType("materials")}>Materials</button>
            </div>
            {
              productType == "products" &&
              products
                .filter(product => {
                  let string = `${product?.name} ${product?.volume}`
                  return (
                    (string.toLowerCase().includes(selectedProduct.toLowerCase()))
                  )
                })
                .map((product, index) => {
                  return (
                    <div className='dropdown_item' key={index} onClick={(e) => handleOnClickItem(e, product.name, product.volume, product.id)}>{product.name} {product.volume}</div>
                  )
                })
            }
            {
              productType == "materials" &&
              materials
                .filter(product => {
                  let string = `${product?.name} ${product?.volume}`
                  return (
                    (string.toLowerCase().includes(selectedProduct.toLowerCase()))
                  )
                })
                .map((product, index) => {
                  return (
                    <div className='dropdown_item' key={index} onClick={(e) => handleOnClickItem(e, product.name, product.volume, product.id)}>{product.name} {product.volume}</div>
                  )
                })
            }
          </ul>
        </div>

        <input className='input_text--mobile' type='number' value={amount} onChange={(e) => setAmount(e.currentTarget.value)} />
      </div>

      <div className='delivered_and_supplier_wrapper'>
        <CheckBox
          text={"Already delivered"}
          textPosition={"left"}
          style={"mobile"}
          margin={"0 0 7.5px 0"}
          state={alreadyDelivered}
          setState={setAlreadyDelivered}
        />

        {
          alreadyDelivered ?
            <div className='dropdown_suppliers__container'>
              <input className='input_text--mobile' type='text' value={selectedWarehouseName} onClick={() => setSupplierDropdownIsOpen(true)} onChange={(e) => setSelectedSupplierName(e.currentTarget.value)} />
              {
                selectedWarehouseName &&
                <IoCloseOutline onClick={handleClearInputWarehouse} />
              }
              <ul className={`dropdown_list_container no-scrollbar ${supplierDropdownIsOpen ? "" : "hide"}`}>
                {
                  warehouses
                    .map((warehouse, index) => (
                      <div className='dropdown_item' onClick={(e) => handleOnClickItemWarehouse(e, warehouse.name, warehouse.id)}>
                        {warehouse.name}
                      </div>
                    ))
                }
              </ul>
            </div>
            :
            <div className='dropdown_suppliers__container'>
              <input className='input_text--mobile' type='text' value={selectedSupplierName} onClick={() => setSupplierDropdownIsOpen(true)} onChange={(e) => setSelectedSupplierName(e.currentTarget.value)} />
              {
                selectedSupplierName &&
                <IoCloseOutline onClick={handleClearInputSupplier} />
              }
              <ul className={`dropdown_list_container no-scrollbar ${supplierDropdownIsOpen ? "" : "hide"}`}>
                {
                  vendors
                    .filter(vendor => (
                      (selectedSupplierName != undefined && vendor?.company_name.toLowerCase().includes(selectedSupplierName.toLowerCase()))
                    ))
                    .map((vendor, index) => (
                      <div className='dropdown_item' onClick={(e) => handleOnClickItemSupplier(e, vendor.company_name, vendor.id)}>
                        {vendor.company_name}

                        <div>
                          {
                            vendor.is_brandowner ?
                              <div className='label__vendors'><Label title="Brandowner" labelColor="blue" highContrast={true} /></div> :
                              vendor.is_pseudo ?
                                <div className='label__vendors'><Label title="Added by customer" labelColor="green" highContrast={true} /></div> :
                                <div className='label__vendors'></div>
                          }
                        </div>
                      </div>
                    ))
                }
              </ul>
            </div>
        }
      </div>

      <div className='comment_and_delete_wrapper'>
        <textarea className='input_text--mobile textarea' value={comment} onChange={(e) => setComment(e.currentTarget.value)} placeholder='Comment' />
        <div className="delete"><DeleteOutlineOutlinedIcon id="delete-icon" onClick={(e) => onClickDelete(e, number)} /></div>
      </div>
    </div>
  )
}

export default AddProductForSalesMobile