import React, {useState, useEffect} from "react"
import { Link } from "react-router-dom"
import httpClient from "../httpClient"

import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined'
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined'
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined'
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined'
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import { BsFillBoxFill } from "react-icons/bs"

import { LuCalendarRange } from "react-icons/lu"
import { PiFunnelBold } from "react-icons/pi"
import { SiHackthebox } from "react-icons/si"
import { MdOutlineAnalytics, MdOutlineShoppingCart, MdOutlinePeopleAlt, MdOutlineGroups2 } from "react-icons/md"


function MenuBar() {
  const [user, setUser] = useState({email: "", id: ""})
  const [error, setError] = useState(false)
  
  const fetchUser = async () => {
    const responseUser = await httpClient.get(process.env.REACT_APP_API_URL + "/api/@me?type=full")
    setUser(responseUser.data)
  }

  useEffect(() => {
    fetchUser()
      .catch( err => {
        console.log(err)
        setError(true)
      })
  }, [])

  const handleClickMenuItem = (e) => {
    const menu = e.currentTarget.nextSibling
    const asideMenu = document.querySelector("#template__aside")

    if(!menu) return
    if(menu.id !== "sub-menu") return

    menu.classList.toggle("active")
    asideMenu.classList.remove("mini")
  }

  return (
    <div className="primary__menu">
      <Link to="/salesforce/home"><div className="menu__item" onClick={handleClickMenuItem}><MdOutlineAnalytics/><p className="menu__title">Dashboard</p></div></Link>
      <Link to="/customers"><div className="menu__item" onClick={handleClickMenuItem}><MdOutlinePeopleAlt/><p className="menu__title">Customers</p></div></Link>
      <Link to="/activities">
        <div className="menu__item" onClick={handleClickMenuItem}><LuCalendarRange/><p className="menu__title">Activities</p></div>
      </Link>
      <Link to="/sales"><div className="menu__item" onClick={handleClickMenuItem}><HandshakeOutlinedIcon/><p className="menu__title">Sales</p></div></Link>
      <Link to="/sales-pipe">
        <div className="menu__item" onClick={handleClickMenuItem}><PiFunnelBold/><p className="menu__title">Sales Pipe</p></div>
      </Link>
      {/* <Link to="/visits"><div className="menu__item" onClick={handleClickMenuItem}><BallotOutlinedIcon/><p className="menu__title">Visits</p></div></Link> */}
      <Link to="/salesforce/inventory"><div className="menu__item" onClick={handleClickMenuItem}><SiHackthebox/><p className="menu__title">Stock</p></div></Link>
    </div>
)}

export default MenuBar
