import React, { useState, useEffect, useRef } from 'react'
import './PopupWindowCreateNewCustomer.css'
import { useSelector, useDispatch } from "react-redux"
import httpClient from "../httpClient"
import { changeNotificationMessage } from "../store/notification"
import { NotificationTop, showNotification } from "./NotificationTop"
import CloseIcon from '@mui/icons-material/Close';
import { ToastContainer, toast } from "react-toastify"
import "../styling/ReactToastify.css"
import CircularProgress from '@mui/material/CircularProgress'
import { IoClose } from "react-icons/io5"
import { showNotificationPopup } from "../components/NotificationPopup.js"
import CheckBox from "./CheckBox.js"
import { TfiMicrosoftAlt } from "react-icons/tfi"

function PopupWindowCreateNewCustomer({ isActive, setIsActive, fetchCustomers, setSelectedCustomerID, setSelectedCustomer }) {
  const [user, setUser] = useState({ email: "", id: "" })
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)
  const [loadingSaveButton, setloadingSaveButton] = useState(false)
  const [emailError, setEmailError] = useState("Not a valid email address")
  const [invoiceEmailError, setInvoiceEmailError] = useState("Not a valid email address")
  const [VATNumberError, setVATNumberError] = useState("VAT Number not in correct format BE0000.000.000")
  const [selectedType, setSelectedType] = useState("")
  const [selectedSubType, setSelectedSubType] = useState("")
  const [typesDropdownIsOpen, setTypesDropdownIsOpen] = useState(false)
  const [subTypeDropdownIsOpen, setsubTypeDropdownIsOpen] = useState(false)
  const [loadingInputBoxes, setLoadingInputBoxes] = useState(false)

  const [businessNameDropdownIsActive, setBusinessNameDropdownIsActive] = useState(true)
  const [loadingBusinessNameDropdown, setLoadingBusinessNameDropdown] = useState(true)
  const [businessNameSelected, setBusinessNameSelected] = useState(false)
  const [googleBusinessSearchResults, setGoogleBusinessSearchResults] = useState([])
  const [debouncedBusinessName, setDebouncedBusinessName] = useState('')
  const [searchRequestLimitReached, setSearchRequestLimitReached] = useState(false)

  const [customer, setCustomer] = useState({
    business_name: "",
    email: "",
    tel_number: "",
    first_name: "",
    last_name: "",
    company_name: "",
    sales_email: "",
    invoice_email: "",
    VAT_number: "",
    invoice_address_street: "",
    invoice_address_nr: "",
    invoice_address_pc: "",
    invoice_address_place: "",
    invoice_address_country: "Belgium",
    delivery_address_street: "",
    delivery_address_nr: "",
    delivery_address_pc: "",
    delivery_address_place: "",
    delivery_address_country: "Belgium",
    bio: "",
    is_supplier: false,
    create_in_business_central: false
  })

  const dispatch = useDispatch()

  const dropdownRefTypes = useRef(null)
  const parentDivRef = useRef(null)
  const dropdownRefSubTypes = useRef(null)
  const userInfo = useSelector(state => state.user.userInfo)

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedBusinessName(customer.business_name)
    }, 750) // 750ms delay

    if (!customer.business_name) {
      setBusinessNameSelected(false)
      setBusinessNameDropdownIsActive(true)
      setGoogleBusinessSearchResults([])
    }

    return () => {
      clearTimeout(timerId)
    }
  }, [customer.business_name])

  useEffect(() => {
    if (selectedType == "wholesaler") {
      setCustomer(prev => ({ ...prev, is_supplier: true }))
    }
  }, [selectedType])

  useEffect(() => {
    const fetchBusinessName = async () => {
      if (!debouncedBusinessName) {
        return
      }

      if (!businessNameSelected) {
        try {
          const response = await httpClient.get(`${process.env.REACT_APP_API_URL}/api/user/text-search?textQuery=${debouncedBusinessName}`)

          if (response.status == 200) {
            setGoogleBusinessSearchResults(response.data.data.places)
          } else {
            setBusinessNameDropdownIsActive(false)
            console.log(response.data)
          }
        } catch (error) {
          console.log(error)
          if (error.response.status == 423) {
            setSearchRequestLimitReached(true)
          }
        }
      }

      setLoadingBusinessNameDropdown(false)

    }

    fetchBusinessName()
  }, [debouncedBusinessName])

  const customerTypesArray = useSelector(state => state.user.customer_types)

  const arrayOfSubTypes = Object.values(customerTypesArray).flat(1)
  const arrayOfTypes = Object.keys(customerTypesArray)

  const handleOnCLickType = () => {
    const type_dropdown = document.getElementById("dropdown__customers_type")
    type_dropdown.classList.toggle("active")
  }

  const handleOnCLickSubType = () => {
    const type_dropdown = document.getElementById("dropdown__customers_sub_type")
    type_dropdown.classList.toggle("active")
  }

  const handleOnClickDropdownItem = (event) => {
    const dropdownList = event.target.parentElement
    switch (dropdownList.id) {
      case "dropdown__customers_type":
        setSelectedType(event.target.innerHTML)
        setSelectedSubType("")
        dropdownList.classList.toggle("active")
        break
      case "dropdown__customers_sub_type":
        setSelectedSubType(event.target.innerHTML)
        dropdownList.classList.toggle("active")
        break
    }
  }

  const handleDuplicateButton = (event) => {
    const input_field = event.target.previousSibling
    switch (input_field.name) {
      case "company_name":
        setCustomer(prev => ({ ...prev, "company_name": customer.business_name }))
        break
      case "invoice_email":
        setCustomer(prev => ({ ...prev, "invoice_email": customer.email }))
        break
      case "sales_email":
        setCustomer(prev => ({ ...prev, "sales_email": customer.email }))
        break
      case undefined:
        if (input_field.parentElement.id == "delivery_address_info") {
          setCustomer(prev => ({
            ...prev,
            "delivery_address_street": customer.invoice_address_street,
            "delivery_address_nr": customer.invoice_address_nr,
            "delivery_address_pc": customer.invoice_address_pc,
            "delivery_address_place": customer.invoice_address_place,
            "delivery_address_country": customer.invoice_address_country,
          }))
        }
        break
    }
  }

  const handleSaveNewCustomer = async () => {
    const saveButton = document.getElementById("customers__btn-save--customer")
    setloadingSaveButton(true)
    saveButton.disabled = true

    const emailErrorElement = document.getElementById("input_error_email")
    const invoiceEmailErrorElement = document.getElementById("input_error_invoice_email")
    const salesEmailErrorElement = document.getElementById("input_error_sales_email")
    const VATNumberErrorElement = document.getElementById("input_error_VAT_number")

    const VATValidation = /[A-Z][0-9]{4}\.[0-9]{3}\.[0-9]{3}/
    const emailValidation = /\S+@\S+\.\S+/

    const data = {
      ...customer,
      "type": selectedType,
      "sub_type": selectedSubType,
      "sales_email": ""
    }

    if (!data.business_name) {

      toast.error("Business name is required", { theme: "dark" })

      setloadingSaveButton(false)
      saveButton.disabled = false
      return
    }

    if (data.email) {
      if (emailValidation.test(data.email)) {
        emailErrorElement.classList.remove("active")
      } else {
        emailErrorElement.classList.add("active")

        toast.error("Not a valid email address", { theme: "dark" })

        setloadingSaveButton(false)
        saveButton.disabled = false
        return
      }
    }

    if (data.invoice_email) {
      if (emailValidation.test(data.invoice_email)) {
        invoiceEmailErrorElement.classList.remove("active")
      } else {
        invoiceEmailErrorElement.classList.add("active")

        toast.error("Not a valid email address", { theme: "dark" })

        setloadingSaveButton(false)
        saveButton.disabled = false
        return
      }
    }

    if (data.sales_email) {
      if (emailValidation.test(data.sales_email)) {
        salesEmailErrorElement.classList.remove("active")
      } else {
        salesEmailErrorElement.classList.add("active")

        toast.error("Not a valid email address", { theme: "dark" })

        setloadingSaveButton(false)
        saveButton.disabled = false
        return
      }
    }

    const cleanState = () => {
      setSelectedSubType("")
      setSelectedType("")
      setCustomer({
        business_name: "",
        email: "",
        tel_number: "",
        first_name: "",
        last_name: "",
        company_name: "",
        sales_email: "",
        invoice_email: "",
        VAT_number: "",
        invoice_address_street: "",
        invoice_address_nr: "",
        invoice_address_pc: "",
        invoice_address_place: "",
        invoice_address_country: "Belgium",
        delivery_address_street: "",
        delivery_address_nr: "",
        delivery_address_pc: "",
        delivery_address_place: "",
        delivery_address_country: "Belgium",
        bio: ""
      })
    }

    const updateCustomer = async (customerId) => {
      const requestData = {
        ...data,
        "id": customerId
      }

      try {
        const response = await httpClient.post(`${process.env.REACT_APP_API_URL}/api/user/update`, requestData)
        if (response.status === 200) {
          toast.success("Customer data updated")
          fetchCustomers()

          setIsActive(false)
          cleanState()
          if (!response.data.business_name) {
            setSelectedCustomer(`${response.data.first_name} ${response.data.last_name}`)
          } else {
            setSelectedCustomer(response.data.business_name)
          }

          setSelectedCustomerID(response.data.id)
        }
      } catch (err) {
        console.log(err)
      }
    }

    try {
      const response = await httpClient.post(`${process.env.REACT_APP_API_URL}/api/user/create`, data)
      console.log(response)

      if (response.status === 200) {
        toast.success("Customer created!")
        fetchCustomers()

        setIsActive(false)
        cleanState()
        if (!response.data.business_name) {
          setSelectedCustomer(`${response.data.first_name} ${response.data.last_name}`)
        } else {
          setSelectedCustomer(response.data.business_name)
        }

        setSelectedCustomerID(response.data.id)

      } else if (response.status === 409) {
        showNotificationPopup({
          title: `Customer with this email address & delivery address already exists under the name of '${response.data.customer_name}'`,
          message: 'Do you want to overwrite the old data with the new data?',
          actionButtonOnClick: () => updateCustomer(response.data.customer_id),
          actionButtonText: 'overwrite',
          onCloseFn: () => setIsActive(false)
        })
      } else if (response.status === 403) {
        toast.error("You have reached your free user limit of 10 customers. Upgrade your account.", { theme: "dark" })
      } else {
        // Handle other statuses if necessary
      }

      setloadingSaveButton(false)
      saveButton.disabled = false
    } catch (err) {

      console.log(err)
      setloadingSaveButton(false)
      saveButton.disabled = false
    }

    setloadingSaveButton(false)
    saveButton.disabled = false
  }

  const closePopup = () => {
    setIsActive(false)
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setCustomer(prev => ({ ...prev, [name]: value }))

    if (name == 'business_name') {
      setLoadingBusinessNameDropdown(true)
    }
  }

  const handleIsSupplier = (value) => {
    setCustomer(prev => ({ ...prev, is_supplier: !prev.is_supplier }))
  }

  const handleBusinessCentralToggle = () => {
    setCustomer(prev => ({
      ...prev,
      create_in_business_central: !prev.create_in_business_central
    }))
  }

  const handleOnClickSearchResult = async (searchResult) => {

    setLoadingInputBoxes(true)

    setBusinessNameDropdownIsActive(false)
    setBusinessNameSelected(true)
    setGoogleBusinessSearchResults([])

    const customerState = { ...customer }
    customerState.business_name = searchResult.displayName.text
    customerState.tel_number = searchResult.nationalPhoneNumber

    const addressParts = searchResult.formattedAddress.split(', ')

    if (addressParts.length === 3) {
      const streetAndNumber = addressParts[0].split(' ')
      const street = streetAndNumber.slice(0, -1).join(' ')
      const number = streetAndNumber[streetAndNumber.length - 1]

      const postalAndPlace = addressParts[1].split(' ')
      const postalCode = postalAndPlace[0]
      const place = postalAndPlace.slice(1).join(' ')

      const country = addressParts[2]

      customerState.delivery_address_street = street
      customerState.delivery_address_nr = number
      customerState.delivery_address_pc = postalCode
      customerState.delivery_address_place = place
      customerState.delivery_address_country = country
    } else {
      // Handle cases where the address does not fit the expected structure
      console.error('Address format is not as expected:', searchResult.formattedAddress)
    }

    // try to find the email address, type & subtype and short description

    const response = await httpClient.post(process.env.REACT_APP_API_URL + `/api/ai/find-customer-info`, searchResult)
    const emailAddresses = response.data.email_addresses

    if (emailAddresses) {
      customerState.email = emailAddresses[0]
    }

    customerState.bio = response.data.short_description
    setSelectedType(response.data.type)
    setSelectedSubType(response.data.sub_type)

    setCustomer(customerState)
    setLoadingInputBoxes(false)
  }

  return (
    <div className={`body__section popup ${isActive ? "" : "hidden"}`} id="new-customer-form-popup">

      <button className={`green-button-header popup`} id="customers__btn-save--customer" onClick={handleSaveNewCustomer}>
        {
          loadingSaveButton ?
            <CircularProgress color="inherit" size={"1rem"} /> :
            <>CREATE CUSTOMER</>
        }
      </button>

      <CloseIcon id="popup__close-icon" onClick={closePopup} />
      <div className="form__section"><h1>Details</h1>
        <div className="input_field">
          <label className="input_field__label" htmlFor="business_name">Business name</label>
          <input
            className={`input_field__input_box--text ${loadingInputBoxes ? "loading" : ""}`}
            id="customers__business_name"
            type="text"
            name="business_name"
            value={customer.business_name}
            onChange={handleChange}
            disabled={loadingInputBoxes}
          />
          <div className={`dropdown_field ${(businessNameDropdownIsActive & customer.business_name != '') ? "" : "hide"}`}>
            {
              searchRequestLimitReached ?
                <div className="limit" onClick={() => setBusinessNameDropdownIsActive(false)}><p>🤖 You've reached your monthly search limit.</p> <IoClose /></div> :

                loadingBusinessNameDropdown ?
                  <div className='circular_loading_spinner__wrapper'><CircularProgress color='inherit' size="1rem" /></div>
                  :
                  <>
                    {
                      googleBusinessSearchResults.map((result, index) => {
                        const addressParts = result.formattedAddress.split(', ')
                        const place = addressParts.length >= 3 ? addressParts[addressParts.length - 2].split(' ')[1] : ''

                        return (
                          <div className="dropdown_field__item" key={index} onClick={() => handleOnClickSearchResult(result)}>
                            <div>{result.displayName.text}</div>
                            <div>{result.primaryType}</div>
                            <div>{place}</div>
                          </div>
                        )
                      })
                    }
                  </>
            }
          </div>
        </div>

        <div className="input_field">
          <label className="input_field__label" htmlFor="email">Email</label>
          <input
            className={`input_field__input_box--text ${loadingInputBoxes ? "loading" : ""}`}
            id="customers__email"
            type="text"
            name="email"
            value={customer.email}
            onChange={handleChange}
            disabled={loadingInputBoxes}
          />
        </div>
        <div className="input_error" id="input_error_email">{emailError}</div>

        <div className="input_field">
          <label className="input_field__label" htmlFor="tel">Telephone number</label>
          <input
            className={`input_field__input_box--text ${loadingInputBoxes ? "loading" : ""}`}
            id="customers__tel"
            type="text"
            name="tel_number"
            value={customer.tel_number}
            onChange={handleChange}
            disabled={loadingInputBoxes}
          />
        </div>

        <div className="input_field--combo-5050">
          <div className="input_field">
            <label className="input_field__label" htmlFor="first_name">First name</label>
            <input
              className={`input_field__input_box--text ${loadingInputBoxes ? "loading" : ""}`}
              id="customers__first_name"
              type="text"
              name="first_name"
              value={customer.first_name}
              onChange={handleChange}
              disabled={loadingInputBoxes}
            />
          </div>
          <div className="input_field">
            <label className="input_field__label" htmlFor="last_name">Last name</label>
            <input
              className={`input_field__input_box--text ${loadingInputBoxes ? "loading" : ""}`}
              id="customers__last_name"
              type="text"
              name="last_name"
              value={customer.last_name}
              onChange={handleChange}
              disabled={loadingInputBoxes}
            />
          </div>
        </div>

        <div className="input_field--combo-5050">
          <div className="input_field">
            <div className="dropdown">
              <p className="input_field__label">Type</p>
              <div
                className={`input_field__input_box--text dropdown ${loadingInputBoxes ? "loading" : ""}`}
                id="customers__type"
                value={selectedType}
                onClick={handleOnCLickType}
              >
                {selectedType}
              </div>
            </div>
            <ul
              className={`input_field__dropdown ${typesDropdownIsOpen ? "active" : ""}`}
              id="dropdown__customers_type"
              ref={dropdownRefTypes}
            >
              {arrayOfTypes.map(type => (
                <li key={type} onClick={handleOnClickDropdownItem}>{type}</li>
              ))}
            </ul>
          </div>
          <div className="input_field">
            <div className="dropdown">
              <p className="input_field__label">Sub type</p>
              <div
                className={`input_field__input_box--text dropdown ${loadingInputBoxes ? "loading" : ""}`}
                id="customers__sub_type"
                value={selectedSubType}
                onClick={handleOnCLickSubType}
              >
                {selectedSubType}
              </div>
            </div>
            <ul
              className={`input_field__dropdown ${subTypeDropdownIsOpen ? "active" : ""}`}
              id="dropdown__customers_sub_type"
              ref={dropdownRefSubTypes}
            >
              {customerTypesArray[selectedType]?.map(subtype => (
                <li key={subtype} onClick={handleOnClickDropdownItem}>{subtype}</li>
              ))}
            </ul>
          </div>
        </div>

        {customer.is_supplier && (
          <>
            <div className="input_field">
              <label className="input_field__label" htmlFor="sales_email">Order email</label>
              <div className="flex">
                <input
                  className={`input_field__input_box--text ${loadingInputBoxes ? "loading" : ""}`}
                  id="customers__sales_email"
                  type="text"
                  name="sales_email"
                  value={customer.sales_email}
                  onChange={handleChange}
                  disabled={loadingInputBoxes}
                />
                <button className="secondairy-button" onClick={handleDuplicateButton}>Duplicate</button>
              </div>
            </div>
            <div className="input_error" id="input_error_sales_email">{emailError}</div>
          </>
        )}

        <div className="input_field">
          <label className="input_field__label" htmlFor="bio">Bio</label>
          <textarea
            className={`input_field__input_box--text ${loadingInputBoxes ? "loading" : ""}`}
            style={{ minHeight: "90px" }}
            id="customers__bio"
            name="bio"
            value={customer.bio}
            onChange={handleChange}
            disabled={loadingInputBoxes}
          />
        </div>
      </div>

      <div className="form__section">
        <h1>Invoicing</h1>
        <div className="input_field">
          <label className="input_field__label" htmlFor="company_name">Company name</label>
          <div className="flex">
            <input
              className={`input_field__input_box--text ${loadingInputBoxes ? "loading" : ""}`}
              id="customers__company_name"
              type="text"
              name="company_name"
              value={customer.company_name}
              onChange={handleChange}
              disabled={loadingInputBoxes}
            />
            <button className="secondairy-button" onClick={handleDuplicateButton}>Duplicate</button>
          </div>
        </div>

        <div className="input_field">
          <label className="input_field__label" htmlFor="invoice_email">Invoice email</label>
          <div className="flex">
            <input
              className={`input_field__input_box--text ${loadingInputBoxes ? "loading" : ""}`}
              id="customers__invoice_email"
              type="text"
              name="invoice_email"
              value={customer.invoice_email}
              onChange={handleChange}
              disabled={loadingInputBoxes}
            />
            <button className="secondairy-button" onClick={handleDuplicateButton}>Duplicate</button>
          </div>
        </div>
        <div className="input_error" id="input_error_invoice_email">{invoiceEmailError}</div>

        <div className="input_field">
          <label className="input_field__label" htmlFor="VAT_number">VAT number</label>
          <input
            className={`input_field__input_box--text ${loadingInputBoxes ? "loading" : ""}`}
            id="customers__VAT_number"
            type="text"
            name="VAT_number"
            value={customer.VAT_number}
            onChange={handleChange}
            disabled={loadingInputBoxes}
          />
        </div>
        <div className="input_error" id="input_error_VAT_number">{VATNumberError}</div>

        <div className="input_field--combo-7030">
          <div className="input_field">
            <label className="input_field__label" htmlFor="invoice_address_street">Street</label>
            <input
              className={`input_field__input_box--text ${loadingInputBoxes ? "loading" : ""}`}
              id="customers__invoice_address_street"
              type="text"
              name="invoice_address_street"
              value={customer.invoice_address_street}
              onChange={handleChange}
              disabled={loadingInputBoxes}
            />
          </div>
          <div className="input_field">
            <label className="input_field__label" htmlFor="invoice_address_nr">Number</label>
            <input
              className={`input_field__input_box--text ${loadingInputBoxes ? "loading" : ""}`}
              id="customers__invoice_address_nr"
              type="text"
              name="invoice_address_nr"
              value={customer.invoice_address_nr}
              onChange={handleChange}
              disabled={loadingInputBoxes}
            />
          </div>
        </div>

        <div className="input_field--combo-3070">
          <div className="input_field">
            <label className="input_field__label" htmlFor="invoice_address_pc">Postal code</label>
            <input
              className={`input_field__input_box--text ${loadingInputBoxes ? "loading" : ""}`}
              id="customers__invoice_address_pc"
              type="text"
              name="invoice_address_pc"
              value={customer.invoice_address_pc}
              onChange={handleChange}
              disabled={loadingInputBoxes}
            />
          </div>
          <div className="input_field">
            <label className="input_field__label" htmlFor="invoice_address_place">City</label>
            <input
              className={`input_field__input_box--text ${loadingInputBoxes ? "loading" : ""}`}
              id="customers__invoice_address_place"
              type="text"
              name="invoice_address_place"
              value={customer.invoice_address_place}
              onChange={handleChange}
              disabled={loadingInputBoxes}
            />
          </div>
        </div>

        <div className="input_field">
          <label className="input_field__label" htmlFor="invoice_address_nr">Country</label>
          <input
            className={`input_field__input_box--text ${loadingInputBoxes ? "loading" : ""}`}
            id="customers__invoice_address_country"
            type="text"
            name="invoice_address_country"
            value={customer.invoice_address_country}
            onChange={handleChange}
            disabled={loadingInputBoxes}
          />
        </div>
      </div>

      <div className="form__section" id="delivery_address_info">
        <h1>Delivery</h1>
        <div className="input_field--combo-7030">
          <div className="input_field">
            <label className="input_field__label" htmlFor="delivery_address_street">Street</label>
            <input
              className={`input_field__input_box--text ${loadingInputBoxes ? "loading" : ""}`}
              id="customers__delivery_address_street"
              type="text"
              name="delivery_address_street"
              value={customer.delivery_address_street}
              onChange={handleChange}
              disabled={loadingInputBoxes}
            />
          </div>
          <div className="input_field">
            <label className="input_field__label" htmlFor="delivery_address_nr">Number</label>
            <input
              className={`input_field__input_box--text ${loadingInputBoxes ? "loading" : ""}`}
              id="customers__delivery_address_nr"
              type="text"
              name="delivery_address_nr"
              value={customer.delivery_address_nr}
              onChange={handleChange}
              disabled={loadingInputBoxes}
            />
          </div>
        </div>

        <div className="input_field--combo-3070">
          <div className="input_field">
            <label className="input_field__label" htmlFor="delivery_address_pc">Postal code</label>
            <input
              className={`input_field__input_box--text ${loadingInputBoxes ? "loading" : ""}`}
              id="customers__delivery_address_pc"
              type="text"
              name="delivery_address_pc"
              value={customer.delivery_address_pc}
              onChange={handleChange}
              disabled={loadingInputBoxes}
            />
          </div>
          <div className="input_field">
            <label className="input_field__label" htmlFor="delivery_address_place">City</label>
            <input
              className={`input_field__input_box--text ${loadingInputBoxes ? "loading" : ""}`}
              id="customers__delivery_address_place"
              type="text"
              name="delivery_address_place"
              value={customer.delivery_address_place}
              onChange={handleChange}
              disabled={loadingInputBoxes}
            />
          </div>
        </div>

        <div className="input_field">
          <label className="input_field__label" htmlFor="invoice_address_nr">Country</label>
          <input
            className={`input_field__input_box--text ${loadingInputBoxes ? "loading" : ""}`}
            id="customers__delivery_address_country"
            type="text"
            name="delivery_address_country"
            value={customer.delivery_address_country}
            onChange={handleChange}
            disabled={loadingInputBoxes}
          />
        </div>
        <button className="secondairy-button" id="duplicate-btn-delivery" onClick={handleDuplicateButton}>Duplicate</button>

        <div className="other_options">
          <CheckBox
            id="customers__is_supplier"
            text="Is supplier"
            state={customer.is_supplier}
            setData={handleIsSupplier}
          />
        </div>
        <div className={`api_options`}>
          <div
            className={`business-central-icon ${customer.create_in_business_central ? 'clicked' : ''} ${!userInfo.api_partners.includes('BusinessCentral') ? "hide" : ""}`}
            onClick={handleBusinessCentralToggle}
          >
            <TfiMicrosoftAlt />
          </div>
        </div>

      </div>
    </div>
  )
}

export default PopupWindowCreateNewCustomer