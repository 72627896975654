import React, { useState, useEffect } from "react"
import httpClient from "../httpClient"
import "./ForgotPasswordPage.css"
import { Link, useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux"
import { addUserInfo } from "../store/user"
import { changeNotificationMessage } from "../store/notification"
import { NotificationTop, showNotification } from "../components/NotificationTop";
import { toast } from "react-toastify"
import CircularProgress from '@mui/material/CircularProgress'

function ForgotPasswordPage() {  

  const [email, setEmail] = useState("")
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)

  const logo_black = require("../assets/logos/Stoqup_logo_black.png")
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {

  }, [])

  const HandlesendLink = async () => {
    setLoading(true)
    const button = document.querySelector('#button-forgot-password')
    button.disabled = true

    const data = {email}

    try {
      const response = await toast.promise(
        httpClient.post(process.env.REACT_APP_API_URL + "/api/forgotpassword?type=dashboard", data),
        {
          success: "An email with reset password link has been send. The link will expire in 60 minutes.",
          pending: "Send email link...",
          error: "Something went wrong"
        }
      )
      console.log(response.data)
    } catch (error) {
      if (error.response.status != 200) {
        setLoading(false)
        button.disabled = false
        return
      }
    }

    navigate("/")
  }
  
  return (
    <div className="body-loginpage forgot_password">
      <NotificationTop />
      <Link className="logo" to="/"><img src={logo_black} alt="stoqup logo black" id="forgot-password-logo" /></Link>
      <form className='login-form'>
        <div className="message">
          Enter your email address. If you have an account registered on this email address, you will receive an email with a reset password link.
        </div>
        <input 
          value={email} 
          onChange={e => setEmail(e.target.value)} 
          placeholder='EMAIL'
        />

        <button type='button' className='button-login' id="button-forgot-password" onClick={HandlesendLink}><div className={`${loading ? "hide" : ""}`}>SEND LINK</div>{loading && <CircularProgress color="inherit" size={"2.3rem"} />}</button>
      </form>
    </div>
)}

export default ForgotPasswordPage
