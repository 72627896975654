import React, { useState, useEffect, useRef } from 'react'
import './DropDownCustomDetails.css'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { MdOutlineArrowDropDown } from "react-icons/md"

function DropDownCustomDetails({
  index, fontStyle, state, marginTop, fontSize, setState,
  height, width, idList, placeholder, listArray, inherit, title,
  defaultState, parentIndex, setChangesMade, style,
  setDropdownID, setDropdownList, setDropdownPopupIsOpen, setStateData
}) {
  const [localState, setLocalState] = useState(defaultState)

  const ref = useRef(null)

  useEffect(() => {
    setLocalState(state[parentIndex]["custom_details"][index].value)
  }, [state])

  const onClickOutside = () => {
    const dropdownList = document.querySelectorAll(`#${idList}`)

    Array.from(dropdownList).forEach(dropdown => {
      dropdown.classList.remove("active")
    })
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside()
      }
    }
    document.addEventListener("click", handleClickOutside, true)

    return () => {
      document.removeEventListener("click", handleClickOutside, true)
    }
  }, [onClickOutside])

  const handleClickDropdown = (event) => {
    const dropdownList = event.currentTarget.nextSibling
    dropdownList.classList.toggle("active")
  }

  const onclickMenuItem = (event) => {
    const dropdownList = event.currentTarget.parentNode

    setChangesMade(true)

    if (typeof listArray[0] === 'string' || typeof listArray[0] === 'number') {
      const selectedValue = event.currentTarget.textContent
      setLocalState(selectedValue)

      try {
        // Create a deep copy of the state to avoid direct mutation
        const newState = JSON.parse(JSON.stringify(state))

        // Access and update the required value
        newState[parentIndex]["custom_details"][index].value = selectedValue

        // Set the new state
        setState(newState)
      }
      catch (error) {
        console.log("No data to set")
      }

    }

    dropdownList.classList.remove("active")

  }

  if (!listArray || listArray.length === 0) {
    return <p>Loading...</p>
  }

  const handleOnClickPopup = (e, type) => {

    setChangesMade(true)
    setDropdownList(listArray)
    setDropdownID(type)
    setDropdownPopupIsOpen(true)
    setStateData({
      "type": "custom-details",
      "parentIndex": parentIndex,
      "index": index,
    })
  }

  return (
    <div className={`dropdown__container-dropdown flex--vert ${style}`} id={`dropdown__${index}`} style={{ width, marginTop }} ref={ref} key={index}>
      {
        style == "mobile" ?
          <div className={`form_dropdown--mobile`} name={`${parentIndex}-customer-details-${index}`} onClick={(e) => handleOnClickPopup(e, `${parentIndex}-customer-details-${index}`)}>
            {
              localState ?
                <p>{localState}</p> :
                <p className='placeholder'>{title}</p>
            }
            <MdOutlineArrowDropDown />
          </div>
          :
          <>
            {
              title &&
              <div className="dropdown__title">{title}</div>
            }
            <span className={`input_field__input_box--text flex dropdown classic`} style={{ height, marginTop }} onClick={handleClickDropdown}>
              <p className="dropdown-input--text_field" style={{ fontSize, fontStyle }}>{localState}</p>
              <KeyboardArrowDownIcon />
            </span>
            <ul className={`input_field__dropdown classic ${inherit && "inherit"}`} id={idList} key={index}>

              {
                typeof listArray[0] === 'object' && listArray[0] !== null && (
                  listArray.map((item, index) => (
                    <li className='dropdown__item' key={index} data-index={item.id} style={{ fontSize }} onClick={onclickMenuItem}>
                      {
                        Object.entries(item).map((entry, index) => {
                          if (entry[0] != "id") {
                            return (
                              <div key={index}>
                                {entry[1]}
                              </div>
                            )
                          }
                        })
                      }
                    </li>
                  ))
                )
              }

              {
                (typeof listArray[0] === 'string' || typeof listArray[0] === 'number') && (
                  listArray.map((item, index) => {
                    return (
                      <li className='dropdown__item--single' key={index} style={{ fontSize }} onClick={onclickMenuItem}>
                        {item}
                      </li>
                    )
                  })
                )
              }

            </ul>
          </>
      }
    </div>
  )
}

export default DropDownCustomDetails

DropDownCustomDetails.defaultProps = {
  marginTop: "5px",
  fontStyle: "normal",
  inherit: false,
  title: null,
  style: "normal",
}