import React, { useRef } from 'react'
import { usePopup } from './context/NotificationPopupContext'
import './NotificationPopupMobile.css'

function NotificationPopupMobile({ children }) {

  const { popupState, hidePopup } = usePopup()
  const thisPopupContainerRef = useRef(null)

  const handleClosePopup = () => {
    hidePopup()
  }

  const actionButtonClick = (e) => {
    e.preventDefault()

    popupState.actionButtonFn()
  }

  return (
    <div className={`popout_container__mobile--notification ${popupState.isActive ? "active" : ""}`} onClick={hidePopup}>
      {
        children ?
          <>
            {children}
          </> :
          <div className="notification-popup__elements--mobile">
            <div className="notification-popup__elements__title--mobile">
              <h3>{popupState.title}</h3>
            </div>
            <div className="notification-popup__elements__text--mobile">
              {popupState.message}
            </div>

            <div className='notification-popup__buttons'>
              <button>Go back</button>
              <button
                className={`${popupState.actionButtonColor}`}
                onClick={actionButtonClick}
              >
                {popupState.actionButtonText}
              </button>
            </div>
          </div>
      }
    </div>
  )
}

export default NotificationPopupMobile