import React, { useState, useEffect } from 'react'
import './NotificationPopup.css'
import CloseIcon from '@mui/icons-material/Close'
import { useSelector, useDispatch } from "react-redux"
import ReactDOM from 'react-dom'

export function showNotificationPopup({
  popupID = null,
  title = '',
  message = '',
  actionButtonOnClick = () => { },
  popupButtonColor = 'green',
  actionButtonText = '',
  onCloseFn = () => { },
}) {
  const overlay = popupID ? document.querySelector(`#${popupID} .notification-popup__container`) : document.querySelector('#notification-popup__container')
  const popupBox = popupID ? document.querySelector(`#${popupID}`) : document.querySelector('#notification-popup-box')

  if (overlay && popupBox) {

    // Set the dynamic content
    if (title) document.querySelector(`#${popupID || 'notification-popup-box'} .notification-popup__elements__title h3`).innerText = title
    if (message) document.querySelector(`#${popupID || 'notification-popup-box'} .notification-popup__elements__text p`).innerText = message
    if (actionButtonText) document.querySelector(`#${popupID || 'notification-popup-box'} .notification-popup__elements__buttons__button--confirm`).innerText = actionButtonText

    const confirmButton = document.querySelector(`#${popupID || 'notification-popup-box'} .notification-popup__elements__buttons__button--confirm`)
    const cancelButton = document.querySelector(`#${popupID || 'notification-popup-box'} .notification-popup__elements__buttons__button--cancel`)

    // Combine the actionButtonOnClick and closeNotification functions
    confirmButton.onclick = () => {
      actionButtonOnClick()
      closePopupNotification(popupID)
    }

    overlay.classList.add('active')
    popupBox.classList.add('active')

    // Combine the closeNotification and onClosefn functions
    cancelButton.onclick = () => {
      closePopupNotification(popupID)
      onCloseFn()
    }

    confirmButton.style.backgroundColor = popupButtonColor === 'red' ? 'var(--red)' : 'var(--primary-green)'
  }
}

export function closePopupNotification(popupID = null) {
  const overlay = popupID ? document.querySelector(`#${popupID} .notification-popup__container`) : document.querySelector('#notification-popup__container')
  const popupBox = popupID ? document.querySelector(`#${popupID}`) : document.querySelector('#notification-popup-box')

  if (overlay && popupBox) {
    overlay.classList.remove('active')
    popupBox.classList.remove('active')
  }
}

export function NotificationPopup({ popupID, title, message, actionButtonOnClick, popupButtonColor, actionButtonText, children, onCloseFn }) {

  useEffect(() => {
    let popup = null

    if (popupID == null | popupID == undefined) {
      popup = document.getElementById('notification-popup-box')
    } else {
      popup = document.getElementById(`${popupID}`)
    }

    window.addEventListener('keydown', e => {
      if (e.key === 'Escape') {
        if (popup.classList.contains("active")) {
          closePopup(null, popupID)
        }
      }
    })
  }, [])

  const closePopup = (event) => {
    onCloseFn()
    closePopupNotification()
  }

  return (
    <div className='notification-popup__overlay--popup-notification' id={`${popupID != undefined ? popupID : "notification-popup-box"}`}>
      <div className='notification-popup__container' id='notification-popup__container'>
        <CloseIcon id="popup__close-icon" onClick={() => closePopup(null, popupID)} />
        <div className="notification-popup__elements">
          <div className="notification-popup__elements__title">
            <h3>{title}</h3>
          </div>
          <div className="notification-popup__elements__text">
            <p>{message}</p>
          </div>
          <div className="notification-popup__elements__buttons">
            <button
              className="notification-popup__elements__buttons__button notification-popup__elements__buttons__button--cancel"
              onClick={closePopup}
            >
              Go back</button>
            <button
              className="notification-popup__elements__buttons__button notification-popup__elements__buttons__button--confirm"
              onClick={actionButtonOnClick}
              style={{ backgroundColor: popupButtonColor == "red" ? "var(--red)" : "var(--primary-green)" }}
            >
              {actionButtonText}</button>
          </div>
        </div>
      </div>
    </div>
  )
}

NotificationPopup.defaultProps = {
  onCloseFn: () => console.log(),
  popupButtonColor: "green",
  popupID: null,
}