import React from 'react'
import './ActionPopupMobile.css'

function ActionPopupMobile({ children, isActive }) {
  return (
    <div className={`popout_container__mobile ${isActive ? "active" : ""}`}>
        {children}
    </div>
  )
}

export default ActionPopupMobile