import React, { useState, useEffect, useRef } from "react"
import httpClient from "../httpClient"
import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { addUserInfo } from "../store/user"
import "./HomePageSalesForce.css"
import Header from "../components/Header"
import MenuBar from "../components/MenuBar"
import ComingSoonOverlay from "../components/placeholders/ComingSoonOverlay"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import BestSellers from "../components/BestSellers"
import MySales from "../components/MySales"
import TodoToday from "../components/TodoToday"
import TotalSales from "../components/TotalSales"
import { askPermissionAndSubscribe } from "../components/helpers/ServiceWorker"

function HomePageSalesForce() {
  const [user, setUser] = useState({ email: "", id: "" })
  const [loaddingBestsellers, setLoaddingBestsellers] = useState(true)
  const [loadingSales, setLoadingSales] = useState(true)
  const [loadingActivities, setLoadingActivities] = useState(true)
  const [error, setError] = useState(false)
  const [orderTotals, setOrderTotals] = useState([])
  const [bestsellers, setBestsellers] = useState({})
  const [lastMonthSales, setLastMonthSales] = useState([])
  const [thisMonthSales, setThisMonthSales] = useState([])
  const [salesReportPeriod, setSalesReportPeriod] = useState("this month")
  const [activities, setActivities] = useState([])

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const userInfo = useSelector(state => state.user)
  const previousUserIdRef = useRef()

  const noTaskPicto = require(`../assets/pictos/no-task.png`)

  let key = 0
  let counter = 1
  let counter2 = 1
  const now = new Date()

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
  )

  const fetchUser = async () => {
    const responseUser = await httpClient.get(process.env.REACT_APP_API_URL + "/api/@me?type=full")
    setUser(responseUser.data)
  }

  const fetchActivities = async () => {
    setLoadingActivities(true)

    const data = {
      filters: {
        doneFilter: true,
      },
    }

    const response = await httpClient.post(process.env.REACT_APP_API_URL + `/api/activities/get?listType=today`, data)
    setActivities(response.data.activities)
    setLoadingActivities(false)
  }

  useEffect(() => {
    fetchUser()
      .catch(err => {
        console.log(err)
        setError(true)
      })

    fetchActivities()
      .catch(err => {
        console.log(err)
        setError(true)
      })

    document.getElementById("header-title").classList.remove("hide")
  }, [])

  useEffect(() => {
    if (userInfo.id && userInfo.id !== previousUserIdRef.current) {
      previousUserIdRef.current = userInfo.id
      askPermissionAndSubscribe(userInfo.id)
    }
  }, [userInfo])

  return (
    <div className="template">
      {error ? window.location.href = "/" : ""}

      <Header />
      <MenuBar />
      <div className="template__body">
        <div className="body__home-salesforce">
          <div className="body__section" id="section1">
            <TotalSales/>
          </div>
          <div className="body__section" id="section2">
            <BestSellers />
          </div>
          <div className="body__section" id="section3">
            <MySales />
          </div>
          <div className="body__section" id="section4">
            <ComingSoonOverlay />
            <h2 className="flex">hunting list</h2>

          </div>
          <div className="body__section" id="section5">
            <TodoToday />
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomePageSalesForce