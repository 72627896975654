import React, { useState, useEffect, useRef } from "react"
import httpClient from "../httpClient"
import { useSelector, useDispatch } from "react-redux"

import DropDownInputCustomer from "../components/DropDownInputCustomer"
import { changeNotificationMessage } from "../store/notification"
import { NotificationTop, showNotification } from "../components/NotificationTop"
import { NotificationPopupMessage, showNotificationPopupMessage } from "../components/NotificationPopupMessage"
import { showNotificationPopup } from "../components/NotificationPopup"
import "./SalesPageAdmin.css"
import Header from "../components/Header"
import MenuBar from "../components/MenuBar"
import AddProductForSales from "../components/AddProductForSales"
import Slider from "../components/Slider"
import { closePopupWindow, PopupWindow } from "../components/PopupWindow"
import CheckBox from "../components/CheckBox"
import CheckBoxStatic from "../components/CheckBoxStatic"
import LoadingSpinner from "../components/placeholders/LoadingSpinner"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import DoneIcon from '@mui/icons-material/Done'
import { Link } from "react-router-dom"
import { Skeleton } from "@mui/material"
import isEqual from 'lodash/isEqual'
import LoadingOverlay from "../components/placeholders/LoadingOverlay"
import Label from "../components/Label"
import { exportToExcel } from "../components/helpers/Exports"
import { formatDateExportTitle } from "../components/helpers/FormatDate"
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import Dropdown from '../components/Dropdown'
import { couriers } from "../assets/Data"
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated'
import PopupWindowCreateNewCustomer from "../components/PopupWindowCreateNewCustomer"
import { resetStateSalesPageBrandOwner, updateFilters, updateOrderList } from "../store/salesPageBrandOwner"
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { ToastContainer, toast } from "react-toastify"
import "../styling/ReactToastify.css"
import DropdownButton from "../components/DropdownButton"
import TableCheckmarkHeader from '../components/TableCheckmarkHeader'
import TableCheckmarkRow from '../components/TableCheckmarkRow'

function SalesPageAdmin() {
  const [user, setUser] = useState({ email: "", id: "" })
  const [loading, setLoading] = useState(true)
  const [loadingProgressStep, setLoadingProgressStep] = useState(false)
  const [error, setError] = useState(false)
  const [selectedCustomer, setSelectedCustomer] = useState("")
  const [selectedCustomerID, setSelectedCustomerID] = useState("")
  const [selectedProducts, setSelectedProducts] = useState([])
  const [countArray, setCountArray] = useState([1])
  const [customers, setCustomers] = useState([])
  const [products, setProducts] = useState([])
  const [selectedOrder, setSelectedOrder] = useState([])
  const [progressChangesArray, setProgressChangesArray] = useState([])
  const [hideCompleted, setHideCompleted] = useState(true)
  const [comment, setComment] = useState("")
  const [showPopup, setShowPopup] = useState(true)
  const [orderEvents, setOrderEvents] = useState([])
  const [updatedTrackingID, setUpdatedTrackingID] = useState()
  const [updatedShippingCode, setUpdatedShippingCode] = useState()
  const [progressChangeOverwrite, setProgressChangeOverwrite] = useState(false)
  const [progressStepStage, setProgressStepStage] = useState("")
  const [progressStepOrderID, setProgressStepOrderID] = useState("")
  const [newCustomerPopupIsActive, setNewCustomerPopupIsActive] = useState(false)
  const [createOrderIsActive, setCreateOrderIsActive] = useState(false)
  const [orderListType, setOrderListType] = useState("new")
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(20)
  const [orderAmounts, setOrderAmounts] = useState({
    "new": 0,
    "toDeliver": 0,
    "atNeighbour": 0,
    "atPickup": 0,
    "problems": 0,
    "completed": 0
  })
  const [searchWordFilter, setSearchWordFilter] = useState("")
  const [popupWindowSalesDetailIsOpen, setPopupWindowSalesDetailIsOpen] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])

  const dispatch = useDispatch()
  const inputRef1 = useRef(null)
  const inputRef2 = useRef(null)
  const userInfo = useSelector(state => state.user.userInfo)
  const orders = useSelector(state => state.salesPageBrandOwner.ordersList)
  let key = 0
  let key2 = 1

  const dateOptions = {
    timeZone: 'Europe/Brussels',
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric"
  }

  const selectedOrderData = orders.filter(order => order.id == selectedOrder.id)[0]

  const productIDArray = [... new Set(selectedProducts.map(product => product.productID))]
  const filteredProducts = products.filter(product => !productIDArray.includes(String(product.id)))

  const requester = userInfo.type

  const fetchUser = async () => {
    const responseUser = await httpClient.get(process.env.REACT_APP_API_URL + "/api/@me?type=full")
    setUser(responseUser.data)
  }

  const fetchCustomers = async () => {
    const responseCustomers = await httpClient.get(process.env.REACT_APP_API_URL + "/api/users/get?requester=" + requester)
    setCustomers(responseCustomers.data)
  }

  const fetchProducts = async () => {
    const responseProducts = await httpClient.get(process.env.REACT_APP_API_URL + "/api/get-my-products?requester=" + requester)
    setProducts(responseProducts.data)
  }

  const fetchOrders = async () => {
    setLoading(true)

    const data = {
      // filters: {
      //   search_word: searchWordFilter,
      //   statusFilter: filters.statusFilter
      // },
    }
    const responseOrders = await httpClient.post(process.env.REACT_APP_API_URL + `/api/admin/orders/get?requester=${requester}&type=${orderListType}&page=${page}&limit=${limit}`, data)
    dispatch(updateOrderList(responseOrders.data.orders))
    setOrderAmounts(responseOrders.data.amounts)

    setLoading(false)
  }

  const fetchOrderEvents = async (orderID) => {
    const responseOrderEvents = await httpClient.get(process.env.REACT_APP_API_URL + "/api/brandowner/order/get-order-events?orderID=" + orderID)
    setOrderEvents(responseOrderEvents.data)
  }

  useEffect(() => {
    fetchUser()
      .catch(err => {
        console.log(err)
        setError(true)
      })

    fetchCustomers()
      .catch(err => {
        console.log(err)
      })

    fetchProducts()
      .catch(err => {
        console.log(err)
      })

    fetchOrders()
      .catch(err => {
        console.log(err)
      })
  }, [])

  useEffect(() => {
    if (page == 1) {
      fetchOrders()
    } else {
      setPage(1)
    }
  }, [orderListType])

  useEffect(() => {
    fetchOrders()
  }, [page, searchWordFilter])

  useEffect(() => {
    setUpdatedTrackingID(selectedOrder.tracking_id)
  }, [selectedOrder])

  useEffect(() => {
    if (inputRef1.current) {
      inputRef1.current.focus()
    }
  }, [updatedTrackingID])

  useEffect(() => {
    if (inputRef2.current) {
      inputRef2.current.focus()
    }
  }, [updatedShippingCode])


  const progressClickWarning = () => {
    actionProgressStep(progressStepOrderID, progressStepStage)
  }

  const deleteOrder = async () => {
    const data = {
      orderIDs: [selectedOrder.id]
    }

    try {
      const response = await httpClient.post(process.env.REACT_APP_API_URL + `/api/brandowner/order/delete`, data)

      if (response.status == 200) {
        toast.success("Order has been deleted")

        console.log(response.data)
        setPopupWindowSalesDetailIsOpen(false)
        fetchOrders()
      }
      if (response.status == 401) {
        toast.error(`${response.data}`, { theme: 'dark' })
      }

    } catch (error) {
      console.log(error)
    }
  }

  const handleOnClickDropdownItem = (event) => {
    event.currentTarget.parentElement.classList.remove("active")

    setSelectedCustomer(event.currentTarget.firstChild.textContent)
    setSelectedCustomerID(event.currentTarget.getAttribute("id"))
  }

  const handleOnClickExtraProduct = () => {
    const inputs = document.getElementsByClassName("dropdown-input--text_field")
    let ok = true
    Array.from(inputs).forEach(element => {
      if (element.innerHTML == "Select product") {
        element.parentElement.classList.add("red-border")
        ok = false
      }
    })

    ok && setCountArray([...countArray, countArray.length + 1])
  }

  const DeleteProductLine = (event) => {
    const identifier = event.currentTarget.parentElement.parentElement.id
    console.log(identifier)
    setCountArray(currentArray => currentArray.filter(element => element != identifier))
    const index = identifier - 1

    const updatedArray = [...selectedProducts]
    updatedArray.splice(index, 1)
    setSelectedProducts(updatedArray)
  }

  const handleCreateOrder = async () => {
    const data = {
      "customer": selectedCustomerID,
      "products": selectedProducts,
    }
    let ok = true

    const productDropdowns = document.getElementsByClassName("dropdown-input--text_field")
    Array.from(productDropdowns).forEach(item => {
      if (item.innerHTML == "Select product") {
        item.parentElement.classList.add("red-border")
        ok = false
      }
    })

    const customerField = document.getElementsByClassName("input_field__input_box--text input-dropdown")
    Array.from(customerField).forEach(item => {
      if (item.value == "") {
        item.classList.add("red-border")
        ok = false
      }
    })

    if (!ok) {
      toast.error("Please fill out all fields", { theme: "dark" })
    } else {
      const response = await toast.promise(
        httpClient.post(process.env.REACT_APP_API_URL + "/api/salesforce/order/send", data),
        {
          success: "Order has been send to the supplier(s)",
          pending: "Sending order to suppliers...",
          error: "Something went wrong"
        }
      )
      console.log(response.data)

      // Clean up
      setSelectedCustomer("")
      setSelectedProducts([])
      setCountArray([1])
      setComment("")
      Array.from(productDropdowns)[0].innerHTML = "Select product"
      const checkboxes = document.querySelectorAll(".checkbox--box")
      Array.from(checkboxes).forEach(checkbox => {
        checkbox.classList.remove("checked")
      })

      fetchOrders()
    }
  }

  const handleOnClickExpand = () => {
    const newSalesForm = document.getElementById("new-sales-form-brandowner")
    const allOrdersTable = document.getElementById("sales-history-table-brandowner")

    newSalesForm.classList.toggle("hide")
    allOrdersTable.classList.toggle("big")
    setCreateOrderIsActive(!createOrderIsActive)
  }

  function findChangedRowIndex(oldData, newData) {
    for (let i = 0; i < oldData.length; i++) {
      if (!isEqual(oldData[i], newData[i])) {
        return oldData[i].id;
      }
    }
    return -1;
  }

  const handleClickProgressStep = async (event) => {
    const parentElement = event.currentTarget.parentElement.parentElement
    const orderID = parentElement.getAttribute("data-orderid")
    setProgressStepOrderID(orderID)
    const progressStage = event.currentTarget.getAttribute("data-progressstage")
    setProgressStepStage(progressStage)
    const thisOrder = orders.find(order => order.id == orderID)

    // if user is pro & supplier is Stoqup, give a warning

    if (
      user.is_pro_account &
      user.type != "admin" &
      thisOrder.supplier == "STOQUP" &
      (progressStage == "Ready for delivery" | progressStage == "Delivered") &
      !progressChangeOverwrite
    ) {
      showNotificationPopup({
        title: 'Are you sure?',
        message: "This order is being handled by STOQUP, the steps 'Ready for delivery' and 'Delivered' are controlled by STOQUP. Pressing 'Overwrite' will change the status anyway, but this could lead to errors in process flow.",
        actionButtonOnClick: () => progressClickWarning(),
        actionButtonText: 'Overwrite',
        popupButtonColor: 'red',
      })

      return
    }

    actionProgressStep(orderID, progressStage)
  }

  const actionProgressStep = async (orderID, progressStage) => {

    const bodySection = document.querySelector("#sales-history-table-brandowner")
    bodySection.classList.add("overflow-hidden")

    const data = {
      'orderIDs': [orderID]
    }

    setLoadingProgressStep(true)

    const response = await httpClient.post(process.env.REACT_APP_API_URL + "/api/brandowner/order/change-status?" + "progressStage=" + progressStage + "&requester=" + userInfo.type, data)
    console.log(response.data)

    await fetchOrders()

    bodySection.classList.remove("overflow-hidden")
    setLoadingProgressStep(false)
  }

  const handleHideCompleted = () => {
    setHideCompleted(!hideCompleted)
  }

  const copyTextOnClick = (event) => {
    const text = event.currentTarget.innerHTML
    navigator.clipboard.writeText(text)
  }

  const showOrderDetails = (order) => {
    setPopupWindowSalesDetailIsOpen(true)

    setSelectedOrder(order)
    fetchOrderEvents(order.id)
  }

  const handleOnClickCheckDelivery = async (orderItemID) => {

    const reponse = await httpClient.get(process.env.REACT_APP_API_URL + "/api/brandowner/order/change-status-to-delivered?orderItemID=" + orderItemID + "&requester=" + userInfo.type)
    console.log(reponse.data)

    const message = "Changes have been saved"
    const errorType = "green"
    dispatch(changeNotificationMessage(message))
    showNotification(errorType)

    fetchOrderEvents(selectedOrder.id)
    fetchOrders()
  }

  function PopupElements() {

    return (
      <div className="popup-elements--admin">
        <div className="client">
          <div className="popup_window__sub-title">Client</div>
          <div className="column2">
            <div className="client-info-item">
              <h2>Business Name</h2>
              <p onClick={copyTextOnClick}>{selectedOrder.customer.business_name}</p>
            </div>
            <div className="client-info-item">
              <h2>Delivery Address</h2>
              <p onClick={copyTextOnClick}>{selectedOrder.customer.delivery_address_street} {selectedOrder.customer.delivery_address_nr}</p>
              <div className="double-field-30-70">
                <p onClick={copyTextOnClick}>{selectedOrder.customer.delivery_address_pc}</p>
                <p onClick={copyTextOnClick}>{selectedOrder.customer.delivery_address_place}</p>
              </div>
            </div>
            <div className="client-info-item">
              <h2>Contact Info</h2>
              <p onClick={copyTextOnClick}>{selectedOrder.customer.contact_name}</p>
              <p onClick={copyTextOnClick}>{selectedOrder.customer.contact_email}</p>
              <p onClick={copyTextOnClick}>{selectedOrder.customer.contact_tel}</p>
            </div>
          </div>

          <div className="column2">
            <div className="client-info-item">
              <h2>Company Name</h2>
              <p onClick={copyTextOnClick}>{selectedOrder.customer.company_name}</p>
            </div>
            <div className="client-info-item">
              <h2>Invoicing Address</h2>
              <p onClick={copyTextOnClick}>{selectedOrder.customer.invoice_address_street} {selectedOrder.customer.invoice_address_nr}</p>
              <div className="double-field-30-70">
                <p onClick={copyTextOnClick}>{selectedOrder.customer.invoice_address_pc}</p>
                <p onClick={copyTextOnClick}>{selectedOrder.customer.invoice_address_place}</p>
              </div>
            </div>
            <div className="client-info-item">
              <h2>Invoice Info</h2>
              <p onClick={copyTextOnClick}>{selectedOrder.customer.VAT_number}</p>
              <p onClick={copyTextOnClick}>{selectedOrder.customer.invoice_email}</p>
            </div>
          </div>
        </div>
        {
          userInfo.type == "admin" &&
          <div className="tracking">
            <div className="popup_window__sub-title">Tracking</div>
            <div className="client-tracking-info">
              <input
                ref={inputRef1}
                className={`input_field__input_box--text--inrow ${selectedOrder.tracking_id ? "disabled" : ""}"`}
                id={`tracking_code_${selectedOrder.id}`}
                value={updatedTrackingID}
                onChange={(e) => setUpdatedTrackingID(e.target.value)}
                placeholder={"Add tracking code"}
              />
              <Dropdown
                placeholder={selectedOrder.shipping_company_code != "" ? selectedOrder.shipping_company_code : "Add courier"}
                state={updatedShippingCode}
                setState={setUpdatedShippingCode}
                listArray={couriers}
                height={"25px"}
                marginTop={"0px"}
                fontStyle={"normal"}
                inherit={true}
              />

              <button className="secondairy-button" onClick={() => updateTrackingCode(selectedOrder.id)}>Save</button>
            </div>
          </div>
        }
        <div className="order">
          <div className="popup_window__sub-title">Order</div>
          <div className="table__secondairy-header-6">
            <div className="table__secondairy-header--field">Amount</div>
            <div className="table__secondairy-header--field">SKU</div>
            <div className="table__secondairy-header--field">Product</div>
            <div className="table__secondairy-header--field">Volume</div>
            <div className="table__secondairy-header--field">Supplier</div>
            <div className="table__secondairy-header--field">Delivered</div>
          </div>
          {
            selectedOrderData != null &&
            selectedOrderData.products.length != 0 &&
            selectedOrderData.products.map(product => {
              return (
                <div key={key++}>
                  <div className="sales__orders--product-rows" key={key++}>
                    <div className="table__secondairy-row--field" onClick={copyTextOnClick}>{product.quantity}</div>
                    <div className="table__secondairy-row--field" onClick={copyTextOnClick}>{product.sku}</div>
                    <div className="table__secondairy-row--field" onClick={copyTextOnClick}>{product.product}</div>
                    <div className="table__secondairy-row--field" onClick={copyTextOnClick}>{product.volume}</div>
                    <div className="table__secondairy-row--field" onClick={copyTextOnClick}>{product.supplier}</div>
                    <div className="table__secondairy-row--field" onClick={copyTextOnClick}><div style={{ height: "20px", width: "20px" }} onClick={() => handleOnClickCheckDelivery(product.order_item_id)} ><CheckBoxStatic labelDisabled={true} state={product.delivered} /></div></div>
                  </div>
                </div>
              )
            })
          }
        </div>

        <div className="history no-scrollbar">
          <div className="popup_window__sub-title">History</div>
          <div className="timeline__container">

            <div className="timeline__event">
              <div className="timeline__event--date">{new Date(selectedOrder.date).toLocaleString('eng-US', dateOptions)}</div>
              <div className="timeline__event--label blue"><div className="ball"></div><h4>CREATED</h4></div>
              <div className="timeline__event--comment">{`Order created by ${selectedOrder.sales_by}`}</div>
            </div>

            {
              orderEvents.map(event => {
                return (
                  <div className="timeline__event" key={key2++}>
                    <div className="timeline__event--date">{new Date(event.timestamp).toLocaleString('eng-US', dateOptions)}</div>
                    <div className={
                      event.event_label == "CREATED" ?
                        "timeline__event--label blue" :
                        event.event_label == "DELIVERED" ?
                          "timeline__event--label green" :
                          event.event_label == "CANCELLATION" ?
                            "timeline__event--label red" :
                            "timeline__event--label grey"
                    }>
                      <div className="ball"></div>
                      <h4>{event.event_label}</h4>
                    </div>
                    <div className="timeline__event--comment">{event.event_description}</div>
                  </div>
                )
              })
            }

          </div>
        </div>
      </div>
    )
  }

  const updateTrackingCode = async (orderID) => {
    let trackingID = updatedTrackingID
    let shippingCode = updatedShippingCode

    if (updatedTrackingID == undefined | updatedTrackingID == null) {
      trackingID = selectedOrder.tracking_id
    } else if (updatedShippingCode == undefined | updatedShippingCode == null) {
      shippingCode = selectedOrder.shipping_company_code
    }
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/brandowner/order/change-tracking-code?orderID=" + orderID + "&trackingID=" + trackingID + "&shippingCode=" + shippingCode)
    console.log(response.data)

    if (response.data['error']) {
      const message = response.data['error']
      dispatch(changeNotificationMessage(message))
      showNotificationPopupMessage()
    } else if (response.data['success']) {
      const message = "We have checked the order and it has already been delivered."
      const errorType = "green"
      dispatch(changeNotificationMessage(message))
      showNotification(errorType)
    } else {
      const message = "Tracking information successfully added"
      const errorType = "green"
      dispatch(changeNotificationMessage(message))
      showNotification(errorType)
    }

    fetchOrders()
  }

  const handleExportAllSales = () => {

    const now = Date.now()
    const fileName = `STOQUP_export_sales_all_${formatDateExportTitle(now)}`
    const exportData = []

    orders.forEach(order => {
      const orderRow = {
        order_id: order.id,
        date: order.date,
        sales_by: order.sales_by,
        type: order.type,
        customer_business_name: order.customer.business_name,
        customer_contact_email: order.customer.contact_email,
        customer_contact_name: order.customer.contact_name,
        customer_contact_tel: order.customer.contact_tel,
        customer_delivery_address_street: order.customer.delivery_address_street,
        customer_delivery_address_nr: order.customer.delivery_address_nr,
        customer_delivery_address_pc: order.customer.delivery_address_pc,
        customer_delivery_address_place: order.customer.delivery_address_place,
        supplier: order.supplier,
        status: order.status,
      }

      order.products.forEach(product => {
        orderRow[`${product.product} ${product.volume}`] = product.quantity
      })

      exportData.push(orderRow)
    })

    exportToExcel(exportData, fileName)

  }

  const handleCheckAllDeliveries = async () => {
    setLoading(true)
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/admin/check-all-deliveries")
    console.log(response.data)
    fetchOrders()
  }

  const handleOnCLickHeaderItem = (event) => {
    const headerItem = event.currentTarget
    const headerItemNumber = event.currentTarget.children[0]
    const allHeaderItems = document.getElementsByClassName("body__section__header_item")
    const allHeaderItemNumbers = document.getElementsByClassName("header_item--number")

    setLoading(true)

    allHeaderItemNumbers.forEach(item => {
      item.classList.remove("selected")
    })
    allHeaderItems.forEach(item => {
      item.classList.remove("selected")
    })

    headerItem.classList.add("selected")
    headerItemNumber.classList.add("selected")

    switch (event.currentTarget.getAttribute("data-id")) {
      case "header-new":
        setOrderListType("new")
        break
      case "header-to-deliver":
        setOrderListType("toDeliver")
        break
      case "header-at-neighbour":
        setOrderListType("atNeighbour")
        break
      case "header-at-pickup":
        setOrderListType("atPickup")
        break
      case "header-problems":
        setOrderListType("problems")
        break
      case "header-completed":
        setOrderListType("completed")
        break
    }
  }

  const handleOnMouseOver = (event) => {
    const headerItem = event.currentTarget
    const headerItemNumber = event.currentTarget.children[0]

    headerItem.classList.add("hovered")
    headerItemNumber.classList.add("hovered")
  }

  const handleOnMouseOut = (event) => {
    const headerItem = event.currentTarget
    const headerItemNumber = event.currentTarget.children[0]

    headerItem.classList.remove("hovered")
    headerItemNumber.classList.remove("hovered")
  }

  const handlePagination = (event) => {
    const element = event.currentTarget

    if (element.classList.contains("grayed-out")) {
      return
    }

    const type = element.dataset.type

    switch (type) {
      case "previous":
        setPage(page - 1)
        break
      case "next":
        setPage(page + 1)
        break
    }
  }

  const handleDoneAndNext = async () => {
    setLoadingProgressStep(true)
    const button = document.getElementById("customers__btn-done")
    button.disabled = true

    const response = await httpClient.post(process.env.REACT_APP_API_URL + "/api/brandowner/order/change-status?orderID=" + selectedOrder.id + "&progressStage=Ready for delivery" + "&requester=" + userInfo.type)
    console.log(response.data)

    const responseOrders = await toast.promise(
      httpClient.get(process.env.REACT_APP_API_URL + `/api/admin/orders/get?requester=${requester}&type=${orderListType}&page=${page}&limit=${limit}`),
      {
        pending: "Saving changes",
        success: "Changes have been saved!",
        error: "Something went wrong"
      })
    dispatch(updateOrderList(responseOrders.data.orders))

    // get the next order and set as selectedOrder

    const currentIndex = orders.findIndex(obj => obj.id === selectedOrder.id)
    const nextIndex = (currentIndex + 1)

    console.log(nextIndex, orders.length)
    console.log(nextIndex >= orders.length)

    if (nextIndex >= orders.length) {
      setLoadingProgressStep(false)
      setPopupWindowSalesDetailIsOpen(false)
    } else {
      setSelectedOrder(orders[nextIndex])
    }

    setLoadingProgressStep(false)
    button.disabled = false
  }

  const handleSendToWebship = async (event) => {
    event.stopPropagation()

    setLoadingProgressStep(true)

    const orderID = event.currentTarget.getAttribute("data-order-id")
    const bodySection = document.querySelector("#sales-history-table-brandowner")
    bodySection.classList.add("overflow-hidden")

    const response = await toast.promise(httpClient.post(process.env.REACT_APP_API_URL + `/api/admin/order/send-to-webship?orderID=${orderID}`),
      {
        pending: "Sending order to Webship...",
        success: "Order send to Webship!",
        error: `${response.data}`
      })

    if (response.status != 200) {
      return
    }

    await fetchOrders()

    bodySection.classList.remove("overflow-hidden")
    setLoadingProgressStep(false)
  }

  const handleDeleteOrder = () => {

    showNotificationPopup({
      title: 'Are you sure?',
      message: "Deleting an order can not be undone",
      actionButtonOnClick: () => deleteOrder(),
      actionButtonText: 'Yes, delete',
      popupButtonColor: 'red',
    })
  }

  const onClickSetStatusDropdownButton = async (value) => {
    setLoading(true)

    const orderIDs = []

    selectedRows.forEach(row => {
      const orderID = row.getAttribute('data-orderid')
      orderIDs.push(orderID)
    })

    const data = {
      orderIDs
    }

    const response = await httpClient.post(process.env.REACT_APP_API_URL + "/api/brandowner/order/change-status?progressStage=" + value + "&requester=" + userInfo.type, data)
    console.log(response.data)

    setSelectedRows([])
    fetchOrders()
  }

  return (
    <div className="template">
      {error ? window.location.href = "/" : ""}

      <button className="floating__button--bottom-right" id="add--btn" onClick={handleOnClickExpand} />

      <Header>
        {
          (!newCustomerPopupIsActive & !popupWindowSalesDetailIsOpen) ?
            <div className="header__options">
              <div className="header__option--slider"><Slider size={1.2} text="Hide completed" onClickFunction={handleHideCompleted} defaultState={true} idNr={"1"} /></div>
              <button className="options-button-header" onClick={handleExportAllSales}>Export all <ExitToAppIcon /></button>
              <button className="options-button-header" onClick={handleCheckAllDeliveries}>Check all deliveries <BrowserUpdatedIcon /></button>
            </div> :

            popupWindowSalesDetailIsOpen ?
              <div className="header__options--right">
                <button className="red-button-header" onClick={handleDeleteOrder}>DELETE ORDER</button>
                {
                  orderListType == "new" &&
                  <button className="green-button-header" id="customers__btn-done" onClick={handleDoneAndNext}>DONE & NEXT</button>
                }
              </div> : null
        }
        {
          !newCustomerPopupIsActive && <button className={`green-button-header ${createOrderIsActive ? "" : "hide"}`} id="customers__btn-save" onClick={handleCreateOrder}>SAVE</button>
        }
        <div className={`header__options--right ${newCustomerPopupIsActive ? "hide" : ""}`}>
          {
            selectedRows.length > 0 & !popupWindowSalesDetailIsOpen ?
              <>
                <DropdownButton
                  id="action-status"
                  title="Change status"
                  valuesArray={["Ready for delivery", "Delivered", "Invoiced"]}
                  onClickFn={onClickSetStatusDropdownButton}
                  disabled={loading}
                />
              </> : null
          }
        </div>
      </Header>
      <MenuBar />

      <ToastContainer position="bottom-right" />

      <NotificationTop />
      <NotificationPopupMessage />

      <div className="template__body">

        <div className="body__sales--admin">

          <PopupWindow
            popupID="sales_brandowner--client-info--popup"
            isActive={popupWindowSalesDetailIsOpen}
            setIsActive={setPopupWindowSalesDetailIsOpen}
          >
            {selectedOrder != undefined && selectedOrder.length != 0 && <PopupElements />}
          </PopupWindow>
          <PopupWindowCreateNewCustomer
            customers={customers}
            isActive={newCustomerPopupIsActive}
            setIsActive={setNewCustomerPopupIsActive}
            fetchCustomers={fetchCustomers}
            setSelectedCustomer={setSelectedCustomer}
            setSelectedCustomerID={setSelectedCustomerID}
          />

          <div className="body__section hide no-scrollbar" id="new-sales-form-brandowner">
            <div className="form__section">
              <h1>New order</h1>
              <div className="new-sales-form">
                <DropDownInputCustomer
                  idList={"sales__dropdownList--customer"}
                  placeholderText="Select the customer"
                  listArray={customers}
                  onClickItem={handleOnClickDropdownItem}
                  value={selectedCustomer}
                  setValue={setSelectedCustomer}
                  createNewCustomer={setNewCustomerPopupIsActive}
                />
                <div className="salespage--titles">
                  <div className="salespage--title">Product</div>
                  <div className="salespage--title">Amount</div>
                  <div className="checkboxes">
                    <div>Already delivered</div>
                    <div>On Consignation</div>
                  </div>
                  <div className="salespage--title">Supplier</div>
                  <div className="salespage--title">Comment</div>
                </div>
                {
                  countArray.map((count, index) => {
                    return <AddProductForSales
                      onClickDelete={DeleteProductLine}
                      key={index}
                      index={index}
                      identifier={count}
                      setValue={setSelectedProducts}
                      value={selectedProducts}
                      listArray={filteredProducts}
                      customerID={selectedCustomerID}
                    />
                  })
                }
                <button className="more-products--btn" onClick={handleOnClickExtraProduct}>+</button>
              </div>

            </div>
          </div>

          <div className="body__section__header">
            <div className="body__section__header_item selected" data-id="header-new" onClick={handleOnCLickHeaderItem} onMouseOver={handleOnMouseOver} onMouseOut={handleOnMouseOut}>New Orders <div className="header_item--number selected">{orderAmounts.new}</div></div>
            <div className="body__section__header_item" data-id="header-to-deliver" onClick={handleOnCLickHeaderItem} onMouseOver={handleOnMouseOver} onMouseOut={handleOnMouseOut}>to be delivered <div className="header_item--number">{orderAmounts.toDeliver}</div></div>
            <div className="body__section__header_item" data-id="header-at-neighbour" onClick={handleOnCLickHeaderItem} onMouseOver={handleOnMouseOver} onMouseOut={handleOnMouseOut}>at neighbour <div className="header_item--number">{orderAmounts.atNeighbour}</div></div>
            <div className="body__section__header_item" data-id="header-at-pickup" onClick={handleOnCLickHeaderItem} onMouseOver={handleOnMouseOver} onMouseOut={handleOnMouseOut}>At pickup point <div className="header_item--number">{orderAmounts.atPickup}</div></div>
            <div className="body__section__header_item" data-id="header-problems" onClick={handleOnCLickHeaderItem} onMouseOver={handleOnMouseOver} onMouseOut={handleOnMouseOut}>Problematic orders <div className="header_item--number">{orderAmounts.problems}</div></div>
            <div className="body__section__header_item" data-id="header-completed" onClick={handleOnCLickHeaderItem} onMouseOver={handleOnMouseOver} onMouseOut={handleOnMouseOut}>Completed <div className="header_item--number">{orderAmounts.completed}</div></div>
          </div>

          <div className="body__section big overflow-hidden" id="sales-history-table-brandowner">
            {loadingProgressStep && <LoadingOverlay />}
            {
              loading ? <LoadingSpinner /> :
                <div className="table">
                  <div className="table__header-10" id="table__header-sales-brandowners">
                    <TableCheckmarkHeader
                      id={`table-checkmark-header-sales--admin`}
                      selectedRows={selectedRows}
                      setSelectedRows={setSelectedRows}
                    />
                    <div className="table__header--field">ID</div>
                    <div className="table__header--field">Date</div>
                    <div className="table__header--field">Customer</div>
                    <div className="table__header--field">Sales by</div>
                    <div className="table__header--field">Ready for delivery</div>
                    <div className="table__header--field">Delivered</div>
                    <div className="table__header--field">{userInfo.type == "admin" ? "Tracking status" : "Invoiced"}</div>
                    <div className="table__header--field">Items</div>
                    <div className="table__header--field">{orderListType == "new" ? "Actions" : "Supplier"}</div>
                  </div>

                  <div className="table__rows_container">
                    {
                      orders.map((order, index) => {
                        return (
                          <span className="table__row-10" data-key={index} data-orderid={order.id} key={index}>
                            <TableCheckmarkRow
                              index={index}
                              id={`table-checkmark-row-sales--admin-${index}`}
                              selectedRows={selectedRows}
                              setSelectedRows={setSelectedRows}
                            />
                            <div className="table__row--field" onClick={() => showOrderDetails(order)}>{order.id}</div>
                            <div className="table__row--field" onClick={() => showOrderDetails(order)}>{new Date(order.date).toLocaleDateString()}</div>
                            <div className="table__row--field" onClick={() => showOrderDetails(order)}>{order.type == "B2C" ? order.customer.contact_name : order.customer.business_name}</div>
                            <div className="table__row--field" onClick={() => showOrderDetails(order)}>{order.sales_by}</div>
                            <div className="table__row--field--uncenter">
                              <div className={order.products.every(product => product.readyForDelivery == true) ? "progress__tracker--first" : "progress__tracker--first unfinished"} onClick={handleClickProgressStep} data-progressstage={"Ready for delivery"}>
                                <div className="progress__tracker_circle">{<DoneIcon className={!order.products.every(product => product.readyForDelivery == true) ? "faded-away" : "visible"} />}</div>
                              </div>
                            </div>
                            <div className="table__row--field--uncenter">
                              <div className={order.products.every(product => product.delivered == true) ? `progress__tracker${userInfo.type == "admin" ? '--last' : ''}` : `progress__tracker${userInfo.type == "admin" ? '--last' : ''} unfinished`} onClick={handleClickProgressStep} data-progressstage={"Delivered"}>
                                <div className="progress__tracker_circle">{<DoneIcon className={!order.products.every(product => product.delivered == true) ? "faded-away" : "visible"} />}</div>
                              </div>
                            </div>
                            {
                              userInfo.type == "admin" ?
                                <div className="tracking_status">
                                  {
                                    order.tracking_status == "Tracking live" ?
                                      <Label bright={true} fontSize="0.70rem" simple={true} labelColor="green" title="Tracking live" /> :
                                      order.tracking_status == "Tracking error" ?
                                        <Label bright={true} fontSize="0.70rem" simple={true} labelColor="red" title="Tracking error" /> :
                                        order.tracking_status == "No track info" ?
                                          <Label bright={false} fontSize="0.70rem" simple={true} labelColor="grey" title="No tracking info" /> :
                                          <Label bright={false} fontSize="0.70rem" simple={true} labelColor="grey" title="No tracking info" />
                                  }
                                </div> :
                                <div className="table__row--field--uncenter">
                                  <div className={order.products.every(product => product.invoiced == true) ? "progress__tracker--last" : "progress__tracker--last unfinished"} onClick={handleClickProgressStep} data-progressstage={"Invoiced"}>
                                    <div className="progress__tracker_circle">{<DoneIcon className={!order.products.every(product => product.invoiced == true) ? "faded-away" : "visible"} />}</div>
                                  </div>
                                </div>
                            }
                            <div className="table__row--field" onClick={() => showOrderDetails(order)}>{order.products.reduce((acc, product) => acc + product.quantity, 0)}</div>
                            <div className="table__row--field" onClick={() => showOrderDetails(order)}>
                              {
                                orderListType == "new" ?
                                  <button className="green-action-button" data-order-id={order.id} onClick={handleSendToWebship}>Send to Webship</button>
                                  :
                                  order.supplier
                              }
                            </div>
                          </span>
                        )
                      })
                    }
                  </div>
                </div>
            }
            <div className="pagination">
              <ArrowBackIosIcon className={`pagination__btn ${page == 1 ? "grayed-out" : ""}`} onClick={handlePagination} data-type="previous" id="prevPage" />
              <ArrowForwardIosIcon className={`pagination__btn ${orders.length < limit ? "grayed-out" : ""}`} onClick={handlePagination} data-type="next" id="nextPage" />
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

export default SalesPageAdmin