import { createAsyncThunk } from '@reduxjs/toolkit'
import httpClient from '../httpClient'
import { addMyTeam } from './user'

export const fetchMyTeam = createAsyncThunk(
  'user/fetchMyTeam',
  async (_, { getState, dispatch }) => {
    const { user } = getState()
    
    // Check if user is logged in and of the correct type
    if (!user.userInfo.id || (user.userInfo.type !== "brandowner" && user.userInfo.type !== "wholesaler")) {
      return
    }

    // Check if myTeam is already fetched
    if (user.myTeam !== null) {
      return
    }

    try {
      const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/brandowner/myteam?type=full`)
      const myTeamNamesArray = response.data
        .filter(obj => obj.active === true)
        .map(obj => ({ "id": obj.salesforce_id, "name": obj.salesforce_name }))

      dispatch(addMyTeam(myTeamNamesArray))
    } catch (error) {
      console.error('Error fetching myTeam:', error)
    }
  }
)