import React, { useEffect, useState } from "react"
import "./CheckBoxCustomDetails.css"

function CheckBoxCustomDetails({ index, width, height, textPosition, state, setState, text, style, margin, defaultState, parentIndex, setChangesMade }) {
  const [stateLocal, setStateLocal] = useState()

  useEffect(() => {
    if (defaultState != null) {
      setStateLocal(defaultState)
    }
  }, [])

  useEffect(() => {
    setStateLocal(state[parentIndex]["custom_details"][index].value)
  }, [state])

  const handleOnClickCheckbox = (event) => {
    event.stopPropagation()

    setChangesMade(true)
    setStateLocal(!stateLocal)

    try {
      // Create a deep copy of the state to avoid direct mutation
      const newState = JSON.parse(JSON.stringify(state))

      // Access and update the required value
      newState[parentIndex]["custom_details"][index].value = !newState[parentIndex]["custom_details"][index].value

      // Set the new state
      setState(newState)
    }
    catch (error) {
      console.log("No data to set")
    }
  }

  return (
    <div className={`checkbox__container ${text == undefined && "no-text"} ${textPosition == "vert" && "vert"} ${style}`} style={{ margin }} onClick={handleOnClickCheckbox}>
      {
        textPosition === "left" & text != undefined | textPosition == "vert" ? <p style={{ marginRight: "10px" }} className={`checkbox--text ${style}`}>{text}</p> : null
      }
      <div className={`checkbox--box__container ${text == undefined && "no-text"} ${style}`} style={{ width, height }}>
        <div className={`${stateLocal ? "checkbox--box checked" : "checkbox--box"} ${style}`}></div>
        <span className={`${stateLocal ? "checkmark checked" : "checkmark"} ${style}`}>
          <div className="checkmark_stem"></div>
          <div className="checkmark_kick"></div>
        </span>
      </div>
      {
        textPosition === "right" & text != undefined ? <p style={{ marginRight: "auto", marginLeft: "10px" }} className={`checkbox--text ${style}`}>{text}</p> : null
      }
    </div>
  )
}

export default CheckBoxCustomDetails

CheckBoxCustomDetails.defaultProps = {
  width: "30px",
  height: "30px",
  textPosition: "right",
  style: "light",
  margin: "10px 0 0 0",
  type: "normal"
}

