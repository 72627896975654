import React, { useState, useEffect, useRef } from "react"
import httpClient from "../httpClient"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import './InventoryPageBrandowner.css'
import Header from "../components/Header"
import MenuBar from "../components/MenuBar"
import ComingSoonOverlay from "../components/placeholders/ComingSoonOverlay"
import { closePopupWindow, PopupWindow } from "../components/PopupWindow"
import Dropdown from "../components/Dropdown"
import DropdownWarehouse from "../components/DropdownWarehouse"
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import CheckBox from "../components/CheckBox"
import { changeNotificationMessage } from "../store/notification"
import { NotificationTop, showNotification } from "../components/NotificationTop"
import { toast } from "react-toastify"
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { PopOutWindow } from "../components/PopOutWindow"
import ImportExportIcon from '@mui/icons-material/ImportExport'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import DvrIcon from '@mui/icons-material/Dvr'
import { PopOutWindowInventoryChange } from "../components/PopOutWindowInventoryChange"
import { PopOutWindowCreateShipment } from "../components/PopOutWindowCreateShipment"
import UnfoldMoreOutlinedIcon from '@mui/icons-material/UnfoldMoreOutlined'
import UnfoldLessOutlinedIcon from '@mui/icons-material/UnfoldLessOutlined'
import { PopOutWindowSettingsWarehouse } from "../components/PopOutWindowSettingsWarehouse"
import SettingsIcon from '@mui/icons-material/Settings'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { PopOutWindowAddDeleteWarehouse } from "../components/PopOutWindowAddDeleteWarehouse"
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import { PiPlusThin } from "react-icons/pi"
import LoadingSpinner from "../components/placeholders/LoadingSpinner"
import CircularProgress from '@mui/material/CircularProgress'
import SearchBarFilter from "../components/SearchBarFilter"
import Slider from "../components/Slider"

function ProductsPageBrandowner() {
  const [user, setUser] = useState({ email: "", id: "" })
  const [loading, setLoading] = useState(true)
  const [loadingProducts, setLoadingProducts] = useState(true)
  const [error, setError] = useState(false)
  const [products, setProducts] = useState([])
  const [materials, setMaterials] = useState([])
  const [productsAndMaterials, setProductsAndMaterials] = useState([])
  const [inventory, setInventory] = useState([])
  const [subscription, setSubscription] = useState()
  const [createInbound, setCreateInbound] = useState(false)
  const [createShipment, setCreateShipment] = useState(false)
  const [createInboundSelectedProducts, setCreateInboundSelectedProducts] = useState([])
  const [createInboundComment, setCreateInboundComment] = useState("")
  const [addProductCounter, setAddProductCounter] = useState(1)
  const [selectedWarehouses, setSelectedWarehouses] = useState({ "from": { "id": null, "name": null }, "to": { "id": null, "name": null } })
  const [inventoryChangeReason, setInventoryChangeReason] = useState("Other")
  const [selectedWarehouse, setSelectedWarehouse] = useState()
  const [addDelete, setAddDelete] = useState("")
  const [warehouseDetailIsActive, setWarehouseDetailIsActive] = useState(false)
  const [expandedSection, setExpandedSection] = useState(null)
  const [inventoryFilters, setInventoryFilters] = useState({
    "limit": 25,
    "page": 1,
    "searchWord": "",
    "hideEmpty": false
  })
  const [inventoryState, setInventoryState] = useState({
    "productsHasMore": false,
    "materialsHasMore": false,
    "materials": [],
    "products": [],
    "loadingMore": false,
  })

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const stoqupWarehouse = require("../assets/pictos/warehouse_picto_stoqup.png")
  const otherWarehousePink = require("../assets/pictos/warehouse_picto_other_pink.png")

  const userInfo = useSelector(state => state.user.userInfo)
  const requester = userInfo.type

  const fetchUser = async () => {
    const responseUser = await httpClient.get(process.env.REACT_APP_API_URL + "/api/@me?type=full")
    setUser(responseUser.data)
  }

  const fetchProducts = async () => {
    const responseProducts = await httpClient.post(`${process.env.REACT_APP_API_URL}/api/brandowner/products/get-products?type=products`, inventoryFilters)
    const responseMaterials = await httpClient.post(`${process.env.REACT_APP_API_URL}/api/brandowner/products/get-products?type=materials`, inventoryFilters)

    const newState = { ...inventoryState }
    newState.productsHasMore = responseProducts.data.has_more
    newState.products = responseProducts.data.products
    newState.materialsHasMore = responseMaterials.data.has_more
    newState.materials = responseMaterials.data.products
    newState.loadingMore = false
    setInventoryState(newState)

    setLoadingProducts(false)
  }

  const fetchInventory = async () => {
    // Fetch the inventory data from the server
    const responseInventory = await httpClient.post(`${process.env.REACT_APP_API_URL}/api/brandowner/inventory/get-inventory`, inventoryFilters)
    setInventory(responseInventory.data.inventory)

    // Check if there's a selected warehouse
    if (selectedWarehouse) {
      const filteredInventory = responseInventory.data.inventory.find(item => item.warehouse_id.id === selectedWarehouse.warehouse_id.id)

      // Update the inventory state to only include items from the selected warehouse
      setSelectedWarehouse(filteredInventory)
    }

    setLoading(false)
  }

  const fetchProductsList = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/brandowner/products/get-products")

    const newProductsAndMaterialsArray = response.data.map(({ id, name, sku, volume }) => {
      return (
        {
          id,
          sku,
          name,
          volume,
        }
      )
    })
    
    setProductsAndMaterials(newProductsAndMaterialsArray)
  }

  const fetchSubscription = async () => {
    const responseSubscription = await httpClient.get(process.env.REACT_APP_API_URL + "/api/brandowner/get-subscription")
    setSubscription(responseSubscription.data)
  }

  const warehouses = inventory.map(warehouse => {
    return {
      "id": warehouse.warehouse_id.id,
      "name": warehouse.warehouse_id.name,
    }
  })

  useEffect(() => {
    fetchUser()
      .catch(err => {
        console.log(err)
        setError(true)
      })

    fetchProducts()
      .catch(err => {
        console.log(err)
        setError(true)
      })

    fetchInventory()
      .catch(err => {
        console.log(err)
        setError(true)
      })

    fetchSubscription()
      .catch(err => {
        console.log(err)
        setError(true)
      })

      fetchProductsList()
      .catch(err => {
        console.log(err)
        setError(true)
      })
      
  }, [])

  useEffect(() => {
    fetchProducts()
    fetchInventory()
  }, [inventoryFilters.page])

  useEffect(() => {
    setLoadingProducts(true)
    setLoading(true)

    fetchProducts()
      .then(() => setLoadingProducts(false))

    fetchInventory()
      .then(() => setLoading(false))

  }, [inventoryFilters.searchWord, inventoryFilters.hideEmpty])

  const handleExpandSection = (section) => {
    setExpandedSection(prevSection => prevSection === section ? null : section)
  }

  const handleSaveInbound = async (createInboundStoqupOrder = false) => {

    const data = {
      products: createInboundSelectedProducts,
      warehouses: selectedWarehouses,
      comment: createInboundComment,
      stoqupOrderToCreate: createInboundStoqupOrder,
      reason: inventoryChangeReason,
    }

    console.log(data)

    // create inbound to do if target warehouse is STOQUP
    if (selectedWarehouses.to.name == "STOQUP") {
      const toDoData = {
        type: "inbound",
        importance: 2,
        todoJSON: {
          brandownerID: user.id,
          products: createInboundSelectedProducts,
        },
        comment: createInboundComment,
      }

      const response = await httpClient.post(process.env.REACT_APP_API_URL + "/api/todo/create", toDoData)
    }

    const response = await httpClient.post(process.env.REACT_APP_API_URL + "/api/brandowner/inventory/change", data)

   if (response.status === 200) {
      fetchInventory()
      fetchProducts()
      setCreateInbound(false)

      setCreateInboundSelectedProducts([])
      setSelectedWarehouses({ "from": { "id": null, "name": null }, "to": { "id": null, "name": null } })
      setCreateInboundComment("")
      setInventoryChangeReason("other")
      document.querySelector("#popout__inventory").classList.remove("active")

      toast.success("Changes succesfully registered")
    }

  }

  const handleCreateInbound = () => {
    const popup = document.getElementById(`popout__inventory`)
    popup.classList.add("active")
  }

  const handleCreateInboundFromDetail = (type) => {
    const popup = document.getElementById(`popout__inventory--from-detail`)
    popup.classList.add("active")

    const newStateWarehouses = { ...selectedWarehouses }
    if (type == 'from') {
      newStateWarehouses['from'] = { "id": selectedWarehouse.warehouse_id.id, "name": selectedWarehouse.warehouse_id.name }
      newStateWarehouses['to'] = { "id": null, "name": null }
    } else {
      newStateWarehouses['to'] = { "id": selectedWarehouse.warehouse_id.id, "name": selectedWarehouse.warehouse_id.name }
      newStateWarehouses['from'] = { "id": null, "name": null }
    }
    setSelectedWarehouses(newStateWarehouses)
  }

  const handleCreateShipment = () => {
    // const popup = document.getElementById("popout__create_shipment")
    // popup.classList.add("active")
  }

  const handleWarehouseSetting = (event) => {
    const popup = document.getElementById("popout__warehouse_settings")
    popup.classList.add("active")
  }

  const handleInventoryLogs = () => {
    navigate("/brandowner/inventory/logs")
  }

  const handleAddDeleteWarehouse = (event, type) => {
    const popup = document.getElementById("popout__add_delete_warehouse")
    popup.classList.add("active")

    setAddDelete(type)
  }

  const handleSelectWarehouse = (index) => {
    setWarehouseDetailIsActive(true)
    setSelectedWarehouse(inventory[index])
  }

  const handleOnClickLoadMore = () => {

    const updatedState = { ...inventoryState }
    updatedState['loadingMore'] = true
    setInventoryState(updatedState)

    const updatedFilters = { ...inventoryFilters }
    updatedFilters['page'] = updatedFilters['page'] + 1
    setInventoryFilters(updatedFilters)
  }

  const handleHideEmpty = () => {
    const updatedFilters = { ...inventoryFilters }
    updatedFilters['page'] = 1
    updatedFilters['hideEmpty'] = !updatedFilters['hideEmpty']
    setInventoryFilters(updatedFilters)
  }

  return (
    <div className="template">
      {/* {error ? window.location.href = "/" : null} */}

      <NotificationTop />

      <Header>
        <div className="header__options" id="header__options">
          {
            !warehouseDetailIsActive &&
            <>
              < button className="options-button-header" onClick={handleCreateInbound} ><ImportExportIcon />Create Stock change</button>
              {/* <button className="options-button-header" onClick={handleCreateShipment} ><LocalShippingIcon />Create shipment</button> */}
              <button className="options-button-header" onClick={handleInventoryLogs} ><DvrIcon />inventory logs</button>
              {
                expandedSection === 'inventory' &&
                <>
                  <button className="green-action-button" onClick={() => handleAddDeleteWarehouse(null, "add")} ><AddCircleIcon />Add a warehouse</button>
                  <button className="red-action-button" onClick={() => handleAddDeleteWarehouse(null, "delete")} ><RemoveCircleIcon />Delete a warehouse</button>
                </>
              }
            </>
          }


        </div>

        <div className="header__options--right">
          {
            warehouseDetailIsActive &&
            <>
              <button className="green-action-button" onClick={() => handleCreateInboundFromDetail('to')} ><AddCircleOutlineIcon />Add to warehouse</button>
              <button className="red-action-button" onClick={() => handleCreateInboundFromDetail('from')} ><RemoveCircleOutlineIcon />Remove from warehouse</button>
              {
                (selectedWarehouse?.warehouse_id.type != 'stoqup' & selectedWarehouse?.warehouse_id.type != 'partner') ?
                  <button className="gray-action-button" onClick={handleWarehouseSetting} ><SettingsIcon />Settings</button> : null
              }
              <div className="header__option--slider"><Slider size={1.2} text="Hide empty" onClickFunction={handleHideEmpty} state={inventoryFilters.hideEmpty} /></div>
              <SearchBarFilter
                id="searchbar-filter--inventory"
                filters={inventoryFilters}
                updateFilters={(setInventoryFilters)}
                placeholder={'Search for products...'}
                stateType={'usestate-object'}
              />
            </>
          }

          {
            !warehouseDetailIsActive ?
              <>
                <div className="header__option--slider"><Slider size={1.2} text="Hide empty" onClickFunction={handleHideEmpty} state={inventoryFilters.hideEmpty} /></div>
                <SearchBarFilter
                  id="searchbar-filter--inventory"
                  filters={inventoryFilters}
                  updateFilters={setInventoryFilters}
                  placeholder={'Search for products...'}
                  stateType={'usestate-object'}
                />
              </> :
              null
          }
        </div>
      </Header >

      <MenuBar />

      <div className="template__body">
        <div className="body__products-page--brandowner" id="body-inventory-page--brandowner">
          <PopOutWindowInventoryChange
            popupID={"popout__inventory"}
            handleSaveInbound={handleSaveInbound}
            warehouses={warehouses}
            productsAndMaterials={productsAndMaterials}
            addProductCounter={addProductCounter}
            setAddProductCounter={setAddProductCounter}
            createInboundSelectedProducts={createInboundSelectedProducts}
            setCreateInboundSelectedProducts={setCreateInboundSelectedProducts}
            createInboundComment={createInboundComment}
            setCreateInboundComment={setCreateInboundComment}
            selectedWarehouses={selectedWarehouses}
            setSelectedWarehouses={setSelectedWarehouses}
            inventoryChangeReason={inventoryChangeReason}
            setInventoryChangeReason={setInventoryChangeReason}
          />
          <PopOutWindowCreateShipment
            popupID={"popout__create_shipment"}
          />
          <PopOutWindowAddDeleteWarehouse
            popupID={"popout__add_delete_warehouse"}
            type={addDelete}
            warehouses={warehouses}
            fetchInventory={fetchInventory}
          />

          <PopupWindow
            popupID={'popup-window--warehouse-detail'}
            isActive={warehouseDetailIsActive}
            setIsActive={setWarehouseDetailIsActive}
            overflow={'hidden'}
          >
            <PopOutWindowInventoryChange
              popupID={"popout__inventory--from-detail"}
              handleSaveInbound={handleSaveInbound}
              warehouses={warehouses}
              productsAndMaterials={productsAndMaterials}
              addProductCounter={addProductCounter}
              setAddProductCounter={setAddProductCounter}
              createInboundSelectedProducts={createInboundSelectedProducts}
              setCreateInboundSelectedProducts={setCreateInboundSelectedProducts}
              createInboundComment={createInboundComment}
              setCreateInboundComment={setCreateInboundComment}
              selectedWarehouses={selectedWarehouses}
              setSelectedWarehouses={setSelectedWarehouses}
              inventoryChangeReason={inventoryChangeReason}
              setInventoryChangeReason={setInventoryChangeReason}
            />
            <PopOutWindowSettingsWarehouse
              popupID={"popout__warehouse_settings"}
              selectedWarehouse={selectedWarehouse}
              setSelectedWarehouse={setSelectedWarehouse}
            />

            <div className="warehouse_detail--header">

            </div>

            <h2>{selectedWarehouse?.warehouse_id.name}</h2>

            <div className="warehouse_detail--inventory_section">
              <div className="table">
                <div className="table__header-4">
                  <div className="table__header-field">SKU</div>
                  <div className="table__header-field">Product name</div>
                  <div className="table__header-field">Type</div>
                  <div className="table__header-field">Total</div>
                </div>
                <div className="table__rows_container">
                  {
                    selectedWarehouse?.products.map((product, index) => {
                      return (
                        <div className="table__row-4" key={index}>
                          <div className="table__row-field">{product.sku}</div>
                          <div className="table__row-field">{product.name} {product.volume}</div>
                          <div className="table__row-field">{product.is_POSM ? "Material" : "Product"}</div>
                          <div className="table__row-field">{product.quantity}</div>
                        </div>
                      )
                    })
                  }
                  {
                    inventoryState.productsHasMore | inventoryState.materialsHasMore ?
                      <button className="load_more_button" onClick={handleOnClickLoadMore}>{inventoryState.loadingMore ? <CircularProgress color="inherit" size="1rem" /> : "Load more..."}</button>
                      : null
                  }

                </div>

              </div>
            </div>

          </PopupWindow>


          <div className={`body__section ${expandedSection === 'products' ? 'expanded' : ''}`} id="products-page--brandowner--products">
            <h2 className="flex" onClick={() => handleExpandSection('products')}>products</h2>

            <div className="table">
              <div className="table__header-3">
                <div className="table__header-field">SKU</div>
                <div className="table__header-field">Product name</div>
                <div className="table__header-field">Total</div>
              </div>
              <div className="table__rows_container">
                {
                  loadingProducts ?
                    <div className="loading_spinner_wrapper"><LoadingSpinner /></div> :
                    <>
                      {
                        inventoryState.products.map((product, index) => {
                          return (
                            <div className="table__row-3" key={index}>
                              <div className="table__row-field">{product.sku}</div>
                              <div className="table__row-field">{product.name} {product.volume}</div>
                              <div className="table__row-field">{product.total_inventory}</div>
                            </div>
                          )
                        })
                      }
                    </>
                }
                {
                  inventoryState.productsHasMore ?
                    <button className="load_more_button" onClick={handleOnClickLoadMore}>{inventoryState.loadingMore ? <CircularProgress color="inherit" size="1rem" /> : "Load more..."}</button>
                    : null
                }
              </div>
            </div>

          </div>
          <div className={`body__section ${expandedSection === 'materials' ? 'expanded' : ''}`} id="products-page--brandowner--materials">
            <h2 className="flex" onClick={() => handleExpandSection('materials')}>materials</h2>

            <div className="table">
              <div className="table__header-3">
                <div className="table__header-field">SKU</div>
                <div className="table__header-field">Material name</div>
                <div className="table__header-field">Total</div>
              </div>
              <div className="table__rows_container">
                {
                  loadingProducts ?
                    <div className="loading_spinner_wrapper"><LoadingSpinner /></div> :
                    <>
                      {
                        inventoryState.materials.map((product, index) => {
                          return (
                            <div className="table__row-3" key={index}>
                              <div className="table__row-field">{product.sku}</div>
                              <div className="table__row-field">{product.name} {product.volume}</div>
                              <div className="table__row-field">{product.total_inventory}</div>
                            </div>
                          )
                        })
                      }
                    </>
                }
                {
                  inventoryState.materialsHasMore ?
                    <button className="load_more_button" onClick={handleOnClickLoadMore}>{inventoryState.loadingMore ? <CircularProgress color="inherit" size="1rem" /> : "Load more..."}</button>
                    : null
                }
              </div>
            </div>

          </div>

          <div className={`body__section ${expandedSection === 'inventory' ? 'expanded' : ''}`} id="products-page--brandowner--inventory">
            {expandedSection === 'inventory' ? <UnfoldLessOutlinedIcon className="unfold-icon" onClick={() => handleExpandSection('inventory')} /> : <UnfoldMoreOutlinedIcon className="unfold-icon" onClick={() => handleExpandSection('inventory')} />}
            <h2 className="flex" onClick={() => handleExpandSection('inventory')}>warehouses</h2>
            {
              loading ?
                <div className="loading_spinner_wrapper"><LoadingSpinner /></div> :
                <div className="warehouses__section">
                  {
                    inventory.map((warehouse, index) => {

                      let width = 100
                      const capacity_calc = Math.max(warehouse.warehouse_id.max_capacity - warehouse.warehouse_id.current_capacity, 0) / warehouse.warehouse_id.max_capacity * 100

                      if (warehouse.warehouse_id.max_capacity != null) {
                        width = capacity_calc
                      }

                      let warehouseIcon = require(`../assets/pictos/warehouse_picto_other.png`)
                      if (warehouse.warehouse_id.icon) {
                        warehouseIcon = require(`../assets/pictos/${warehouse.warehouse_id.icon}`)
                      }

                      return (
                        <div className="inventory__section" key={index} onClick={() => handleSelectWarehouse(index)}>
                          <div className="warehouse_container" >
                            <div className="warehouse_picto__container">
                              <p className="warehouse-title">{warehouse.warehouse_id.name}</p>
                              <img src={warehouseIcon} alt="stoqup warehouse picto" className="inventory__section-picto" />
                            </div>
                          </div>

                        </div>
                      )
                    })
                  }

                  <div className="inventory__section" onClick={() => handleAddDeleteWarehouse(null, "add")} >
                    <div className="warehouse_container" >
                      <div className="plus_icon__container">
                        <PiPlusThin />
                      </div>
                    </div>
                  </div>
                </div>
            }

          </div >
        </div >
      </div >
    </div >
  )
}

export default ProductsPageBrandowner