import React, { useState, useEffect, useRef } from 'react'
import httpClient from '../../httpClient'
import { useSelector, useDispatch } from 'react-redux'
import './PopupwindowMobile.css'
import { PiCaretLeftThin } from "react-icons/pi"

function PopupwindowMobile({ children, id, isActive, setIsActive, title, message, actionButtonFn }) {

  const dispatch = useDispatch()
  const thisPopupContainerRef = useRef(null)
  const saveButtonRef = useRef(null)

  useEffect(() => {
  }, [])

  const handleClosePopup = () => {
    setIsActive(false)
  }

  return (
    <div className={`popup_window__container ${isActive ? "active" : ""}`} id={id} ref={thisPopupContainerRef}>

      <div className='popup_header'>
        <div className='go_back' onClick={handleClosePopup}><PiCaretLeftThin /></div>
        {/* <button className='green-button-header' ref={saveButtonRef}>SAVE</button> */}
      </div>
      <div className='popup_body'>
        {
          children
        }
      </div>
    </div>
  )
}

export default PopupwindowMobile

PopupwindowMobile.defaultProps = {
  id: "popup-window-mobile,"
}

