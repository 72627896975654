import { createSlice } from "@reduxjs/toolkit";

export const visitReportSlice = createSlice({
  name: "visitReportState",
  initialState: {},
  reducers: {
    updateVisitReport: (state, action) => {
      Object.assign(state, action.payload);
    },

    resetStateVisitReport: (state) => {
      Object.keys(state).forEach(key => delete state[key]);
    },
  },
})

export const { updateVisitReport, resetStateVisitReport } = visitReportSlice.actions
export default visitReportSlice.reducer