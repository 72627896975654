import React from "react";

function Forklift() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      enableBackground="new 0 0 512 512"
      viewBox="0 0 128 128"
    >
      <path
        fill="#5e5e5e"
        d="M70.131 61.502l1.734-1.069 7.445 12.069-1.733 1.07zm-7.189 35.47l6.574 3.82h20.29l7.914-9.617-1.672-16.051-9.587.78-7.133 2.453-5.462 4.411-4.013 6.513z"
        data-original="#384868"
      ></path>
      <path
        fill="#5e5e5e"
        d="M72.873 21.42h-3.7l12.669 45.8L70.61 78.082h-6.025v-5.5a2.168 2.168 0 00-.373-1.216L60.784 66.3a2.17 2.17 0 00-1.8-.952H25.573L22.7 69.331 49.237 94h19.221s2.726-6.025 4.447-9.324 7.89-6.6 7.89-6.6l6.826-3.671z"
        data-original="#43567c"
      ></path>
      <path
        fill="#55c1b2"
        d="M67.738 90.931h-13.9L25.573 65.352a2.358 2.358 0 00-1.58-.608H5.949a2.355 2.355 0 00-2.3 1.858L.168 82.768s-1.455 10.1 4.2 16.731l3.718.485S5.664 83.5 19.889 82.768s16 13.174 15.68 18.024h36.406s-2.136-25.668 23.567-21.11v-5.4s-21.527-4.666-27.804 16.649z"
        data-original="#f7b521"
      ></path>
      <path
        fill="#55c1b2"
        d="M26.108 70.753c-.087 0-.176 0-.266-.011a3 3 0 01-2.725-3.251l4.159-47.338a3 3 0 012.989-2.737h46.046a3 3 0 010 6h-43.3l-3.92 44.6a3 3 0 01-2.983 2.737z"
        data-original="#f7b521"
      ></path>
      <path
        fill="#5e5e5e"
        d="M125.983 85.527h-23.1V23.438a2.018 2.018 0 00-2.018-2.018H97.56a2.017 2.017 0 00-2.018 2.018V97.59l4.158-1.365L102.886 94h23.1A2.017 2.017 0 00128 91.987v-4.442a2.018 2.018 0 00-2.017-2.018z"
        data-original="#384868"
      ></path>
      <circle
        cx="21.504"
        cy="96.225"
        r="14.358"
        fill="#5e5e5e"
        data-original="#43567c"
      ></circle>
      <circle
        cx="21.504"
        cy="96.225"
        r="7.454"
        fill="#dbe2eb"
        data-original="#dbe2eb"
      ></circle>
      <circle
        cx="88.527"
        cy="96.225"
        r="14.358"
        fill="#5e5e5e"
        data-original="#43567c"
      ></circle>
      <circle
        cx="88.527"
        cy="96.225"
        r="7.454"
        fill="#dbe2eb"
        data-original="#dbe2eb"
      ></circle>
      <path
        fill="#5e5e5e"
        d="M54.154 59.505h-12.71v-12.9a1 1 0 00-1.005-1.005h-4.677a1 1 0 00-1.006 1.005v17.577a1.17 1.17 0 001.17 1.17h18.228a1 1 0 001.006-1.005v-3.836a1 1 0 00-1.006-1.006zm21.608-2.725a1.4 1.4 0 00-1.962-.28l-7.821 5.882a1.4 1.4 0 001.688 2.244l7.82-5.882a1.4 1.4 0 00.275-1.964z"
        data-original="#384868"
      ></path>
    </svg>
  );
}

export default Forklift;
