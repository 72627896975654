import React, { useState, useEffect, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"
import httpClient from "../httpClient"
import { useNavigate } from "react-router-dom"
import Header from "../components/Header"
import MenuBar from "../components/MenuBar"
import { closePopupWindow, PopupWindow } from "../components/PopupWindow"
import { PopOutWindow } from "../components/PopOutWindow"
import CheckBoxStatic from "../components/CheckBoxStatic"
import { useParams } from 'react-router-dom'
import { NotificationTop, showNotification } from "../components/NotificationTop"
import { changeNotificationMessage } from "../store/notification"
import { updateSalesPipeNeedsRefresh } from "../store/salesPipePage"
import { updateGlobalState } from "../store/global"
import "./CustomerInfoPage.css"
import Label from "../components/Label"
import { ToastContainer, toast } from "react-toastify"
import ComingSoonOverlay from "../components/placeholders/ComingSoonOverlay"
import ActivityFiche from "../components/ActivityFiche"
import LoadingSpinner from "../components/placeholders/LoadingSpinner"
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import { formatDate } from "../components/helpers/FormatDate"
import { activity_types } from '../assets/Data'
import { PopOutWindowCreateActivity } from "../components/PopOutWindowCreateActivity"
import { PopOutWindowCreateActivityReport } from "../components/PopOutWindowCreateActivityReport"
import TableCheckmarkHeader from '../components/TableCheckmarkHeader'
import TableCheckmarkRow from '../components/TableCheckmarkRow'
import { NotificationPopupMessage, showNotificationPopupMessage } from "../components/NotificationPopupMessage"
import { PopOutWindowCreateOrder } from "../components/PopOutWindowCreateOrder"
import CheckBox from "../components/CheckBox"
import CheckBoxCustomDetails from "../components/CheckBoxCustomDetails"
import InputCustomDetails from "../components/InputCustomDetails"
import DropDownCustomDetails from "../components/DropDownCustomDetails"
import PopupWindowCreateNewCustomer from "../components/PopupWindowCreateNewCustomer"
import EditIcon from '@mui/icons-material/Edit'
import Dropdown from "../components/Dropdown"
import SearchBarList from "../components/SearchBarList"
import CircularProgress from '@mui/material/CircularProgress'
import Slider from "../components/Slider"
import {
  updateFilters,
  updateCustomerList,
  resetStateCustomerPage,
  updateTotalPagesCustomers,
  updateArrayOfCitiesCustomers
} from "../store/customerPage"

function CustomerInfoPage() {

  const { userID } = useParams()

  const [user, setUser] = useState({ email: "", id: "" })
  const [customerID, setcustomerID] = useState(null)
  const [loading, setLoading] = useState(false)
  const [loadingActivities, setLoadingActivities] = useState(false)
  const [loadingLatestReport, setLoadingLatestReport] = useState(false)
  const [loadingProductAvailability, setLoadingProductAvailability] = useState(true)
  const [loadingMaterialAvailability, setLoadingMaterialAvailability] = useState(true)
  const [loadingCustomDetails, setLoadingCustomDetails] = useState(false)
  const [error, setError] = useState(false)
  const [customer, setCustomer] = useState([])
  const [emailError, setEmailError] = useState("Not a valid email address")
  const [invoiceEmailError, setInvoiceEmailError] = useState("Not a valid email address")
  const [VATNumberError, setVATNumberError] = useState("VAT Number not in correct format BE0000.000.000")
  const [selectedType, setSelectedType] = useState("")
  const [selectedSubType, setSelectedSubType] = useState("")
  const [changesMade, setChangesMade] = useState(false)
  const [changesMadeCustomDetails, setChangesMadeCustomDetails] = useState(false)
  const [activities, setActivities] = useState([])
  const [myProductSuppliers, setMyProductSuppliers] = useState([])
  const [vendors, setVendors] = useState([])
  const [popUpSuppliersProduct, setPopUpSuppliersProduct] = useState("")
  const [popUpSuppliersVendors, setpopUpSuppliersVendors] = useState([])
  const [selectedVendorID, setSelectedVendorID] = useState()
  const [selectedProductID, setSelectedProductID] = useState()
  const [searchValueSupplier, setSearchValueSupplier] = useState("")
  const [typesDropdownIsOpen, setTypesDropdownIsOpen] = useState(false)
  const [subTypeDropdownIsOpen, setsubTypeDropdownIsOpen] = useState(false)
  const [menuItem, setMenuItem] = useState("contact-details")
  const [productAvailability, setproductAvailability] = useState([])
  const [customDetails, setCustomDetails] = useState([])
  const [myBrands, setMyBrands] = useState([])
  const [newCustomerPopupIsActive, setNewCustomerPopupIsActive] = useState(false)
  const [selectedActivity, setSelectedActivity] = useState(null)
  const [selectedCustomer, setSelectedCustomer] = useState("")
  const [selectedCustomerID, setSelectedCustomerID] = useState("")
  const [selectedRows, setSelectedRows] = useState([])
  const [editActivity, setEditActivity] = useState(false)
  const [ownerOfClient, setOwnerOfClient] = useState("")
  const [ownerHistory, setOwnerHistory] = useState([])
  const [editMode, setEditMode] = useState(false)

  const [availabilityState, setAvailabilityState] = useState({
    "productsPage": 1,
    "materialsPage": 1,
    "limit": 25,
    "productsHasMore": true,
    "materialsHasMore": true,
    "productsAvailability": [],
    "materialsAvailability": [],
    "loadingMoreProducts": false,
    "loadingMoreMaterials": false,
  })
  const [availabilityFilters, setAvailabilityFilters] = useState({
    "searchWord": "",
    "hideUnavailable": false
  })

  const dispatch = useDispatch()
  const navigate = useNavigate()

  let key = 0
  let key2 = 1

  const userInfo = useSelector(state => state.user.userInfo)
  const customerTypesArray = useSelector(state => state.user.customer_types)
  const filters = useSelector(state => state.customerPage.filters)
  const customers = useSelector(state => state.customerPage.customerList)
  const requester = userInfo.type
  const arrayOfSubTypes = Object.values(customerTypesArray).flat(1)
  const arrayOfTypes = Object.keys(customerTypesArray)
  const now = new Date()
  const myTeam = useSelector(state => state.user.myTeam)

  const dropdownRefTypes = useRef(null)
  const parentDivRef = useRef(null)
  const dropdownRefSubTypes = useRef(null)

  const fetchUser = async () => {
    const responseUser = await httpClient.get(process.env.REACT_APP_API_URL + "/api/@me?type=full")
    setUser(responseUser.data)
  }

  const fetchCustomer = async () => {
    setLoading(true)

    const responseCustomer = await httpClient.get(process.env.REACT_APP_API_URL + "/api/user/get?customerID=" + userID)

    setCustomer(responseCustomer.data)
    setcustomerID(responseCustomer.data.id)
    setSelectedSubType(responseCustomer.data.sub_type)
    setSelectedType(responseCustomer.data.type)
    setSelectedCustomer(responseCustomer.data.business_name)
    setSelectedCustomerID(responseCustomer.data.id)

    setLoading(false)
  }

  const fetchActivities = async () => {
    setLoadingActivities(true)
    const responseActivities = await httpClient.get(process.env.REACT_APP_API_URL + `/api/activities/get-all-activities-of-customer?customerID=${customerID}&requester=${requester}`)
    setActivities(responseActivities.data)
    setLoadingActivities(false)
  }

  const fetchMyProductSuppliers = async () => {
    const responseProducts = await httpClient.get(process.env.REACT_APP_API_URL + "/api/customer/get-suppliers-of-my-products?requester=" + requester + "&customerID=" + customerID)
    setMyProductSuppliers(responseProducts.data)
  }

  const fetchVendors = async () => {
    const reponseVendors = await httpClient.get(process.env.REACT_APP_API_URL + "/api/customer/get-vendors?requester=" + requester + "&customerID=" + customerID)
    setVendors(reponseVendors.data)
  }

  const fetchProductAvailability = async () => {

    const data = {
      ...availabilityState,
      "filters": availabilityFilters,
    }

    const response = await httpClient.post(process.env.REACT_APP_API_URL + `/api/user/classification/product-availability/get?customerID=${customerID}`, data)
    const responseData = response.data

    const updatedState = { ...availabilityState }

    updatedState["productsAvailability"] = responseData.availabilities.products
    updatedState["productsHasMore"] = responseData.has_more_products
    updatedState["loadingMoreProducts"] = false

    updatedState["materialsAvailability"] = responseData.availabilities.materials
    updatedState["materialsHasMore"] = responseData.has_more_materials
    updatedState["loadingMoreMaterials"] = false

    setAvailabilityState(updatedState)
  }

  const fetchCustomCustomerDetails = async () => {
    setLoadingCustomDetails(true)

    const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/user/classification/custom-user-details/get?customerID=" + customerID)
    setCustomDetails(response.data)

    setLoadingCustomDetails(false)
  }

  const fetchMyBrands = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/salesforce/get-my-brandowners`)

    setMyBrands(response.data)
  }

  const fetchCustomers = async () => {
    const requestData = {
      listType: filters.customersListType,
      filters: {
        city: filters.cityFilter,
        postal_code: filters.postalCodeFilter,
        type: filters.typeFilter,
        sub_type: filters.subTypeFilter,
        search_word: filters.searchWord,
        b2c: filters.filterB2C,
        owner: filters.owner
      },
      sort: filters.sortFilter,
      sortAscending: filters.sortAscending,
    }

    const responseCustomers = await httpClient.post(process.env.REACT_APP_API_URL + "/api/users/get?page=" + filters.page, requestData)

    dispatch(updateCustomerList(responseCustomers.data[0]))
    dispatch(updateTotalPagesCustomers(responseCustomers.data[1]))
    dispatch(updateArrayOfCitiesCustomers(responseCustomers.data[2]))
  }

  const fetchOwner = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/sales-pipe/owner/get?customerID=${customerID}&includeHistory=${true}`)

    const owner = response.data.owner
    setOwnerOfClient(owner.name)

    const history = response.data.history
    setOwnerHistory(history)
  }

  useEffect(() => {
    fetchUser()
      .catch(err => {
        console.log(err)
        setError(true)
      })

    fetchCustomer()
      .catch(err => {
        console.log(err)
        setError(true)
      })

    if (userInfo.type != "admin" & userInfo.type != undefined) {

      fetchMyBrands()
        .catch(err => {
          console.log(err)
          setError(true)
        })

    }

  }, [])

  useEffect(() => {
    if (customer.type == "wholesaler") {
      setCustomer(prev => ({ ...prev, is_supplier: true }))
    }
  }, [customer.type])

  useEffect(() => {
    if (customerID) {
      fetchMyProductSuppliers()
        .catch(err => {
          console.log(err)
          setError(true)
        })

      fetchVendors()
        .catch(err => {
          console.log(err)
          setError(true)
        })

      fetchActivities()
        .catch(err => {
          console.log(err)
          setError(true)
        })

      fetchProductAvailability()
        .then(() => setLoadingProductAvailability(false))
        .catch(err => {
          console.log(err)
          setError(true)
        })

      fetchCustomCustomerDetails()
        .catch(err => {
          console.log(err)
          setError(true)
        })

      fetchOwner()
        .catch(err => {
          console.log(err)
          setError(true)
        })

    }
  }, [customerID])

  useEffect(() => {
    let dropdownElement = null

    if (typesDropdownIsOpen) {
      dropdownElement = dropdownRefTypes.current
    } else if (subTypeDropdownIsOpen) {
      dropdownElement = dropdownRefSubTypes.current
    }

    const parentDivElement = parentDivRef.current

    if (dropdownElement) {
      const dropdownRect = dropdownElement.getBoundingClientRect()
      const parentDivRect = parentDivElement.getBoundingClientRect()

      const { top: dropdownTop, height: dropdownHeight } = dropdownRect
      const { top: parentDivTop, height: parentDivHeight } = parentDivRect

      const dropdownBottom = dropdownTop + dropdownHeight
      const parentDivBottom = parentDivTop + parentDivHeight

      if (dropdownBottom > parentDivBottom) {
        // Calculate the amount to scroll by
        const scrollAmount = dropdownBottom - parentDivBottom
        // Scroll the parent div with smooth animation
        parentDivElement.scrollBy({
          top: scrollAmount,
          behavior: 'smooth',
        })
      }
    }
  }, [typesDropdownIsOpen, subTypeDropdownIsOpen])

  useEffect(() => {
    fetchProductAvailability()
  }, [availabilityState.productsPage, availabilityState.materialsPage])

  useEffect(() => {
    setLoadingProductAvailability(true)

    fetchProductAvailability()
      .then(() => setLoadingProductAvailability(false))

  }, [availabilityFilters.searchWord])

  useEffect(() => {
    setLoadingMaterialAvailability(true)

    fetchProductAvailability()
      .then(() => setLoadingMaterialAvailability(false))

  }, [availabilityFilters.searchWord])

  useEffect(() => {
    setLoadingMaterialAvailability(true)
    setLoadingProductAvailability(true)

    fetchProductAvailability()
      .then(() => {
        setLoadingProductAvailability(false)
        setLoadingMaterialAvailability(false)
      })
  }, [availabilityFilters.hideUnavailable])

  const handleOnCLickType = () => {
    setTypesDropdownIsOpen(!typesDropdownIsOpen)
  }

  const handleOnCLickSubType = () => {
    setsubTypeDropdownIsOpen(!subTypeDropdownIsOpen)
  }

  const handleDuplicateButton = (event, type) => {
    const newData = { ...customer }

    switch (type) {
      case "name":
        newData["company_name"] = customer.business_name
        break
      case "email":
        newData["invoice_email"] = customer.email
        break
      case "address":
        newData["delivery_address_street"] = customer.invoice_address_street
        newData["delivery_address_pc"] = customer.invoice_address_pc
        newData["delivery_address_nr"] = customer.invoice_address_nr
        newData["delivery_address_place"] = customer.invoice_address_place
        newData["delivery_address_country"] = customer.invoice_address_country
        break
    }

    setCustomer(newData)
  }

  const handleEdit = () => {
    setChangesMade(true)
    const inputElements = document.querySelectorAll('input')
    const textAreaElements = document.querySelectorAll('textarea')
    const dropdowns = document.querySelectorAll('.dropdown')

    setEditMode(true)

    inputElements.forEach(input => {
      input.classList.remove("disabled")
    })

    textAreaElements.forEach(input => {
      input.classList.remove("disabled")
    })

    dropdowns.forEach(dropdown => {
      dropdown.classList.remove("disabled")
    })

    const buttons = document.getElementsByClassName("secondairy-button")
    for (let button of buttons) {
      button.classList.remove("hide")
    }

  }

  const onChangeCustomerInfo = (event, elementName = null, elementValue = null) => {
    const element = event.currentTarget
    let name = ""
    let value = ""

    if (elementName == null) {
      name = element.name
    } else {
      name = elementName
    }

    if (elementValue == null) {
      value = element.value
    } else {
      value = elementValue
    }

    const newData = { ...customer }
    newData[name] = value

    // clean sub_type state
    if (elementName == "type") {
      newData["sub_type"] = null
    }

    setCustomer(newData)
    setsubTypeDropdownIsOpen(false)
    setTypesDropdownIsOpen(false)
  }

  const handleSaveUpdate = async () => {
    const buttons = document.getElementsByClassName("secondairy-button")
    for (let button of buttons) {
      button.classList.add("hide")
    }

    let okToSave = true

    const emailErrorElement = document.getElementById("input_error_email--detail")
    const invoiceEmailErrorElement = document.getElementById("input_error_invoice_email--detail")
    const salesEmailErrorElement = document.getElementById("input_error_sales_email--detail")
    const VATNumberErrorElement = document.getElementById("input_error_VAT_number--detail")

    const VATValidation = /[A-Z][0-9]{4}\.[0-9]{3}\.[0-9]{3}/
    const emailValidation = /\S+@\S+\.\S+/

    let arrayRequiredData = []

    if (selectedType == "B2C") {
      arrayRequiredData = [
        "first_name"
      ]
    } else {
      arrayRequiredData = [
        "business_name",
      ]
    }

    arrayRequiredData.forEach(field => {
      const inputElement = document.querySelector(`#customers__${field}--detail`)
      if (customer[field] == "" && inputElement) {
        inputElement.classList.add('red-border')
      } else {
        inputElement.classList.remove('red-border')
      }
    })

    if (selectedType != "B2C") {
      if (customer.email) {

        if (emailValidation.test(customer.email)) {
          emailErrorElement.classList.remove("active")
        } else {
          emailErrorElement.classList.add("active")
          okToSave = false
        }

      }

      if (customer.sales_email & customer.type == "wholesaler") {

        if (emailValidation.test(customer.sales_email)) {
          salesEmailErrorElement.classList.remove("active")
        } else {
          salesEmailErrorElement.classList.add("active")
          okToSave = false
        }

      }

      if (!arrayRequiredData.every(item => customer[item] != "")) {
        okToSave = false
      }
    }

    if (!okToSave) {
      toast.error("Please complete all fields", { theme: "dark" })
      return
    }

    if (okToSave) {
      const response = await toast.promise(
        httpClient.post(process.env.REACT_APP_API_URL + "/api/user/update", customer),
        {
          pending: "Saving changes",
          success: "Customer has been successfully updated!",
          error: "Something went wrong"
        }
      )
      if (response.status == 200) {
        fetchCustomer()
        fetchCustomers()
        setChangesMade(false)
        return
      }
      document.getElementById("customer-info__section").classList.add("disabled")
    }

    setEditMode(false)
  }

  const createActivity = () => {
    const createActivityPopup = document.getElementById("popout-create-activity")
    createActivityPopup.classList.toggle("active")
  }

  const createOrder = () => {
    const createOrderPopup = document.getElementById("popout-create-order")
    createOrderPopup.classList.toggle("active")
  }

  const showSuppliersPopup = (productName, productVolume, productID) => {
    setPopUpSuppliersProduct(`${productName} ${productVolume}`)
    setSelectedProductID(productID)

    const suppliersPopup = document.getElementById('popup-window--customerInfoPage--suppliers')
    suppliersPopup.classList.add('active')
  }

  const handleChangeVendorOfProduct = async (event) => {
    const vendorID = event.currentTarget.getAttribute('data-vendor-id')

    const response = await httpClient.post(process.env.REACT_APP_API_URL + `/api/customer/update-supplier-of-product?productID=${selectedProductID}&vendorID=${vendorID}&customerID=${customerID}`)
    console.log(response.data)

    fetchMyProductSuppliers()
    const suppliersPopup = document.getElementById('popup-window--customerInfoPage--suppliers')
    suppliersPopup.classList.remove('active')
  }

  const handleOnMouseOver = (event) => {
    const headerItem = event.currentTarget
    headerItem.classList.add("hovered")
  }

  const handleOnMouseOut = (event) => {
    const headerItem = event.currentTarget
    headerItem.classList.remove("hovered")
  }

  const handleOnCLickHeaderItem = (event) => {
    const headerItem = event.currentTarget
    const allHeaderItems = document.getElementsByClassName("body__section__header_item")

    allHeaderItems.forEach(item => {
      item.classList.remove("selected")
    })

    headerItem.classList.add("selected")

    switch (event.currentTarget.getAttribute("data-id")) {
      case "header-contact-details":
        setMenuItem("contact-details")
        break
      case "header-details":
        setMenuItem("details")
        break
      case "header-availability":
        setMenuItem("availability")
        break
      case "header-suppliers":
        setMenuItem("suppliers")
        break
      case "header-activities":
        setMenuItem("activities")
        break
    }

  }

  const onClickCheckAvailability = async (event) => {
    setLoadingProductAvailability(true)
    setLoadingMaterialAvailability(true)

    const productID = event.currentTarget.parentElement.getAttribute("data-product-id")
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/user/classification/product-availability/change?productID=" + productID + "&customerID=" + customerID)

    fetchProductAvailability()
      .then(() => {
        setLoadingProductAvailability(false)
        setLoadingMaterialAvailability(false)
      })

    dispatch(updateSalesPipeNeedsRefresh(true))
  }

  const handleGoBack = () => {
    navigate(-1)
  }

  const handleClickRow = (event) => {
    // Find the closest parent row to the clicked element
    const parentRow = event.target.closest('.table__row-9')

    // If a parent row is found
    if (parentRow) {
      // Find the next sibling element which should be the .activity__detail div
      const detailDiv = parentRow.nextElementSibling

      // Check if the next sibling is actually the .activity__detail div
      if (detailDiv && detailDiv.classList.contains('activity__detail')) {
        detailDiv.classList.toggle('hide')
      }
    }
  }

  const handlePreCheckDone = async (id) => {
    const activity = activities.find(obj => obj.id == id)
    setSelectedActivity(activity)

    if (userInfo.type != "brandowner" & userInfo.id != activity.sales_rep_id.id) {
      dispatch(changeNotificationMessage("You can look but you can't touch 🤖. You are not the owner of this activity."))
      showNotificationPopupMessage()
      return
    }

    if (activity.done) {
      const data = {
        "ids": [id]
      }

      const response = await httpClient.post(process.env.REACT_APP_API_URL + `/api/activities/change-done-status?id=${id}`, data)
      console.log(response.data)

      fetchActivities()
    } else {
      const popout = document.querySelector("#popout-create-activity-report")
      popout.classList.add("active")
    }

  }

  const handleSaveUpdateCustomDetails = async () => {
    const data = {
      customerID,
      customDetails
    }

    const response = await httpClient.post(process.env.REACT_APP_API_URL + `/api/user/classification/custom-user-details/update`, data)
    console.log(response.data)

    setChangesMadeCustomDetails(false)
    fetchCustomCustomerDetails()
  }

  function renderCustomerDetail(details, parentIndex) {
    return details.map((detail, index) => {
      switch (detail.type) {
        case 'checkmark':
          return <CheckBoxCustomDetails
            key={index}
            setChangesMade={setChangesMadeCustomDetails}
            parentIndex={parentIndex}
            index={index}
            text={detail.title}
            defaultState={detail.value}
            state={customDetails}
            setState={setCustomDetails}
            textPosition="vert"
            margin="0"
          />
        case 'input':
          return <InputCustomDetails
            key={index}
            setChangesMade={setChangesMadeCustomDetails}
            parentIndex={parentIndex}
            index={index}
            text={detail.title}
            defaultState={detail.value}
            state={customDetails}
            setState={setCustomDetails}
            inputBoxSize={100}
            fontSize="0.75rem"
            textPosition="vert"
          />
        case 'dropdown':
          return <DropDownCustomDetails
            key={index}
            setChangesMade={setChangesMadeCustomDetails}
            parentIndex={parentIndex}
            index={index}
            title={detail.title}
            defaultState={detail.value}
            state={customDetails}
            setState={setCustomDetails}
            listArray={detail.dropdown_values}
            inherit={true}
            marginTop="0"
          />
        default:
          return null
      }
    })
  }

  const handleEditActivity = (event, id) => {
    event.stopPropagation()

    const activity = activities.find(obj => obj.id == id)
    setSelectedActivity(activity)

    if (userInfo.type != "brandowner" & userInfo.id != activity.sales_rep_id.id) {
      dispatch(changeNotificationMessage("You can look but you can't touch 🤖. You are not the owner of this activity."))
      showNotificationPopupMessage()
      return
    }

    setEditActivity(true)

    const popout = document.querySelector("#popout-create-activity")
    popout.classList.add("active")
  }

  const handleChangeOwner = async (owner) => {
    const data = {
      "customerIDs": [customerID],
      "salesforceID": owner.id
    }

    const response = await toast.promise(httpClient.post(process.env.REACT_APP_API_URL + `/api/sales-pipe/owner/update`, data),
      {
        "success": "Owner has been changed!",
        "pending": "Changing the owner...",
        "error": "Something went wrong 😢"
      })
    console.log(response.data)

    fetchOwner()
  }

  const handleOnClickLoadMore = (type) => {

    const updatedState = { ...availabilityState }

    if (type == 'products') {
      updatedState['loadingMoreProducts'] = true
      updatedState['productsPage'] = updatedState['productsPage'] + 1
    } else if (type == 'materials') {
      updatedState['loadingMoreMaterials'] = true
      updatedState['materialsPage'] = updatedState['materialsPage'] + 1
    }

    setAvailabilityState(updatedState)
  }

  const handleHideUnavailable = () => {
    const updatedState = { ...availabilityFilters }
    updatedState['hideUnavailable'] = !updatedState['hideUnavailable']
    setAvailabilityFilters(updatedState)
  }

  const handleIsSupplier = (value) => {
    setCustomer(prev => ({ ...prev, is_supplier: !prev.is_supplier }))
  }

  return (
    <div className="template">
      {/* {error ? window.location.href = "/" : ""} */}

      <NotificationPopupMessage />

      <PopOutWindowCreateOrder
        popupID={"popout-create-order"}
        customer={customer}
        selectedCustomerID={selectedCustomerID}
        selectedCustomer={customer.business_name}
        setSelectedCustomerID={setSelectedCustomerID}
        setNewCustomerPopupIsActive={setNewCustomerPopupIsActive}
      />

      <PopOutWindowCreateActivity
        popupID={"popout-create-activity"}
        customers={[]}
        selectedCustomer={customer.business_name}
        selectedCustomerID={selectedCustomerID}
        setSelectedCustomerID={setSelectedCustomerID}
        setNewCustomerPopupIsActive={setNewCustomerPopupIsActive}
        fetchActivities={fetchActivities}
        myBrands={myBrands}
        type="customer-info"
        editActivity={editActivity}
        setEditActivity={setEditActivity}
        selectedActivity={selectedActivity}
        myTeam={myTeam}
        keepCustomerState={true}
      />

      <PopOutWindowCreateActivityReport
        selectedActivity={selectedActivity}
        setSelectedActivity={setSelectedActivity}
        customers={[]}
        popupID={"popout-create-activity-report"}
        selectedCustomerID={customer.id}
        setSelectedCustomerID={setSelectedCustomerID}
        selectedCustomer={customer.business_name}
        setSelectedCustomer={setSelectedCustomer}
        setNewCustomerPopupIsActive={setNewCustomerPopupIsActive}
        fetchActivities={fetchActivities}
        myBrands={myBrands}
        type="customer-info"
      />

      <Header>
        <div className="back_button" onClick={handleGoBack}><KeyboardBackspaceIcon /> Go Back</div>
        {
          menuItem == "contact-details" ?
            changesMade ?
              <button className="green-button-header" id="customers__btn-edit" onClick={handleSaveUpdate}>SAVE</button> :
              <button className="green-button-header" id="customers__btn-edit" onClick={handleEdit}>EDIT</button> : null
        }
        {
          menuItem == "details" ?
            changesMadeCustomDetails ?
              <button className="green-button-header" id="customers__btn-edit" onClick={handleSaveUpdateCustomDetails}>SAVE</button> : null : null
        }
        <div className="header__options" id="header__options">
          <button className="options-button-header" onClick={createActivity}>Create Activity</button>
          <button className="options-button-header" onClick={createOrder}>Create Order</button>
        </div>

        <div className="header__options--right">
          {
            menuItem == "availability" ?
              <div className="header__option--slider"><Slider size={1.2} text="Hide unavailable" onClickFunction={handleHideUnavailable} state={availabilityFilters.hideUnavailable} /></div>
              : null
          }
        </div>
      </Header>
      <MenuBar />

      <NotificationTop />

      <div className="template__body">
        <div className="body__customer-info" id="body-customer-info">

          <PopupWindowCreateNewCustomer
            isActive={newCustomerPopupIsActive}
            setIsActive={setNewCustomerPopupIsActive}
            fetchCustomers={fetchCustomers}
            setSelectedCustomer={setSelectedCustomer}
            setSelectedCustomerID={setSelectedCustomerID}
          />

          <PopOutWindow popupID="popup-window--customerInfoPage--suppliers" overflow="hidden">
            <h4 className="popup-window__details__item--title">{popUpSuppliersProduct}</h4>
            <input className="search-bar dropdown" placeholder="Search..." value={searchValueSupplier} onChange={event => setSearchValueSupplier(event.currentTarget.value)} autoComplete="off" />
            <ul className="popup_window__suppliers_list overflow-scroll">
              {
                vendors
                  .filter(supplier => supplier.company_name.toLowerCase().includes(searchValueSupplier.toLowerCase()))
                  .map((item, index) => {
                    return (
                      <li className='supplier__item' key={index} onClick={handleChangeVendorOfProduct} data-vendor-id={item.id}>
                        <div className='supplier__item__company_name'>{item.company_name}</div>
                        <div className='supplier__item__contact_email'>{item.contact_email}</div>
                        <div>
                          {
                            item.account_id?.type == 'brandowner' ?
                              <div className='label__vendors'><Label title="Brandowner" labelColor="blue" /></div> :
                              item.is_pseudo ?
                                <div className='label__vendors'><Label title="Added by customer" labelColor="green" /></div> :
                                <div className='label__vendors'></div>
                          }
                        </div>
                      </li>
                    )
                  })
              }
            </ul>
          </PopOutWindow>

          <div className="body__section__header">
            {
              userInfo.type != "admin" &&
              <>
                <div className="body__section__header_item selected" data-id="header-contact-details" onClick={handleOnCLickHeaderItem} onMouseOver={handleOnMouseOver} onMouseOut={handleOnMouseOut}>Contact details</div>
                <div className="body__section__header_item" data-id="header-details" onClick={handleOnCLickHeaderItem} onMouseOver={handleOnMouseOver} onMouseOut={handleOnMouseOut}>Details</div>
                <div className="body__section__header_item" data-id="header-availability" onClick={handleOnCLickHeaderItem} onMouseOver={handleOnMouseOver} onMouseOut={handleOnMouseOut}>Availability</div>
                {
                  userInfo.type == "brandowner" || !userInfo.sub_type == "wholesaler" ?
                    <div className="body__section__header_item" data-id="header-suppliers" onClick={handleOnCLickHeaderItem} onMouseOver={handleOnMouseOver} onMouseOut={handleOnMouseOut}>Suppliers</div> : null
                }
                <div className="body__section__header_item" data-id="header-activities" onClick={handleOnCLickHeaderItem} onMouseOver={handleOnMouseOver} onMouseOut={handleOnMouseOut}>Activities</div>
              </>
            }
          </div>

          {
            menuItem == "contact-details" &&

            <div className="body__section" id="customer-info__section">
              <div className="form__section no-scrollbar" ref={parentDivRef}><h1>Details</h1>
                <div className="input_field">
                  <label className="input_field__label" htmlFor="business_name">Business name</label>
                  <input className={`input_field__input_box--text disabled ${loading ? "loading" : ""}`} id="customers__business_name--detail" type="text" name="business_name" value={customer.business_name} onChange={onChangeCustomerInfo} />
                </div>

                <div className="input_field">
                  <label className="input_field__label" htmlFor="email">Email</label>
                  <input className={`input_field__input_box--text disabled ${loading ? "loading" : ""}`} id="customers__email--detail" type="text" name="email" value={customer.email} onChange={onChangeCustomerInfo} />
                </div>
                <div className="input_error" id="input_error_email--detail">{emailError}</div>

                <div className="input_field">
                  <label className="input_field__label" htmlFor="tel">Telephone number</label>
                  <input className={`input_field__input_box--text disabled ${loading ? "loading" : ""}`} id="customers__tel_number--detail" type="text" name="tel_number" value={customer.tel_number} onChange={onChangeCustomerInfo} />
                </div>

                <div className="input_field--combo-5050">
                  <div className="input_field">
                    <label className="input_field__label" htmlFor="first_name">First name</label>
                    <input className={`input_field__input_box--text disabled ${loading ? "loading" : ""}`} id="customers__first_name--detail" type="text" name="first_name" value={customer.first_name} onChange={onChangeCustomerInfo} />
                  </div>
                  <div className="input_field">
                    <label className="input_field__label" htmlFor="last_name">Last name</label>
                    <input className={`input_field__input_box--text disabled ${loading ? "loading" : ""}`} id="customers__last_name--detail" type="text" name="last_name" value={customer.last_name} onChange={onChangeCustomerInfo} />
                  </div>
                </div>

                <div className="input_field--combo-5050">
                  <div className="input_field">
                    <div className="dropdown disabled">
                      <p className="input_field__label">Type</p>
                      <div className={`input_field__input_box--text dropdown ${loading ? "loading" : ""}`} id="customers__type--detail" onClick={handleOnCLickType}>{customer.type}</div>
                    </div>
                    <ul className={`input_field__dropdown ${typesDropdownIsOpen ? "active" : ""}`} id="dropdown__customers_type" ref={dropdownRefTypes}>
                      {
                        arrayOfTypes.map((type, index) => {
                          return <li key={index} name="type" onClick={(e) => onChangeCustomerInfo(e, "type", type)}>{type}</li>
                        })
                      }
                    </ul>
                  </div>
                  <div className="input_field">
                    <div className="dropdown disabled">
                      <p className="input_field__label">Sub type</p>
                      <div className={`input_field__input_box--text dropdown ${loading ? "loading" : ""}`} id="customers__sub_type--detail" onClick={handleOnCLickSubType}>{customer.sub_type}</div>
                    </div>
                    <ul className={`input_field__dropdown ${subTypeDropdownIsOpen ? "active" : ""}`} id="dropdown__customers_sub_type" ref={dropdownRefSubTypes}>
                      {
                        customerTypesArray[customer ? customer.type : ""]?.map((subtype, index) => {
                          return <li key={index} name="sub_type" onClick={(e) => onChangeCustomerInfo(e, "sub_type", subtype)}>{subtype}</li>
                        })
                      }
                    </ul>
                  </div>

                </div>
                {
                  customer.is_supplier &&
                  <>
                    <div className="input_field">
                      <label className="input_field__label" htmlFor="sales_email">Order email</label>
                      <div className="flex">
                        <input className="input_field__input_box--text" id="customers__sales_email--detail" type="text" name="sales_email" value={customer.sales_email} onChange={onChangeCustomerInfo} />
                      </div>
                    </div>
                    <div className="input_error" id="input_error_sales_email--detail">{emailError}</div>
                  </>
                }
                <div className="input_field">
                  <label className="input_field__label" htmlFor="bio">Bio</label>
                  <textarea className={`input_field__input_box--text disabled ${loading ? "loading" : ""}`} style={{ "minHeight": "90px" }} id="customers__bio--detail" name="bio" value={customer.bio} onChange={onChangeCustomerInfo} />
                </div>
              </div>

              <div className="form__section no-scrollbar"><h1>Invoicing</h1>
                <div className="input_field">
                  <label className="input_field__label" htmlFor="company_name">Company name</label>
                  <div className="flex">
                    <input className={`input_field__input_box--text disabled ${loading ? "loading" : ""}`} id="customers__company_name--detail" type="text" name="company_name" value={customer.company_name} onChange={onChangeCustomerInfo} />
                    <button className="secondairy-button hide" onClick={e => handleDuplicateButton(e, "name")}>Duplicate</button>
                  </div>
                </div>
                <div className="input_field">
                  <label className="input_field__label" htmlFor="invoice_email">Invoice email</label>
                  <div className="flex">
                    <input className={`input_field__input_box--text disabled ${loading ? "loading" : ""}`} id="customers__invoice_email--detail" type="text" name="invoice_email" value={customer.invoice_email} onChange={onChangeCustomerInfo} />
                    <button className="secondairy-button hide" onClick={e => handleDuplicateButton(e, "email")}>Duplicate</button>
                  </div>
                </div>
                <div className="input_error" id="input_error_invoice_email">{invoiceEmailError}</div>
                <div className="input_field">
                  <label className="input_field__label" htmlFor="VAT_number">VAT number</label>
                  <input className={`input_field__input_box--text disabled ${loading ? "loading" : ""}`} id="customers__VAT_number--detail" type="text" name="VAT_number" value={customer.VAT_number} onChange={onChangeCustomerInfo} />
                </div>
                <div className="input_error" id="input_error_VAT_number--detail">{VATNumberError}</div>
                <div className="input_field--combo-7030">
                  <div className="input_field">
                    <label className="input_field__label" htmlFor="invoice_address_street">Street</label>
                    <input className={`input_field__input_box--text disabled ${loading ? "loading" : ""}`} id="customers__invoice_address_street--detail" type="text" name="invoice_address_street" value={customer.invoice_address_street} onChange={onChangeCustomerInfo} />
                  </div>
                  <div className="input_field">
                    <label className="input_field__label" htmlFor="invoice_address_nr">Number</label>
                    <input className={`input_field__input_box--text disabled ${loading ? "loading" : ""}`} id="customers__invoice_address_nr--detail" type="text" name="invoice_address_nr" value={customer.invoice_address_nr} onChange={onChangeCustomerInfo} />
                  </div>
                </div>
                <div className="input_field--combo-3070">
                  <div className="input_field">
                    <label className="input_field__label" htmlFor="invoice_address_pc">Postal code</label>
                    <input className={`input_field__input_box--text disabled ${loading ? "loading" : ""}`} id="customers__invoice_address_pc--detail" type="text" name="invoice_address_pc" value={customer.invoice_address_pc} onChange={onChangeCustomerInfo} />
                  </div>
                  <div className="input_field">
                    <label className="input_field__label" htmlFor="invoice_address_place">City</label>
                    <input className={`input_field__input_box--text disabled ${loading ? "loading" : ""}`} id="customers__invoice_address_place--detail" type="text" name="invoice_address_place" value={customer.invoice_address_place} onChange={onChangeCustomerInfo} />
                  </div>
                </div>
                <div className="input_field">
                  <label className="input_field__label" htmlFor="invoice_address_country">Country</label>
                  <input className={`input_field__input_box--text disabled ${loading ? "loading" : ""}`} id="customers__invoice_address_country--detail" type="text" name="invoice_address_country" value={customer.invoice_address_country} onChange={onChangeCustomerInfo} />
                </div>

              </div>

              <div className="form__section no-scrollbar" id="delivery_address_info"><h1>Delivery</h1>
                <div className="input_field--combo-7030">
                  <div className="input_field">
                    <label className="input_field__label" htmlFor="delivery_address_street">Street</label>
                    <input className={`input_field__input_box--text disabled ${loading ? "loading" : ""}`} id="customers__delivery_address_street--detail" type="text" name="delivery_address_street" value={customer.delivery_address_street} onChange={onChangeCustomerInfo} />
                  </div>
                  <div className="input_field">
                    <label className="input_field__label" htmlFor="delivery_address_nr">Number</label>
                    <input className={`input_field__input_box--text disabled ${loading ? "loading" : ""}`} id="customers__delivery_address_nr--detail" type="text" name="delivery_address_nr" value={customer.delivery_address_nr} onChange={onChangeCustomerInfo} />
                  </div>
                </div>
                <div className="input_field--combo-3070">
                  <div className="input_field">
                    <label className="input_field__label" htmlFor="delivery_address_pc">Postal code</label>
                    <input className={`input_field__input_box--text disabled ${loading ? "loading" : ""}`} id="customers__delivery_address_pc--detail" type="text" name="delivery_address_pc" value={customer.delivery_address_pc} onChange={onChangeCustomerInfo} />
                  </div>
                  <div className="input_field">
                    <label className="input_field__label" htmlFor="delivery_address_place">City</label>
                    <input className={`input_field__input_box--text disabled ${loading ? "loading" : ""}`} id="customers__delivery_address_place--detail" type="text" name="delivery_address_place" value={customer.delivery_address_place} onChange={onChangeCustomerInfo} />
                  </div>
                </div>
                <div className="input_field">
                  <label className="input_field__label" htmlFor="delivery_address_couintry">Country</label>
                  <input className={`input_field__input_box--text disabled ${loading ? "loading" : ""}`} id="customers__delivery_address_country--detail" type="text" name="delivery_address_country" value={customer.delivery_address_country} onChange={onChangeCustomerInfo} />
                </div>
                <button className="secondairy-button hide" id="duplicate-btn-delivery" onClick={e => handleDuplicateButton(e, "address")}>Duplicate</button>
                
                <div className="other_options">
                  <CheckBox
                    id="customers__is_supplier"
                    text="Is supplier"
                    state={customer.is_supplier}
                    setData={handleIsSupplier}
                    disabled={!editMode}
                  />
                </div>
              </div>

            </div>

          }

          {
            menuItem == "details" &&

            <div className="body__section--board" id="customer-details__section">

              <div className="classification_section" id="custom-details">
                <h1>Custom details</h1>
                <div className="classification_section__content_container">
                  {
                    loadingCustomDetails ?
                      <LoadingSpinner /> :

                      <>
                        {
                          customDetails.map((brand, index) => {
                            const details = brand.custom_details

                            return (
                              <div className="custom_details__container" key={index}>
                                {
                                  (customDetails.length > 1 & details.length != 0) ?
                                    <h2>Custom details of {brand.brandowner}</h2> : null
                                }
                                {
                                  details.length != 0 ?
                                    <div className="custom_details__wrapper">
                                      {
                                        renderCustomerDetail(details, index)
                                      }
                                    </div> : null
                                }
                              </div>
                            )
                          })
                        }
                      </>
                  }
                </div>
              </div>

              <div className="classification_section overflow-hidden" id="ownership">
                <h1>Client owned by</h1>
                <div className="overflow-scroll">
                  <Dropdown
                    listArray={myTeam}
                    inherit={true}
                    state={ownerOfClient}
                    setState={setOwnerOfClient}
                    onClickFn={handleChangeOwner}
                  />

                  <h2>History</h2>
                  {
                    ownerHistory.map((historyLine, index) => (
                      <div className="history_line">
                        <div>{formatDate(historyLine.time_stamp)}</div>
                        <div>{historyLine.owner_name}</div>
                      </div>
                    ))
                  }
                </div>
              </div>

              <div className="classification_section overflow-hidden" id="estimates">
                <h1>Estimates</h1>
                <ComingSoonOverlay />
              </div>

              <div className="classification_section overflow-hidden" id="segmentation">
                <h1>Segmentation</h1>
                <ComingSoonOverlay />
              </div>

              <div className="classification_section overflow-hidden" id="business-details">
                <h1>Business details</h1>
                <ComingSoonOverlay />
              </div>

            </div>

          }

          {
            menuItem == "availability" &&

            <div className="body__section--board" id="customer-availability__section">

              <div className="availability_section" id="available-products">
                <h1>Available products</h1>
                <SearchBarList
                  filters={availabilityFilters}
                  updateFilters={setAvailabilityFilters}
                  stateKey={"searchWord"}
                />
                <div className="availability_section__content_container">
                  {
                    loadingProductAvailability ?
                      <LoadingSpinner /> :

                      <>
                        {
                          availabilityState.productsAvailability &&
                          availabilityState.productsAvailability
                            .map((product, index) => {
                              return (
                                <div className="product" key={index} data-product-id={product.id}>
                                  <p>{product.name} {product.volume}</p>
                                  <CheckBoxStatic
                                    labelDisabled={true}
                                    state={product.availability}
                                    onClick={onClickCheckAvailability}
                                  />
                                </div>
                              )
                            })
                        }
                        {
                          availabilityState.productsHasMore ?
                            <button className="load_more_button" onClick={() => handleOnClickLoadMore("products")}>{availabilityState.loadingMoreProducts ? <CircularProgress color="inherit" size="1rem" /> : "Load more..."}</button>
                            : null
                        }
                      </>
                  }

                </div>

              </div>

              <div className="availability_section" id="available-POSM">
                <h1>Available POSM</h1>
                <SearchBarList
                  filters={availabilityFilters}
                  updateFilters={setAvailabilityFilters}
                  stateKey={"searchWord"}
                />
                <div className="availability_section__content_container">
                  {
                    loadingProductAvailability ?
                      <LoadingSpinner /> :

                      <>
                        {
                          availabilityState.materialsAvailability &&
                          availabilityState.materialsAvailability
                            .map((product, index) => {
                              return (
                                <div className="product" key={index} data-product-id={product.id}>
                                  <p>{product.name} {product.volume}</p>
                                  <CheckBoxStatic
                                    labelDisabled={true}
                                    state={product.availability}
                                    onClick={onClickCheckAvailability}
                                  />
                                </div>
                              )
                            })
                        }
                        {
                          availabilityState.materialsHasMore ?
                            <button className="load_more_button" onClick={() => handleOnClickLoadMore("materials")}>{availabilityState.loadingMoreMaterials ? <CircularProgress color="inherit" size="1rem" /> : "Load more..."}</button>
                            : null
                        }
                      </>
                  }

                </div>

              </div>

              <div className="availability_section overflow-hidden" id="latest-report">
                <h1>Estimates</h1>
                <ComingSoonOverlay />
              </div>

            </div>

          }


          {
            menuItem == "suppliers" &&

            <div className="body__section" id="customer-suppliers__section">

              <div className="overflow-scroll no-scrollbar">
                {
                  myProductSuppliers.map((productSupplier, index) => {
                    return (
                      <div className="grid--2-colums-30-70" style={{ marginBottom: "5px" }} key={index}>
                        <div className="input_field__label">{productSupplier.product_name} {productSupplier.product_volume}</div>
                        <div className="input_field__input_box--text static" onClick={() => showSuppliersPopup(productSupplier.product_name, productSupplier.product_volume, productSupplier.product_id)}>{myProductSuppliers[index].supplier_name != "" ? myProductSuppliers[index].supplier_name : `Select a supplier for ${productSupplier.product_name} ${productSupplier.product_volume}`}</div>
                      </div>
                    )
                  })
                }
              </div>
            </div>

          }

          {
            menuItem == "activities" &&

            <div className="body__section" id="customer-visits__section">

              {
                loadingActivities ?
                  <LoadingSpinner /> :
                  <div className="overflow-scroll no-scrollbar">
                    <div className="table">
                      <div className="table__header-9">
                        <TableCheckmarkHeader
                          id={`table-checkmark-header-activities`}
                          selectedRows={selectedRows}
                          setSelectedRows={setSelectedRows}
                        />
                        <div className="table__header--field flex--center">Type</div>
                        <div className="table__header--field">Customer</div>
                        <div className="table__header--field">Title</div>
                        <div className="table__header--field">Owner</div>
                        <div className="table__header--field">Due date</div>
                        <div className="table__header--field flex--center">Priority</div>
                        <div className="table__header--field flex--center">Done</div>
                        <div className="table__header--field flex--center"></div>
                      </div>
                      <div className="table__rows_container">
                        {
                          activities.map((activity, index) => {

                            let isOverdue = false
                            if (new Date(activity.planned_date) < now & !activity.done) {
                              isOverdue = true
                            }

                            return (
                              <div key={index}>
                                <div className={`table__row-9 ${isOverdue ? "overdue" : activity.done ? "done" : ""}`} id='checkmark-row' data-activity-id={activity.id} onClick={handleClickRow}>
                                  <TableCheckmarkRow
                                    index={index}
                                    id={`table-checkmark-row-activities-${index}`}
                                    selectedRows={selectedRows}
                                    setSelectedRows={setSelectedRows}
                                  />                            <div className="table__row--field flex--center"><img src={activity_types[activity.type].picto} /></div>
                                  <div className="table__row--field">{activity.customer_id.business_name}</div>
                                  <div className="table__row--field">{activity.title}</div>
                                  <div className="table__row--field">{activity.sales_rep_id.first_name} {activity.sales_rep_id.last_name}</div>
                                  <div className="table__row--field">{formatDate(activity.planned_date)}</div>
                                  <div className="table__row--field flex--center">{activity.priority}</div>
                                  <div className="table__row--field flex--center">
                                    <CheckBox
                                      index={activity.id}
                                      state={activity.done}
                                      width={"20px"}
                                      height={"20px"}
                                      margin={"0"}
                                      setData={handlePreCheckDone}
                                      style={"hybrid"}
                                      type={"controlled"}
                                    />
                                  </div>
                                  <div className="table__row--field flex--center" onClick={(e) => handleEditActivity(e, activity.id)}><EditIcon /></div>
                                </div>
                                <div className="activity__detail hide">
                                  <div className={`activity__detail--comment ${activity.comment == "" ? "hide" : ""}`}>
                                    <h3>Comment</h3>
                                    {activity.comment}
                                  </div>
                                  {
                                    activity.done &&
                                    activity.report.map((report, index) => (
                                      <div key={index}>
                                        <h3>Report for {report.brandowner_id.business_name}</h3>
                                        {report.report}
                                      </div>
                                    ))
                                  }
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                  </div>
              }

            </div>

          }

        </div>
      </div>
    </div>

  )
}

export default CustomerInfoPage

