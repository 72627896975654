import React from 'react'
import "./MenuBarMobile.css"
import { Link, useLocation } from 'react-router-dom'

import { LuCalendarRange } from "react-icons/lu"
import { PiFunnelBold } from "react-icons/pi"
import { SiHackthebox } from "react-icons/si"
import { MdOutlineAnalytics, MdOutlineShoppingCart, MdOutlinePeopleAlt, MdOutlineGroups2 } from "react-icons/md"


function MenuBarMobile() {

  const location = useLocation()

  const isSelected = (path) => {
    return location.pathname === path;
  }

  return (
    <div className='template__menu--mobile'>
      <Link to="/m/home">
        <div className={`menu__item ${isSelected('/m/home') ? 'selected' : ''}`}><MdOutlineAnalytics /></div>
      </Link>
      <Link to="/m/customers">
        <div className={`menu__item ${isSelected('/m/customers') ? 'selected' : ''}`}><MdOutlinePeopleAlt /></div>
      </Link>
      <Link to="/m/activities">
        <div className={`menu__item ${isSelected('/m/activities') ? 'selected' : ''}`}><LuCalendarRange /></div>
      </Link>
      <Link to="/m/brandowner/sales">
        <div className={`menu__item ${isSelected('/m/brandowner/sales') ? 'selected' : ''}`}><MdOutlineShoppingCart /></div>
      </Link>
      {/* <Link to="/m/sales-pipe">
        <div className={`menu__item ${isSelected('/m/sales-pipe') ? 'selected' : ''}`}><PiFunnelBold /></div>
      </Link> */}

    </div>
  )
}

export default MenuBarMobile