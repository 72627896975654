import React, { useEffect, useState } from "react"
import "./CheckBox.css"

function CheckBox({ index, width, height, textPosition, state, setState, setData, text, style, margin, type, disabled }) {
  const [stateLocal, setStateLocal] = useState(state)

  useEffect(() => {
    setStateLocal(state)
  }, [state])

  const handleOnClickCheckbox = (event) => {
    event.stopPropagation()

    if (type != "controlled") {
      setStateLocal(!stateLocal)
    } 
    
    try {
      setState(!state)
    } catch (error) {
      console.log("No data to set")
    }
    
    try {
      setData(index)
    }
    catch (error) {
      console.log(error)
      console.log("No data to set")
    }
  }

  return (
    <div className={`checkbox__container ${style} ${ text == undefined && "no-text"} ${textPosition == "vert" && "vert"} ${disabled && "disabled"}`} style={{margin}} onClick={handleOnClickCheckbox}>
      {
        textPosition === "left" & text != undefined | textPosition == "vert" ? <p style={{marginRight: "10px"}} className={`checkbox--text ${style}`}>{text}</p> : null
      }
      <div className={`checkbox--box__container ${ text == undefined && "no-text"}`} style={{width, height}}>
        <div className={`${stateLocal ? "checkbox--box checked" : "checkbox--box"} ${style}`}></div>
        <span className={`${stateLocal ? "checkmark checked" : "checkmark"} ${style}`}>
          <div className="checkmark_stem"></div>
          <div className="checkmark_kick"></div>
        </span>
      </div>
      {
        textPosition === "right" & text != undefined ? <p style={{marginRight: "auto", marginLeft: "10px"}} className={`checkbox--text ${style}`}>{text}</p> : null
      }
    </div>
)}

export default CheckBox

CheckBox.defaultProps = {
  width: "30px",
  height: "30px",
  textPosition: "right",
  style: "light",
  margin: "10px 0 0 0",
  type: "normal",
  disabled: false
}

