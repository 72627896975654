import React, { useState, useEffect, useRef } from 'react'
import './DropdownWarehouse.css'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import ClearIcon from '@mui/icons-material/Clear'


function Dropdown({ fontStyle, state, marginTop, fontSize, setState, height, width, idList, key, placeholder, listArray, type }) {
  const [selectedValue, setSelectedValue] = useState(state)

  Dropdown.defaultProps = {
    key: 1,
    marginTop: "5px",
    fontStyle: "normal",
  }

  useEffect(() => {
    setSelectedValue(state)
  }, [state])

  const ref = useRef(null)

  const onClickOutside = () => {
    const dropdownList = document.querySelectorAll(`#${idList}`)

    Array.from(dropdownList).forEach(dropdown => {
      dropdown.classList.remove("active")
    })
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside()
      }
    }
    document.addEventListener("click", handleClickOutside, true)

    return () => {
      document.removeEventListener("click", handleClickOutside, true)
    }
  }, [onClickOutside])

  useEffect(() => {
    if (!listArray || listArray.length === 0) {
      return
    }
    if (typeof listArray[0] === 'string' || typeof listArray[0] === 'number') {
      setState(selectedValue)
    }
  }, [selectedValue])

  const handleClickDropdown = (event) => {
    const dropdownList = event.currentTarget.nextSibling
    dropdownList.classList.toggle("active")
  }

  const onclickMenuItem = (event) => {
    const dropdownList = event.currentTarget.parentNode

    dropdownList.classList.remove("active")
    setSelectedValue(event.currentTarget.childNodes[1].innerHTML)
    setState(type, event.currentTarget.childNodes[1].innerHTML, event.currentTarget.getAttribute('data-index'))

  }

  const handleClear = (event) => {
    event.stopPropagation()

    setState(type)
    setSelectedValue(null)
  }
  
  if (!listArray) {
    return <p>Loading...</p>
  }

  return (
    <div className="dropdown__container-dropdown--warehouse flex--vert" id={`warehouse_dropdown__${key}`} style={{ width, marginTop }} ref={ref} key={key}>
      <span className="input_field__input_box--text dropdown classic" style={{ height, marginTop }} onClick={handleClickDropdown}>
        <div className="dropdown-input--text_field" style={{ fontSize, fontStyle }}>{selectedValue != null ? selectedValue : <div className='placeholder'>{placeholder}</div>}</div>
        {
          selectedValue != null ?
          <ClearIcon onClick={handleClear}/> :
          <KeyboardArrowDownIcon />
        }
      </span>
      <ul className="input_field__dropdown classic" id={idList} key={key}>

        {
          typeof listArray[0] === 'object' && listArray[0] !== null && (
            listArray.map((item, index) => (
              <li className='dropdown__item' key={index} data-index={item.id} style={{ fontSize }} onClick={onclickMenuItem}>
                {
                  Object.entries(item).map((entry, index) => (
                    <div key={index}>
                      {entry[0] !== "id" && entry[1]}
                    </div>
                  ))
                }
              </li>
            ))
          )
        }

        {
          (typeof listArray[0] === 'string' || typeof listArray[0] === 'number') && (
            listArray.map((item, index) => {
              return (
                <li className='dropdown__item--single' key={index} style={{ fontSize }} onClick={onclickMenuItem}>
                  {item}
                </li>
              )
            })
          )
        }

      </ul>
    </div>
  )
}

export default Dropdown