import React from "react";

function ProfilePicMan2() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
      <circle cx="64" cy="64" r="64" fill="#f85863"></circle>
      <path
        fill="#01277b"
        d="M64 128c18 0 34.3-7.5 45.9-19.4-6-16.4-24.3-21.8-45.9-21.8s-39.9 5.4-45.9 21.8C29.7 120.5 46 128 64 128z"
      ></path>
      <path
        fill="#fff"
        d="M51.4 72.3v15.8c0 7 5.7 12.6 12.6 12.6 7 0 12.6-5.7 12.6-12.6V72.3z"
      ></path>
      <path
        fill="#01277b"
        d="M51.4 72.3v7.4c3.8 3.3 8.3 5.6 13.2 6.6 4.4.9 8.6.5 12.1-.9V72.3z"
      ></path>
      <path
        fill="#fff"
        d="M39.8 37.3v21.5c0 13.6 11.1 25 24.7 24.7 13.2-.3 23.7-11 23.7-24.2v-22c0-3.7-3-6.7-6.7-6.7h-35c-3.7 0-6.7 3-6.7 6.7z"
      ></path>
      <path
        fill="#01277b"
        d="M56.1 38.8H36.9c0-9.6 7.8-17.4 17.4-17.4h19.3c-.1 9.6-7.9 17.4-17.5 17.4z"
      ></path>
      <path
        fill="#01277b"
        d="M44.3 36.1v16.1c0 2.1-1.7 3.7-3.7 3.7-2.1 0-3.7-1.7-3.7-3.7V38.8z"
      ></path>
      <ellipse cx="37.3" cy="53.1" fill="#fff" rx="4.9" ry="5.9"></ellipse>
      <path
        fill="#01277b"
        d="M73.5 21.4H76c8.5 0 15.4 6.9 15.4 15.4V55h-3.9c-1.4 0-2.5-1.1-2.5-2.5V38.8h-4.4c-4.4 0-8.5-2.3-10.8-6.1l-1.6-2.6z"
      ></path>
      <ellipse cx="91.9" cy="53.1" fill="#fff" rx="4.9" ry="5.9"></ellipse>
      <path
        fill="#01277b"
        d="M55.9 26.8c3.8 4 10.1 4.6 14.5 1.2 4.5-3.4 5.5-9.7 2.5-14.3z"
      ></path>
      <path
        fill="#01277b"
        d="M52.9 26.5c4 1.6 8.7-.2 10.6-4.1s.6-8.7-3.1-11zM53.9 64c.5 5.2 4.9 9.3 10.2 9.3s9.7-4.1 10.2-9.3z"
      ></path>
      <circle cx="54.3" cy="51" r="2.8" fill="#01277b"></circle>
      <circle cx="73.8" cy="51" r="2.8" fill="#01277b"></circle>
    </svg>
  );
}

export default ProfilePicMan2;