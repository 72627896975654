import React from "react";

function Note() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      viewBox="0 0 512.002 512.002"
    >
      <path d="M180 151.001v-105c0-8.284 6.716-15 15-15s15 6.716 15 15v105zM135 451.001c-12.02 0-23.32-4.68-31.82-13.18A455.22 455.22 0 010 278.751v187.25c0 8.28 6.72 15 15 15h360c8.28 0 15-6.72 15-15v-15z"></path>
      <path d="M507.61 395.391C432.87 320.651 390 218.921 390 116.291v-10.29c0-8.28-6.72-15-15-15H240v75c0 8.28-6.72 15-15 15h-60c-8.28 0-15-6.72-15-15v-75H15c-8.28 0-15 6.72-15 15v10.29c0 115.58 46.77 222.7 124.39 300.32 2.82 2.81 6.63 4.39 10.61 4.39h362c6.07 0 11.54-3.65 13.86-9.26a15.01 15.01 0 00-3.25-16.35zM195 211.001h120c8.28 0 15 6.72 15 15s-6.72 15-15 15H195c-8.28 0-15-6.72-15-15s6.72-15 15-15zm120 150H165c-8.28 0-15-6.72-15-15s6.72-15 15-15h150c8.28 0 15 6.72 15 15s-6.72 15-15 15zm30-60H135c-8.28 0-15-6.72-15-15s6.72-15 15-15h210c8.28 0 15 6.72 15 15s-6.72 15-15 15z"></path>
    </svg>
  );
}

export default Note;