import React, { useEffect } from "react"
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import httpClient from "./httpClient"
import HomePageBrandOwner from "./pages/HomePageBrandOwner"
import { ToastContainer, toast } from "react-toastify"
import "./styling/ReactToastify.css"

import { NotificationPopup } from "./components/NotificationPopup"
import NotificationPopupMobile from "./components/mobile/NotificationPopupMobile"
import { PopupProvider } from "./components/mobile/context/NotificationPopupContext"

import { ProtectedRoute } from "./components/helpers/ProtectedRoute"
import { setIsMobile } from "./store/global"
import { addUserInfo, resetStateUser } from "./store/user"
import { addCustomerTypes } from "./store/user"
import { fetchMyTeam } from './store/userThunks'

import LoginPage from "./pages/LoginPage"
import HomePageSalesForce from "./pages/HomePageSalesForce"
import SalesPage from "./pages/SalesPage"
import SalesPageBrandOwner from "./pages/SalesPageBrandOwner"
import SalesforcePageBrandOwner from "./pages/SalesforcePageBrandOwner"
import CustomersPage from "./pages/CustomersPage"
import CustomerInfoPage from "./pages/CustomerInfoPage"
import SignupPage from "./pages/SignupPage"
import SignupLandingPage from "./pages/SignupLandingPage"
import ForgotPasswordPage from "./pages/ForgotPasswordPage"
import ResetPasswordPage from "./pages/ResetPasswordPage"
import SalesforcePageBrandOwnerPerformance from "./pages/SalesforcePageBrandOwnerPerformance"
import InvoicePage from "./pages/InvoicePage"
import HomePageAdmin from "./pages/HomePageAdmin"
import SalesPageAdmin from "./pages/SalesPageAdmin"
import AdminInvoicePage from "./pages/AdminInvoicePage"
import UsersPageAdmin from "./pages/UsersPageAdmin"
import InvoicesPage from "./pages/InvoicesPage"
import InventoryPageBrandowner from "./pages/InventoryPageBrandowner"
import SubscriptionPlansPage from "./pages/SubscriptionPlansPage"
import SalesforcePageBrandOwnerMyTeam from "./pages/SalesforcePageBrandOwnerMyTeam"
import SettingsPage from "./pages/SettingsPage"
import SalesPipePage from "./pages/SalesPipePage"
import ProductsPageBrandowner from "./pages/ProductsPageBrandowner"
import InventoryPageBrandownerLogs from "./pages/InventoryPageBrandownerLogs"
import InventoryPageSalesforce from "./pages/InventoryPageSalesforce"
import ActivitiesPage from "./pages/ActivitiesPage"
import HomePageBrandownerMobile from "./pages/mobile/HomePageBrandownerMobile"
import CustomerPageMobile from "./pages/mobile/CustomerPageMobile"
import ActivitiesPageMobile from "./pages/mobile/ActivitiesPageMobile"
import SalesPageBrandownerMobile from "./pages/mobile/SalesPageBrandownerMobile"
import SalesPipePageMobile from "./pages/mobile/SalesPipePageMobile"

function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  )
}

function App() {
  const dispatch = useDispatch()
  const userInfo = useSelector(state => state.user.userInfo)
  const userState = useSelector(state => state.user)
  const isMobile = useSelector(state => state.global.isMobile)
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    dispatch(setIsMobile(window.innerWidth < 768))

    const handleResize = () => {
      dispatch(setIsMobile(window.innerWidth < 768))
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [dispatch])

  useEffect(() => {

    const fetchUserData = async () => {
      if (!userInfo?.id) {
        return
      }

      if (
        // check if all user data is updated
        userState.customer_types
      ) {
        return
      }
      const response = await httpClient.get(`${process.env.REACT_APP_API_URL}/api/fetch/user-data`)
      if (response.status == 200) {
        const data = response.data
        dispatch(addCustomerTypes(data.customer_types))
      }
    }

    fetchUserData()
  }, [userInfo])

  useEffect(() => {

    const checkSession = async () => {
      try {
        const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/check-session")
        if (response.status === 200) {
          const data = response.data
          if (data.user) {
            dispatch(addUserInfo(data.user))
            // If user is on the login page, redirect to home
            if (location.pathname === '/') {
              redirectToHome(data.user)
            }
          } else {
            dispatch(resetStateUser())
            if (isProtectedRoute(location.pathname)) {
              navigate('/')
            }
          }
        } else {
          dispatch(resetStateUser())
          if (isProtectedRoute(location.pathname)) {
            navigate('/')
          }
        }
      } catch (error) {
        console.error("Session check error:", error)
        dispatch(resetStateUser())
        if (isProtectedRoute(location.pathname)) {
          navigate('/')
        }
      }
    }
    checkSession()
  }, [dispatch, location, navigate])

  useEffect(() => {
    dispatch(fetchMyTeam())
  }, [dispatch])

  const isProtectedRoute = (path) => {
    const protectedRoutes = [
      "/settings",
      "/brandowner/home",
      "/brandowner/inventory",
      "/brandowner/inventory/logs",
      "/brandowner/products",
      "/brandowner/sales",
      "/brandowner/salesforce",
      "/brandowner/salesforce/performance",
      "/brandowner/salesforce/myteam",
      "/brandowner/subscriptions",
      "/sales-pipe",
      "/salesforce/home",
      "/salesforce/inventory",
      "/sales",
      "/activities",
      "/customers",
      "/customer/:userID",
      "/invoice/:orderID",
      "/admin/home",
      "/admin/sales",
      "/admin/invoices",
      "/admin/invoice/:invoiceID",
      "/admin/users",
      "/m/home",
      "/m/customers",
      "/m/activities",
      "/m/brandowner/sales",
      "/m/sales-pipe"
    ]
    return protectedRoutes.includes(path)
  }

  const redirectToHome = (userInfo) => {
    let from = isMobile ? "/m/home" : "/home"

    if (userInfo.type === "admin") {
      from = isMobile ? "/m/home" : "/admin/home"
    } else if (userInfo.type === "brandowner" || (userInfo.type === "wholesaler" && userInfo.is_pro_account)) {
      from = isMobile ? "/m/home" : "/brandowner/home"
    } else if (userInfo.type === "salesforce") {
      from = isMobile ? "/m/home" : "/salesforce/home"
    } else {
      toast.error("Your Stoqup account is not authorized", { theme: "dark" })
      return
    }

    navigate(from)
  }

  return (
    <PopupProvider>
      <Routes>
        {/* Routes that do not require authentication */}
        <Route path="/" element={<LoginPage />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/email-verification/:userID" element={<SignupLandingPage />} />
        <Route path="/forgotpassword" element={<ForgotPasswordPage />} />
        <Route path="/reset/:token" element={<ResetPasswordPage />} />

        {/* Protected Routes */}
        <Route path="/settings" element={
          <ProtectedRoute isAuthenticated={userInfo ? !!userInfo.id : false}>
            <SettingsPage />
          </ProtectedRoute>
        } />
        <Route path="/brandowner/home" element={
          <ProtectedRoute isAuthenticated={userInfo ? !!userInfo.id : false}>
            <HomePageBrandOwner />
          </ProtectedRoute>
        } />
        <Route path="/brandowner/inventory" element={
          <ProtectedRoute isAuthenticated={userInfo ? !!userInfo.id : false}>
            <InventoryPageBrandowner />
          </ProtectedRoute>
        } />
        <Route path="/brandowner/inventory/logs" element={
          <ProtectedRoute isAuthenticated={userInfo ? !!userInfo.id : false}>
            <InventoryPageBrandownerLogs />
          </ProtectedRoute>
        } />
        <Route path="/brandowner/products" element={
          <ProtectedRoute isAuthenticated={userInfo ? !!userInfo.id : false}>
            <ProductsPageBrandowner />
          </ProtectedRoute>
        } />
        <Route path="/brandowner/sales" element={
          <ProtectedRoute isAuthenticated={userInfo ? !!userInfo.id : false}>
            <SalesPageBrandOwner />
          </ProtectedRoute>
        } />
        <Route path="/brandowner/salesforce" element={
          <ProtectedRoute isAuthenticated={userInfo ? !!userInfo.id : false}>
            <SalesforcePageBrandOwner />
          </ProtectedRoute>
        } />
        <Route path="/brandowner/salesforce/performance" element={
          <ProtectedRoute isAuthenticated={userInfo ? !!userInfo.id : false}>
            <SalesforcePageBrandOwnerPerformance />
          </ProtectedRoute>
        } />
        <Route path="/brandowner/salesforce/myteam" element={
          <ProtectedRoute isAuthenticated={userInfo ? !!userInfo.id : false}>
            <SalesforcePageBrandOwnerMyTeam />
          </ProtectedRoute>
        } />
        <Route path="/brandowner/subscriptions" element={
          <ProtectedRoute isAuthenticated={userInfo ? !!userInfo.id : false}>
            <SubscriptionPlansPage />
          </ProtectedRoute>
        } />
        <Route path="/sales-pipe" element={
          <ProtectedRoute isAuthenticated={userInfo ? !!userInfo.id : false}>
            <SalesPipePage />
          </ProtectedRoute>
        } />
        <Route path="/salesforce/home" element={
          <ProtectedRoute isAuthenticated={userInfo ? !!userInfo.id : false}>
            <HomePageSalesForce />
          </ProtectedRoute>
        } />
        <Route path="/salesforce/inventory" element={
          <ProtectedRoute isAuthenticated={userInfo ? !!userInfo.id : false}>
            <InventoryPageSalesforce />
          </ProtectedRoute>
        } />
        <Route path="/sales" element={
          <ProtectedRoute isAuthenticated={userInfo ? !!userInfo.id : false}>
            <SalesPage />
          </ProtectedRoute>
        } />
        <Route path="/activities" element={
          <ProtectedRoute isAuthenticated={userInfo ? !!userInfo.id : false}>
            <ActivitiesPage />
          </ProtectedRoute>
        } />
        <Route path="/customers" element={
          <ProtectedRoute isAuthenticated={userInfo ? !!userInfo.id : false}>
            <CustomersPage />
          </ProtectedRoute>
        } />
        <Route path="/customer/:userID" element={
          <ProtectedRoute isAuthenticated={userInfo ? !!userInfo.id : false}>
            <CustomerInfoPage />
          </ProtectedRoute>
        } />
        <Route path="/invoice/:orderID" element={
          <ProtectedRoute isAuthenticated={userInfo ? !!userInfo.id : false}>
            <InvoicePage />
          </ProtectedRoute>
        } />
        <Route path="/admin/home" element={
          <ProtectedRoute isAuthenticated={userInfo ? !!userInfo.id : false}>
            <HomePageAdmin />
          </ProtectedRoute>
        } />
        <Route path="/admin/sales" element={
          <ProtectedRoute isAuthenticated={userInfo ? !!userInfo.id : false}>
            <SalesPageAdmin />
          </ProtectedRoute>
        } />
        <Route path="/admin/invoices" element={
          <ProtectedRoute isAuthenticated={userInfo ? !!userInfo.id : false}>
            <InvoicesPage />
          </ProtectedRoute>
        } />
        <Route path="/admin/invoice/:invoiceID" element={
          <ProtectedRoute isAuthenticated={userInfo ? !!userInfo.id : false}>
            <AdminInvoicePage />
          </ProtectedRoute>
        } />
        <Route path="/admin/users" element={
          <ProtectedRoute isAuthenticated={userInfo ? !!userInfo.id : false}>
            <UsersPageAdmin />
          </ProtectedRoute>
        } />

        {/* Mobile routes */}
        <Route path="/m/home" element={
          <ProtectedRoute isAuthenticated={userInfo ? !!userInfo.id : false}>
            <HomePageBrandownerMobile />
          </ProtectedRoute>
        } />
        <Route path="/m/customers" element={
          <ProtectedRoute isAuthenticated={userInfo ? !!userInfo.id : false}>
            <CustomerPageMobile />
          </ProtectedRoute>
        } />
        <Route path="/m/activities" element={
          <ProtectedRoute isAuthenticated={userInfo ? !!userInfo.id : false}>
            <ActivitiesPageMobile />
          </ProtectedRoute>
        } />
        <Route path="/m/brandowner/sales" element={
          <ProtectedRoute isAuthenticated={userInfo ? !!userInfo.id : false}>
            <SalesPageBrandownerMobile />
          </ProtectedRoute>
        } />
        <Route path="/m/sales-pipe" element={
          <ProtectedRoute isAuthenticated={userInfo ? !!userInfo.id : false}>
            <SalesPipePageMobile />
          </ProtectedRoute>
        } />
      </Routes>

      <div className="App">
        {
          isMobile ?
            <>
              <NotificationPopupMobile />
            </> :
            <>
              <ToastContainer position="bottom-right" />
              <NotificationPopup />
            </>
        }
      </div>
    </PopupProvider>
  )
}

export default AppWrapper