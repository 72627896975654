import React, { useState, useEffect } from 'react'
import './TableCheckmarkRow.css'

function TableCheckmarkRow({ index, id, size, setSelectedRows, selectedRows }) {
  const [paddingTopValue, setPaddingTopValue] = useState("0px")
  const [paddingBottomValue, setPaddingBottomValue] = useState("0px")
  const [marginTopValue, setMarginTopValue] = useState("0px")
  const [marginBottomValue, setMarginBottomValue] = useState("0px")
  const [mouseOver, setMouseOver] = useState(false)
  const [clicked, setClicked] = useState(false)
  const [circleStyle, setcircleStyle] = useState({})

  TableCheckmarkRow.defaultProps = {
    size: 1
  }

  useEffect(() => {
    const thisCheckmarkElement = document.getElementById(`table-checkmark-row-${id}`)
    const parentElement = thisCheckmarkElement.parentElement
    const computedStyle = window.getComputedStyle(parentElement)
    setPaddingTopValue(computedStyle.getPropertyValue('padding-top'))
    setPaddingBottomValue(computedStyle.getPropertyValue('padding-bottom'))
    setMarginTopValue(computedStyle.getPropertyValue('margin-top'))
    setMarginBottomValue(computedStyle.getPropertyValue('margin-bottom'))
  }, [])

  useEffect(() => {
    setcircleStyle({height: `${15 * size}px`, width: `${15 * size}px`})
  }, [size])

  useEffect(() => {
    const thisCheckmarkElement = document.getElementById(`table-checkmark-row-${id}`)

    if (mouseOver) {
      thisCheckmarkElement.children[0].classList.add("hovered")
      thisCheckmarkElement.children[0].children[0].classList.add("hovered")
      thisCheckmarkElement.children[0].children[1].classList.add("hovered")

    } else {
      thisCheckmarkElement.children[0].classList.remove("hovered")
      thisCheckmarkElement.children[0].children[0].classList.remove("hovered")
      thisCheckmarkElement.children[0].children[1].classList.remove("hovered")

    }

  }, [mouseOver])

  const lineStyle = {
    height: `calc(100% + ${paddingTopValue} + ${paddingBottomValue} + ${marginTopValue} + ${marginBottomValue})`,
    top: `-${paddingTopValue}`
  }

  const handleOnclick = (event) => {
    event.stopPropagation()
    event.preventDefault()
    const thisCheckmarkElement = document.getElementById(`table-checkmark-row-${id}`)
    const thisCheckmarkRow = thisCheckmarkElement.parentElement

    if (selectedRows.includes(thisCheckmarkRow)) {
      setSelectedRows(prevSelectedRows => prevSelectedRows.filter(row => row !== thisCheckmarkRow))
      thisCheckmarkElement.children[0].classList.remove("selected")
    } else {
      setSelectedRows(prevSelectedRows => [...prevSelectedRows, thisCheckmarkRow])
      thisCheckmarkElement.children[0].classList.add("selected")
    }
  }


  return (
    <div className='table_checkmark_row__container'
      id={`table-checkmark-row-${id}`}
      onMouseOver={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
      onClick={handleOnclick}
    >
      <div className={`table_checkmark_row__circle`} style={circleStyle}>
        <div className="checkmark_stem"></div>
        <div className="checkmark_kick"></div>
      </div>
      <div className='table_checkmark_row__line' style={lineStyle}></div>
    </div>
  )
}

export default TableCheckmarkRow