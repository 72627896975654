import React, { useState, useEffect, useRef } from 'react'
import './DropdownMultiselect.css'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CloseIcon from '@mui/icons-material/Close'

function DropdownMultiselect({ fontStyle, state, marginTop, fontSize, setState, height, width, idList, index, placeholder, listArray }) {
  const [selectedValues, setSelectedValues] = useState(state)

  DropdownMultiselect.defaultProps = {
    marginTop: "5px",
    fontStyle: "italic",
    height: 40,
  }

  useEffect(() => {
    setSelectedValues(state)
  }, [state])

  const ref = useRef(null)

  const onClickOutside = () => {
    const dropdownList = document.querySelectorAll(`#${idList}`)

    Array.from(dropdownList).forEach(dropdown => {
      dropdown.classList.remove("active")
    })
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside()
      }
    }
    document.addEventListener("click", handleClickOutside, true)

    return () => {
      document.removeEventListener("click", handleClickOutside, true)
    }
  }, [onClickOutside])


  useEffect(() => {
    if (!listArray) {
      return
    }
    if (typeof listArray[0] === 'string' || typeof listArray[0] === 'number') {
      setState(selectedValues)
    }
  }, [selectedValues])

  const handleClickDropdown = (event) => {
    const dropdownList = event.currentTarget.nextSibling
    dropdownList.classList.toggle("active")
  }

  const onclickMenuItem = (event) => {

    if (typeof listArray[0] === 'object') {
      const itemID = event.currentTarget.getAttribute("data-index")
      const itemValue = event.currentTarget.childNodes[1].textContent

      const alreadySelected = selectedValues.some(item => item.name == itemValue) 

      if (alreadySelected == true) {
        return
      }

      setSelectedValues(prevArray => {
        return (
          [...prevArray, {
            "id": itemID,
            "name": itemValue
          }]
        )
      })
      setState != undefined && setState(itemValue, itemID)
    }

    // if (typeof listArray[0] === 'string' || typeof listArray[0] === 'number') {
    //   // setSelectedValues(event.currentTarget.textContent)
    // }
  }

  const deleteMenuItem = (event, isOwner) => {
    event.stopPropagation()

    if (isOwner) {
      return
    }

    const itemID = event.currentTarget.getAttribute("data-item-id")
    const itemValue = event.currentTarget.childNodes[0].textContent

    const newState = selectedValues.filter(value => value.id != itemID)
    setSelectedValues(newState)
    setState(itemValue, itemID, "remove")
  }

  if (!listArray) {
    return <p></p>
  }

  return (
    <div className="dropdown__container-dropdown multiselect flex--vert" id={`dropdown__${index}`} style={{ width, marginTop }} ref={ref} key={index}>
      <span className="input_field__input_box--text dropdown classic" style={{ height, marginTop }} onClick={handleClickDropdown}>
        {
          state != undefined &&
          selectedValues.map((value, index) => {
            return (
              <p className={`dropdown-input--text_field ${value.is_owner ? "gray": ""}`} onClick={(event) => deleteMenuItem(event, value.is_owner)} style={{ fontSize, fontStyle }} key={index} data-item-id={value.id}>
                {value.name}
                <CloseIcon />
              </p>
            )
          })
        }
        <KeyboardArrowDownIcon />
      </span>
      <ul className="input_field__dropdown classic" id={idList} key={index}>

        {
          typeof listArray[0] === 'object' && listArray[0] !== null && (
            listArray.map((item, index) => (
              <li className='dropdown__item' key={index} data-index={item.id} style={{ fontSize }} onClick={onclickMenuItem}>
                {
                  Object.entries(item).map((entry, index) => (
                    <div key={index}>
                      {entry[0] !== "id" && entry[1]}
                    </div>
                  ))
                }
                <div className='dropdown_checkmark'><CheckCircleIcon /></div>
              </li>
            ))
          )
        }

        {
          (typeof listArray[0] === 'string' || typeof listArray[0] === 'number') && (
            listArray.map((item, index) => {
              return (
                <li className='dropdown__item--single' key={index} style={{ fontSize }} onClick={onclickMenuItem}>
                  {item} <div className='dropdown_checkmark'><CheckCircleIcon /></div>
                </li>
              )
            })
          )
        }

      </ul>
    </div>
  )
}

export default DropdownMultiselect