import React, { useState, useEffect, useRef } from "react"
import httpClient from "../httpClient"
import { useSelector, useDispatch } from "react-redux"
import { addUserInfo } from "../store/user"
import "./HomePageAdmin.css"
import Header from "../components/Header"
import MenuBar from "../components/MenuBar"
import { askPermissionAndSubscribe } from "../components/helpers/ServiceWorker"

function HomePageAdmin() {
  const [user, setUser] = useState({ email: "", id: "" })
  const [error, setError] = useState(false)
  const [stats, setStats] = useState({
    "mrr": 0,
  })

  const dispatch = useDispatch()
  const userInfo = useSelector(state => state.user.userInfo)
  const previousUserIdRef = useRef()

  const fetchStats = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/admin/stats")

    if (response.status == 200) {
      setStats(response.data)
    }
  }

  useEffect(() => {
    fetchStats()
    document.getElementById("header-title").classList.remove("hide")
  }, [])

  useEffect(() => {
    if (userInfo.id && userInfo.id !== previousUserIdRef.current) {
      previousUserIdRef.current = userInfo.id
      askPermissionAndSubscribe(userInfo.id)
    }
  }, [userInfo])

  return (
    <div className="template">

      <Header />
      <MenuBar />
      <div className="template__body">
        <div className="body__home-admin">
          <div className="body__section" id="section1"></div>
          <div className="body__section flex" id="section2">
            <h2>MRR:</h2>
            <p className="number">€ {stats.mrr}</p>
          </div>
          <div className="body__section flex" id="section7">
            <h2>ARR:</h2>
            <p className="number">€ {stats.mrr * 12}</p>
          </div>
          <div className="body__section flex" id="section3">
            <h2>USERS:</h2>
            <p className="number">{stats.users}</p>
          </div>
          <div className="body__section" id="section4"></div>
          <div className="body__section" id="section5"></div>
          <div className="body__section" id="section6"></div>
        </div>
      </div>
    </div>
  )
}
export default HomePageAdmin