import React, {useState, useEffect} from 'react'
import './NotificationPopupMessage.css'
import CloseIcon from '@mui/icons-material/Close';
import { useSelector, useDispatch } from "react-redux"

export function showNotificationPopupMessage() {

    const overlay = document.getElementById('notification-popupMessage__container')
    const popupBox = document.getElementById('notification-popup')

    overlay.classList.add('active')
    popupBox.classList.add('active')
}

export function NotificationPopupMessage() {

    useEffect(() => {
        const popup = document.getElementById('notification-popup')
            window.addEventListener('keydown', e => {      
                if(e.key === 'Escape'){
                    if(popup.classList.contains("active")) {
                        closePopup()
                    }
                }
            })
    }, [])

    const closePopup = () => {
        const overlay = document.getElementById('notification-popupMessage__container')
        const popupBox = document.getElementById('notification-popup')
    
        overlay.classList.remove('active')
        popupBox.classList.remove('active')
    }

    const message = useSelector(state => state.notification)

    return (
        <div className='notification-popup__overlay' id='notification-popup'>
            <div className='notification-popup__container' id='notification-popupMessage__container'>
                <CloseIcon id="popup__close-icon" onClick={closePopup}/>
                <div className='notification-popup__message'>{message}</div>
            </div>
        </div>
    )
}